import React from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { eStorage } from './../../Services/StorageService';

function PrivateRoute(props) {
  const isSession = localStorage.getItem(eStorage.isSession);
  const user = localStorage.getItem(eStorage.user);
  let location = useLocation();
  const isValidUser = user && Object.keys(JSON.parse(user)).length > 0;
  const isAuth = isSession && isSession === 'true';

  if (!isAuth || !isValidUser) {
    if (location.pathname !== "/logout") {
      localStorage.setItem("redirectUrl", location.pathname + location.search);
    }
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}

export default PrivateRoute;