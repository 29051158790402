/* eslint-disable react-hooks/exhaustive-deps */

import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide, ButtonGroup, Button } from '@mui/material';
import { forwardRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalSelectAddress(props) {
    const { t } = useTranslation();

    useEffect(() => {

    }, []);

    const handleCancel = () => {
        props.setModal({ open: false, adresses: [] });
    }

    return (
        <Dialog open={props.modal.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'sm'} keepMounted onClose={handleCancel}>
            <DialogTitle className='text-center'>{t('modal.select-address')}</DialogTitle>
            <hr className='m-0' />
            <DialogContent>
                <div className='row mt-2 justify-content-md-center'>
                    <div className='col-md-10'>
                        <ButtonGroup orientation="vertical">
                            {props.modal.adresses.map((item, index) =>
                                <Button key={index} onClick={() => props.saveAddress(props.modal.adresses.addressSaved, item.PremiseType)} >{item.Address1}, {item.City} {item.State} /&nbsp;<strong>{item.PremiseType}</strong></Button>
                            )}
                        </ButtonGroup>
                    </div>
                </div>
            </DialogContent>
            <hr className='mb-0 mt-2' />
            <DialogActions className='justify-content-md-center'>
                <Button className='btn-secondary me-1' onClick={handleCancel}>{t('general.close')}</Button>
            </DialogActions>
        </Dialog>
    );
}