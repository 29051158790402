import { eAction, eCategory, eEvent, submitTrackingEvent } from "../../Services/EventService";
import { eStorage, getStorage, getStorageJSON, saveStorageJSON } from "../../Services/StorageService";
import { calcEngineApiInstance, dataServiceApiInstance } from "../../Util/Api";
import { eAccountType } from "../Shared/Enum/EAccountType";

export const getEnergyIntensityData = (customerId) => {
  const response = dataServiceApiInstance.get(`actionplan/energy_intensity?customerId=${customerId}`)
    .then(({ data }) => {
      return data;
    });
  return response;
}

export const getEnergyLeakageData = (viewType, customerId) => {
  let language = getStorage(eStorage.language);
  const response = dataServiceApiInstance.get(`/fingerprint/energy-leakage/${customerId}?lang=${language}&view=${viewType}`)
    .then(({ data }) => {
      return data;
    });
  return response;
}

export const getDisaggregationData = (viewType, customerId) => {
  let language = getStorage(eStorage.language);
  const response = dataServiceApiInstance.get(`/fingerprint/disaggregation/${customerId}?lang=${language}&view=${viewType}`)
    .then(({ data }) => {
      return data;
    });
  return response;
}

export const getFuelHeatingData = () => {
  const response = dataServiceApiInstance.get(`domain/fuel_heating`)
    .then(({ data }) => {
      return data.valueList;
    });
  return response;
}

export const getWaterHeatingData = () => {
  const response = dataServiceApiInstance.get(`domain/water_heating`)
    .then(({ data }) => {
      return data.valueList;
    });
  return response;
}

export const getWaterAgeData = () => {
  const response = dataServiceApiInstance.get(`domain/water_heater_age`)
    .then(({ data }) => {
      return data.valueList;
    });
  return response;
}

export const getLightData = () => {
  const response = dataServiceApiInstance.get(`domain/light_bulbs`)
    .then(({ data }) => {
      return data.valueList;
    });
  return response;
}

export const getHvacData = () => {
  const response = dataServiceApiInstance.get(`domain/hvac_system`)
    .then(({ data }) => {
      return data.valueList;
    });
  return response;
}

export const eBucket = {
  cooling: 'cooling',
  other: 'other',
  heater: 'waterHeater',
  heating: 'heating',
  lighting: 'lighting',
  refrigerator: 'refrigerator',
  pool: 'poolPump',
  tv: 'tv',
  laundry: 'laundry'
}

export const getColor = (bucket) => {
  switch (bucket) {
    case eBucket.cooling:
      return '#1D5676';
    case eBucket.other:
      return '#999999';
    case eBucket.heater:
      return '#E85D69';
    case eBucket.heating:
      return '#FFA536';
    case eBucket.lighting:
      return '#FCEE21';
    case eBucket.refrigerator:
      return '#573A80';
    case eBucket.laundry:
      return '#AD73FF';
    case eBucket.pool:
      return '#3DB3F5';
    case eBucket.tv:
      return '#4DDC74';
    default:
  }
}

export const postGeniusFingerprint = (model) => {

  let accountType = getStorage(eStorage.accountType);
  let lang = getStorage(eStorage.language);

  let id = '';
  let entity = '';
  let type = '';
  let url = '';

  if (accountType === eAccountType.commercial) {
    entity = getStorageJSON(eStorage.location);
    id = entity.id;
    type = entity.type ? entity.type.split('::')[0] : 'BASIC';
    url = 'V2/';
  } else {
    entity = getStorageJSON(eStorage.user);
    id = entity.customerId;
    type = entity.type ? entity.type.split('::')[0] : 'BASIC';
    url = '';
  }

  let path = type === 'BASIC' ? `${url}preferences/${id}?type=genius` : `${url}geniusFingerprint?lang=${lang}`;
  let axios = type === 'BASIC' ? dataServiceApiInstance : calcEngineApiInstance;

  const response = axios.post(path, model)
    .then(({ data }) => {
      let fingerprint = getStorageJSON(eStorage.fingerprint);

      if (fingerprint !== null) {
        fingerprint.domainValues = model.domainValues;
        fingerprint.selectedValues = model.selectedValues;
        fingerprint.level = 'genius';
        saveStorageJSON(eStorage.fingerprint, fingerprint);
        submitTrackingEvent(eEvent.efficiencyCompleted, eCategory.fingerprint, eAction.created);
      }
      return fingerprint;
    });
  return response;
}

export const changeCurrentPlanExpiration = (date) => {
  let fingerprint = getStorageJSON(eStorage.fingerprint);
  if (fingerprint !== null) {
    const data = {
      customerId: fingerprint.customerId,
      date
    }
    const response = dataServiceApiInstance.post(`customer/currentPlanExpirationDate?customerId=${fingerprint.customerId}&date=${date}`, data)
      .then((data) => {
        return data;
      });
    return response;
  }
  return null;
}
