/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { getStorageJSON } from "../../Services/StorageService";
import { eStorage } from './../../Services/StorageService';
import cost from '../../../src/Assets/img/EMM_Icons/MoneySavings_Icon.png';
import leakage from '../../../src/Assets/img/EMM_Icons/EnergyLeakage_Icon.png';
import carbonfp from '../../../src/Assets/img/EMM_Icons/EnvironmentImpact_Icon.png';
import energyused from '../../../src/Assets/img/EMM_Icons/EnergyUsed_Icon.png';
import { Switch } from '@mui/material';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ePage } from './../../Components/Shared/Routes';
import ModalCloseAccount from './ModalCloseAccount';

export default function CloseAccount(props) {

  const { t } = useTranslation();
  const [user] = useState(getStorageJSON(eStorage.user));
  const navigate = useNavigate();
  const [reason, setReason] = useState('');
  const [modal, setModal] = useState(false);
  const [elementsChecked, setElementsChecked] = useState(0);
  const [reasonsCheck, setReasonsCheck] = useState({
    emails: false,
    value: false,
    privacy: false,
    unwanted: false
  });

  useEffect(() => {
    props.functions.setTitle(t('closeAccount.closeAccount'));
  }, []);

  const handleExit = () => {
    navigate(ePage.profile);
  }

  const handleReason = (e) => {
    setReason(e.target.value);
  }

  const handleModal = () => {
    setModal(true);
  }

  const handleChangeCheck = (event) => {
    const { name, checked } = event.target;
    const check = checked ? elementsChecked + 1 : elementsChecked - 1;
    setElementsChecked(check);
    setReasonsCheck(prevState => ({
      ...prevState, [name]: checked
    }));
  }

  return (
    <div className='content'>
      <div className="row justify-content-md-center">
        <div className="col-md-10">
          <p className="description-text">{t('closeAccount.youSure')}</p>
        </div>
      </div>
      <h2>{t('closeAccount.dontloose')}</h2>
      <div className="row justify-content-md-center">
        <div className="col-md-10">
          <div className="container-card card-content">
            <div className="row">
              <div className='col-md-3' style={{ textAlign: 'center' }}>
                <img alt="" src={cost} style={{ 'width': '80px' }} />
                <h5 className="h5-center">{t('closeAccount.savings')}</h5>
              </div>
              <div className='col-md-3' style={{ textAlign: 'center' }}>
                <img alt="" src={energyused} style={{ 'width': '80px' }} />
                <h5 className="h5-center">{t('closeAccount.energySaved')}</h5>
              </div>
              <div className='col-md-3' style={{ textAlign: 'center' }}>
                <img alt="" src={carbonfp} style={{ 'width': '80px' }} />
                <h5 className="h5-center">{t('closeAccount.treesSaved')}</h5>
              </div>
              <div className='col-md-3' style={{ textAlign: 'center' }}>
                <img alt="" src={leakage} style={{ 'width': '80px' }} />
                <h5 className="h5-center">{t('closeAccount.leaks')}</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h2 className="mt-3">{t('closeAccount.telluswhy')}</h2>
      <p className="description-text">{t('closeAccount.reason')}</p>
      <div className="row justify-content-md-center">
        <div className="col-md-10">
          <div className="container-card card-content">
            <div className="row justify-content-md-center">
              <div className="col-md-5">
                <h3 className='text-start'>{t('closeAccount.tooManyEmails')}</h3>
              </div>
              <div className="col-md-5">
                <Switch name="emails" onChange={handleChangeCheck} />
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-md-5">
                <h3 className='text-start'>{t('closeAccount.noValue')}</h3>
              </div>
              <div className="col-md-5">
                <Switch name="value" onChange={handleChangeCheck} />
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-md-5">
                <h3 className='text-start'>{t('closeAccount.privacyConcern')}</h3>
              </div>
              <div className="col-md-5">
                <Switch name="privacy" onChange={handleChangeCheck} />
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-md-5">
                <h3 className='text-start'>{t('closeAccount.unwantedContact')}</h3>
              </div>
              <div className="col-md-5">
                <Switch name="unwanted" onChange={handleChangeCheck} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3 justify-content-md-center">
        <div className="col-md-10">
          <p className="description-text">{t('closeAccount.feedback')}</p>
          <textarea className="w-100" value={reason} onChange={event => handleReason(event)}></textarea>
        </div>
      </div>
      <div className="row mt-3 justify-content-md-center">
        <div className="col-md-10">
          <Button className="btn-primary" onClick={handleExit}>{t('closeAccount.exit')}</Button>&nbsp;&nbsp;&nbsp;&nbsp;
          <Button className='btn-primary' disabled={!reason || elementsChecked === 0 ? true : false} onClick={handleModal}  >{t('general.continue')}</Button>
        </div>
      </div>
      <ModalCloseAccount open={modal} setModalOpen={setModal} checks={reasonsCheck} reason={reason} />
    </div>
  );
}