/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import { getCarbonFootprint, getDisaggregationData, getEnergyIntensity, getPoints } from "../../Components/DashboardBase/DashboardBase";
import { eStorage, getStorageJSON } from "../../Services/StorageService";
import { Box, Tabs, Tab } from '@mui/material';
import { eLevel } from "../../Services/LevelService";
import ChartContainer from "./chartContainer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ePage } from "../../Components/Shared/Routes";
import ModalUpdatePhone from "../../Components/Shared/ModalUpdatePhone";
import { eDevice } from "../../Components/Shared/Enum/EDevice";
import { ePeriod } from './../../Components/DashboardBase/DashboardBase';
import { getCarbonData, getCarbonEqData } from "../../Components/SmartFingerprintBase/SmartFingerprintBase";
import { getDayNightIndexesData, getWeekWeekendIndexesData } from "../../Components/BasicFingerprintBase/BasicFingerprintBase";
import { getEnergyLeakageData } from "../../Components/GeniusFingerprintBase/GeniusFingerprintBase";
import { getFactorsData, getKpis } from "../../Components/EnergyScoreBase/HomeEnergyScoreBase";
import CardsUse from "./CardsUse";
import Savings from "./Savings";
import EnergyScore from "./EnergyScore";
import EnviromentalImpact from "./EnviromentalImpact";
import Factors from "./Factors";
import { TabContext } from "@mui/lab";
import Disaggregations from "./Disaggregation";
import thumbnail_image002 from '../../Assets/thumbnail_image002.png';
import MixedTooltip from "../shared/MixedTooltip";
import ChartUseBar from "./ChartUseBar";
import screenshotCompareImage from '../../Assets/Screenshot/screenshot-compare.png';
import Devices from "./Devices";
import { getDevices } from "../../Components/DeviceBase/DeviceBase";

function Dashboard(props) {
    const { t } = useTranslation();
    const [user] = useState(getStorageJSON(eStorage.user));
    const [fingerprint, setFingerprint] = useState(null);
    const [energyIntensity, setEnergyIntensity] = useState({});
    const [carbonFootprint, setCarbonFootprint] = useState({});
    const [periodData, setPeriodData] = useState({});
    const [level, setLevel] = useState('MEMBER');
    const [dayNightIndexes, setDayNightIndexes] = useState({});
    const [weekWeekendIndexes, setWeekWeekendIndexes] = useState({});
    const [viewType, setViewType] = useState('dollars');
    const [currentView, setCurrentView] = useState({});
    const [disaggregation, setDisaggregation] = useState({});
    const [address, setAddress] = useState({});
    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [periodValue, setPeriodValue] = useState(ePeriod.lastWeekVSPrevious);
    const [carbonEq, setCarbonEq] = useState({});
    const [carbon, setCarbon] = useState({});
    const [energyLeakage, setEnergyLeakage] = useState({});
    const [factors, setFactors] = useState({});
    const [value, setValue] = useState('general');
    const [comparison, setComparison] = useState({});
    const [periodName, setPeriodName] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        props.functions.setTitle('MY USAGE');

        if (props.functions.user.type !== undefined) {
            let userType = user.type.split('::')[0];
            if ((userType === 'BASIC'))
                navigate(ePage.selectPath, { replace: true });
            else
                getData();
        }

    }, [props.functions.user]);

    useEffect(() => {
        let tab = searchParams.get("tab");
        if (tab)
            setValue(tab);

        let address = getStorageJSON(eStorage.address);
        if (address !== null)
            setAddress(address);

        let fingerprint = getStorageJSON(eStorage.fingerprint);
        if (fingerprint !== null)
            setFingerprint(fingerprint);

    }, []);

    const getData = () => {

        props.functions.setIsLoading(true);
        getEnergyIntensity(user.customerId).then(data => {
            setEnergyIntensity(data);
        });

        getCarbonFootprint(user.customerId).then(data => {
            setCarbonFootprint(data);
        });

        getDisaggregationData(viewType, user.customerId).then(data => {
            setDisaggregation(data);
        });

        getPoints(user.customerId).then(data => {
            if (data >= 0 && data < 100)
                setLevel(eLevel.newSaver);
            if (data >= 100 && data < 250)
                setLevel(eLevel.bronze);
            if (data >= 250 && data < 500)
                setLevel(eLevel.silver);
            if (data >= 500 && data < 1000)
                setLevel(eLevel.gold);
            if (data >= 1000)
                setLevel(eLevel.platinum);

            props.functions.setPoints(data);
        });

        getCarbonEqData(user.customerId).then(data => {
            setCarbonEq(data);
            let type = user.type.split('::')[0];
            if (data === '' && type !== 'BASIC')
                getData();
        });

        getCarbonData(user.customerId).then(data => {
            setCarbon(data);
        });

        getDayNightIndexesData(viewType, user.customerId).then(data => {
            setDayNightIndexes(data);
        });

        getWeekWeekendIndexesData(viewType, user.customerId).then(data => {
            setWeekWeekendIndexes(data);
        });

        getEnergyLeakageData(viewType, user.customerId).then(data => {
            setEnergyLeakage(data);
        });

        getFactorsData(user.customerId).then(data => {
            if (data.status === 'FAILURE')
                getData();
            setFactors(data);
        }).finally(() => props.functions.setIsLoading(false))

        getKpis(user.customerId).then(data => {
            setComparison(data);
        });

        getDevices(user.customerId).then(data => {
            console.log(data);
            setDevices(data);
        });

        if (address?.phone === null)
            setModalOpen(true);
    }

    const handlePeriodSelect = (value) => {
        setCurrentView(JSON.parse(value));
    }

    const getEnergyLeakage = (viewType) => {
        props.functions.setIsLoading(true);
        getEnergyLeakageData(viewType, user.customerId).then(data => {
            setEnergyLeakage(data);
        });

        getDisaggregationData(viewType, user.customerId).then(data => {
            setDisaggregation(data);
            props.functions.setIsLoading(false);
        });
    }

    const handleChangeViewType = (value) => {
        setViewType(value);
        getEnergyLeakage(value);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="content">
            <ModalUpdatePhone modalOpen={modalOpen} />
            <div className='container-fluid'>
                <div className="row justify-content-center">
                    <div className="col-md-11">
                        <TabContext value={value}>
                            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                <div className="row">
                                    <div className="col-lg-9">
                                        <Tabs style={{ float: 'left' }} value={value} onChange={handleChange} centered>
                                            <Tab value={'general'} label='Consumption' />
                                            <Tab value={'detail'} label='Detail' />
                                            <Tab value={'mysmartdevice'} label='My smart Devices' />
                                        </Tabs>
                                    </div>
                                </div>
                            </Box>
                        </TabContext>
                    </div>
                </div>
                {value === 'general' && <>
                    <div className="row mt-4 justify-content-center">
                        <div className="col-md-5">
                            <ChartContainer
                                handlePeriodSelect={handlePeriodSelect}
                                setPeriodData={setPeriodData}
                                setCurrentView={setCurrentView}
                                setPeriodValue={setPeriodValue}
                                periodName={periodName}
                                setPeriodName={setPeriodName}
                                functions={props.functions} />
                        </div>
                        <div className="col-md-6 mt-md-0 mt-2">
                            <Savings currentView={currentView} functions={props.functions} periodName={periodName} />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-11">
                            <CardsUse dayNightIndexes={dayNightIndexes} weekWeekendIndexes={weekWeekendIndexes} />
                        </div>
                    </div>
                </>
                }
                {value === 'detail' && <>
                    <div className="row justify-content-center mt-4 mb-3">
                        <div className="col-lg-3">
                            <EnergyScore energyIntensity={energyIntensity} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            <div className="container-card card-content h-100">
                                {fingerprint === null && <img className="img-fluid" src={screenshotCompareImage}></img>}
                                {fingerprint !== null &&
                                    <>
                                        <h2 className="mt-md-4">{t('energy-score.comparing-others')}</h2>

                                        <div className="small-legend mt-1">
                                            {/* <Tooltip title={t('tooltips.energy-score-use').replace('#energyIntensity#', energyIntensity?.energyIntensity?.toFixed(2))}><img alt="" className="icon-info" src={infoImage} /></Tooltip> */}
                                        </div>
                                        <div className="row justify-content-center">
                                            <div className="col-5">
                                                <div className="dashboard-percentaje">
                                                    {(Math.abs(((energyIntensity?.energyIntensity - 7.82) / 7.82) * 100)).toFixed(0)}%
                                                </div>
                                                <div>
                                                    <div>
                                                        <small> <strong>{t('energy-score.you-use1')
                                                            .replace('#how#', energyIntensity?.energyIntensity < 7.82 ? t('general.lessCap') : t('general.moreCap'))}</strong></small>
                                                    </div>
                                                    <div>
                                                        <small>{t('energy-score.you-use2')}</small>
                                                        <MixedTooltip title={t('tooltips.energy-score-use').replace('#energyIntensity#', energyIntensity?.energyIntensity?.toFixed(2))} />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-3 pt-3">
                                                <img src={thumbnail_image002} alt="" />
                                            </div>
                                        </div>
                                        <div className="mb-1">

                                        </div>
                                        <span className="label-blue">{energyIntensity?.energyIntensity < 7.82 ? t('energy-score.energy-better') : t('energy-score.energy-not-good')}</span>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="col-md-5">
                            <EnviromentalImpact comparison={comparison} energyIntensity={energyIntensity} carbonEq={carbonEq} carbon={carbon} functions={props.functions} />
                        </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                        <div className="col-lg-6">
                            <Factors factors={factors} />
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-5">
                            <div className="container-card card-content h-100">
                                <h2 className="text-start title-section">{t('dashboard.consumption-detail')}</h2>

                                <div className="row justify-content-md-center">
                                    <div className="col-md-5">
                                        <Disaggregations viewType={viewType} disaggregation={disaggregation} />
                                    </div>
                                    <div className="col-md-6">
                                        {Object.keys(disaggregation).length > 0 && <ChartUseBar showLegend={true} viewType={viewType} chartData={disaggregation} />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                }
                {value === 'mysmartdevice' &&
                    <Devices devices={devices} />
                }

            </div>
        </div>
    )
}

export default Dashboard;