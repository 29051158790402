import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect } from 'react';
import * as Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';
import { getYearMonthName } from "../../../Util/Util";
import { eChart } from "../../../Components/Shared/Enum/EChart";
import { eMetric } from "../../../Components/Shared/Enum/EMetric";

function ChartMonth(props) {
    const { t } = useTranslation();

    useEffect(() => {

    }, []);

    function buildChart() {

        return {
            chart: {
                type: 'spline',
                height: '320px'
            },
            credits: { enabled: false },
            title: {
                text: null
            },
            xAxis: {
                type: 'category',
                categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13',
                    '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26',
                    '27', '28', '29', '30', '31']
            },
            yAxis: [
                {
                    offset: 2,
                    min: 0,
                    title: {
                        text: props.metric === eMetric.energy ? t('dashboard.energy-consumption') : props.metric === eMetric.carbon ? t('dashboard.carbon-emission') : t('dashboard.cost-dollar'),
                        x: -15,
                    },
                    labels: {
                        align: 'left'
                        , x: -20
                    }
                }
            ],
            tooltip: {
                shared: true
            },
            series: [{
                name: getYearMonthName(props?.periodData?.lastMonth_start),
                data: props?.periodData?.lastMonth,
                tooltip: {
                    pointFormat: '',
                }
            },
            {
                name: getYearMonthName(props.type === eChart.lastMonthVSPrevious ? props?.periodData?.previousMonth_start : props?.periodData?.previousYearMonth_start),
                data: props.type === eChart.lastMonthVSPrevious ? props?.periodData?.previousMonth : props?.periodData?.previousYearMonth,
                tooltip: {
                    pointFormat: '',
                },
                color: "rgb(3, 47, 74, 0.5)"
            },
            {
                name: 'Goal',
                data: (props.type === eChart.lastMonthVSPrevious ? props?.periodData?.previousMonth : props?.periodData?.previousYearMonth).map(x => x * ((props.metric === eMetric.energy ? (100 - props.goals?.energy) : props.metric === eMetric.carbon ? (100 - props.goals?.carbon) : (100 - props.goals?.bill)) / 100)),
                yAxis: 0,
                tooltip: {
                    pointFormat: '',
                },
                color: '#a7d036'
            }]
        }
    }

    return (
        <HighchartsReact highcharts={Highcharts} options={buildChart()} />
    )
}
export default ChartMonth;