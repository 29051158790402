/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useState } from 'react';
import { Dialog, Slide, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import thermostatImage from '../../Assets/Icons/thermostat.png';
import { postDevice } from '../../Components/DeviceBase/DeviceBase';
import { useNavigate } from "react-router-dom";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { eStorage, getStorageJSON } from '../../Services/StorageService';
import { getDevices } from "../../Components/DeviceBase/DeviceBase";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalSelectDevice(props) {
    const navigate = useNavigate();
    const [user] = useState(getStorageJSON(eStorage.user));
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        setDevices(props.devices);
        if (Array.isArray(props.devices) && props.devices.length > 0) {
            getDevices(user.customerId).then(data => {
                console.log(data, props.devices);
                if (Array.isArray(data) && data.length > 0) {
                    const filtered = props.devices.filter(f => data.find(o => o.apiId !== f.name));
                    setDevices(filtered);
                }
            });
        }
    }, [props.devices]);

    const handleClose = () => {
        props.setModal(false);
    };

    const handleConnectDevice = (device) => {
        let customer = getStorageJSON(eStorage.user);
        let model = {
            customerId: customer.customerId,
            deviceId: 'thermostat',
            apiId: device.name,
            name: device.parentRelations[0].displayName
        }

        postDevice(model).then(data => {
            Notify.success('The device was added successfully.', { position: 'center-bottom', });
            navigate('/my-usage?tab=mysmartdevice');
        }).catch(console.log);
    };

    return (
        <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'md'} keepMounted onClose={handleClose}>
            <DialogTitle className='text-center'>SELECT DEVICE TO CONNECT</DialogTitle>
            <hr className='m-0' />
            <DialogContent>
                <div className='row'>
                    {devices && devices.map((item, index) => (
                        <div key={index} className='col-md-3 text-center object-select' onClick={() => handleConnectDevice(item)}>
                            <img className="icon-savings" src={thermostatImage} alt="icon-savings" />
                            <h3>{item.parentRelations[0].displayName}</h3>
                        </div>
                    ))}
                </div>
            </DialogContent>
            <hr className='mb-0 mt-2' />
            <DialogActions className='justify-content-md-center'>
                <Button className='btn-secondary me-1' onClick={handleClose}>
                    CLOSE
                </Button>
            </DialogActions>
        </Dialog >
    );
}