/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { Button } from '@mui/material';
import Search from "./Search";
import { eFilter, eQuickFilter, getPlansData, getServiceArea } from "../../Components/BrowserBase/BrowserBase";
import PlanCard from "../../Components/Shared/PlanCard";
import { eStorage, getStorageJSON } from "../../Services/StorageService";
import { useSearchParams } from "react-router-dom";
import allFilterImage from '../../Assets/Icons/all-icon.png';
import allDisableFilterImage from '../../Assets/Icons/all-disable-icon.png';
import contractFilterImage from '../../Assets/Icons/contract-icon.png';
import contractDisableFilterImage from '../../Assets/Icons/contract-disable-icon.png';
import greenFilterImage from '../../Assets/Icons/green-icon.png';
import greenDisableFilterImage from '../../Assets/Icons/green-disable-icon.png';
import moneyFilterImage from '../../Assets/Icons/money-icon.png';
import moneyDisableFilterImage from '../../Assets/Icons/money-disable-icon.png';
import timeFilterImage from '../../Assets/Icons/time-icon.png';
import timeDisableFilterImage from '../../Assets/Icons/time-disable-icon.png';
import ModalServiceArea from "./ModalServiceArea";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { eDevice } from "../../Components/Shared/Enum/EDevice";

function Browser(props) {
    const addressStorage = getStorageJSON(eStorage.address);
    const { t } = useTranslation();
    const isFirstRender = useRef(true);
    const [searchParams] = useSearchParams();
    const [plans, setPlans] = useState([]);
    const [plansAll, setPlansAll] = useState([]);
    const [serviceArea, setServiceArea] = useState('');
    const [currPage, setCurrPage] = useState(1); // storing current page number
    const [wasLastList, setWasLastList] = useState(false); // setting a flag to know the last list
    const [zipCode, setZipCode] = useState(addressStorage && Object.keys(addressStorage).length > 0 ? addressStorage.zipCode : searchParams.get("zipCode") !== null ? searchParams.get("zipCode") : '');
    const [quickFilter, setQuickFilter] = useState(eQuickFilter.all);
    const formRef = useRef();
    const [filter, setFilter] = useState({
        use: '1000',
        rep: '',
        rating: '',
        contract: '',
        min: '',
        max: '',
        type: '',
        renewable: '',
        order: '',
        quick:''

    });

    const [filterString, setFilterString] = useState('&avg-kw=AVG1000');
    const [modal, setModal] = useState(false);
    const [serviceAreaList, setServiceAreaList] = useState([]);
    const [lastElement, setLastElement] = useState(null);
    const [handleLoad, setHandleLoad] = useState(false);
    const [use, setUse] = useState(1000);
    const [showSearch, setShowSearch] = useState(false);

    const observer = useRef(
        new IntersectionObserver((entries) => {
            const first = entries[0];
            if (first.isIntersecting) {
                const button = document.getElementById('nextButton');
                if (button) {
                    button.click();
                }
            }
        })
    );

    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    useEffect(() => {
        if (handleLoad && serviceArea)
            getPlans();
    }, [wasLastList, serviceArea, zipCode, currPage, filterString, handleLoad]);

    useEffect(() => {
        let reps = searchParams.get("reps");
        if (reps !== null) {
            reps = reps.split('.');
            setPlans(plansAll.filter(w => !reps.includes(w.rep)));
        }
    }, [plansAll]);

    useEffect(() => {
        if (props.functions.device !== eDevice.mobile && props.functions.device !== eDevice.tablet)
            setShowSearch(true);
        else
            setShowSearch(false);

        props.functions.setTitle(t('general.browser'));
        if (zipCode === '') {
            let zipCodeUrl = searchParams.get("zipCode");
            if (zipCodeUrl !== null) {
                setZipCode(zipCodeUrl);
            }
        }

        if (isFirstRender.current) {
            isFirstRender.current = false;
            document.getElementById('submit-btn').click();
            return; // 👈️ return early if first render
        }
    }, []);

    useEffect(() => {
        if (props.functions.device !== eDevice.mobile && props.functions.device !== eDevice.tablet)
            setShowSearch(true);
        else
            setShowSearch(false);
    }, [props.functions.device]);


    const getPlans = () => {
        if (!serviceArea || wasLastList) return;
        props.functions.setIsLoading(true);
        getPlansData(serviceArea, 10, currPage, filterString)
            .then(data => {
                if (!data.data || !data.data.length) {
                    setHandleLoad(false);
                    setWasLastList(true);
                    setCurrPage(1);
                    return;
                }
                setPlans([...plans, ...data.data]);
                setPlansAll([...plans, ...data.data]);
            })
            .catch(error => console.log(error))
            .finally(() => {
                props.functions.setIsLoading(false);
                setHandleLoad(false);
            })
    }

    const handleSubmit = () => {
        if (!zipCode) return;
        props.functions.setIsLoading(true);
        setCurrPage(1);
        setPlans([]);
        setWasLastList(false);
        setServiceArea('');
        setHandleLoad(false);
        getServiceArea(zipCode)
            .then(data => {
                if (Array.isArray(data) && data.length === 1) {
                    setServiceArea(data[0].uid);
                } else {
                    setServiceAreaList(data);
                    setModal(true);
                }
            })
            .catch(error => console.log(error))
            .finally(() => {
                props.functions.setIsLoading(false);
                setHandleLoad(true);
            })
    }

    const handleChangeZipCode = (event) => {
        setZipCode(event.target.value);
    }

    const handledIncreased = () => {
        setCurrPage(currPage + 1);
        setHandleLoad(true);
    }

    function handleFilterChange(evt) {
        const keys = Object.keys(evt);
        setFilter(evt);
        let params = '';
        let existQuick = false;
        keys.forEach(key => {
            if (evt[key]) {
                if (key.toLowerCase() === 'use') {
                    setUse(evt[key]);
                }
                params += `${eFilter[key]}${evt[key]}`;
            }
            existQuick = key === 'quick';
        });

        if (!existQuick) {
            params += `${eFilter['quick']}${quickFilter}`;
        }

        setFilterString(params);
        setPlans([]);
        setCurrPage(1);
        setWasLastList(false);
        setHandleLoad(true);
    }

    const handleQuickFilter = (QFilter) => {
        console.log('asd');
        setQuickFilter(QFilter);
        switch (QFilter) {
            case eQuickFilter.all:
                setPlans(plansAll);
                break;
            case eQuickFilter.long:
                setPlans(plansAll.filter(w => w.contractTerm === '36 Months' || w.contractTerm === '24 Months' || w.contractTerm === '12 Months'));
                break;
            case eQuickFilter.green:
                setPlans(plansAll.filter(w => w.renewable === 1));
                break;
            case eQuickFilter.noContract:
                setPlans(plansAll.filter(w => w.contractTerm === '1 Months' || w.repName === "Payless Power"));
                break;
            case eQuickFilter.poorCredit:
                setPlans(plansAll.filter(w => w.repName === "Payless Power"));
                break;
            default: break;
        }
        filter.quick = QFilter;
        handleFilterChange(filter);
    }

    const handleShowSearch = () => {
        setShowSearch(!showSearch);
    }

    return (
        <div className="content">
            <ModalServiceArea setModalOpen={setModal} open={modal} setServiceArea={setServiceArea} serviceareas={serviceAreaList} zipCode={zipCode} />
            {props.functions.device !== eDevice.mobile && <h1>{t('browser.subtitle')}</h1>}
            <div className="row justify-content-md-center">
                <div className="col-sm-12 col-md-8 col-lg-3">
                    <div className={(props.functions.device === eDevice.mobile || props.functions.device === eDevice.tablet) ? "container-card card-content search pt-1 pb-0" : "container-card card-content search"}>
                        <h2 onClick={() => handleShowSearch()}><FilterAltIcon></FilterAltIcon> {t('general.search-filter')} {!showSearch && <ExpandMoreIcon />} {showSearch && <ExpandLessIcon />} </h2>
                        {showSearch &&
                            <Search filter={filter} onFilterChange={handleFilterChange} />
                        }
                    </div>
                </div>
                <ValidatorForm ref={formRef} onSubmit={handleSubmit} onError={errors => console.log(errors)} className="col-md-12 col-lg-9">
                    <h2 className="mt-4 mt-lg-0">{t('browser.welcome-enter')}</h2>
                    <div className="row justify-content-md-center">
                        <div className="col-md-5 col-lg-3 ms-1 me-1">
                            <TextValidator fullWidth size="small" className="white" name="zipCode" value={zipCode} onChange={handleChangeZipCode} label={t('general.enter-zipcode')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.zipcode'))]} />
                        </div>
                        <div className={(props.functions.device === eDevice.mobile || props.functions.device === eDevice.tablet) ? "mt-2 mt-md-0 col-md-3 text-center" : "mt-2 mt-md-0 col-md-3 text-start"}>
                            <Button id='submit-btn' className='btn-primary' variant="outlined" type="submit">
                                {t('general.find')}
                            </Button>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <span onClick={() => handleQuickFilter(eQuickFilter.all)}><img className="quick-filter-icon" alt="" src={quickFilter === eQuickFilter.all ? allFilterImage : allDisableFilterImage} /> <span className="quick-filter">{t('browser.all-plans')}</span></span>
                            <span onClick={() => handleQuickFilter(eQuickFilter.long)}><img className="quick-filter-icon" alt="" src={quickFilter === eQuickFilter.long ? timeFilterImage : timeDisableFilterImage} /> <span className="quick-filter">{t('browser.long-term')}</span></span>
                            <span onClick={() => handleQuickFilter(eQuickFilter.green)}><img className="quick-filter-icon" alt="" src={quickFilter === eQuickFilter.green ? greenFilterImage : greenDisableFilterImage} /> <span className="quick-filter">{t('browser.green')}</span></span>
                            <span onClick={() => handleQuickFilter(eQuickFilter.noContract)}><img className="quick-filter-icon" alt="" src={quickFilter === eQuickFilter.noContract ? contractFilterImage : contractDisableFilterImage} /> <span className="quick-filter"> {t('browser.no-contract')}</span></span>
                            <span onClick={() => handleQuickFilter(eQuickFilter.poorCredit)}><img className="quick-filter-icon" alt="" src={quickFilter === eQuickFilter.poorCredit ? moneyFilterImage : moneyDisableFilterImage} /> <span className="quick-filter">{t('browser.poor-credit')}</span></span>
                        </div>
                    </div>
                    <hr />
                    {
                        plans && plans.length > 0 &&
                        <div className='row' >
                            {plans.map((item, index) => {
                                return index === plans.length - 1 && !props.functions.isLoading ? (
                                    <div key={index} className="col-md-6 mt-2" ref={setLastElement}>
                                        <PlanCard functions={props.functions} data={item} use={use} />
                                    </div>
                                ) : (
                                    <div key={index} className="col-md-6 mt-2">
                                        <PlanCard functions={props.functions} data={item} use={use} />
                                    </div>
                                );
                            })}
                        </div>
                    }
                    {
                        (!plans || plans.length === 0) &&
                        <div className='row' >
                            <h1>{t('general.noAvailablePlan')}</h1>
                        </div>
                    }
                </ValidatorForm>
            </div>
            <button className="hideButton" id="nextButton" onClick={handledIncreased}></button>
        </div>
    )
}

export default Browser;