import { Button, List, ListItem } from '@mui/material';
import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { eStorage, getStorageJSON, saveStorageJSON } from '../../Services/StorageService';

import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { useTranslation } from "react-i18next";
import { getRecommendationsData, postRecommendations } from '../../Components/RecommendationsBase/RecommendationsBase';

function Recommendations(props) {
    const [recommendations, setRecommendations] = useState([]);
    const [user] = useState(getStorageJSON(eStorage.user));
    const [maxRecommendations, setMaxRecommendations] = useState(3);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {

        /*  let criteria = getStorageJSON(eStorage.criteria);
          if (!criteria) {
              window.location = ePage.dashboard;
          }*/

        let fingerprint = getStorageJSON(eStorage.fingerprint);
        if (fingerprint !== null) {
            const storageRecommendations = getStorageJSON(eStorage.recommendations);
            if (storageRecommendations) {
                setRecommendations(storageRecommendations);
            }
            else
                getRecommendations(maxRecommendations);
        }

    }, []);

    const getRecommendations = (maxRecommendations) => {
        getRecommendationsData(user.customerId, maxRecommendations).then(data => {
            setRecommendations(data);
            saveStorageJSON(eStorage.recommendations, data);
        });
    }

    const handleChange = (id, action, category) => {
        postRecommendations(user.customerId, id, action, maxRecommendations).then(data => {
            if (data.status === 'SUCCESS' && action !== 'added')
                setRecommendations(data.results);

            if (action === 'added') {
                let updated = recommendations.map(item => {
                    if (item.category === category) {
                        return {
                            ...item, recommendations: item.recommendations.map(subItem => {
                                if (subItem.id === id) {
                                    return { ...subItem, status: subItem.status === 'new' ? 'added' : 'new' };
                                }
                            })
                        };
                    }
                    return item;
                });
                setRecommendations(updated);
                saveStorageJSON(eStorage.recommendations, updated);
            }
            else if (action === 'done') {
                props.setShowConfetti(true);
                Notify.success(t('recommendations.done-recommendation'), { position: 'center-bottom', });
                const interval = setInterval(() => {
                    props.setShowConfetti(false);
                    clearInterval(interval);
                }, 5000);
            } else if (action === 'skipped') {
                Notify.warning(t('recommendations.skipped'));
            }
        })
            .catch(error => console.log(error));
    }

    const handleChangeMore = () => {
        setMaxRecommendations(5);
        getRecommendations(5);
    }


    const handleNavigate = () => {
        navigate('/select-recommendation');
    };

    return (
        <List component="nav" sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <ListItem>
                <div className="row w-100">
                    <div className="col-md-6 text-start">
                        <p className="fw-bold description-text m-0 object-select" onClick={handleNavigate}>Set Preferences <EditIcon></EditIcon></p>
                    </div>
                    <div className="col-md-6 text-end">
                        <p className="fw-bold description-text m-0">Points</p>
                    </div>
                </div>
            </ListItem>

            {recommendations.map((row, index) => {
                return (
                    <div className='row mt-2 justify-content-md-center' key={index}>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                            <p className="text-start card-title">{row.category_description}</p>
                            {row.recommendations.map((subRow, subIndex) => (
                                <div className="row mt-2" key={subIndex}>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 col-lg-10">
                                            <p className="general-text text-start mb-0 mt-2">{subRow?.task}</p>
                                        </div>
                                        <div className="col-sm-12 col-md-1 col-lg-2">
                                            <p className="general-text text-center mb-0 mt-2">{subRow?.pointsCompletion}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-12 col-lg-12 mt-2 text-start">
                                            <Button className={subRow?.status === 'added' || subRow?.status === 'done' ? 'btn-primary-s me-1' : 'btn-tertiary-s me-1'} onClick={() => handleChange(subRow.id, 'added', row.category)}>{t('recommendations.lets-do')}</Button>
                                            <Button className='btn-tertiary-s me-1' onClick={() => handleChange(subRow?.id, 'skipped', row.category)}>{t('recommendations.maybe-later')}</Button>
                                            <Button className='btn-tertiary-s' onClick={() => handleChange(subRow?.id, 'done', row.category)}>{t('recommendations.done')}</Button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            })}
        </List>

    )
}

export default Recommendations;