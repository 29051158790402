import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import 'react-rater/lib/react-rater.css';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function ResponseInfo(props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [seeMore, setSeeMore] = useState(false);

    useEffect(() => {

    }, []);

    const goPayment = (url) => {
        if (url.includes('http'))
            window.open(url, "_blank");
        else
            navigate(url);
    }

    return (
        <div>
            <div className="row justify-content-md-center">
                <h2 className="mb-3">{props?.response.title}</h2>
                <div className="col-md-10">
                    <h3 dangerouslySetInnerHTML={{ __html: props?.response.description }}></h3>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-12">
                    {props?.response.buttonTwo !== '' && <Button className='btn-secondary me-2' component={Link} to={props?.response.buttonTwoUrl}>{props.response.buttonTwo}</Button>}
                    <Button className='btn-primary' onClick={() => goPayment(props?.response.buttonOneUrl)}>{props?.response.buttonOne}</Button>
                </div>
            </div>
            <div className="row mt-3 justify-content-md-center">
                <div className="col-md-10">
                    {props?.response?.more &&
                        <h3 className="mt-2 mb-3 click-here-blue-noline" onClick={() => setSeeMore(!seeMore)}>----- <strong className="click-here">{seeMore ? t('general.see-less-detail') : t('general.see-more-detail')}</strong> -----</h3>
                    }
                    {seeMore &&
                        <h3 className="text-justify" dangerouslySetInnerHTML={{ __html: props?.response.more }}></h3>
                    }
                </div>
            </div>
        </div>
    )
}

export default ResponseInfo;