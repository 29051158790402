/* eslint-disable jsx-a11y/anchor-is-valid */
import "bootstrap/dist/css/bootstrap.min.css";
import owlImage from '../../Assets/Icons/white_owl.png';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import { React } from 'react';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';

export default function SidebarCommercial(props) {
  const { t } = useTranslation();
  return <>
    <div className="col-auto">
      <div id="sidebar" className={props.menuOpen ? "sidebar show border-end" : "sidebar hide border-end"}>
        <div className="list-group border-0 rounded-0 text-sm-start min-vh-100">
          <Button onClick={() => props.setMenuOpen(false)} component={Link} to="/dashboard" className="list-group-item first border-end-0 d-inline-block text-truncate text-capitalize"><img src={owlImage} alt="icon" /> <span>{t('sidebar.dashboard')}</span> </Button>
          <ul className="submenu nav flex-column ms-2 my-2">
            <li className="w-100">
              <FmdGoodOutlinedIcon sx={{ fontSize: 35 }} htmlColor="white" />
              <Button onClick={() => props.setMenuOpen(false)} component={Link} to="/locations" className="nav-link-img px-0"><span className="d-sm-inline">{t('sidebar.locations')}</span></Button>
            </li>
          </ul>
          <ul className="submenu nav flex-column ms-2 my-2">
            <li className="w-100">
              <TodayOutlinedIcon sx={{ fontSize: 35 }} htmlColor="white" />
              <Button onClick={() => props.setMenuOpen(false)} component={Link} to="/fingerprint-data" className="nav-link-img px-0"><span className="d-sm-inline">{t('sidebar.building-feature')}</span></Button>
            </li>
          </ul>
          <ul className="submenu nav flex-column ms-2 my-2">
            <li className="w-100">
              <StorefrontOutlinedIcon sx={{ fontSize: 35 }} htmlColor="white" />
              <Button onClick={() => props.setMenuOpen(false)} component={Link} to="/electrical-service-request" className="nav-link-img px-0"><span className="d-sm-inline">{t('sidebar.shop-electricity')}</span></Button>
            </li>
          </ul>
          <ul className="submenu nav flex-column ms-2 my-2">
            <li>
              <SupervisorAccountOutlinedIcon sx={{ fontSize: 35 }} htmlColor="white" />
              <IconButton onClick={() => props.setMenuOpen(false)} component={Link} to="/users" className="nav-link-img px-0">
                <span className="d-sm-inline">{t('sidebar.users')}</span></IconButton>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </>
}