/* eslint-disable eqeqeq */
import { useTranslation } from "react-i18next";
import { React } from 'react';
import * as Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';

function ChartUseBar(props) {
    const { t } = useTranslation();

    function buildChart() {

        const total = props.chartData.yearly.cooling + props.chartData.yearly.heating + props.chartData.yearly.lighting + props.chartData.yearly.poolPump + props.chartData.yearly.refrigerator +
            props.chartData.yearly.water_heater + props.chartData.yearly.tv + props.chartData.yearly.laundry + props.chartData.yearly.other;

        const titletext = props.viewType == 'dollars' ? '$' + Math.round(total).toLocaleString('en-US', { maximumFractionDigits: 0 }) + '<br/>' + t('general.per-year') : Math.round(total).toLocaleString('en-US', { maximumFractionDigits: 0 }) + ' kWh<br/>' + t('general.per-year')

        return {
            chart: {
                x: -50,
                type: 'column',
                height: '400px'
            },
            credits: { enabled: false },
            title: {
                text: titletext,
                style: {
                    fontSize: props.showLegend ? '13px' : '18px'
                }
            },
            xAxis: {
                categories: ['Consumption']
            },
            legend: {
                enabled: false
            },
            yAxis: {
                title: {
                    enabled: false
                },
                labels: {
                    enabled: false
                }
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                series: {
                    stacking: 'normal'
                },
                column: {
                    dataLabels: {
                        enabled: true,
                        distance: -50,
                        formatter: function () {
                            var dlabel = this.series.name + ' ';
                            // dlabel += Math.round(this.percentage * 100) / 100 + ' %';
                            return dlabel
                        },
                        x: 50,
                        align: 'left',
                        style: {
                            color: 'black',
                            fontWeight: 'normal'
                        }
                    }
                },
            },
            series: [{
                name: t('genius-fingerprint.air-conditioning'),
                data: [props.chartData.yearly.cooling],
                color: '#1D5676',
                pointWidth: 50,
            },
            {
                name: t('genius-fingerprint.space-heater'),
                data: [props.chartData.yearly.heating,],
                color: '#FFA536',
                pointWidth: 50,
            },
            {
                name: t('genius-fingerprint.water-heater'),
                data: [props.chartData.yearly.water_heater],
                color: '#E85D69',
                pointWidth: 50,
            },
            {
                name: t('genius-fingerprint.pool'),
                data: [props.chartData.yearly.poolPump],
                color: '#3DB3F5',
                pointWidth: 50,
            },
            {
                name: t('genius-fingerprint.lights'),
                data: [props.chartData.yearly.lighting],
                color: '#FCEE21',
                pointWidth: 50,
            },
            {
                name: t('genius-fingerprint.refrigerators'),
                data: [props.chartData.yearly.refrigerator],
                color: '#573A80',
                pointWidth: 50,
            },
            {
                name: t('genius-fingerprint.tv-electronnics'),
                data: [props.chartData.yearly.tv],
                color: '#4DDC74',
                pointWidth: 50,
            },
            {
                name: t('genius-fingerprint.laundry'),
                data: [props.chartData.yearly.laundry],
                color: '#AD73FF',
                pointWidth: 50,
            },
            {
                name: t('genius-fingerprint.other'),
                data: [props.chartData.yearly.other],
                color: '#999999',
                pointWidth: 50,
            }]
        }

    }

    return (
        <HighchartsReact  allowChartUpdate={false} highcharts={Highcharts} options={buildChart()} />
    )
}
export default ChartUseBar;