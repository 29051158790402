/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Detail from "./Detail";
import Privacy from "./Privacy";
import Communications from "./Communications";
import Account from "./Account";
import { eStorage, getStorageJSON } from "../../Services/StorageService";
import Addresses from "./Addresses";
import { useSearchParams } from "react-router-dom";
import SubscriptionManagement from "./SubscriptionManagement";

function Profile(props) {
    const { t } = useTranslation();
    const [tab, setTab] = useState('1');
    const [user] = useState(getStorageJSON(eStorage.user));
    const [searchParams] = useSearchParams();
    const [openModalAddress, setOpenModalAddress] = useState(false);

    useEffect(() => {
        props.functions.setTitle(t('general.configuration'));
        let tab = searchParams.get("tab");
        if (tab === 'address'){
            setTab('2');
            setOpenModalAddress(true);
        }
    }, []);

    const handleChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <div className='content'>

            <h1>{user.name} {user.lasname} {t('profile.boss')}</h1>
            <div className="row justify-content-md-center">
                <div className="col-sm-12 col-md-12 col-lg-8">
                    <div className="container-card card-content">
                        <TabContext value={tab}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label={t('profile.profile')} value="1" />
                                    <Tab label={t('profile.addresses')} value="2" />
                                    <Tab label={t('profile.privacy')} value="3" />
                                    <Tab label={t('profile.communications')} value="4" />
                                    <Tab label={t('profile.account')} value="5" />
                                    <Tab label={t('profile.subscription')} value="6" />
                                </TabList>
                            </Box>
                            <TabPanel value="1"><Detail functions={props.functions} /></TabPanel>
                            <TabPanel value="2"><Addresses functions={props.functions} openModal={openModalAddress} /></TabPanel>
                            <TabPanel value="3"><Privacy/></TabPanel>
                            <TabPanel value="4"><Communications/></TabPanel>
                            <TabPanel value="5"><Account/></TabPanel>
                            <TabPanel value="6"><SubscriptionManagement functions={props.functions}/></TabPanel>
                        </TabContext>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;