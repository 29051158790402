import { eAction, eCategory, eEvent, submitTrackingEvent } from "../../Services/EventService";
import { addPropertyStorage, eStorage, getStorage, saveRegisterStorage, saveStorage, saveStorageJSON } from "../../Services/StorageService";
import { dataServiceApiInstance, orchestratorApiInstance } from "../../Util/Api";
import { eAccountType } from "../Shared/Enum/EAccountType";

export const isMailAvailable = (email) => {
    let emailFormat = encodeURIComponent(email.toLowerCase());
    const response = orchestratorApiInstance.post(`auth/isMailAvailable/${emailFormat}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const isPhoneAvailable = (phone) => {
    let phoneFormat = encodeURIComponent(phone.replace('+', '').toLowerCase());
    const response = orchestratorApiInstance.post(`auth/isPhoneAvailable/${phoneFormat}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const postRegisterBasic = (model) => {
    let accountType = getStorage(eStorage.accountType);
    let apiVersion = '';

    if (accountType === eAccountType.commercial)
        apiVersion = 'V2/';
    else{
        apiVersion = '';
        delete model.role;
    }

    model.email = model.email.toLowerCase();
    const response = orchestratorApiInstance.post(apiVersion + `smt2/signupBaseCustomer`, model)
        .then(({ data }) => {
            if (data.status === 'Success') {
                saveRegisterStorage(data, model);
                submitTrackingEvent(eEvent.userCreated, eCategory.account, eAction.created);
            }
            return data;
        });
    return response;
}

export const getEsiid = (model) => {
    let search = "zipCode=" + model.address.zipCode + "&address=" + model.address.address;
    if (model.address.houseType !== 'apto' && model.address.houseType !== 'townhouse')
        model.address.apto = '';

    if (model.address.housingType === 'apto')
        search = search + " %23" + model.address.apto;
    else
        search = search + " " + model.address.apto;

    const response = dataServiceApiInstance.get(`esiids/getEsiid?${search}`)
        .then(({ data }) => {
            saveStorageJSON(eStorage.address, model.address);
            if (data.length === 1) {
                addPropertyStorage(eStorage.user, 'esiid', data[0].Esiid);
                addPropertyStorage(eStorage.user, 'phone', model.phone);
                model.address.serviceArea = data[0].TDSPUid;
                saveStorageJSON(eStorage.address, model.address);
            } else if (data.length > 1) {
                addPropertyStorage(eStorage.user, 'esiid', data[0].Esiid);
                addPropertyStorage(eStorage.user, 'phone', model.phone);
                model.address.serviceArea = data[0].TDSPUid;
                saveStorageJSON(eStorage.address, model.address);
            }
            data.addressSaved = model.address;
            return data;
        });
    return response;
}


export const postLocation = (model) => {
    model.email = model.email.toLowerCase();
    const response = orchestratorApiInstance.post(`V2/smt2/location`, model)
        .then(({ data }) => {
            if (data.status === 'Success') {
                let location = {
                    id: data.locationId,
                    site: model.site,
                    esiid: model.esiid,
                    meterNumber: model.meterNumber,
                    rep: model.rep,
                    houseType: model.houseType,
                    city: model.city,
                    address: model.address,
                    apto: model.apto,
                    zipCode: model.zipCode,
                    state: model.state,
                    moving: model.moving,
                    serviceArea: model.serviceArea,
                    isCommercial: model.isCommercial,
                    name: model.locationName,
                    latitude: model.latitude,
                    longitude: model.longitude,
                    type: data.locationType
                }
                saveStorage(eStorage.requestId, data.requestId);
                saveStorageJSON(eStorage.location, location);
            }
            return data;
        });
    return response;
}

export const postRegisterUser = (model) => {
    model.email = model.email.toLowerCase();
    const response = orchestratorApiInstance.post(`smt2/registeruser`, model)
        .then(({ data }) => {
            if (data.status === 'Success') {
                addPropertyStorage(eStorage.user, 'type', data.userType);
            }
            return data;
        });
    return response;
}

export const postMoveOut = (model) => {
    model.email = model.email.toLowerCase();
    const response = orchestratorApiInstance.post(`V2/smt2/moveOut`, model)
        .then(({ data }) => {
            if (data.status === 'Success') {
                addPropertyStorage(eStorage.user, 'type', data.userType);
            }
            return data;
        });
    return response;
}

export const postAddress = (model) => {
    const response = orchestratorApiInstance.post(`smt2/registeraddress`, model)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getRepsData = () => {
    const response = dataServiceApiInstance.get(`reps`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const validateReferrer = (code) => {
    const response = orchestratorApiInstance.post(`V2/smt2/validateVoucher/${code}`, { referrer: code })
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getAddressEsiid = (esiid) =>{
    const response = dataServiceApiInstance.post(`esiids/getAddress?esiid=${esiid}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}