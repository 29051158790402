/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import React from 'react';
import { Paper, IconButton, InputBase, Collapse, Typography, Avatar, styled, CardActions, CardContent, CardMedia, CardHeader, Card, ListItem, ListItemText, ListItemAvatar } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SendIcon from '@mui/icons-material/Send';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { red } from '@mui/material/colors';
import { deleteLike, getCommentsData, postComment, postLike } from '../../Components/HomeBase/HomeBase';
import { eStorage, getStorageJSON } from '../../Services/StorageService';
import ModalBadge from '../../Components/Shared/ModalBadges';
import { useTranslation } from "react-i18next";

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

function Publication(props) {
    const [expanded, setExpanded] = useState(false);
    const [like, setLike] = useState(false);
    const [text, setText] = useState('');
    const [comments, setComments] = useState([]);
    const [modal, setModal] = useState({ open: false });
    const { t } = useTranslation();

    useEffect(() => {
        setLike(props.publication.ilike);
    }, []);

    const handleExpandClick = () => {
        setExpanded(!expanded);
        getComments();
    };

    const getComments = () => {
        getCommentsData(props.publication.customerBadgeId).then(data => {
            setComments(data);
        });
    }

    const handleComment = () => {
        let customer = getStorageJSON(eStorage.user);
        let model = {
            type: 'BADGE',
            customerId: customer.customerId,
            itemId: props.publication.customerBadgeId,
            text: text
        }

        postComment(model).then(data => {
            getComments();
            setExpanded(true);
            setText('');
        });
    }

    const handleLike = () => {
        setLike(!like);
        let customer = getStorageJSON(eStorage.user);
        let model = {
            type: 'BADGE',
            customerId: customer.customerId,
            itemId: props.publication.customerBadgeId
        }

        if (!like)
            postLike(model).then(data => {
                console.log(data);
            });
        else
            deleteLike(model).then(data => {
                console.log(data);
            });
    };

    const handleChange = (event) => {
        const { value } = event.target;
        setText(value);
    }

    const handleViewDetail = () => {
        setModal({ open: true, badge: props.publication, isObtained: true });
    }

    return (
        <Card className="mt-3">
            <ModalBadge modal={modal} setModal={setModal} />
            <CardHeader avatar={
                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    {props.publication.customerName === ' ' ? 'T' : props.publication.customerName[0]}
                </Avatar>}
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                }
                title={t('home.new-achievement') + " " + (props.publication.customerName === ' ' ? 'Test' : props.publication.customerName)}
                subheader={new Date(props.publication.obtained).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
            />
            <p>{t('home.i-earned')} <strong>{props.publication.badge.replace('-', ' ').replace('_', ' ').toUpperCase()}</strong> {t('home.badge-m')} </p>
            <CardContent className="content-publish">
                <CardMedia
                    className="img-publish"
                    component="img"
                    image={props.publication.icon}
                    alt="Badge"
                />
            </CardContent>
            <p className="description-text ps-4 pe-4">{props.publication.description.en}</p>
            <CardActions className='b-comment'>
                <Paper sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <IconButton sx={{ p: '10px' }} aria-label="like" onClick={handleLike}>
                        <FavoriteIcon style={{ color: like ? 'red' : '' }} />
                    </IconButton>
                    <IconButton sx={{ p: '10px' }} aria-label="share" onClick={handleViewDetail}>
                        <ShareIcon />
                    </IconButton>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder={t('home.comment')}
                        value={text}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'comment' }}
                    />
                    {text.trim().length !== 0 &&
                        <IconButton sx={{ p: '10px' }} aria-label="send" onClick={handleComment}>
                            <SendIcon />
                        </IconButton>
                    }
                </Paper>
                <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            {props.publication.countComments > 1 && !expanded &&
                <CardContent className='pt-0 pb-2'>
                    <ListItem className='pb-0 pt-0' alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar alt={props.publication.firstCommentCustomer} src="/static/images/avatar/3.jpg" />
                        </ListItemAvatar>
                        <ListItemText
                            primary={props.publication.firstCommentCustomer}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                    </Typography>
                                    {props.publication.firstComment}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                    <hr className='divider-comment' />
                    <h3 className='text-start ps-4 pt-1' onClick={handleExpandClick}><strong className="click-here">
                    {t('home.see')} {props.publication.countComments - 1} {t('home.more-comment')}
                    </strong></h3>
                </CardContent>
            }
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent className='pt-0 pb-2'>
                    {comments.map((value, index) => {
                        return (
                            <>
                                <ListItem className='pb-0 pt-0' alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt={value.name} src="/static/images/avatar/3.jpg" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={value.name}
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                </Typography>
                                                {value.text}
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                {index !== (comments.length - 1) &&
                                    <hr className='divider-comment' />
                                }
                            </>
                        )
                    })}
                </CardContent>
            </Collapse>
        </Card>
    )
}

export default Publication;