import { Button, ListItem, ListItemAvatar, Avatar, Divider, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { postCommentSuscribe } from '../../Components/ChallengesBase/ChallengesBase';
import { eStorage, getStorageJSON } from '../../Services/StorageService';
import { useNavigate } from 'react-router-dom';
import ModalChallenge from './ModalChallenge';
import { useState } from 'react';

function ChallengeActive(props) {
    const navigate = useNavigate();
    const [modalChallenge, setModalChallenge] = useState(false);

    const handleSuscribed = () => {
        let customer = getStorageJSON(eStorage.user);
        let model = {
            challengeId: props.item.id,
            customerId: customer.customerId
        }

        postCommentSuscribe(model).then(data => {
            Notify.success('You have successfully subscribed to the challenge.', { position: 'center-bottom', });
            navigate('/challenge/' + props.item.id + '/' + data.id);
        });
    };

    const handleOpenModal = () => {
        setModalChallenge(true);
    }

    return (
        <>
            <ModalChallenge challenge={props.item} setModal={setModalChallenge} modalOpen={modalChallenge}></ModalChallenge>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <EmojiEventsIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={props.item.name} secondary={new Date(props.item.created).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} />
                {props.item.suscribed === 'notsubscribed' ?
                    <Button className={'btn-primary-s me-1'} onClick={handleOpenModal}>Detail</Button> :
                    props.item.suscribed === 'suscribed' ?
                        <Button className={'btn-blue-s me-1'} component={Link} to={'/challenge/' + props.item.id + '/' + props.item.customerChallengeId}>Suscribed</Button> :
                        props.item.suscribed === 'completed' ?
                            <Button className={'btn-blue-s me-1'} component={Link} to={'/challenge/' + props.item.id + '/' + props.item.customerChallengeId}>View</Button> :
                            null
                }
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
    )
}

export default ChallengeActive;