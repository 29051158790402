
import { useEffect, React, useState } from 'react';
import useErrorBoundary from "use-error-boundary";
import { useTranslation } from "react-i18next";
import oops from '../../Assets/img/EMM_Icons/error.png';
import { eStorage, getStorageJSON } from '../../Services/StorageService';
import { saveLogInServer } from '../../Components/LogBase/LogBase';

export default function ErrorBoundaryApp(props) {

  const {
    ErrorBoundary,
    didCatch,
    error
  } = useErrorBoundary();

  const { t } = useTranslation();
  const [user] = useState(getStorageJSON(eStorage.user));

  useEffect(()=>{
  },[]);

  useErrorBoundary({
    onDidCatch: (error, errorInfo) => {
      console.log(error);
      logErrorToMyService(error, errorInfo);
    },
  })

  const logErrorToMyService = (error, errorInfo) => {
    fetch('https://api.ipify.org?format=json')
      .then(res => res.json())
      .then(json => {
        const postData = {
          error: error,
          info: errorInfo,
          customer: user.customerId,
          lastPage: window.location.href,
          context: localStorage,
          url: window.location.href,
          browser: {
            browserName: '',
            deviceType: '',
            osVersion: '',
            clientIp: json.ip
          }
        }
        Promise.resolve(saveLogInServer(postData));
      }).finally(()=>{
        window.location = '/';
      });
  }

  const redirect = () => {
    logErrorToMyService(error, error);
  }

  return (
    <>
      {didCatch ? (
        <div>
          <div style={{ textAlign: 'center', paddingTop: '20px' }}>
            <img style={{ margin: 'auto', width: '40%' }} src={oops} alt=""></img>
          </div>
          <div style={{ textAlign: 'center', margin: '10px' }}>
            <h1>{t("general.errorL1")}</h1>
            <h3>{t("general.errorL2")}</h3>
          </div>
          <div className="row" style={{ textAlign: 'center' }}>
            <button className="btn btn-primary" style={{ width: "30%", margin:'auto' }} onClick={redirect}>{t('general.goToDashboard')}</button>
          </div>
        </div>
      ) : (
        <ErrorBoundary>
            {props.children}
        </ErrorBoundary>
      )}
    </>
  )
}
