import React, { useState } from 'react';

const NumberControl = ({ value, onChange, min, max, step }) => {
  const handleIncrement = () => {
    onChange(value + step <= max ? value + step : max);
  };

  const handleDecrement = () => {
    onChange(value - step >= min ? value - step : min);
  };

  return (
    <div className="number-control">
      <button onClick={handleDecrement}>-</button>
      <input
        type="number"
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={(e) => onChange(parseInt(e.target.value))}
      />
      <button onClick={handleIncrement}>+</button>
    </div>
  );
};

export default NumberControl;