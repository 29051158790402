import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import { formatCVC, formatCreditCardNumber, formatExpirationDate } from "../../Util/Util";
import StripeImage from '../../Assets/stripe-powered.svg';

function CreditCard(props) {
    const { t } = useTranslation();
    const [focus, setFocus] = useState('');

    const handleInputFocus = (evt) => {
        setFocus((prev) => (evt.target.name));
    }

    const handleChange = (evt) => {
        let { name, value } = evt.target;

        if (name === "cardNumber") {
            value = formatCreditCardNumber(value);
        } else if (name === "expiry") {
            value = formatExpirationDate(value);
        } else if (name === "cvc") {
            value = formatCVC(value);
        }

        props.setPayment((prev) => ({ ...prev, [name]: value }));
    }

    return (
        <div className="row  mt-2 justify-content-md-center">
            <div className="col-md-5">
                <Cards
                    number={props.payment.cardNumber}
                    expiry={props.payment.expiry}
                    cvc={props.payment.cvc}
                    name={props.payment.cardName}
                    focused={focus}
                />
            </div>
            <div className="col-md-5">
                <div className="row mt-2 justify-content-md-center">
                    <div className="col-md-12 mt-2">
                        <TextValidator fullWidth size="small" name="cardName" value={props.payment.cardName} onChange={handleChange} onFocus={handleInputFocus} label={t('general.card-name')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.card-name'))]} />
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-12 mt-2">
                        <TextValidator fullWidth size="small" name="cardNumber" value={props.payment.cardNumber} onChange={handleChange} onFocus={handleInputFocus} label={t('general.card-number')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.card-number'))]} />
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6 mt-2">
                                <TextValidator fullWidth size="small" name="expiry" value={props.payment.expiry} onChange={handleChange} onFocus={handleInputFocus} label={t('general.expiry-date')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.expiry-date'))]} />
                            </div>
                            <div className="col-md-6 mt-2">
                                <TextValidator fullWidth size="small" name="cvc" value={props.payment.cvc} onChange={handleChange} onFocus={handleInputFocus} label={t('general.cvc')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.cvc'))]} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3 justify-content-md-center">
                    <div className="col-md-12 text-center">
                        <img className="img-stripe" src={StripeImage} alt="stripe" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreditCard;