import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import { eStorage, getStorageJSON } from "../../../Services/StorageService";
import Savings from "./Savings";
import { eDevice } from "../../../Components/Shared/Enum/EDevice";
import ChartTexas from "./ChartTexas";
import { Button, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Radio, RadioGroup, FormControlLabel, FormControl } from "@mui/material";
import CardsContainerLocation from "./CardsContainerLocation";
import ChartGoal from "./ChartGoal";
import { Circle } from "@mui/icons-material";
import ChartConsumptionContainer from "./ChartConsumptionContainer";
import { lbsToTonnes, formatToLocaleUs } from "../../../Util/Util";
import { eMetric } from "../../../Components/Shared/Enum/EMetric";
import { ePeriod } from "../../../Components/DashboardBase/DashboardBase";
import { Link } from "react-router-dom";
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';

function DashboardGeneral(props) {
    const { t } = useTranslation();
    const [fingerprint, setFingerprint] = useState({});

    useEffect(() => {
        let fingerprint = getStorageJSON(eStorage.fingerprint);
        if (fingerprint !== null)
            setFingerprint(fingerprint);
    }, []);

    const format = (number, fraction = 0) => {
        const format = !number || isNaN(number) ? 0 : number;
        return format.toLocaleString('en-US', { maximumFractionDigits: fraction })
    }

    return (
        <div>
            {/* mobile disposition */}
            {(props.functions.device === eDevice.mobile || props.functions.device === eDevice.tablet) &&
                <div className="mt-0 pt-1}">
                    <ChartConsumptionContainer
                        currentView={props.currentView}
                        handlePeriodSelect={props.handlePeriodSelect}
                        functions={props.functions}
                        handleTypeChange={props.handleTypeChange}
                        metric={props.metric}
                        period={props.period}
                        periodData={props.periodData}
                        goals={props.goals} />
                </div>
            }

            {(props.functions.device === eDevice.mobile || props.functions.device === eDevice.tablet) &&
                <div className="row">
                    <div className="col-12 ">
                        <Button className='btn-primary mt-2' component={Link} to="/electrical-service-request">{t('dashboard.card-request-electricity')}</Button>
                    </div>
                </div>
            }
            {(props.functions.device === eDevice.mobile || props.functions.device === eDevice.tablet) && <Savings currentView={props.currentView} functions={props.functions} />}
            {(props.functions.device === eDevice.mobile || props.functions.device === eDevice.tablet) &&
                <CardsContainerLocation carbonFootprint={props.carbonFootprint} energyIntensity={props.energyIntensity} selectedValues={fingerprint.selectedValues} functions={props.functions} generalStats={props.generalStats} />}

            {/* desktop disposition */}
            {
                (props.functions.device !== eDevice.mobile && props.functions.device !== eDevice.tablet) &&
                <>
                    {/* <CardsContainerLocation energyIntensity={props.energyIntensity} carbonFootprint={props.carbonFootprint} selectedValues={fingerprint.selectedValues} functions={props.functions} generalStats={props.generalStats} /> */}
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-3 mt-0 mt-md-2" >
                            <div className="container-card dashboard-card b-card mt-2 h-100 p-2 ">
                                <div className="row pt-2 d-flex align-items-center justify-content-center">
                                    <div className="col-md-5">
                                        <div className="number-circle text-center">
                                            ${props.generalCost} <p>{props.generalCost > 0 ? 'more' : 'less'}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-5 text-center">
                                        <h2 className="text-center title-section title-section-card">THIS MONTH</h2>
                                        <h2 className="text-center title-section title-section-card">vs</h2>
                                        <h2 className="text-center title-section title-section-card">LAST MONTH</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4 mt-0 mt-md-2" >
                            <div className="container-card dashboard-card b-card mt-2 h-100 p-0">
                                <h2 className="text-center title-section pt-4 title-section-card"><ElectricBoltIcon className='icon-house-type' /> {format(props.energyIntensity?.yearConsuption)} kWh</h2>
                                <h3 className="subtitle-section">Electric consumption for last 12 months</h3>
                                <hr className="m-0 p-0" />
                                <h2 className="text-center title-section pt-4 title-section-card"><LightbulbIcon className='icon-house-type' /> {fingerprint.selectedValues?.homeSize ? format(props.energyIntensity?.yearConsuption / fingerprint.selectedValues?.homeSize) : 0} kWh/sqft</h2>
                                <h3 className="subtitle-section">Energy intensity (amount of energy/building)</h3>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg mt-0 mt-md-2" >
                            <div className="container-card dashboard-card b-card mt-2 h-100">
                                <TableContainer>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ borderBottom: "none" }} className="cell-small">
                                                    <div className="row">
                                                        <div className="col-4"><h2 className="title-section">CARBON</h2> </div>
                                                        <div className="col-4"><h2 className="title-section">EMMISION</h2></div>
                                                        <div className="col-4"><h2 className="title-section">INTENSITY</h2></div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ borderBottom: "none" }} className="cell-small">
                                                    <div className="row">
                                                        <div className="col-4 fw-bold">Standard</div>
                                                        <div className="col-4">{format(lbsToTonnes(props.carbonFootprint?.co2))} tonnes</div>
                                                        <div className="col-4">{fingerprint.selectedValues?.homeSize ? format((lbsToTonnes(props.carbonFootprint?.co2) / fingerprint.selectedValues.homeSize), 2) : 0} tonnes/sqft</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell className="cell-small">
                                                    <div className="row">
                                                        <div className="col-4 fw-bold">Itemized</div>
                                                        <div className="col-4">{format(lbsToTonnes(props.generalStats?.co2))} tonnes</div>
                                                        <div className="col-4">{fingerprint?.selectedValues?.homeSize ? format((lbsToTonnes(props.generalStats?.co2) / fingerprint.selectedValues.homeSize), 2) : 0} tonnes/sqft</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ borderBottom: "none" }} className="cell-small">
                                                    <div className="row">
                                                        <div className="col-4 fw-bold">Difference</div>
                                                        <div className="col-4">{format(lbsToTonnes(props.carbonFootprint?.co2 - props.generalStats?.co2))} tonnes</div>
                                                        <div className="col-4">{fingerprint?.selectedValues?.homeSize ? format(((lbsToTonnes(props.carbonFootprint?.co2) / fingerprint.selectedValues.homeSize) - (lbsToTonnes(props.generalStats?.co2) / fingerprint.selectedValues.homeSize)), 2) : 0} tonnes/sqft</div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </>
            }
            <hr className="mt-4 mb-2 mt-2" />
            {(props.functions.device !== eDevice.mobile && props.functions.device !== eDevice.tablet) &&
                <div className="row mt-3">
                    <div className="col-md-3">
                        <FormControl size="small" fullWidth>
                            <InputLabel id="period-select">{t('dashboard.period-select')}</InputLabel>
                            <Select labelId="period-select" value={props.period} label="period-select" onChange={(event) => { props.handlePeriodSelect(event.target.value) }}>
                                <MenuItem value={ePeriod.lastWeekVSPrevious}>{t('dashboard.week-vs-previous')}</MenuItem>
                                <MenuItem value={ePeriod.lastMonthVSPrevious}>{t('dashboard.month-vs-previous')}</MenuItem>
                                <MenuItem value={ePeriod.lastWeekVSLastYear}>{t('dashboard.week-vs-last-year')}</MenuItem>
                                <MenuItem value={ePeriod.lastMonthVSLastYear}>{t('dashboard.month-vs-last-year')}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-3">
                        <FormControl>
                            <RadioGroup value={props.metric} row onChange={(event) => { props.handleTypeChange(event.target.value) }}>
                                <FormControlLabel value={eMetric.energy} control={<Radio />} label={t('dashboard.energy')} />
                                <FormControlLabel value={eMetric.carbon} control={<Radio />} label={t('dashboard.carbon')} />
                                <FormControlLabel value={eMetric.cost} control={<Radio />} label={t('dashboard.cost')} />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            }
            <div className="b-blue mt-2">
                <div className="row">
                    {(props.functions.device !== eDevice.mobile && props.functions.device !== eDevice.tablet) &&
                        <div className="col-12 col-sm-12 col-md-12 col-lg-5 mt-3 mt-md-0">
                            <div className="container-card b-card card-content pb-0">
                                <h2 className="text-start title-section">METRIC VARIATIONS</h2>
                                <ChartConsumptionContainer
                                    currentView={props.currentView}
                                    functions={props.functions}
                                    metric={props.metric}
                                    period={props.period}
                                    periodData={props.periodData} />
                            </div>
                        </div>
                    }

                    <div className="col-12 col-sm-12 col-md-12 col-lg-4 mt-3 mt-md-0">
                        <div className="container-card card-content pb-3">
                            <h2 className="text-start title-section">{t('dashboard.progress-goals')}</h2>
                            <div className="row justify-content-md-center">
                                <div className="col-md-8">
                                    <ChartGoal size='lg' tableGoals={props.tableGoals} />
                                </div>
                            </div>
                            {Object.keys(props.periodData).length > 0 &&
                                <div className="row">
                                    <div className="col-md-4 text-center">
                                        <h3>{t('dashboard.energy')}</h3>
                                        <span className="label-energy-s">
                                            {props.tableGoals?.energy.value}%
                                        </span>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <h3>{t('dashboard.carbon')}</h3>
                                        <span className="label-carbon-s">
                                            {props.tableGoals?.carbon.value}%
                                        </span>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <h3>{t('dashboard.cost')}</h3>
                                        <span className="label-cost-s">
                                            {props.tableGoals?.cost.value}%
                                        </span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-3 mt-3 mt-md-0">
                        <div className="container-card card-content pb-0">
                            <h2 className="text-start title-section">{t('dashboard.locations')}</h2>
                            {props.locations.length > 0 &&
                                <ChartTexas period={props.period} metric={props.metric} locations={props.locations} />
                            }
                        </div>
                    </div>
                </div>
                {
                    Object.keys(props.generalStats).length > 0 &&
                    Object.keys(fingerprint).length > 0 &&
                    <div className="row mt-4">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3 mt-md-0">
                            <div className="container-card card-content pb-0">
                                <h2 className="text-start title-section">{t('dashboard.locations-metric')}</h2>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell scope="col" colSpan={4}></TableCell >
                                                <TableCell scope="col" colSpan={2}><strong>{t('dashboard.carbon-footprint-lbs')}</strong></TableCell>
                                                <TableCell scope="col" colSpan={2}><strong>{t('dashboard.carbon-intensity-lbs')}</strong></TableCell>
                                                <TableCell scope="col" colSpan={3}><strong>{t('dashboard.goal-reduction')}</strong></TableCell>
                                                <TableCell scope="col" colSpan={3}><strong>{t('dashboard.progress-goals')}</strong></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell scope="col"><strong>{t('dashboard.location')}</strong></TableCell>
                                                <TableCell scope="col"><strong>{t('dashboard.total-energy')}</strong></TableCell>
                                                <TableCell scope="col"><strong>{t('dashboard.cost')}</strong></TableCell>
                                                <TableCell scope="col"><strong>Sqft</strong></TableCell>
                                                <TableCell scope="col"><strong>{t('dashboard.standard')}</strong></TableCell>
                                                <TableCell scope="col"><strong>{t('dashboard.itemized')}</strong></TableCell >
                                                <TableCell scope="col"><strong>{t('dashboard.standard')}</strong></TableCell>
                                                <TableCell scope="col"><strong>{t('dashboard.itemized')}</strong></TableCell>
                                                <TableCell scope="col"><strong>{t('dashboard.energy')}</strong></TableCell>
                                                <TableCell scope="col"><strong>{t('dashboard.cost')}</strong></TableCell>
                                                <TableCell scope="col"><strong>{t('dashboard.carbon')}</strong></TableCell>
                                                <TableCell scope="col"><strong>{t('dashboard.energy')}</strong></TableCell>
                                                <TableCell scope="col"><strong>{t('dashboard.cost')}</strong></TableCell>
                                                <TableCell scope="col"><strong>{t('dashboard.carbon')}</strong></TableCell >
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {props.locations?.map((item, index) =>
                                                <TableRow key={index}>
                                                    <TableCell>{item.name}</TableCell>
                                                    <TableCell>{formatToLocaleUs(item?.energyIntensity?.yearConsuption, 2)} </TableCell>
                                                    <TableCell>${formatToLocaleUs((item?.energyIntensity?.yearConsuption * 0.0923926), 2)}</TableCell>
                                                    <TableCell>{formatToLocaleUs(parseInt(item?.preferences?.selectedValues?.homeSize), 2)}</TableCell>
                                                    <TableCell>{formatToLocaleUs(lbsToTonnes(item?.carbonFootprint?.co2), 2)}</TableCell>
                                                    <TableCell>{formatToLocaleUs(lbsToTonnes(item?.generalStats?.co2), 2)}</TableCell>
                                                    <TableCell>{fingerprint?.selectedValues?.homeSize ? formatToLocaleUs(lbsToTonnes(item?.carbonFootprint?.co2) / fingerprint.selectedValues.homeSize, 2) : 0}</TableCell>
                                                    <TableCell>{fingerprint?.selectedValues?.homeSize ? formatToLocaleUs(lbsToTonnes(item?.generalStats?.co2) / fingerprint.selectedValues.homeSize, 2) : 0}</TableCell>
                                                    <TableCell>{item?.goals?.energy}%</TableCell>
                                                    <TableCell>{item?.goals?.bill}%</TableCell>
                                                    <TableCell>{item?.goals?.carbon}%</TableCell>
                                                    <TableCell>{item?.tableGoals?.energy?.value}% <Circle sx={{ color: item?.tableGoals?.energy?.color }} /></TableCell>
                                                    <TableCell>{item?.tableGoals?.cost?.value}% <Circle sx={{ color: item?.tableGoals?.cost?.color }} /></TableCell>
                                                    <TableCell>{item?.tableGoals?.carbon?.value}% <Circle sx={{ color: item?.tableGoals?.carbon?.color }} /></TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default DashboardGeneral;