/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState, useRef } from 'react';
import { getRepsData, renewAgreement } from "../../Components/AgreementBase/AgreementBase";
import { ValidatorForm, SelectValidator } from 'react-material-ui-form-validator';
import { Button, MenuItem } from '@mui/material';
import { eStorage, getStorageJSON, getStorage, saveStorage } from './../../Services/StorageService';
import { getUniqueVisitorId, getIp } from './../../Util/Util';
import { useNavigate } from 'react-router-dom';
import { Notify } from 'notiflix/build/notiflix-notify-aio';

function VerifySettings(props) {
    const { t } = useTranslation();
    const [reps, setReps] = useState([]);
    const [rep, setRep] = useState('');
    const formRef = useRef();
    const [language] = useState(getStorage(eStorage.language));
    const [request] = useState(getStorage(eStorage.requestId));
    const [address] = useState(getStorageJSON(eStorage.address));
    const [user] = useState(getStorageJSON(eStorage.user));
    const navigate = useNavigate();

    useEffect(() => {
        props.functions.setTitle(t('general.renew-agreement'));
        getReps();
    }, []);

    const getReps = () => {
        getRepsData().then(data => {
            setReps(data);
        })
    };

    const handleSubmit = async () => {
        props.functions.setIsLoading(true);
        var json = {
            address: address.address,
            apto: address.apartmentOrUnit,
            city: address.city,
            state: address.state,
            zipCode: address.zipCode,
            houseType: "house",
            rep: rep,
            esiid: user.esiid,
            meterNumber: user.meternumber,

            customerId: user.customerId,
            requestId: request,
            email: user.email,
            eweMail: user.eweMail,
            name: user.name,
            lastname: user.lastName,
            lang: language,
            moving: false,
            referrer: "",
            serviceArea: address.serviceArea,
            site: 'FingerPrint',

            uniqueVisitorId: await getUniqueVisitorId(),
            clientIp: await getIp()
        };

        renewAgreement(json).then(data => {
            if (data.status === "Success") {
                saveStorage(eStorage.renew, true);
                navigate('/creating-agreement');
            } else {
                Notify.failure(t('general.renew-agreement-failed'), { position: 'center-bottom', });
            }
            props.functions.setIsLoading(false);
        }).catch(() =>
            Notify.failure(t('general.renew-agreement-failed'), { position: 'center-bottom', })
        );
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setRep(value);
    }

    return (
        <div className="content">
            <ValidatorForm ref={formRef} className='container' onSubmit={handleSubmit} onError={errors => console.log(errors)}>
                <h1>{t('agreement.select-rep')}</h1>
                <div className='row justify-content-md-center'>
                    <div className='col-md-7 container-card card-content text-center'>
                        <div className="row justify-content-md-center">
                            <div className="col-md-6">
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <SelectValidator className="text-start" fullWidth size="small" name="rep" value={rep} onChange={handleChange} label={t('general.electricity-company')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.electricity-company'))]}>
                                            {reps.map((item, index) => (
                                                <MenuItem key={index} value={item.uid}>{item.name}</MenuItem>
                                            ))}
                                        </SelectValidator>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row mt-2">
                            <div className="col-md-12">
                                <Button className='btn-primary' type="submit">
                                    {t('general.renew')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </ValidatorForm>
        </div>
    )
}

export default VerifySettings;