import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { React, useEffect, useState, useRef } from 'react';
import LogoPrincipal from '../../Assets/logo-enerwisely.png';
import { Button } from '@mui/material';
import { sendResetPassword } from './../../Components/AuthBase/AuthBase';
import { eModal } from './../../Services/ModalService';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { useNavigate } from "react-router-dom";
import { ePage } from "../../Components/Shared/Routes";

function ResetPassword(props) {
  const { otu } = useParams();
  const formRef = useRef();
  const { t } = useTranslation();
  const [model, setModel] = useState({ pass: '', repass: '' });
  const navigate = useNavigate();

  useEffect(() => {
    props.functions.setTitle(t('general.password-recover'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
    if (value !== model.pass) {
      return false;
    }
    return true;
  });

  const handleChange = (event) => {

    const { name, value } = event.target;
    setModel(prevState => ({
      ...prevState, [name]: value
    }));
  }

  const handleSubmit = async () => {
    props.functions.setIsLoading(true);
    try {
      const resetPasswordParams = {
        key: otu,
        password: model.pass
      }
      const response = await sendResetPassword(resetPasswordParams);
      if (response === 'success') {
        Notify.success(t('modal.resetPassSuccess'), { position: 'center-bottom' });
        navigate(ePage.login);
      }
      if (response === 'invalid') Notify.failure(t('modal.resetPassInvalid'), { position: 'center-bottom' });
    } catch (error) {
      console.log(error);
    } finally {
      props.functions.setIsLoading(false);
    }
  }

  return (
    <ValidatorForm ref={formRef} className='content' onSubmit={handleSubmit} onError={errors => console.log(errors)}>
      <div className='container'>
        <div className='row justify-content-md-center'>
          <div className='col-md-6 container-card card-content text-center'>
            <img className="logo-login" src={LogoPrincipal} alt="logo" />
            <div className="row mt-4 justify-content-md-center">
              <div className="col-md-8">
                <TextValidator fullWidth size="small" name="pass" type="password" value={model.pass} onChange={handleChange} label={t('general.password')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.password'))]} />
              </div>
              <div className="col-md-8 mt-2">
                <TextValidator fullWidth size="small" name="repass" type="password" value={model.repass} label={t('general.repassword')} onChange={handleChange} validators={['isPasswordMatch', 'required']} errorMessages={[t('general.passwordMismatch'), t('general.required').replace('#field#', t('general.repassword'))]} />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12">
                <Button className='btn-primary' type="submit">
                  {t('general.resetPassword')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidatorForm>
  );

}

export default ResetPassword;