import { Dialog, Slide, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { forwardRef, useEffect } from 'react';
import ChartHeatMap from './ChartHeatMap';
import CloseIcon from '@mui/icons-material/Close';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalChartHeatMapCarbon(props) {

  const handleClose = () => {
    props.setModalOpen(false);
  }

  return <>
    <Dialog open={props.modalOpen} TransitionComponent={Transition} fullWidth={true} maxWidth={'md'} keepMounted onClose={handleClose}>
      <DialogTitle className='text-center'>
        <div className='row'>
          <div className='col-11 p-2'>CARBON EMISSIONS VARIATIONS</div>
          <div className='col-1' style={{ textAlign: "right" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <hr className='m-0' />
      <DialogContent>
        <div className="row justify-content-center mt-2">
          <div className='col-md-8'>
            {
              Object.keys(props.chartObject) &&
              <ChartHeatMap
                key={1}
                location={props.chartObject.location}
                type={'carbon'}
                values={props.chartObject.values}
                start={props.chartObject.start}
                end={props.chartObject.end} />
            }
          </div>
        </div>
      </DialogContent>
    </Dialog>
  </>
}