/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import { useRef } from 'react';
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FormControl, InputLabel, Select, MenuItem, Switch, Button, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function ServiceInfo(props) {
    const { t } = useTranslation();
    const formRef = useRef();
    const [password, setPassword] = useState("password");
    const [passwordVerify, setPasswordVerify] = useState("password");
    const [disableButton, setDisableButton] = useState(true);

    useEffect(() => {
        validButtonNext();
    }, [props.service]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        props.setService(prevState => ({
            ...prevState, [name]: value
        }));
    }

    const handleChangeCheck = (event) => {
        props.setService(prevState => ({
            ...prevState, ['chronic']: event.target.checked
        }));
    };

    const handleBackStep = () => {
        props.setActiveStep(props.activeStep - 1);
    };

    const handleSubmit = () => {
        props.setActiveStep(props.activeStep + 1);
    }

    const handleChangeDate = (value) => {
        props.setService(prevState => ({
            ...prevState, ['serviceDate']: value
        }));
    };


    const handleClickShowPassword = () => {
        setPassword(password === 'password' ? 'text' : 'password');
    };
    const handleClickShowPasswordVerify = () => {
        setPasswordVerify(passwordVerify === 'password' ? 'text' : 'password');
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseDownPasswordVerify = (event) => {
        event.preventDefault();
    };

    const validButtonNext = () => {
        if (props.service.password === props.service.passwordVerify && props.service.pin === props.service.pinVerify)
            setDisableButton(false);
        else {

        }
    };

    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        if (value !== props.service.password) {
          return false;
        }
        return true;
      });

      ValidatorForm.addValidationRule('isPinMatch', (value) => {
        if (value !== props.service.pin) {
          return false;
        }
        return true;
      });

    return (
        <ValidatorForm
            ref={formRef}
            onSubmit={handleSubmit}
            onError={errors => console.log(errors)}
        >
            <div className="row justify-content-md-center">
                <h2 className="mb-3">{t('general.service-detail')}</h2>
                <div className="col-md-4 mt-2">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker disablePast label={t('general.date-start')} value={props.service.serviceDate} onChange={handleChangeDate} renderInput={(params) => <TextValidator style={{ width: '100%' }} size="small" {...params} />} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.date-start'))]} />
                    </LocalizationProvider>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-4 mt-2">
                    <TextValidator type={password} fullWidth size="small" name="password" value={props.service.password} onChange={handleChange} label={t('payless.password')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('payless.password'))]} />
                    <InputAdornment className="password-eye d-flex justify-content-end" position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {password === 'password' ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                </div>
                <div className="col-md-4 mt-2">
                    <TextValidator type={passwordVerify} fullWidth size="small" name="passwordVerify" value={props.service.passwordVerify} onChange={handleChange} label={t('payless.repassword')} validators={['isPasswordMatch','required']} errorMessages={[t('general.passwordMismatch'),t('general.required').replace('#field#', t('payless.repassword'))]} />
                    <InputAdornment className="password-eye d-flex justify-content-end" position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPasswordVerify}
                            onMouseDown={handleMouseDownPasswordVerify}
                            edge="end"
                        >
                            {password === 'password' ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-4 mt-2">
                    <TextValidator fullWidth size="small" name="pin" value={props.service.pin} onChange={handleChange} label={t('payless.pin')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('payless.pin'))]} />
                </div>
                <div className="col-md-4 mt-2">
                    <TextValidator fullWidth size="small" name="pinVerify" value={props.service.pinVerify} onChange={handleChange} label={t('payless.pin-verify')} validators={['isPinMatch','required']} errorMessages={[t('general.pin-mismatch'),t('general.required').replace('#field#', t('payless.pin-verify'))]} />
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-4 mt-2">
                    <h3 className="text-start">{t('payless.how-would')}</h3>
                </div>
                <div className="col-md-4 mt-2">
                    <FormControl size="small" fullWidth>
                        <InputLabel id="alert">{t('payless.alert')}</InputLabel>
                        <Select labelId="alert" required name="alert" value={props.service.alert} onChange={handleChange} label={t('payless.alert')}>
                            <MenuItem value={'emailText'}>Email + Text</MenuItem>
                            <MenuItem value={'email'}>Email</MenuItem>
                            <MenuItem value={'text'}>Text</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-4 mt-2">
                    <h3 className="text-start">{t('payless.does-anyone')}</h3>
                </div>
                <div className="col-md-4 mt-2">
                    <span className="small-legend">{t('general.no')}</span><Switch size="small" value={props.service.chronic} onChange={handleChangeCheck} />  <span className="small-legend">{t('general.yes')}</span>
                </div>
            </div>
            {props.service.chronic &&
                <div className="row justify-content-md-center">
                    <div className="col-md-8 mt-2">
                        <h3 className="text-start">{t('payless.chronic-condition')}</h3>
                        <p>
                            <a className="link-file" href='https://www.puc.texas.gov/agency/rulesnlaws/subrules/electric/25.497/25.497.pdf' target="_blank">
                                {t('payless.review-rules')}
                            </a>
                        </p>
                    </div>
                </div>
            }
            <div className="row mt-4">
                <div className="col-md-12">
                    {props.activeStep > 0 && <Button className='btn-secondary me-2' onClick={handleBackStep}>{t('general.back')}</Button>}
                    <Button className='btn-primary' type="submit" disabled={disableButton}>{t('general.next')}</Button>
                </div>
            </div>
        </ValidatorForm>
    )
}

export default ServiceInfo;