
import { useTranslation } from 'react-i18next';
import { Dialog, Slide, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { forwardRef, useEffect } from 'react';
import { getParams } from '../../Util/Util';
import { Capacitor } from '@capacitor/core';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalVersionApp(props) {
    const { t } = useTranslation();

    useEffect(() => {
    }, []);

    const handleClose = () => {
        props.setModal(false);
    };

    const handleUpdateApp = () => {
        let platform = Capacitor.getPlatform();
        if (platform == 'android')
            window.open(getParams().ANDROID_URL, '_blank');
        else
            window.open(getParams().IOS_URL, '_blank');
    };

    return (
        <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'md'} keepMounted onClose={handleClose}>
            <DialogTitle className='text-center'>{t('modal.version-app-title')}</DialogTitle>
            <hr className='m-0' />
            <DialogContent>
                <div className="row justify-content-center mt-2">
                    <div className='col-md-8'>
                        <h3 className='text-center'>{t('modal.version-app-description')}</h3>
                    </div>
                </div>
            </DialogContent>
            <hr className='mb-0 mt-2' />
            <DialogActions className='justify-content-md-center'>
                <Button className='btn-primary' onClick={handleUpdateApp}>{t('general.update')}</Button>
            </DialogActions>
        </Dialog>
    );
}