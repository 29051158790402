import { dataServiceApiInstance } from "../../Util/Api";

export const updateNotificationToken = (requestId, customerId, token) => {
    const response = dataServiceApiInstance.post(`customer/tokenFCM?customerId=${customerId}&requestId=${requestId}&token=${token}`)
        .then(({ data }) => {

            return data;
        });
    return response;
}

export const getAllNotifications = (customerId) => {
    const response = dataServiceApiInstance.get(`/notification/all/${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getNewNotifications = (customerId) => {
    const response = dataServiceApiInstance.get(`/notification/new/${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const postUpdateStatus = (customerId, status) => {
    const response = dataServiceApiInstance.post(`/notification/updateStatus/${customerId}?status=${status}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const postDelete = (id) => {
    const response = dataServiceApiInstance.post(`/notification/delete/${id}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const sendNotification = (model)=>{
    const parameters = Object.keys(model).map(m => (`${m}=${model[m]}`)).join('&');
    console.log(parameters);
    const response = dataServiceApiInstance.post(`/notification/send?${parameters}`, model)
        .then((response) => {
            return response?.data ?? false;
        });
    return response;
}