import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CampaignIcon from '@mui/icons-material/Campaign';
import Avatar from '@mui/material/Avatar';
import { useEffect, useState } from 'react';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { useTranslation } from 'react-i18next';
import { eStorage, getStorageJSON } from './../../Services/StorageService';
import { sendNotification } from './../../Components/NotificationBase/NotificationBase';
import { eDevice } from '../../Components/Shared/Enum/EDevice';

function CurrrentRanking(props) {
    const [bests, setBests] = useState([]);
    const { t } = useTranslation();
    const [user] = useState(getStorageJSON(eStorage.user));

    useEffect(() => {
        if (props?.bests && Array.isArray(props?.bests)) {
            setBests(props.bests.map(m => {
                m.show = true;
                return m;
            }))
        }
    }, [props?.bests]);

    const sendCongrat = (index) => {
        const tmp = bests;
        tmp[index].show = false;
        setBests(tmp.slice());
        const model = {
            type: 'congratulate',
            receiverId: user.customerId,
            senderId: tmp[index].customerId
        }
        sendNotification(model)
            .then(resp => {
                Notify.success(t('general.congratUserMessage'), { position: 'center-bottom', });
            }).catch(console.log);
    }

    return (
        <>
            {bests.map((value, index) => {
                return (
                    <div className="row mt-1" key={index}>
                        <div className="col-4 col-md-3 text-end">
                            {index <= 2 &&
                                <EmojiEventsIcon className={index === 0 ? 'first-icon' : index === 1 ? 'second-icon' : 'third-icon'}></EmojiEventsIcon>
                            }
                            <span className="description-text m-0">{value.points} pts</span>
                        </div>
                        <div className="col-6 col-md-5">
                            <div className="row text-md-start text-center">
                                <div className="col-md-3 d-flex justify-content-md-start justify-content-center">
                                    <Avatar alt={value.name} src={`/static/images/avatar/1.jpg`} />
                                </div>
                                <div className="col-md-9 d-flex align-items-center justify-content-md-start justify-content-center">
                                    <span className="description-text m-0">{value.name}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-2 col-md-4">
                            {value.show && <p className="congratulate" onClick={() => sendCongrat(index)} >
                                {props.functions.device !== eDevice.mobile &&
                                    <span>Congratulate</span>
                                }
                                <CampaignIcon /></p>}
                        </div>
                    </div >
                )
            })}
        </>
    )
}

export default CurrrentRanking;