/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useState } from 'react';
import { MenuItem, Select, InputLabel, FormControl, Checkbox, TextField } from '@mui/material';
import leaveImage from '../../../Assets/Icons/leave.png';
import returnImage from '../../../Assets/Icons/return.png';
import { getOccupants, getRenewable, getSchedule, getTemperatures } from "../../../Util/Util";
import thermostatCoolingImage from '../../../Assets/Icons/thermostat-cooling.png';
import thermostatHealtingImage from '../../../Assets/Icons/thermostat-heating.png';
import occupantsImage from '../../../Assets/Icons/occupants.png';
import billImage from '../../../Assets/Icons/bill.png';
import renewableImage from '../../../Assets/Icons/input-renewable.png';
function Schedule(props) {
    const { t } = useTranslation();
    const [wfh, setWfh] = useState('no');
    const [profile, setProfile] = useState({});

    const handleChangeWfh = (event) => {
        setWfh(event.target.value);
    }

    return (
        <div className='container'>
            <div className='row justify-content-md-center'>
                <div className="col-sm-12 col-md-10 col-lg-7">
                    <div className="container-card card-content">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4">
                                        <img alt="" className="icon-savings" src={leaveImage} />
                                    </div>
                                    <div className="col-9 col-md-8 mt-2 d-flex align-items-center justify-content-center">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="wake-up">{t('smart-fingerprint-data.open')}</InputLabel>
                                            <Select labelId="wake-up" name="weekday_wakeup" value={props.simpleSchedule.weekday_wakeup} onChange={props.handleChangesSchedule} label={t('smart-fingerprint-data.open')}>
                                                {getSchedule().map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4">
                                        <img alt="" className="icon-savings" src={returnImage} />
                                    </div>
                                    <div className="col-9 col-md-8 d-flex align-items-center justify-content-center">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="go-sleep">{t('smart-fingerprint-data.close')}</InputLabel>
                                            <Select labelId="go-sleep" name="weekday_sleep" value={props.simpleSchedule.weekday_sleep} onChange={props.handleChangesSchedule} label={t('smart-fingerprint-data.close')}>
                                                {getSchedule().map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4">
                                        <img alt="" className="icon-savings" src={thermostatCoolingImage} />
                                    </div>
                                    <div className="col-9 col-md-8 d-flex align-items-center justify-content-center">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="thermostat-cooling">{t('smart-fingerprint-data.thermostat-cooling')}</InputLabel>
                                            <Select labelId="thermostat-cooling" name="coolingTemperature" value={props.selectedValues.coolingTemperature} onChange={props.handleChangesSelected} label={t('smart-fingerprint-thermostat-cooling')}>
                                                {getTemperatures().map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4">
                                        <img alt="" className="icon-savings" src={thermostatHealtingImage} />
                                    </div>
                                    <div className="col-9 col-md-8 d-flex align-items-center justify-content-center">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="thermostat-heating">{t('smart-fingerprint-data.thermostat-heating')}</InputLabel>
                                            <Select labelId="thermostat-heating" name="heatingTemperature" value={props.selectedValues.heatingTemperature} onChange={props.handleChangesSelected} label={t('smart-fingerprint-data.thermostat-heating')}>
                                                {getTemperatures().map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4">
                                        <img alt="" className="icon-savings" src={occupantsImage} />
                                    </div>
                                    <div className="col-9 col-md-8 d-flex align-items-center justify-content-center">
                                        {/* <FormControl size="small" fullWidth>
                                            <InputLabel id="number-occupants">{t('smart-fingerprint-data.number-workers')}</InputLabel>
                                            <Select labelId="number-occupants" name="numbersOfOccupant" value={props.selectedValues.numbersOfOccupant} onChange={props.handleChangesSelected} label={t('smart-fingerprint-data.number-workers')}>
                                                {getOccupants().map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl> */}
                                        <TextField type={'number'} fullWidth size="small" name="numbersOfWorkers" onChange={props.handleChangesSelected} value={props.selectedValues.numbersOfWorkers} label={t('smart-fingerprint-data.number-workers')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {profile?.currentPlan === null &&
                            <>
                                <hr />
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-3 col-md-4">
                                                <img alt="" className="icon-savings" src={billImage} />
                                            </div>
                                            <div className="col-9 col-md-8 mt-3 d-flex align-items-center justify-content-center">
                                                <TextField fullWidth size="small" name="currentPlanAverageBill" value={props.selectedValues.currentPlanAverageBill} onChange={props.handleChangesSelected} label={t('smart-fingerprint-data.average-bill')} disabled={props.currentAvg} />
                                                <Checkbox name="currentAvg" checked={props.currentAvg} onChange={props.handleChangeAvg} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-3 col-md-4 mt-3">
                                                <img alt="" className="icon-savings" src={renewableImage} />
                                            </div>
                                            <div className="col-9 col-md-8 mt-3 d-flex align-items-center justify-content-center">
                                                <FormControl size="small" fullWidth>
                                                    <InputLabel id="current-plan">{t('smart-fingerprint-data.current-plan')}</InputLabel>
                                                    <Select labelId="current-plan" name="current-plan" value={props.selectedValues.currentPlanRenewable} onChange={props.handleChangesSelected} label={t('smart-fingerprint-data.current-plan')}>
                                                        {getRenewable().map((item, index) => (
                                                            <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Schedule;