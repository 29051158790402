/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState, useRef } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { eStorage, getStorage, getStorageJSON } from '../../Services/StorageService';
import ShareButtons from '../../Components/Shared/ShareButtons';
import { Button } from '@mui/material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { getPlanData, getProfileData, getRepData } from '../../Components/ProfileBase/ProfileBase';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { updateProfile } from './../../Components/ProfileBase/ProfileBase';
import Checkbox from '@mui/material/Checkbox';
import { updateUseNickname } from './../../Components/ProfileBase/ProfileBase';


function Detail(props) {
    const { t } = useTranslation();
    const formRef = useRef();
    const [language] = useState(getStorage(eStorage.language));
    const [profile, setProfile] = useState({});
    const [plan, setPlan] = useState({});
    const [rep, setRep] = useState({});
    const [update, setUpdate] = useState(false);
    const [user] = useState(getStorageJSON(eStorage.user));
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        props.functions.setIsLoading(false);
        getProfileData(user.customerId).then(data => {
            setProfile(data);
            setChecked(data?.useNickname?? false);
            getRep(data.rep);
            getPlan(data.currentPlan);
        });
    }

    const getRep = (rep) => {
        getRepData(rep).then(data => {
            setRep(data);
        });
    }

    const getPlan = (plan) => {
        getPlanData(plan).then(data => {
            setPlan(data);
            props.functions.setIsLoading(false);
        });
    }

    const handleSubmit = () => {
        let model = {
            customerId: profile.id,
            name: profile.name,
            lastname: profile.lastname,
            email: profile.email,
            phone: profile.phone,
            nickname : profile.nickname,
            address: profile.address,
            city: profile.city,
            state: profile.state,
            zipCode: profile.zipCode,
            preferredLang: profile.preferredLang,
            dataSharingConsent: true,
            publicDataConsent: true
        }
        updateProfile(model)
            .then(() => {
                Notify.success(t('profile.saved'), { position: 'center-bottom', });
            }).catch(() => {
                Notify.failure(t('general.register-error'));
            }).finally(() => setUpdate(false));
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setProfile(prevState => ({
            ...prevState, [name]: value
        }));
    }

    const handleCheckbox = (event)=>{
        setChecked(event.target.checked);
        updateUseNickname(user.customerId, event.target.checked)
        .then(resp =>{
            console.log(resp);
        })
        .catch(console.error)
    }

    return (
        <ValidatorForm ref={formRef} onSubmit={handleSubmit} onError={errors => console.log(errors)}>
            <div className="row justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-end'><strong>{t('profile.name')}:</strong></h3>
                </div>
                <div className="col-md-5">
                    {!update && <h3 className='text-start'>{profile.name}</h3>}
                    {update && <TextValidator fullWidth className='mb-2' size="small" name="name" value={profile.name} onChange={handleChange} label={t('general.name')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.name'))]} />}
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-end'><strong>{t('profile.lastname')}:</strong></h3>
                </div>
                <div className="col-md-5">
                    {!update && <h3 className='text-start'>{profile.lastname}</h3>}
                    {update && <TextValidator fullWidth className='mb-2' size="small" name="lastname" value={profile.lastname} onChange={handleChange} label={t('general.last-name')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.last-name'))]} />}
                </div>
            </div>

            <div className='row justify-content-md-center'>
                <div className='col-md-5'>
                    <h3 className='text-end'><strong>{t('profile.nick-name')}:</strong></h3>
                </div>
                <div className='col-md-5'>
                    {!update && <h3 className='text-start'>{profile.nickname}</h3>}
                    {update && <TextValidator fullWidth className='mb-2' size="small" name='nickname' value={profile.nickname} onChange={handleChange} label={t('profile.nick-name')} validators={['required']} errorMessages={[t('general.requiered'.replace('#field#', t('profile.nick-name')))]}/>}
                </div>
            </div>
            <div className='row justify-content-md-center'>
                <div className='col-md-5'>
                    <h3 className='text-end'><strong>{t('profile.i-prefer-my-nickname')}:</strong></h3>
                </div>
                <div className='col-md-5 text-start'>
                    <Checkbox checked={checked} onChange={handleCheckbox} inputProps={{ 'aria-label': 'controlled' }} />
                </div>

            </div>


            <div className="row justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-end'><strong>{t('profile.recovery-email')}:</strong></h3>
                </div>
                <div className="col-md-5">
                    {!update && <h3 className='text-start'>{profile.email}</h3>}
                    {update && <TextValidator fullWidth className='mb-2' size="small" name="email" value={profile.email} onChange={handleChange} label={t('general.email')} validators={['required', 'isEmail']} errorMessages={[t('general.required').replace('#field#', t('general.email')), t('general.is-email').replace('#field#', t('general.email'))]} />}
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-end'><strong>{t('profile.service-address')}:</strong></h3>
                </div>
                <div className="col-md-5">
                    <h3 className='text-start'>{profile.address}, {profile.city} {profile.state}, {profile.zipCode}</h3>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-end'><strong>{t('profile.meter-number')}:</strong></h3>
                </div>
                <div className="col-md-5">
                    <h3 className='text-start'>{profile.meterNumber}</h3>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-end'><strong>{t('profile.retail-provider')}:</strong></h3>
                </div>
                <div className="col-md-5">
                    <h3 className='text-start'>{rep.name}</h3>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-end'><strong>{t('profile.retail-phone')}:</strong></h3>
                </div>
                <div className="col-md-5">
                    <h3 className='text-start'>{rep.phone}</h3>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-end'><strong>{t('profile.current-plan')}:</strong></h3>
                </div>
                <div className="col-md-5">
                    {Object.keys(plan).length > 0 && <h3 className='text-start'>{plan.name[language]}</h3>}
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-end'><strong>{t('profile.current-date')}:</strong></h3>
                </div>
                <div className="col-md-5">
                    <h3 className='text-start'>{new Date(profile.currentPlanExpiration).toLocaleDateString(language, { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' })}</h3>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-end'><strong>{t('profile.referral-code')}:</strong></h3>
                </div>
                <div className="col-md-5">
                    <h3 className='text-start'>{profile.currentVoucher}</h3>
                    <div className="share-left">
                        <ShareButtons />
                    </div>
                </div>
            </div>
            <div className="row mt-3 justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-end'><strong>{t('profile.email-account')}:</strong></h3>
                </div>
                <div className="col-md-5">
                    <h3 className='text-start'>{profile.eweMail}</h3>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-end'><strong>{t('profile.member-since')}:</strong></h3>
                </div>
                <div className="col-md-5">
                    <h3 className='text-start'>{new Date(profile.created).toLocaleDateString(language, { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' })}</h3>
                </div>
            </div>
            <hr />
            <div className="row justify-content-md-center">
                <div className="col-md-8">
                    {update &&
                        <div>
                            <Button className='btn-secondary me-2' variant="outlined" onClick={() => setUpdate(false)}>
                                {t('general.close')}
                            </Button>
                            <Button type='submit' className='btn-primary'>
                                {t('profile.save')}
                            </Button>
                        </div>
                    }
                    {!update &&
                        <Button className='btn-primary' onClick={() => setUpdate(true)}>
                            {t('profile.edit')}
                        </Button>
                    }
                </div>
            </div>
        </ValidatorForm>
    )
}

export default Detail;