import { React, useEffect, forwardRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { useTranslation } from "react-i18next";
import { eModal } from '../../Services/ModalService';
import { eStorage, getStorageJSON } from '../../Services/StorageService';
import { useNavigate } from 'react-router-dom';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Modal(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const modalParameters = { title: '', description: '', primaryButton: { text: '', link: '' }, secondaryButton: { text: '', link: '' } };

    const prepareModal = () => {
        let fingerprint = '';
        switch (props.modalType) {
            case eModal.efficiencySavings:
                modalParameters.title = t('general.howCanImprove');
                modalParameters.description = t('modal.efficiency-savings-description');
                modalParameters.primaryButton = { text: 'continue', link: '' };
                modalParameters.secondaryButton = { text: '', link: '' };
                break;
            case eModal.dashboardSaving:
                modalParameters.title = t('general.howCanImprove');
                modalParameters.description = t('modal.efficiency-savings-description');
                modalParameters.primaryButton = { text: 'continue', link: '' };
                modalParameters.secondaryButton = { text: '', link: '' };
                break;
            case eModal.dashboardEnergyUse:
                modalParameters.title = t('general.howCanImprove');
                modalParameters.description = t('modal.dashboard-energy-use-description');
                modalParameters.primaryButton = { text: 'continue', link: '' };
                modalParameters.secondaryButton = { text: '', link: '' };
                break;
            case eModal.dashboardSavedTree:
                modalParameters.title = t('general.howCanImprove');
                modalParameters.description = t('modal.dashboard-saved-tree-description');
                modalParameters.primaryButton = { text: 'continue', link: '' };
                modalParameters.secondaryButton = { text: '', link: '' };
                break;
            case eModal.dashboardLeaks:
                modalParameters.title = t('general.howCanImprove');
                modalParameters.description = t('modal.dashboard-leaks-description');
                modalParameters.primaryButton = { text: 'continue', link: '' };
                modalParameters.secondaryButton = { text: '', link: '' };
                break;
            case eModal.energyScoreEfficiency:
                modalParameters.title = t('modal.efficiency-savings-title');
                modalParameters.description = t('modal.energy-score-efficiency');
                modalParameters.primaryButton = { text: 'continue', link: '' };
                modalParameters.secondaryButton = { text: '', link: '' };
                break;
            case eModal.energyScoreLeaks:
                modalParameters.title = t('modal.leakageSavingsModalTitle');
                modalParameters.description = t('modal.energy-score-leaks');
                modalParameters.primaryButton = { text: 'continue', link: '' };
                modalParameters.secondaryButton = { text: '', link: '' };
                break;
            case eModal.energyScoreElectricyPlan:
                modalParameters.title = t('modal.planSavingsModalTitle');
                modalParameters.description = t('modal.energy-score-electricy-plan');
                modalParameters.primaryButton = { text: 'continue', link: '/matching-preferences' };
                modalParameters.secondaryButton = { text: '', link: '' };
                break;
            case eModal.energyScoreTrees:
                modalParameters.title = t('modal.completeFingerprintModalTitle');
                modalParameters.description = t('modal.energy-score-trees');
                modalParameters.primaryButton = { text: 'continue', link: '/matching-preferences' };
                modalParameters.secondaryButton = { text: '', link: '' };
                break;
            case eModal.environmentImpactCarbono:
                modalParameters.title = t('modal.modalCarsTitle');
                modalParameters.description = t('modal.environment-impact-carbono');
                modalParameters.primaryButton = { text: 'continue', link: '' };
                modalParameters.secondaryButton = { text: '', link: '' };
                break;
            case eModal.environmentImpactOffseting:
                modalParameters.title = t('modal.modalTreesTitle');
                modalParameters.description = t('modal.environment-impact-offseting');
                modalParameters.primaryButton = { text: 'continue', link: '' };
                modalParameters.secondaryButton = { text: '', link: '' };
                break;
            case eModal.resetPassSuccess:
                modalParameters.title = t('general.reset-password');
                modalParameters.description = t('modal.resetPassSuccess');
                modalParameters.primaryButton = { text: 'ok', link: '/login' };
                modalParameters.secondaryButton = { text: '', link: '' };
                break;
            case eModal.resetPassInvalid:
                modalParameters.title = t('general.reset-password');
                modalParameters.description = t('modal.resetPassInvalid');
                modalParameters.primaryButton = { text: 'ok', link: '' };
                modalParameters.secondaryButton = { text: '', link: '' };
                break;
            case eModal.resetPassServerError:
                modalParameters.title = t('general.reset-password');
                modalParameters.description = t('modal.resetPassServerError');
                modalParameters.primaryButton = { text: 'ok', link: '' };
                modalParameters.secondaryButton = { text: '', link: '' };
                break;
            case eModal.moreBenefits:
                modalParameters.title = t('modal.more-benefits-title');
                modalParameters.description = t('modal.more-benefits-description');
                modalParameters.primaryButton = { text: t('modal.fetch-my-data'), link: '/electric-info' };
                modalParameters.secondaryButton = { text: t('modal.ill-skip'), link: '' };
                break;
            case eModal.homeEnergyScore:
                modalParameters.title = t('modal.home-energy-title');
                modalParameters.description = t('modal.home-energy-description');
                modalParameters.primaryButton = { text: t('modal.fetch-my-data'), link: '/electric-info' };
                modalParameters.secondaryButton = { text: t('modal.ill-skip'), link: '' };
                break;
            case eModal.completeRecommendations:
                fingerprint = getStorageJSON(eStorage.fingerprint);
                modalParameters.title = t('modal.complete-recommendations-title');
                modalParameters.description = t('modal.complete-recommendations-description');
                modalParameters.primaryButton = { text: t('modal.lets-it'), link: fingerprint?.level === 'smart' ? '/genius-fingerprint-data' : '/smart-fingerprint-data' };
                modalParameters.secondaryButton = { text: t('modal.ill-skip'), link: '' };
                break;
            case eModal.completeAchievements:
                fingerprint = getStorageJSON(eStorage.fingerprint);
                modalParameters.title = t('modal.complete-achievements-title');
                modalParameters.description = t('modal.complete-achievements-description');
                modalParameters.primaryButton = { text: t('modal.lets-it'), link: fingerprint?.level === 'smart' ? '/genius-fingerprint-data' : '/smart-fingerprint-data' };
                modalParameters.secondaryButton = { text: t('modal.ill-skip'), link: '' };
                break;
            case eModal.completeQuiz:
                fingerprint = getStorageJSON(eStorage.fingerprint);
                modalParameters.title = t('modal.complete-quiz-title');
                modalParameters.description = t('modal.complete-quiz-description');
                modalParameters.primaryButton = { text: t('modal.lets-it'), link: fingerprint?.level === 'smart' ? '/genius-fingerprint-data' : '/smart-fingerprint-data' };
                modalParameters.secondaryButton = { text: t('modal.ill-skip'), link: '' };
                break;
            case eModal.skypMeter:
                modalParameters.title = t('modal.skyp-titile');
                modalParameters.description = t('modal.skyp-description');
                modalParameters.primaryButton = { text: 'Skyp', link: '/home' };
                modalParameters.secondaryButton = { text: t('general.start-audit'), link: '/electric-info' };
                break;
            default: ;
        }
    }

    useEffect(() => {
        prepareModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = () => {
        if (modalParameters.primaryButton.link) {
            navigate(modalParameters.primaryButton.link);
        }
        props.setModalOpen(false);
    };

    prepareModal();

    return (
        <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'sm'} keepMounted onClose={handleClose}>
            <DialogTitle className='text-center'>{modalParameters.title}</DialogTitle>
            <hr className='m-0' />
            <DialogContent>
                <h3 dangerouslySetInnerHTML={{ __html: modalParameters.description }}></h3>
            </DialogContent>
            <hr className='mb-0 mt-2' />
            <DialogActions className='justify-content-md-center'>
                
                {modalParameters.secondaryButton.text !== '' &&
                    <Button className='btn-secondary' onClick={() => props.setModalOpen(false)}>{modalParameters.secondaryButton.text}</Button>
                }
                {modalParameters.primaryButton.text !== '' &&
                    <Button className='btn-primary' onClick={handleClose}>{modalParameters.primaryButton.text}</Button>
                }
            </DialogActions>
        </Dialog>
    );
}
