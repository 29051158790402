
import { useTranslation } from 'react-i18next';
import { Dialog, Slide, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { forwardRef, useEffect } from 'react';
import imageMaintenance from "../../Assets/page-maintenance.png";
import { Link } from 'react-router-dom';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalTask(props) {
    const { t } = useTranslation();

    useEffect(() => {
    }, []);

    const handleClose = () => {
        props.setModal(false);
    };

    return (
        <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'md'} keepMounted onClose={handleClose}>
            <DialogTitle className='text-center'>Tasks</DialogTitle>
            <hr className='m-0' />
            <DialogContent>
                <div className="row justify-content-center">
                    <div className='col-md-12 text-center'>
                        <Button className='btn-primary'>Add New Task</Button>
                    </div>
                </div>
                <hr />
                <div className='row'>
                    <div className='col-md-12'>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell scope="col">Task</TableCell >
                                        <TableCell scope="col">Type</TableCell >
                                        <TableCell scope="col">Rule</TableCell >
                                        <TableCell scope="col">Status</TableCell >
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Power Off Air Conditioning</TableCell >
                                        <TableCell>Action</TableCell>
                                        <TableCell>If temperature more than 45 </TableCell>
                                        <TableCell>Active</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Notification Optimal Moment</TableCell >
                                        <TableCell>Notification</TableCell>
                                        <TableCell>If price is less or equal 45 </TableCell>
                                        <TableCell>Active</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </DialogContent>
            <hr className='mb-0 mt-2' />
            <DialogActions className='justify-content-md-center'>
                <Button className='btn-secondary me-1' onClick={handleClose}>
                    CLOSE
                </Button>
            </DialogActions>
        </Dialog>
    );
}