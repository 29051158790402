/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import { Stack, Step, StepLabel, Stepper } from "@mui/material";
import Header from "../Header";
import AddressInfo from "../AddressInfo";
import QontoStepIcon from "../../../Components/Shared/Stepper";
import StepConnectorCustom from "../../../Components/Shared/StepConnector";
import ServiceInfo from "../ServiceInfo";
import UserInfo from "../UserInfo";
import AutopayInfo from "./AutopayInfo";
import OrderInfo from "../OrderInfo";
import { eStorage, getStorageJSON } from "../../../Services/StorageService";
import ResponseInfo from "../ResponseInfo";
import { getServiceTypeDate, getWaiveDepositOptions } from "../../../Components/IntegrationBase/ChariotBase";

function Chariot(props) {
    const { t } = useTranslation();
    const [planSelected] = useState(getStorageJSON(eStorage.planSelected));
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Address Info', 'Service Info', 'User Info', 'Autopay Info', 'Order Confirm'];
    const [address, setAddress] = useState(getStorageJSON(eStorage.address));
    const [billingAddress, setBillingAddress] = useState({ sameAddress: true });
    const [user, setUser] = useState(getStorageJSON(eStorage.user));
    const [autopay, setAutopay] = useState({});
    const [service, setService] = useState({
        requestType: 'switching'
    });
    const [waiveDeposit, setWaiveDeposit] = useState({});
    const [response, setResponse] = useState({});
    const [commentaries, setCommentaries] = useState([{ id: 1, description: t('chariot.commentary-three') }]);

    useEffect(() => {
        props.functions.setTitle(t('chariot.title'));
        if (!user.hasOwnProperty('birthdate')) {
            let date = new Date();
            date.setFullYear(date.getFullYear() - 18);
            setUser(prevState => ({
                ...prevState, ['birthdate']: date
            }));
        }

        if (!service.hasOwnProperty('serviceDate')) {
            let date = new Date();
            date.setDate(date.getDate() + 1);
            setService(prevState => ({
                ...prevState, ['serviceDate']: date
            }));
        }

        if (!user.hasOwnProperty('ssn'))
            setUser(prevState => ({
                ...prevState, ['ssn']: ''
            }));

        getWaiveDepositOptions().then(data => {
            setWaiveDeposit(data);
        });
    }, []);

    useEffect(() => {
        if (service.hasOwnProperty('serviceDate') && service.hasOwnProperty('requestType')) {
            props.functions.setIsLoading(true);
            let date = service.serviceDate.toISOString().split('T')[0];
            getServiceTypeDate(service.requestType === 'moving', date, user.esiid).then(data => {
                if (data.Status === 'Success') {
                    if (service.requestType === 'moving')
                        setCommentaries([
                            { id: 1, description: data.Description },
                            { id: 2, description: t('chariot.commentary-two') },
                            { id: 3, description: t('chariot.commentary-three') }
                        ]);
                    else
                        setCommentaries([
                            { id: 1, description: data.Description },
                            { id: 2, description: t('chariot.commentary-three') }
                        ]);
                } else {
                    setCommentaries([
                        { id: 1, description: data.Message + '. Please select another date.' }
                    ]);
                }
                props.functions.setIsLoading(false);
            });
        }
    }, [service.requestType, service.serviceDate]);

    return (
        <div className='content'>
            <div className='container'>
                <div className='row justify-content-md-center'>
                    <div className='col-sm-12 col-md-12 col-lg-10'>
                        <Header functions={props.functions} planSelected={planSelected} />
                    </div>
                </div>
                <div className='row mt-3 justify-content-md-center'>
                    <div className='col-sm-12 col-md-12 col-lg-10'>
                        <div className="container-card card-content">
                            {Object.keys(response).length === 0 &&
                                <div>
                                    {activeStep === 0 && <AddressInfo functions={props.functions} address={address} setAddress={setAddress} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                    {activeStep === 1 && <ServiceInfo commentaries={commentaries} service={service} setService={setService} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                    {activeStep === 2 && <UserInfo user={user} setUser={setUser} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                    {activeStep === 3 && <AutopayInfo autopay={autopay} setAutopay={setAutopay} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                    {activeStep === 4 && <OrderInfo billingAddress={billingAddress} address={address} setAddress={setAddress} user={user} setUser={setUser} service={service} setService={setService} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                </div>
                            }
                            {Object.keys(response).length > 0 &&
                                <ResponseInfo response={response} />
                            }
                        </div>
                    </div>
                </div>
                <Stack className="mt-4 mb-4" sx={{ width: '100%' }}>
                    <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnectorCustom />}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Stack>
            </div>
        </div>
    )
}

export default Chariot;