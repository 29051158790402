/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import completedImage from '../../Assets/Icons/completed.png';
import { BorderLinearProgress } from "../../Components/Shared/BarProgress";
import { Button } from '@mui/material';
import { getAgreementReady } from "../../Components/AgreementBase/AgreementBase";
import { eStorage, getStorage, getStorageJSON } from "../../Services/StorageService";
import { useNavigate } from "react-router-dom";
import { ePage } from "../../Components/Shared/Routes";
import { eAction, eCategory, eEvent, submitTrackingEvent } from "../../Services/EventService";
import { eAccountType } from "../../Components/Shared/Enum/EAccountType";
import imageLoading  from "../../Assets/loading.gif";

function Creating(props) {
    const { t } = useTranslation();
    const [download, setDownload] = useState({ progress: 0, status: false, counter: 0 });
    const [user] = useState(getStorageJSON(eStorage.user));
    const [location] = useState(getStorageJSON(eStorage.location));
    const navigate = useNavigate();

    useEffect(() => {
        props.functions.setTitle(t('general.initiating-agreement'));

        const interval = setInterval(() => {
            validateAgreement();
            setDownload(prevState => ({
                ...prevState, ['counter']: download.counter++, ['progress']: download.progress += 5
            }));

            if (download.counter === 60 || download.status === 'Ready')
                clearInterval(interval);


        }, 1000);
        return () => clearInterval(interval);

    }, []);

    const validateAgreement = () => {
        let accountType = getStorage(eStorage.accountType);
        let id = accountType == eAccountType.commercial ? location.id : user.customerId;

        getAgreementReady(id).then(data => {
            setDownload(prevState => ({
                ...prevState, ['status']: data.status
            }));
        });
    }

    const handleSubmit = () => {
        submitTrackingEvent(eEvent.agreementCreated, eCategory.account, eAction.created);
        navigate(ePage.acceptAgreement);
    }

    return (
        <div className="content">
            <div className='container'>
                <h1>{t('agreement.creating-subtitle')}</h1>
                <div className="row justify-content-md-center">
                    <div className="col-sm-12 col-md-10 col-lg-7">
                        <div className="container-card card-content">
                            <img alt="" className={download.status === 'Ready'? "icon-switch" : "icon-affect"} src={download.status === 'Ready' ? completedImage : imageLoading} />
                            <div className="row mt-4 justify-content-md-center">
                                <h2>{t('agreement.process-status')}</h2>
                                <div className="col-md-10 mt-1">
                                    <BorderLinearProgress variant="determinate" value={download.status === 'Ready' ? 100 : download.progress} />
                                </div>
                                <h3 className="mt-2">{t('agreement.processing')}</h3>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-12">
                                    <Button className='btn-primary' onClick={handleSubmit} disabled={download.status !== 'Ready'}>
                                        {t('agreement.go-agreement')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Creating;