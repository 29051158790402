/* eslint-disable react-hooks/exhaustive-deps */

import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { forwardRef, useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalAgreement(props) {
  const { t } = useTranslation();
  const [agreementStatus, setAgreementStatus] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    setAgreementStatus(props.user ? props.user.agreementStatus : '');
  }, []);


  const handleContinue = () => {
    props.setModalOpen(false);
    navigate('/verify-settings');
  }

  return (
    <Dialog open={props.open && props.isSession} TransitionComponent={Transition} fullWidth={true} keepMounted onClose={handleContinue}>
      <DialogTitle className='text-center'>{(agreementStatus === 'Non Active - Time Out' || agreementStatus === 'Non Active - Expired' || agreementStatus === 'No Agreements Found') ? t('agreement.expired') : t('agreement.expiring')}</DialogTitle>
      <hr className='m-0' />
      <DialogContent>
        <div className='row mt-2 justify-content-md-center'>
          <div className='col-md-12'>
            {props.user.name}, {t('agreement.linkAccessData')} {(agreementStatus === 'Non Active - Time Out' || agreementStatus === 'Non Active - Expired' || agreementStatus === 'No Agreements Found') ? t('agreement.expired') :
              agreementStatus === 'Active - Expiring in 15 days or less' ? t('agreement.expiring15') :
                agreementStatus === 'Active - Expiring in 30 days or less' ? t('agreement.expiring30') :
                  t('agreement.aboutExpire')}
            <br />{t('agreement.pressContinue')}
          </div>
        </div>
      </DialogContent>
      <hr className='mb-0 mt-2' />
      <DialogActions className='justify-content-md-center'>
        <Button className='btn-primary' onClick={handleContinue}>{t('general.continue')}</Button>
      </DialogActions>
    </Dialog>
  );
}