
import { forwardRef, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './calendar.css';
import { useTranslation } from "react-i18next";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { getGoalsPercentage } from '../../../Components/DashboardBase/DashboardBase';
import ChartGoalCalendar from './ChartGoalCalendar';

const current = new Date();

const component = (date, goalsPercentage) => {
  const renderDate = new Date(date);
  const day = renderDate.getDate();
  const month = renderDate.getMonth();

  return (
    <div>
      {goalsPercentage?.filter(e => e.day === day).length > 0 && month === current.getMonth() &&
        <ChartGoalCalendar goalPercentage={goalsPercentage?.filter(e => e.day === day)} size='sm' />}
      <span>{day}</span>
    </div>
  )
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GoalCalendarGraphics(props) {

  const { t } = useTranslation();
  const [goalsPercentage, setGoalsPercentage] = useState({});
  const [showDetail, setShowDetail] = useState(false);
  const [daySelect, setDaySelect] = useState('');

  useEffect(() => {
    if (props.open) {
      getGoalsPercentage(props.locationId).then(data => {
        setGoalsPercentage(data);
      });
    }
  }, [props.open]);

  const handleClickDay = (event) => {
    console.log(event);
    console.log(event.getDate());
    setDaySelect(event.getDate());
    setShowDetail(true);
  };

  const handleClose = () => {
    setShowDetail(false);
    props.setOpen(false);
  };

  return <div>
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      fullWidth={true} maxWidth={'sm'} keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle className='text-center'>{t('dashboard.progress-goals')}</DialogTitle>
      <hr className='m-0' />

      <DialogContent>
        {goalsPercentage.length > 0 && !showDetail &&
          <Calendar onClickDay={handleClickDay} formatDay={(locale, date) => component(date, goalsPercentage)} />
        }
        {showDetail &&
          <ChartGoalCalendar goalPercentage={goalsPercentage?.filter(e => e.day === daySelect)} size='lg' />
        }
      </DialogContent>
      <hr className='mb-0 mt-2' />
      <DialogActions className='justify-content-md-center'>
        <Button className='btn-primary' onClick={handleClose}>{t('profile.close')}</Button>
      </DialogActions>
    </Dialog>
  </div>
}