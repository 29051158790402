
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React } from 'react';
import { useNavigate } from "react-router-dom";
import { ePage } from "../../../Components/Shared/Routes";
import { eDevice } from "../../../Components/Shared/Enum/EDevice";
import { lbsToTonnes } from "../../../Util/Util";
import MixedTooltip from "../../shared/MixedTooltip";

function CardsContainerLocation(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const format = (number, fraction = 0)=>{
        const format = !number || isNaN(number) ? 0 : number;
        return format.toLocaleString('en-US', { maximumFractionDigits: fraction })
    }

    return <>
        <div className="row justify-content-md-center">
            <div className="col-sm-12 col-md-6 col-lg-3 mt-0 mt-2 align-self-center">
                <h2 className="m-0">{t('dashboard.standard')}</h2>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-3 mt-0 mt-2 align-self-center">
                <h2 className="m-0">{t('dashboard.itemized')}</h2>
            </div>
        </div>
        <div className="row">
            {props.functions.device === eDevice.mobile && <h2 className="text-start mt-3 mb-0">{t('general.annual-consumption-detail')}</h2>}
            <div className="col-sm-12 col-md-6 col-lg mt-0" onClick={() => navigate(ePage.basicFingerprint)}>
                <div className="container-card-mid-blue dashboard-card-mid justify-content-center mt-2">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{format(props.energyIntensity?.yearConsuption)} <span>kWh</span><MixedTooltip color='white' title={t('tooltips.dashboard-card-one-top')} /></h4>
                            <h2 className="mb-0">{t('dashboard.card-one')}</h2>
                        </div>
                    </div>
                </div>
                <div className="container-card-mid-withe dashboard-card-mid justify-content-center">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{props?.selectedValues?.homeSize ? format(props.energyIntensity?.yearConsuption / props.selectedValues.homeSize) : 0} <span>kWh/sqft</span><MixedTooltip color='blue' title={t('tooltips.dashboard-card-one-bottom')} /></h4>
                            <h2 className="mb-0">{t('dashboard.card-energy-intensity')}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg" onClick={() => navigate(ePage.homeEnergyScore)}>
                <div className="container-card-mid-blue dashboard-card-mid justify-content-center mt-2">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{format(lbsToTonnes(props.carbonFootprint?.co2))} <span>tonnes</span> <MixedTooltip color='white' title={t('tooltips.dashboard-card-two-top')} /></h4>
                            <h2 className="mb-0">{t('dashboard.card-four')}</h2>
                        </div>
                    </div>
                </div>
                <div className="container-card-mid-withe dashboard-card-mid justify-content-center">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{props?.selectedValues?.homeSize ? format((lbsToTonnes(props.carbonFootprint?.co2) / props.selectedValues.homeSize), 2) : 0} <span>tonnes/sqft</span><MixedTooltip color='blue' title={t('tooltips.dashboard-card-two-bottom')} /></h4>
                            <h2 className="mb-0">{t('dashboard.card-carbon-intensity')}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg" onClick={() => navigate(ePage.geniusFingerprint)}>
                <div className="container-card-mid-blue dashboard-card-mid justify-content-center mt-2">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{format(lbsToTonnes(props.generalStats?.co2))} <span>tonnes</span><MixedTooltip color='white' title={t('tooltips.dashboard-card-three-top')} /></h4>
                            <h2 className="mb-0">{t('dashboard.card-four')}</h2>
                        </div>
                    </div>
                </div>
                <div className="container-card-mid-withe dashboard-card-mid justify-content-center">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{props?.selectedValues?.homeSize ? format((lbsToTonnes(props.generalStats?.co2) / props.selectedValues.homeSize),2) : 0} <span>tonnes/sqft</span><MixedTooltip color='blue' title={t('tooltips.dashboard-card-three-bottom')} /></h4>
                            <h2 className="mb-0">{t('dashboard.card-carbon-intensity')}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg" onClick={() => navigate(ePage.smartFingerprint)}>
                <div className="container-card-mid-blue dashboard-card-mid justify-content-center mt-2">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{format(lbsToTonnes(props.carbonFootprint?.co2 - props.generalStats?.co2))} <span>tonnes</span><MixedTooltip color='white' title={t('tooltips.dashboard-card-four-top')} /></h4>
                            <h2 className="mb-0">{t('dashboard.difference-carbon')}</h2>
                        </div>
                    </div>
                </div>
                <div className="container-card-mid-withe dashboard-card-mid justify-content-center">
                    <div className="row">
                        <div className="col-md-12">
                            <h4>{props?.selectedValues?.homeSize ? format(((lbsToTonnes(props.carbonFootprint?.co2) / props.selectedValues.homeSize) - (lbsToTonnes(props.generalStats?.co2) / props.selectedValues.homeSize)),2) : 0} <span>tonnes/sqft</span><MixedTooltip color='blue' title={t('tooltips.dashboard-card-four-bottom')} /></h4>
                            <h2 className="mb-0">{t('dashboard.difference-intensity')}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default CardsContainerLocation;