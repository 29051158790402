/* eslint-disable react-hooks/exhaustive-deps */

import { useTranslation } from 'react-i18next';
import { Dialog, Slide, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { forwardRef } from 'react';
import ShareButtons from './ShareButtons';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalBadge(props) {
  const { t } = useTranslation();

  const handleClose = () => {
    props.setModal({ open: false });
  };


  return (
    <Dialog open={props.modal.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'sm'} keepMounted onClose={handleClose}>
      <DialogTitle className='text-center'>{props.modal.badge?.name?.replace('-', ' ').replace('_', ' ')}</DialogTitle>
      <hr className='m-0' />
      <DialogContent>
        <div className="row justify-content-center">
          <div className='col-md-4'>
            <img className="img-fluid" alt="" src={props.modal.badge?.icon} />
          </div>
        </div>
        <div className="row justify-content-center mt-2">
          <div className='col-md-10 text-center'>
            <h3>{props.modal.badge?.description?.en.replace('-', ' ').replace('_', ' ')}</h3>
          </div>
        </div>
        {props.modal.isObtained &&
          <div className="row justify-content-center mt-2">
            <div className='col-md-10 text-center'>
              <ShareButtons type='badge' name={props.modal.badge.badge} description={props.modal.badge.description?.en.replace('-', ' ').replace('_', ' ')} />
            </div>
          </div>
        }
      </DialogContent>
      <hr className='mb-0 mt-2' />
      <DialogActions className='justify-content-md-center'>
        <Button className='btn-primary' onClick={handleClose}>{t('profile.close')}</Button>
      </DialogActions>
    </Dialog>
  );
}