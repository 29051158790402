/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import { eStorage, getStorageJSON } from "../../Services/StorageService";
import { IconButton, Button } from '@mui/material';

function CheckoutProduct(props) {
    const { t } = useTranslation();
    const [checkout, setCheckout] = useState(getStorageJSON(eStorage.checkout));

    useEffect(() => {

    }, []);

    return (
        <div className="row">
            <div className="col-md-12 ">
                <div className="container-card card-content">

                    <h2 className="mb-3">PRODUCTS</h2>
                    <div className="row justify-content-md-center mt-3">
                        <div className="col-md-3">
                            <p className="description-text text-center">
                                <IconButton aria-label="delete" size="small">
                                    <strong>-</strong>
                                </IconButton>  1  <IconButton aria-label="delete" size="small">
                                    <strong>+</strong>
                                </IconButton>
                            </p>
                        </div>
                        <div className="col-md-6">
                            <p className="description-text text-start">
                                {checkout.name}
                            </p>
                        </div>
                        <div className="col-md-3">
                            <p className="description-text text-start">
                                <strong>${checkout.amount?.toFixed(2)}</strong>
                            </p>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-12">
                            <Button className='btn-primary mt-4 mt-md-0' >
                                Add more products
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CheckoutProduct;