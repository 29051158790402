// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-button, .menu-button:hover {
  background-color: #eae9e9 !important ;

}`, "",{"version":3,"sources":["webpack://./src/Components/Shared/menu-button.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;;AAEvC","sourcesContent":[".menu-button, .menu-button:hover {\n  background-color: #eae9e9 !important ;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
