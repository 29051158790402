/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useRef } from 'react';
import { Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Button } from '@mui/material';
import { TextValidator } from 'react-material-ui-form-validator';

function AutopayInfo(props) {
    const { t } = useTranslation();
    const formRef = useRef();

    useEffect(() => {

        if (props.autopay.isAutopay === undefined)
            props.setAutopay(prevState => ({
                ...prevState, ['isAutopay']: false
            }));

        if (props.autopay.autopayType === undefined)
            props.setAutopay(prevState => ({
                ...prevState, ['autopayType']: 'Credit Card'
            }));

        if (props.autopay.accountType === undefined)
            props.setAutopay(prevState => ({
                ...prevState, ['accountType']: 'savings'
            }));

    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        props.setAutopay(prevState => ({
            ...prevState, [name]: value
        }));
    }
    const handleSubmit = () => {
        props.setActiveStep(props.activeStep + 1);
    }

    const handleBackStep = () => {
        props.setActiveStep(props.activeStep - 1);
    };

    return (
        <div>
            <ValidatorForm
                ref={formRef}
                onSubmit={handleSubmit}
                onError={errors => console.log(errors)}
            >
                <div className="row justify-content-md-center">
                    <h2 className="mb-3">{t('general.autopay')}</h2>
                    <div className="col-md-4">
                        <FormControl size="small" fullWidth>
                            <InputLabel id="autopay">{t('general.autopay')}</InputLabel>
                            <Select labelId="autopay" required name="isAutopay" value={props.autopay.isAutopay === undefined ? false : props.autopay.isAutopay} onChange={handleChange} label={t('general.autopay')}>
                                <MenuItem value={true}>{t('general.yes')}</MenuItem>
                                <MenuItem value={false}>{t('general.no')}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {props.autopay.isAutopay &&
                        <div className="col-md-4">
                            <FormControl size="small" fullWidth>
                                <InputLabel id="autopay-type">{t('general.autopay-type')}</InputLabel>
                                <Select labelId="autopay-type" required name="autopayType" value={props.autopay.autopayType === undefined ? 'Credit Card' : props.autopay.autopayType} onChange={handleChange} label={t('general.autopay-type')}>
                                    <MenuItem value={'Credit Card'}>{t('general.credit-card')}</MenuItem>
                                    <MenuItem value={'E-Check'}>E-Check</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    }
                </div>
                {props.autopay.isAutopay &&
                    <div>
                        {props.autopay.autopayType === 'Credit Card' ?
                            <div>
                                <div className="row justify-content-md-center">
                                    <div className="col-md-4 mt-2">
                                        <TextValidator fullWidth size="small" name="cardName" value={props.autopay.cardName} onChange={handleChange} label={t('general.card-name')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.card-name'))]} />
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <TextValidator fullWidth size="small" name="cardNumber" value={props.autopay.cardNumber} onChange={handleChange} label={t('general.card-number')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.card-number'))]} />
                                    </div>
                                </div>
                                <div className="row justify-content-md-center">
                                    <div className="col-md-4 mt-2">
                                        <TextValidator fullWidth size="small" name="expiry" value={props.autopay.expiry} onChange={handleChange} label={t('general.expiry-date')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.expiry-date'))]} />
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <TextValidator fullWidth size="small" name="cvc" value={props.autopay.cvc} onChange={handleChange} label={t('general.cvc')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.cvc'))]} />
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="row justify-content-md-center">
                                    <div className="col-md-4 mt-2">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="account-type">{t('general.account-type')}</InputLabel>
                                            <Select labelId="account-type" required name="accountType" value={props.autopay.accountType === undefined ? 'savings' : props.autopay.accountType} onChange={handleChange} label={t('general.account-type')}>
                                                <MenuItem value={'savings'}>{t('general.savings-autopay')}</MenuItem>
                                                <MenuItem value={'checking'}>{t('general.checking')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <TextValidator fullWidth size="small" name="accountName" value={props.autopay.accountName} onChange={handleChange} label={t('general.account-name')} />
                                    </div>
                                </div>
                                <div className="row justify-content-md-center">
                                    <div className="col-md-4 mt-2">
                                        <TextValidator fullWidth size="small" name="routingNumber" value={props.autopay.routingNumber} onChange={handleChange} label={t('general.routing-number')} />
                                    </div>
                                    <div className="col-md-4 mt-2">
                                        <TextValidator fullWidth size="small" name="accountNumber" value={props.autopay.accountNumber} onChange={handleChange} label={t('general.account-number')} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
                <div className="row mt-4">
                    <div className="col-md-12">
                        {props.activeStep > 0 && <Button className='btn-secondary me-2' onClick={handleBackStep}>{t('general.back')}</Button>}

                        {props.activeStep < 4 && <Button className='btn-primary' type="submit">{t('general.next')}</Button>}

                        {props.activeStep === 4 && <Button className='btn-primary'type="submit">{t('general.continue')}</Button>}
                    </div>
                </div>
            </ValidatorForm>
        </div>
    )
}

export default AutopayInfo;