/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import { Button } from "@mui/material";
import NumberControl from "../../Components/Shared/NumberControl";

function Goal(props) {
    const [goalValue, setGoalValue] = useState(0);

    const { t } = useTranslation();

    useEffect(() => {
        setGoalValue(props.goalValue ?? 5);
    }, [props.goalValue]);

    const next = () => {
        if (props.skipCondition === undefined) {
            if (props.activeStep === 3) {
                if (props.handleSubmit) props.handleSubmit();
            }
            else {
                props.setActiveStep(props.activeStep + 1);
                if (props.handleFullSubmit) props.handleFullSubmit({ value: props.goalValue, type: props.type });
            }
        } else {
            if (props.handleSubmit) props.handleSubmit();
            if (props.handleFullSubmit) props.handleFullSubmit({ value: props.goalValue, type: props.type });
        }
    }

    return (
        <div className="container">
            <h1>{props.type === 'energy' ? t('goals.goal-energy') :
                props.type === 'carbon' ? t('goals.goal-carbon') :
                    t('goals.goal-cost')}</h1>
            <div className='row justify-content-md-center'>
                <div className="col-sm-12 col-md-10 col-lg-7">
                    <div className="container-card card-content">
                        <div className="row mt-3 justify-content-md-center">
                            <div className="col-sm-4 col-md-6 col-lg-4 input-goal">
                                <NumberControl
                                    className='input-goal'
                                    max={100}
                                    min={0}
                                    step={1}
                                    value={props.goalValue ?? 5}                          
                                    onChange={props.handleChange}
                                />%
                            </div>
                        </div>
                        <hr />
                        <div className="row mt-3 justify-content-md-center">
                            <div className="col-md-12">
                                <Button className='btn-primary' onClick={next} >{t('general.next')}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Goal;