
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import weekdayImage from '../../Assets/Icons/weekday.png';
import weekendImage from '../../Assets/Icons/weekend.png';
import dayImage from '../../Assets/Icons/day.png';
import nightImage from '../../Assets/Icons/night.png';
import screenshotDayImage from '../../Assets/Screenshot/screenshot-dayuse.png';
import screenshotNightImage from '../../Assets/Screenshot/screenshot-nightuse.png';
import screenshotWeekdayImage from '../../Assets/Screenshot/screenshot-weekdayuse.png';
import screenshotWeekendImage from '../../Assets/Screenshot/screenshot-weekenduse.png';
import { eStorage, getStorageJSON } from "../../Services/StorageService";

function CardsUse(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [fingerprint] = useState(getStorageJSON(eStorage.fingerprint));

    useEffect(() => {

    }, []);

    return <div className="row mt-4 mb-3">
        <div className="col-6 col-md-6 col-lg-3">
            <div className="container-card card-content h-100">
                {fingerprint === null && <img className="img-fluid" src={screenshotDayImage}></img>}
                {fingerprint !== null &&
                    <div className="row justify-content-md-center">
                        <div className="col-md-5">
                            <img alt="" className="icon-fingerprint" src={dayImage} />
                        </div>
                        <div className="col-md-6">
                            <h2 className="mb-0 mt-0 mt-md-3">{t('basic-fingerprint.day-use')}</h2>
                            <h4>{Object.keys(props.dayNightIndexes).length > 0 && Math.round(props.dayNightIndexes.dayStats.percentage * 100)} %</h4>
                        </div>
                    </div>
                }
            </div>
        </div>
        <div className="col-6 col-md-6 col-lg-3 mt-md-2 mt-md-0">
            <div className="container-card card-content h-100">
                {fingerprint === null && <img className="img-fluid" src={screenshotNightImage}></img>}
                {fingerprint !== null &&
                    <div className="row justify-content-md-center">
                        <div className="col-md-5">
                            <img alt="" className="icon-fingerprint" src={nightImage} />
                        </div>
                        <div className="col-md-6">
                            <h2 className="mb-0 mt-0 mt-md-3">{t('basic-fingerprint.night-use')}</h2>
                            <h4>{Object.keys(props.dayNightIndexes).length > 0 && Math.round(props.dayNightIndexes.nightStats.percentage * 100)} %</h4>
                        </div>
                    </div>
                }
            </div>
        </div>
        <div className="col-6 col-md-6 col-lg-3 mt-2 mt-md-0">
            <div className="container-card card-content h-100">
                {fingerprint === null && <img className="img-fluid" src={screenshotWeekdayImage}></img>}
                {fingerprint !== null &&
                    <div className="row justify-content-md-center">
                        <div className="col-md-5">
                            <img alt="" className="icon-fingerprint" src={weekdayImage} />
                        </div>
                        <div className="col-md-6">
                            <h2 className="mb-0 mt-0 mt-md-3">{t('basic-fingerprint.weekday-use')}</h2>
                            <h4>{Object.keys(props.weekWeekendIndexes).length > 0 && Math.round(props.weekWeekendIndexes.weekStats.percentage * 100)} %</h4>
                        </div>
                    </div>
                }
            </div>
        </div>
        <div className="col-6 col-md-6 col-lg-3 mt-2 mt-md-0">
            <div className="container-card card-content h-100">
                {fingerprint === null && <img className="img-fluid" src={screenshotWeekendImage}></img>}
                {fingerprint !== null &&
                    <div className="row justify-content-md-center">
                        <div className="col-md-5">
                            <img alt="" className="icon-fingerprint" src={weekendImage} />
                        </div>
                        <div className="col-md-6">
                            <h2 className="mb-0 mt-0 mt-md-3">{t('basic-fingerprint.weekend-use')}</h2>
                            <h4>{Object.keys(props.weekWeekendIndexes).length > 0 && Math.round(props.weekWeekendIndexes.weekendStats.percentage * 100)} %</h4>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
}

export default CardsUse;