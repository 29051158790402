import { React, useEffect, useState } from 'react';
import * as Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';
import HighchartsMap from "highcharts/modules/map";
import mapData from "@highcharts/map-collection/countries/us/us-tx-all.topo.json";
import proj4 from "proj4";
import { eMetric } from '../../../Components/Shared/Enum/EMetric';

HighchartsMap(Highcharts);


function ChartTexas(props) {;
    if (typeof window !== "undefined") {
        window.proj4 = window.proj4 || proj4;
    }

    const [locations, setLocations] = useState([]);
    const [viewChart, setViewChart] = useState(true);

    useEffect(() => {
        if (props.locations.length > 0) {
            buildPoints();
        }
    }, [props.locations]);

    useEffect(() => {
        setViewChart(false);
        setTimeout(function () {
            setViewChart(true);

        }, 500);
    }, [props.metric, props.period]);

    const buildPoints = () => {
        const formatLocations = [];
        props.locations.forEach(location => {
            formatLocations.push({
                z: props.metric === eMetric.carbon ? location.tableGoals?.carbon.value : props.metric === eMetric.energy ? location.tableGoals?.energy.value : location.tableGoals?.cost.value,
                lat: parseFloat(location.latitude),
                lon: parseFloat(location.longitude),
                keyword: location.name
            })
        });
        setLocations(formatLocations);
    }

    function buildChart() {
        return {
            chart: {
                height: '320px',
                events: {
                    load: function (e) {
                        this.mapView.zoomBy(2.6, [39.99, -73.59]);
                    }
                }
            },
            credits: { enabled: false },
            title: {
                text: null
            },
            mapNavigation: {
                enabled: true,
                buttonOptions: {
                    verticalAlign: 'bottom'
                }
            },
            colorAxis: {
                min: 0,
                max: 100,
                stops: [
                    [0, '#ED1C24'],
                    [0.6, '#F4E424'],
                    [0.8, '#58B266']
                ],
                labels: {
                    formatter: function () {
                        return this.value + "%";
                    }
                }
            },

            series: [{
                name: 'Basemap',
                borderColor: '#29abe2',
                nullColor: 'rgba(200, 200, 200, 0.3)',
                showInLegend: false,
                mapData: mapData,
            }, {
                type: 'mapbubble',
                name: 'Location',
                data: locations
            }]
        }

    }

    return (
        <>
            {locations.length > 0 && viewChart &&
                <HighchartsReact allowChartUpdate={false} constructorType={'mapChart'} highcharts={Highcharts} options={buildChart()} />
            }
        </>
    )
}
export default ChartTexas;