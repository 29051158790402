/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import completedImage from '../../Assets/Icons/completed.png';
import { BorderLinearProgress } from "../../Components/Shared/BarProgress";
import { Button } from '@mui/material';
import { getDataReady, postFingerprint } from "../../Components/DownloadingBase/DownloadingBase";
import { ePage } from "../../Components/Shared/Routes";
import { useNavigate } from "react-router-dom";
import { eStorage, getStorage, getStorageJSON } from "../../Services/StorageService";
import { eAccountType } from "../../Components/Shared/Enum/EAccountType";
import imageLoading from "../../Assets/loading.gif";

function Downloading(props) {
    const { t } = useTranslation();
    const [download, setDownload] = useState({ progress: 0, status: false, counter: 0 });
    const navigate = useNavigate();
    const [user] = useState(getStorageJSON(eStorage.user));
    const [accountType] = useState(getStorage(eStorage.accountType));

    useEffect(() => {
        props.functions.setTitle(t('general.data-link'));

        const interval = setInterval(() => {
            validateData();
            setDownload(prevState => ({
                ...prevState, ['counter']: download.counter++, ['progress']: download.progress += 1
            }));

            if (download.counter === 300 || download.status) {
                clearInterval(interval);
            }

        }, 1000);
        return () => clearInterval(interval);

    }, []);

    const validateData = () => {
        getDataReady().then(data => {
            setDownload(prevState => ({
                ...prevState, ['status']: data.data_ready
            }));
        });
    }

    const handleSubmit = () => {
        postFingerprint().then(data => {
            const isRenew = getStorage(eStorage.renew);
            if (data.level === 'basic')
                props.functions.setFingerprint(data);

            if (isRenew) {
                localStorage.removeItem('agreement');
                navigate(ePage.home);
            }

            if (accountType === eAccountType.commercial) {
                navigate(ePage.smartFingerprintData);
            }
            else
                navigate(ePage.inputs);
        });
    }

    return (
        <div className="content">
            <div className='container'>
                <h1>{t('downloading.subtitle-download')}</h1>
                <div className="row justify-content-md-center">
                    <div className="col-sm-12 col-md-10 col-lg-7">
                        <div className="container-card card-content">
                            <img alt="" className={download.status ? "icon-switch" : "icon-affect"} src={download.status ? completedImage : imageLoading} />
                            <div className="row mt-4 justify-content-md-center">
                                <h2>{t('agreement.process-status')}</h2>
                                <div className="col-md-10 mt-1">
                                    <BorderLinearProgress variant="determinate" value={(download.status) ? 100 : download.progress} />
                                </div>
                                <h3 className="mt-2">{t('downloading.downloading-data')}</h3>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-12">
                                    <Button className='btn-primary' onClick={handleSubmit} disabled={!download.status}>
                                        {t('general.next')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Downloading;