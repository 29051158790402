import { React, useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Button } from '@mui/material';
import { ePage } from './../../Components/Shared/Routes';
import { useNavigate } from 'react-router-dom';
import ModalChangePassword from './ModalChangePassword';

function Account(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);

    useEffect(() => {

    }, []);

    const closeHandle = () =>{
        navigate(ePage.closeAccount);
    }

    const handlePass = ()=>{
        setModal(true);
    }

    return (
        <div>
            <div className="row justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-start'><strong>{t('profile.password')}</strong></h3>
                    <h3 className='text-start'>{t('profile.chose-password')}</h3>
                </div>
                <div className="col-md-5">
                    <Button className='btn-primary' onClick={handlePass}>
                        {t('profile.change')}
                    </Button>
                </div>
            </div>
            <hr />
            <div className="row justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-start'><strong>{t('profile.close-account')}</strong></h3>
                    <h3 className='text-start'>{t('profile.options')}</h3>
                </div>
                <div className="col-md-5">
                    <Button className='btn-primary' onClick={closeHandle}>
                        {t('profile.close')}
                    </Button>
                </div>
            </div>
            <ModalChangePassword open={modal} setModalOpen={setModal}/>
        </div>
    )
}

export default Account;