import { ListItem, ListItemAvatar, Avatar, Divider, ListItemText } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { postCommentSuscribe } from '../../Components/ChallengesBase/ChallengesBase';
import { eStorage, getStorageJSON } from '../../Services/StorageService';
import { useNavigate } from 'react-router-dom';

function RecommendationComplete(props) {
    const navigate = useNavigate();

    const handleSuscribed = () => {
        let customer = getStorageJSON(eStorage.user);
        let model = {
            challengeId: props.item.id,
            customerId: customer.customerId
        }

        postCommentSuscribe(model).then(data => {
            Notify.success('You have successfully subscribed to the challenge.', { position: 'center-bottom', });
            navigate('/challenge/' + props.item.id);
        });
    };

    return (
        <>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <EmojiEventsIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={props.item.recommendation} secondary={new Date(props.item.created).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })} />
            </ListItem>
            <Divider variant="inset" component="li" />
        </>
    )
}

export default RecommendationComplete;