

import { eAction, eCategory, eEvent, submitTrackingEvent } from "../../Services/EventService";
import { eStorage, getStorage } from "../../Services/StorageService";
import { dataServiceApiInstance, orchestratorApiInstance } from "../../Util/Api";
import { eAccountType } from "../Shared/Enum/EAccountType";


export const getAgreementReady = (id) => {
    let accountType = getStorage(eStorage.accountType);
    let url = accountType === eAccountType.commercial ? 'V2/smt2/isAgreementReady?locationId=' : 'smt2/isAgreementReady?customerId=';

    const response = orchestratorApiInstance.get(url + id)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getAgreementContent = (id, container) => {
    let accountType = getStorage(eStorage.accountType);
    let url = accountType === eAccountType.commercial ? 'V2/smt2/getAgreementContent?locationId=' : 'smt2/getAgreementContent?customerId=';

    const response = orchestratorApiInstance.get(url + id)
        .then(({ data }) => {
            container.innerHTML = data.content;
            return data;
        });
    return response;
}

export const postAgreementAccepted = (id) => {
    let requestId = getStorage(eStorage.requestId);
    let accountType = getStorage(eStorage.accountType);

    const isRenew = getStorage(eStorage.renew);

    let url = '';
    if (isRenew)
        url = accountType === eAccountType.commercial ? 'V2/smt2/renewalAccepted?locationId=' : 'smt2/renewalAccepted?customerId=';
    else
        url = accountType === eAccountType.commercial ? 'V2/smt2/agreementAccepted?locationId=' : 'smt2/agreementAccepted?customerId=';

    const response = orchestratorApiInstance.post(url + `${id}&requestId=${requestId}`)
        .then(({ data }) => {
            if (data.status === "Success")
                submitTrackingEvent(eEvent.agreementAccepted, eCategory.account, eAction.updated);

            return data;
        });
    return response;
}

export const renewAgreement = (json) => {
    const response = orchestratorApiInstance.post('smt2/renewAgreement', json)
        .then(({ data }) => {
            return data;
        })
        .catch(error => {
            console.log(error);
            throw error;
        });
    return response;
}

export const postDoAcceptAgreement = (urlAgreement) => {
    let accountType = getStorage(eStorage.accountType);
    let url = accountType === eAccountType.commercial ? 'V2/smt2/doAcceptAgreement?url=' : 'smt2/doAcceptAgreement?url=';
    const response = orchestratorApiInstance.get(url + urlAgreement)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getRepsData = () => {
    const response = dataServiceApiInstance.get(`reps`)
        .then(({ data }) => {
            return data;
        });
    return response;
}