/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import ImageGoogle from '../../Assets/Logos/logo-google.png'
import { Avatar, Button } from "@mui/material";
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { eStorage, getStorageJSON } from "../../Services/StorageService";
import { getDevicesGoogle, postToken, postDevice, getDevices } from "../../Components/DeviceBase/DeviceBase";
import ModalSelectDevice from "./ModalSelectDevice";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { getParams } from "../../Util/Util";

function ConnectDeviceNest(props) {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [devices, setDevices] = useState([]);
    const [modal, setModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        let code = searchParams.get("code");
        let customer = getStorageJSON(eStorage.user);
        if (code) {
            let model = {
                authorize: code,
                customerId: customer.customerId,
                platform: 'GOOGLE'
            }
            getToken(model);
        }
    }, []);

    const getToken = (model) => {
        postToken(model).then(data => {
            getAllDevices();
            //setModal(true);
        }).catch(console.log);
    }

    const getAllDevices = () => {
        props.functions.setIsLoading(true);
        let customer = getStorageJSON(eStorage.user);
        Promise.all([getDevicesGoogle(customer.customerId), getDevices(customer.customerId)])
            .then(data => {
                const googleDevices = data[0];
                const devices = data[1] ?? [];
                let filtered = [];
                if (googleDevices) {
                    if (devices.length == 0) {
                        filtered = googleDevices;
                    } else {
                        googleDevices.forEach(element => {
                            const id = element.id;
                            const exist = devices.find(f => f.apiId == id);
                            if (!exist) {
                                filtered.push(element);
                            }
                        });
                    }

                    if (filtered.length > 0) {
                        let customer = getStorageJSON(eStorage.user);
                        const requests = filtered.map(m => {
                            let model = {
                                customerId: customer.customerId,
                                deviceId: 'thermostat',
                                apiId: m.id,
                                name: m.name,
                                type: "GOOGLE"
                            }
                            return postDevice(model);
                        })

                        Promise.all(requests).then(data => {
                            Notify.success('The devices was added successfully.', { position: 'center-bottom', });
                            navigate('/my-usage?tab=mysmartdevice');
                        }).catch(console.log);
                    }
                } else {
                    navigate('/my-usage?tab=mysmartdevice');
                }
            }).catch(console.log)
            .finally(props.functions.setIsLoading(false));
    }

    return (
        <div className='content'>
            <ModalSelectDevice devices={devices} open={modal} setModal={setModal} />
            <div className="row justify-content-center">
                <div className="col-md-7">
                    <div className="container-card card-content">
                        <h2 className="text-center">CONNECT GOOGLE NEST THERMOSTAT</h2>
                        <div className="row justify-content-center">
                            <div className="col-md-1">
                                <Avatar className="b-brand">
                                    <img className="text-center" alt="google logo" src={ImageGoogle} />
                                </Avatar>
                            </div>
                        </div>

                        <p className="description-text text-center mt-3">
                            Your thermostat will automatically switch to eco mode during energy-saving events. You will be rewarded for participating, and you will receive a notification before each event. Opt-out anytime.
                        </p>

                        <Button className='btn-primary' component={Link} to={getParams().NEST_PATH_SDM} >CONNECT DEVICE</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConnectDeviceNest;