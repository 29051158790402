import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect } from 'react';
import * as Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';
import { eStorage, getStorage } from "../../Services/StorageService";
import { eMetric } from "../../Components/Shared/Enum/EMetric";

function ChartWeekPrevius(props) {
    const { t } = useTranslation();

    useEffect(() => {

    }, []);

    function buildChart() {
        const categories = getStorage(eStorage.language) === 'es' ?
            ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"] : ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        return {
            chart: {
                type: 'spline',
                height: '270px'
            },
            credits: { enabled: false },
            title: {
                text: null
            },
            xAxis: {
                type: 'category',
                categories: categories
            },
            yAxis: [
                {
                    title: {
                        text: props.metric === eMetric.energy ? t('dashboard.energy-consumption') : props.metric === eMetric.carbon ? t('dashboard.carbon-emission') : t('dashboard.cost-dollar'),
                        x: -15,
                    },
                },
                {
                    title: {
                        text: t('dashboard.temperature')
                    },
                    opposite: true
                }
            ],
            tooltip: {
                shared: true
            },
            series: [{
                name: props?.periodData?.lastWeek_start + " - " + props?.periodData?.lastWeek_end,
                data: props?.periodData?.lastWeek,
                type: 'column',
                yAxis: 0,
                tooltip: {
                    pointFormat: '',
                }
            },
            {
                name: props?.periodData?.previousWeek_start + " - " + props?.periodData?.previousWeek_end,
                data: props?.periodData?.previousWeek,
                yAxis: 0,
                type: 'column',
                tooltip: {
                    pointFormat: '',
                },
                color: "rgb(3, 47, 74, 0.5)"
            },
            {
                name: t('dashboard.temperature-difference'),
                data: props?.periodData?.temperature_difference_detail,
                yAxis: 1,
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.y:.1f}°F</b>',
                },
                color: '#FCB400'
            }
            ]
        }
    }

    return (
        <HighchartsReact allowChartUpdate={true} highcharts={Highcharts} options={buildChart()} />
    )
}

export default ChartWeekPrevius;