import { dataServiceApiInstance } from "../../Util/Api";
import { eStorage, getStorageJSON } from "../../Services/StorageService";

export const GetCustomerOrder = (customerId) => {
  let user = getStorageJSON(eStorage.user);
  const response = dataServiceApiInstance.get(`/order/byCustomerId/${user.customerId}`)
    .then((response) => {
      return response?.data;
    });
  return response;
}