
import { useTranslation } from 'react-i18next';
import { Dialog, Slide, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { eStorage, getStorageJSON } from '../../Services/StorageService';
import { postCommentSuscribe } from '../../Components/ChallengesBase/ChallengesBase';
import { useNavigate } from 'react-router-dom';
import { Notify } from 'notiflix/build/notiflix-notify-aio';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function ModalChallenge(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
    }, []);

    const handleClose = () => {
        props.setModal(false);
    };

    const handleSuscribed = () => {
        let customer = getStorageJSON(eStorage.user);
        let model = {
            challengeId: props.challenge.id,
            customerId: customer.customerId
        }

        postCommentSuscribe(model).then(data => {
            Notify.success('You have successfully subscribed to the challenge.', { position: 'center-bottom', });
            navigate('/challenge/' + props.challenge?.id + '/' + data.id);
        });
    };

    return (
        <Dialog open={props.modalOpen} TransitionComponent={Transition} fullWidth={true} maxWidth={'md'} keepMounted onClose={handleClose}>
            <DialogTitle className='text-center'>{props.challenge?.name}</DialogTitle>
            <hr className='m-0' />
            <DialogContent>
                <div className="row mt-3 justify-content-md-center">
                    <div className="col-md-5 ">
                        <div className="row">
                            <div className="col-md-12">
                                <h6 className="text-start">Deadline {new Date(props.challenge?.endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h6 className="text-start">Description:</h6>
                                <p className="description-text text-start">{props.challenge?.challengeDescription}</p>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-12">
                                <h6 className="text-start">Why is this important?</h6>
                                <p className="description-text text-start">{props.challenge?.important}</p>
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-12">
                                <h6 className="text-start">Education</h6>
                                <p className="description-text text-start">{props.challenge?.education}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-12">
                                <h6 className="text-start">Prize:</h6>
                                <p className="description-text text-start">{props.challenge?.prizeDescription}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h6 className="text-start">How will winners be chosen?</h6>
                                <p className="description-text text-start">{props.challenge?.winners}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h6 className="text-start">How to participate:</h6>
                                <p className="description-text text-start">{props.challenge?.participate}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <hr className='mb-0 mt-2' />
            <DialogActions className='justify-content-md-center'>
                <Button className='btn-secondary' onClick={handleClose}>{t('profile.close')}</Button>
                <Button className='btn-primary' onClick={handleSuscribed}>Suscribe</Button>
            </DialogActions>
        </Dialog >
    );
}