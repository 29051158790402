/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Badge, Toolbar, Typography, Button, MenuItem, Select, Tooltip, Menu, Divider, ListItemIcon, FormControl, InputLabel, AppBar } from '@mui/material';
import Logo from '../../Assets/logo-header.png';
import "bootstrap/dist/css/bootstrap.min.css";
import { useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import LocaleContext from '../../Util/LocaleContext';
import i18n from '../../Util/i18n';
import { useNavigate, useLocation } from "react-router-dom";
import { ePage } from './Routes';
import { clearStorage, eStorage, getStorage, getStorageJSON, saveStorage } from '../../Services/StorageService';
import SettingsIcon from '@mui/icons-material/Settings';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import detectBrowserLanguage from 'detect-browser-language';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { updateLanguageData } from '../AuthBase/AuthBase';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Logout from '@mui/icons-material/Logout';
import PremiumImage from '../../Assets/premium.png';
import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import { eDevice } from './Enum/EDevice';
import { eAccountType } from './Enum/EAccountType';
import avatar from '../../Assets/avatar.png';
import { getParams } from './../../Util/Util';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import NotificationsIcon from '@mui/icons-material/Notifications';
import "./menu-button.css";
import MenuResidential from './MenuResidential';
import Notification from './Notification';
import { postUpdateStatus } from '../NotificationBase/NotificationBase';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

function NavBar(props) {
    const { locale } = useContext(LocaleContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorNotification, setAnchorNotification] = useState(null);
    const openNotification = Boolean(anchorNotification);
    const open = Boolean(anchorEl);
    const [currentPath, setCurrentPath] = useState('');
    const fileElement = useRef(null);
    const [avatarUrl, setAvatarUrl] = useState('');
    const location = useLocation();
    const [newNotifications, setNewNotifications] = useState(0);

    const handleClickNotification = (event) => {
        setAnchorNotification(event.currentTarget);
        postUpdateStatus(props.user.customerId, 'viewed').then(() => {
            setNewNotifications(0);
        });
    };
    const handleCloseNotification = () => {
        setAnchorNotification(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeLanguage = (event) => {
        let value = event.target.value;
        props.setLanguage(value);
        i18n.changeLanguage(value);
        if (props.isSession)
            updateLanguage(value);

    };

    const handleLogout = () => {
        navigate(ePage.logout, { replace: true });
    }

    const handleLogin = () => {
        navigate(ePage.login, { replace: true });
    }

    useEffect(() => {
        initLanguage();
        generateUrlAvatar();
    }, []);

    useEffect(() => {
        setCurrentPath(window.location.pathname);
        generateUrlAvatar();
    }, [window.location.pathname]);

    useEffect(() => {
        if (props.currentAvatar) {
            setAvatarUrl(props.currentAvatar);
        }
        generateUrlAvatar();
    }, [props.currentAvatar]);

    useEffect(() => {
        generateUrlAvatar();
    }, [props.isSession]);

    const generateUrlAvatar = () => {
        const user = getStorageJSON(eStorage.user);
        if (user && Object.keys(user).length > 0 && user.avatar) {
            const url = `${getParams().DATA_SERVICE_BASEURL}user/avatar/${user.avatar}`;
            setAvatarUrl(url);
        }
    }

    const initLanguage = () => {
        if (!props.isSession) {
            let language = detectBrowserLanguage().split('-')[0];
            props.setLanguage(language);
            i18n.changeLanguage(language);
        } else {
            let language = getStorage(eStorage.language);
            props.setLanguage(language);
            i18n.changeLanguage(language);
        }
    }

    const handleLogo = () => {
        props.setMenuOpen(false);
        let fingerprint = getStorageJSON(eStorage.fingerprint);
        let accountType = getStorage(eStorage.accountType);

        if (accountType === eAccountType.commercial)
            navigate(ePage.dashboard);
        else if (Object.keys(props.user).length > 0) {
            let type = props.user.type?.split('::')[0];


            if ( type === undefined || type === 'BASIC' || (type === 'BASIC' && fingerprint === null))
                navigate(ePage.electricInfo);
            else if (fingerprint?.level === 'basic')
                navigate(ePage.inputs);
            else
                navigate(ePage.home);
        }
    }

    const updateLanguage = (language) => {
        props.setIsLoading(true);
        updateLanguageData(props.user.customerId, language).then(() => {
            props.setIsLoading(false);
            saveStorage(eStorage.language, language)
        });
    }

    const handleViewLocations = () => {
        let accountType = getStorage(eStorage.accountType);
        if (accountType === eAccountType.commercial)
            navigate(ePage.locations);
    }

    const handleAvatar = () => {
        if (props.setModalAvatar) {
            fileElement.current.click();
            //props.setModalAvatar(true);
        }
    }

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    const onFileChange = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            var totalSizeMB = file.size / Math.pow(1024, 2)
            if (totalSizeMB > 1.024) {
                Notify.failure(t('File is too big!'), { position: 'center-bottom', });
            } else {
                let imageDataUrl = await readFile(file)
                props.setImageSrc(imageDataUrl);
                props.setModalAvatar(true);
                /*try {
                    // apply rotation if needed
                    const orientation = await getOrientation(file)
                    const rotation = ORIENTATION_TO_ANGLE[orientation]
                    if (rotation) {
                        imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
                    }
                } catch (e) {
                    console.warn('failed to detect the orientation')
                }

                setImageSrc(imageDataUrl)**/
            }
        }
    }

    return (
        <AppBar component="nav">
            <Toolbar>
                {props.isSession && currentPath !== '/select-path' && <IconButton
                    color="primary"
                    sx={{ display: { sm: 'none' } }}
                    edge="start"
                    onClick={() => props.setMenuOpen(!props.menuOpen)}>
                    <MenuIcon />
                </IconButton>
                }
                <Typography className='nav-container-logo'
                    component="div"
                    sx={{ flexGrow: 1, display: { xs: 'inline', sm: 'block' }, textAlign: { xs: 'center', md: 'left', sm: 'left' } }} >

                    <img className='nav-logo object-select' onClick={handleLogo} src={Logo} alt="logo" />
                </Typography>
                {props.isSession && props.showMenu && props.accountType === eAccountType.residential &&
                    <MenuResidential />
                }
                <Box sx={{ display: { xs: 'none', sm: 'contents', md: 'contents' } }}>
                    {props.isSession && <>
                        {props.accountType === eAccountType.residential &&
                            <div style={{ display: 'inline-block', marginRight: '10px', color: '#000000', cursor: 'pointer' }} onClick={handleAvatar}>
                                {/* <PersonAddIcon sx={{ fontSize: 40 }} /> */}
                                <img src={avatarUrl} alt="avatar" className='avatar' onError={(e) => { e.target.src = avatar }} />
                                <input type="file" style={{ display: 'none' }} max-file-size="1024" onChange={onFileChange} accept="image/*" ref={fileElement} />
                            </div>
                        }
                        <div className='user-info' onClick={() => handleViewLocations()}>
                            <p className='m-0'>
                                {props.user.subscriptionPremium && <img className='icon-premium' src={PremiumImage} alt="premium" />}
                                {props.accountType === eAccountType.commercial ? props.company.name : props.user.name + ' ' + props.user.lastName} {props.accountType === eAccountType.commercial ? <BusinessIcon className='icon-house-type' /> : <HomeIcon className='icon-house-type' />}</p>
                            {Object.keys(props.location).length > 0 ?
                                <p className='navbar-address m-0'>{props.location.address}, {props.location.zipCode}</p> :
                                <p className='navbar-address m-0'>{props.address.address}, {props.address.zipCode}</p>
                            }
                        </div>
                    </>
                    }
                    {(props.isSession && props.device !== eDevice.tablet && props.accountType !== eAccountType.commercial) && <div className='points'>{props.points} {t('general.points')}</div>}
                    {!props.isSession && <Button className='logout' onClick={handleLogin}>
                        <VpnKeyIcon />
                    </Button>}
                </Box>
                {props.isSession &&
                    <div className='account-menu'>
                        {props.accountType === eAccountType.residential &&
                            <Tooltip title="Notifications">
                                <IconButton
                                    className='me-1'
                                    edge="end"
                                    onClick={handleClickNotification}>
                                    <Badge badgeContent={newNotifications} color="primary">
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        }
                        <Tooltip title="Account settings">
                            <IconButton
                                edge="end"
                                onClick={handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                        </Tooltip>
                        <Notification setNewNotifications={setNewNotifications} handleCloseNotification={handleCloseNotification} openNotification={openNotification} anchorNotification={anchorNotification} />
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem>
                                <FormControl size="small">
                                    <InputLabel id="language"></InputLabel>
                                    <Select labelId="language" className='language-change' value={props.language} onChange={handleChangeLanguage} >
                                        <MenuItem value={'es'}>{t('general.spanish')}</MenuItem>
                                        <MenuItem value={'en'}>{t('general.english')}</MenuItem>
                                    </Select>
                                </FormControl></MenuItem>
                            {Object.keys(props.address).length > 0 && props.accountType === eAccountType.residential &&
                                <div>
                                    <Divider className='m-0' />
                                    <MenuItem component={Link} to="/profile" replace={true}><ListItemIcon><SettingsIcon fontSize="small" /></ListItemIcon> {t('general.configuration')}</MenuItem>
                                </div>
                            }
                            <Divider className='m-0' />
                            <MenuItem component={Link} to="/order"><ListItemIcon><ShoppingBagIcon fontSize="small" /></ListItemIcon>Orders</MenuItem>
                            <Divider className='m-0' />
                            <MenuItem onClick={handleLogout}><ListItemIcon><Logout fontSize="small" /></ListItemIcon>{t('general.logout')}</MenuItem>
                        </Menu>
                    </div>
                }
            </Toolbar>
        </AppBar >
    )
}

export default NavBar;
