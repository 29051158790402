import { eLevel } from "../../Services/LevelService";
import { eStorage, getStorage } from "../../Services/StorageService";
import { dataServiceApiInstance } from "../../Util/Api";


export const getPointsData = (customerId) => {
    const response = dataServiceApiInstance.get(`/actionplan/currentPoints?customerId=${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getLastMonthVSLastYearData = (customerId) => {
    let requestId = getStorage(eStorage.requestId);
    const response = dataServiceApiInstance.get(`/periodToPeriod/lastMonthvsLastYear/${customerId}?requestId=${requestId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getMsBadgesData = (customerId) => {
    const response = dataServiceApiInstance.get(`/actionplan/msbadges?customerId=${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getBadgesData = (customerId) => {
    const response = dataServiceApiInstance.get(`/actionplan/badges?customerId=${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getBadgesPublications = (customerId, page, pageSize) => {
    const response = dataServiceApiInstance.get(`/badges/paging?customerId=${customerId}&page=${page}&pageSize=${pageSize}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getLevel = (points) => {
    if (points >= 0 && points < 100)
        return eLevel.newSaver;
    if (points >= 100 && points < 250)
        return eLevel.bronze;
    if (points >= 250 && points < 500)
        return eLevel.silver;
    if (points >= 500 && points < 1000)
        return eLevel.gold;
    if (points >= 1000)
        return eLevel.platinum;
}

export const getNeeded = (points) => {
    if (points >= 0 && points < 100)
        return 100 - points;
    if (points >= 100 && points < 250)
        return 250 - points;
    if (points >= 250 && points < 500)
        return 500 - points;
    if (points >= 500 && points < 1000)
        return 1000 - points;
}