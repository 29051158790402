
import { useTranslation } from 'react-i18next';
import { Dialog, Slide, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { forwardRef } from 'react';
import calendarImage from '../../Assets/Icons/calendar.png';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useState } from 'react';
import { changeCurrentPlanExpiration } from '../GeniusFingerprintBase/GeniusFingerprintBase';
import { Notify } from 'notiflix/build/notiflix-notify-aio';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalExpiration(props) {
  const { t } = useTranslation();

  const [currentPlanExpiration, setCurrentPlanExpiration] = useState(null);

  const handleClose = () => {
    props.setModalOpen(false);
  };

  const handleChangeDate = (event)=>{
    setCurrentPlanExpiration(event);
  }

  const handleApplyChange = () => {
    changeCurrentPlanExpiration(new Date(currentPlanExpiration).toISOString().split('T')[0])
      .then(() => {
        Notify.success(t('general.expirationDateChangeSuccess'), { position: 'center-bottom', });
      })
      .catch((error) => {
        console.log(error);
        Notify.failure(t('general.expirationDateChangeFailed'), { position: 'center-bottom', });
      })
      .finally(() => {
        props.setModalOpen(false);
      });
  }

  return (
    <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'sm'} keepMounted onClose={handleClose}>
      <DialogTitle className='text-center'>{t('sidebar.expiration-date')}</DialogTitle>
      <hr className='m-0' />
      <DialogContent>
        <div className="row justify-content-center">
          <div className="col-md-3">
            <img className="icon-savings" src={calendarImage} alt="" />
          </div>
          <div className="col-md-6 d-flex align-items-center ">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label={t('matching-criteria.plan-expiration')}
                value={currentPlanExpiration}
                onChange={handleChangeDate}
                renderInput={(params) => <TextField style={{ width: '100%' }} size="small" {...params} />}
              />
            </LocalizationProvider>
          </div>
        </div>
      </DialogContent>
      <hr className='mb-0 mt-2' />
      <DialogActions className='justify-content-md-center'>
        <Button className='btn-primary' onClick={handleApplyChange}>{t('sidebar.apply')}</Button>
        <Button className='btn-secondary' onClick={handleClose}>{t('profile.close')}</Button>
      </DialogActions>
    </Dialog>
  );
}