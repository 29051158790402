
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { eBucket, getColor } from "../../Components/GeniusFingerprintBase/GeniusFingerprintBase";
import airImage from '../../Assets/Breakdown/air-conditioning.png';
import electronicsImage from '../../Assets/Breakdown/electronics.png';
import fridgeImage from '../../Assets/Breakdown/fridge.png';
import laundryImage from '../../Assets/Breakdown/laundry.png';
import lightingImage from '../../Assets/Breakdown/lighting.png';
import otherImage from '../../Assets/Breakdown/other.png';
import poolImage from '../../Assets/Breakdown/pool.png';
import spaceImage from '../../Assets/Breakdown/space-heater.png';
import weaterImage from '../../Assets/Breakdown/water-heater.png';
import { eStorage, getStorageJSON } from "../../Services/StorageService";

function Disaggregations(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [fingerprint] = useState(getStorageJSON(eStorage.fingerprint));

    useEffect(() => {

    }, []);

    return <div className="row">
        <div className="col-md-12">
            {props.disaggregation && props.disaggregation.sortedYearly && props.disaggregation.sortedYearly.map((row, index) => (
                row.consumption > 0 &&
                <div className="row mt-2" key={index}>
                    <div className="col-6 col-md-3 d-flex align-items-center justify-content-center">
                        {row.bucket === eBucket.cooling ? <img alt="" src={airImage} className="bucket-icon" style={{ backgroundColor: getColor(eBucket.cooling) }} /> :
                            row.bucket === eBucket.pool ? <img alt="" src={poolImage} className="bucket-icon" style={{ backgroundColor: getColor(eBucket.pool) }} /> :
                                row.bucket === eBucket.lighting ? <img alt="" src={lightingImage} className="bucket-icon" style={{ backgroundColor: getColor(eBucket.lighting) }} /> :
                                    row.bucket === eBucket.laundry ? <img alt="" src={laundryImage} className="bucket-icon" style={{ backgroundColor: getColor(eBucket.laundry) }} /> :
                                        row.bucket === eBucket.refrigerator ? <img alt="" src={fridgeImage} className="bucket-icon" style={{ backgroundColor: getColor(eBucket.refrigerator) }} /> :
                                            row.bucket === eBucket.tv ? <img alt="" src={electronicsImage} className="bucket-icon" style={{ backgroundColor: getColor(eBucket.tv) }} /> :
                                                row.bucket === eBucket.heating ? <img alt="" src={spaceImage} className="bucket-icon" style={{ backgroundColor: getColor(eBucket.heating) }} /> :
                                                    row.bucket === eBucket.heater ? <img alt="" src={weaterImage} className="bucket-icon" style={{ backgroundColor: getColor(eBucket.heater) }} /> :
                                                        <img alt="" src={otherImage} className="bucket-icon" style={{ backgroundColor: getColor(eBucket.other) }} />}

                    </div>
                    <div className="col-6 col-md-9 text-start">
                        {row.bucket === eBucket.cooling ? <span className="general-text">{t('genius-fingerprint.air-conditioning')}</span> :
                            row.bucket === eBucket.pool ? <span className="general-text">{t('genius-fingerprint.pool')}</span> :
                                row.bucket === eBucket.lighting ? <span className="general-text">{t('genius-fingerprint.lights')}</span> :
                                    row.bucket === eBucket.laundry ? <span className="general-text">{t('genius-fingerprint.laundry')}</span> :
                                        row.bucket === eBucket.refrigerator ? <span className="general-text">{t('genius-fingerprint.refrigerators')}</span> :
                                            row.bucket === eBucket.tv ? <span className="general-text">{t('genius-fingerprint.tv-electronnics')}</span> :
                                                row.bucket === eBucket.heating ? <span className="general-text">{t('genius-fingerprint.space-heater')}</span> :
                                                    row.bucket === eBucket.heater ? <span className="general-text">{t('genius-fingerprint.water-heater')}</span> :
                                                        <span className="general-text">{t('genius-fingerprint.other')} {fingerprint?.selectedValues?.electricalVehicles === 'yes' &&
                                                            <span>/ EV</span>
                                                        } </span>}
                        <p className="general-text m-0 lh-2 fw-bold">{props.viewType === 'dollars' && <span>$</span>}{Math.round(row.consumption).toLocaleString('en-US', { maximumFractionDigits: 0 })}{props.viewType !== 'dollars' && <span> kWh</span>}</p>
                    </div>
                </div>
            ))}
        </div>
    </div>
}

export default Disaggregations;