/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import { Stack, Step, StepLabel, Stepper } from "@mui/material";
// import ResponseInfo from "../ResponseInfo";
import AddressInfo from "./AddressInfo";
import ServiceInfo from "./ServiceInfo";
import UserInfo from "./UserInfo";
import OrderInfo from "./OrderInfo";
import { eStorage, getStorageJSON } from "../../Services/StorageService";
import StepConnectorCustom from "../../Components/Shared/StepConnector";
import QontoStepIcon from "../../Components/Shared/Stepper";
import CheckoutProduct from "./CheckoutProducts";

function Checkout(props) {
    const { t } = useTranslation();
    const [planSelected] = useState(getStorageJSON(eStorage.planSelected));
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Address Info', 'User Info', 'Order Confirm'];
    const [address, setAddress] = useState(getStorageJSON(eStorage.address));
    const [billingAddress, setBillingAddress] = useState({ sameAddress: true });
    const [user, setUser] = useState(getStorageJSON(eStorage.user));
    const [rep, setRep] = useState({});
    const [service, setService] = useState({
        requestType: 'switching'
    });
    const [response, setResponse] = useState({});

    const agreements = [
        { description: t('lone-star.agreement-user-one'), required: true, name: 'userOne' }
    ];

    const [terms, setTerms] = useState([
        { id: 1, description: t('lone-star.term-service-one'), required: false, value: false }
    ]);

    useEffect(() => {
        props.functions.setTitle(t('lone-star.title'));
        if (!user.hasOwnProperty('birthdate')) {
            let date = new Date();
            date.setFullYear(date.getFullYear() - 18);
            setUser(prevState => ({
                ...prevState, ['birthdate']: date
            }));
        }

        if (!service.hasOwnProperty('serviceDate')) {
            let date = new Date();
            date.setDate(date.getDate() + 1);
            setService(prevState => ({
                ...prevState, ['serviceDate']: date
            }));
        }

        if (!user.hasOwnProperty('ssn'))
            setUser(prevState => ({
                ...prevState, ['ssn']: ''
            }));

    }, []);

    const handleSubmit = () => {

    }

    const showResponse = (data) => {

    }

    return (
        <div className='content'>
            <div className='container-fluid'>
                <div className='row justify-content-md-center'>
                    <div className='col-sm-12 col-md-12 col-lg-10'>
                        {/* <Header functions={props.functions} planSelected={planSelected} /> */}
                    </div>
                </div>
                <div className='row mt-3 justify-content-md-center'>
                    <div className='col-sm-12 col-md-8 col-lg-8'>
                        <div className="container-card card-content">
                            {Object.keys(response).length === 0 &&
                                <div>
                                    {activeStep === 0 &&
                                        <AddressInfo functions={props.functions} address={address} setAddress={setAddress} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                    {activeStep === 1 &&
                                        <UserInfo user={user} setUser={setUser} agreements={agreements} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                    {activeStep === 2 &&
                                        <OrderInfo handleSubmit={handleSubmit} billingAddress={billingAddress} setBillingAddress={setBillingAddress} address={address} setAddress={setAddress} user={user} setUser={setUser} service={service} setService={setService} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                </div>
                            }
                            {/* {Object.keys(response).length > 0 &&
                                <ResponseInfo response={response} />
                            } */}
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-3 col-lg-3'>
                        <CheckoutProduct />
                    </div>
                </div> 
                <Stack className="mt-4 mb-4" sx={{ width: '100%' }}>
                    <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnectorCustom />}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Stack>
            </div>
        </div >
    )
}

export default Checkout;