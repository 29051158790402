/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Button } from '@mui/material';
import LogoPrincipal from '../../Assets/logo-enerwisely.png';
import { Link } from 'react-router-dom';
import { React, useEffect, useState, useRef } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { getRecover } from "../../Components/AuthBase/AuthBase";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { ePage } from "../../Components/Shared/Routes";
import { useNavigate } from "react-router-dom";

function Recover(props) {
    const { t } = useTranslation();
    const formRef = useRef();
    const navigate = useNavigate();
    const [model, setModel] = useState({ email: '', baseUrl: '' });

    useEffect(() => {
        props.functions.setTitle(t('general.password-recover'));
        if (props.functions.email !== '')
            setModel(prevState => ({
                ...prevState, ['email']: props.functions.email
            }));
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setModel(prevState => ({
            ...prevState, [name]: value
        }));
    }

    const handleSubmit = () => {
        props.functions.setIsLoading(true);
        getRecover(model).then(data => {
            if (data) {
                Notify.success(t('general.email-sent'), { position: 'center-bottom' });
                navigate(ePage.login);
            }

        }).catch(console.log).finally(() => props.functions.setIsLoading(false));
    }

    return (
        <ValidatorForm ref={formRef} className='content' onSubmit={handleSubmit} onError={errors => console.log(errors)}>
            <div className='container'>
                <div className='row justify-content-md-center  mb-1'>
                    <div className='col-sm-12 col-md-8 col-lg-6 container-card card-content text-center'>
                        <img className="logo-login" src={LogoPrincipal} alt="logo" />
                        <div className="row mt-4 justify-content-md-center">
                            <div className="col-md-8">
                                <TextValidator fullWidth size="small" name="email" value={model.email} onChange={handleChange} label={t('general.email')} validators={['required', 'isEmail']} errorMessages={[t('general.required').replace('#field#', t('general.email')), t('general.is-email').replace('#field#', t('general.email'))]} />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <Button className='btn-primary' type="submit">
                                    {t('general.recover')}
                                </Button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Button className='btn-basic mt-2' component={Link} to="/login">{t('general.have-account')}</Button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Button className='btn-basic mt-2' component={Link} to="/recover-sms">{t('general.password-recover-phone')}</Button>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12">
                                <Button className='btn-secondary' variant="outlined" component={Link} to="/select-path">
                                    {t('general.join-now')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ValidatorForm>
    )
}

export default Recover;