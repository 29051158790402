import { React, useState, forwardRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { useTranslation } from "react-i18next";
import Search from '../Browser/Search';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalFilter(props) {
    const { t } = useTranslation();
    const [filter, setFilter] = useState({
        use: '',
        rep: '',
        rating: '',
        contract: '',
        min: '',
        max: '',
        type: '',
        renewable: '',
        order: ''
    });

    const handleClose = () => {
        props.setModalOpen(false);
    };

    const handleSubmit = () => {
        props.handleChange(filter);
        props.setModalOpen(false);
    }

    function handleFilterChange(evt) {
        setFilter(evt);
       /* const keys = Object.keys(evt);
        let params = '';
        keys.forEach(key => {
            if (evt[key]) {
                params += `${eFilter[key]}${evt[key]}`;
            }
        });
        props.filterParams(params);*/
    }

    return (
        <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'sm'} keepMounted onClose={handleClose}>
            <DialogTitle className='text-center'>{t('general.filters')}</DialogTitle>
            <hr className='m-0' />
            <DialogContent>
                <div className='row mt-2 justify-content-md-center'>
                    <div className='col-md-12'>
                        <Search filter={filter} onFilterChange={handleFilterChange} />
                    </div>
                </div>
            </DialogContent>
            <hr className='mb-0 mt-2' />
            <DialogActions className='justify-content-md-center'>
                <Button className='btn-secondary' onClick={handleClose}>{t('general.cancel')}</Button>
                <Button className='btn-primary' onClick={handleSubmit}>{t('general.see-recommendations')}</Button>
            </DialogActions>
        </Dialog>
    );
}
