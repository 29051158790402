/* eslint-disable react-hooks/exhaustive-deps */

import { useTranslation } from 'react-i18next';
import { Dialog, Slide, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { forwardRef , useState} from 'react';
import { Document, Page, pdfjs  } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalViewPDF(props) {
  const { t } = useTranslation();

  const handleClose = () => {
    props.setModal({ open: false });
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Dialog open={props.modal.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'sm'} keepMounted onClose={handleClose}>
      <DialogTitle className='text-center'>{props.modal.name}</DialogTitle>
      <hr className='m-0' />
      <DialogContent>
        <div className="row justify-content-center">
          <div className='col-12'>
            <Document file={props.modal.url} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error}>
              <Page pageNumber={pageNumber} />
            </Document>
          </div>
        </div>
      </DialogContent>
      <hr className='mb-0 mt-2' />
      <DialogActions className='justify-content-md-center'>
        <Button className='btn-primary' onClick={handleClose}>{t('profile.close')}</Button>
      </DialogActions>
    </Dialog>
  );
}