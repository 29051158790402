import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import thermostatImage from '../../Assets/Icons/thermostat.png';
import greenImage from '../../Assets/Icons/green.png';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button, Switch, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import BarChartIcon from '@mui/icons-material/BarChart';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ModalTask from "../Device/ModalTask";

function Devices(props) {
    const { t } = useTranslation();
    const [modalTask, setModalTask] = useState(false);
    useEffect(() => {

    }, []);

    return (
        <div className="row justify-content-center mt-3">
            <ModalTask setModal={setModalTask} open={modalTask}></ModalTask>
            <div className="col-md-6">
                <div className="container-card card-content">
                    <h2 className="text-start">MY SMART DEVICES</h2>
                    <div className="row">
                        {props.devices.map((item, index) => (
                            <div key={index} className='col-4 col-md-3 text-center object-select'>
                                <img className="icon-savings" src={thermostatImage} alt="icon-savings" />
                                <h3 className="m-0">Thermostat</h3>
                                <h3 className="m-0"><strong>{item.name}</strong></h3>
                                <div className="row justify-content-center">
                                    {/* <div className="col-md-4">
                                        <Switch defaultChecked />
                                    </div> */}
                                    <div className="col-md-3">
                                        <IconButton component={Link} to={`/device/thermostat/${item.id}/${item.type}`}>
                                            <SettingsIcon />
                                        </IconButton>
                                    </div>
                                    <div className="col-md-3">
                                        <IconButton component={Link} to={"/trigger-device/"+ item.id}>
                                            <TaskAltIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <hr></hr>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <Button className='btn-primary me-1' component={Link} to="/add-device">ADD NEW DEVICE</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-2">
                <div className="container-card card-content">
                    <h2 className="text-start">Energy Use Traffic Light</h2>
                    <img className="img-fluid p-5" src={greenImage} alt="icon-savings" />

                </div>
            </div>
            <div className="col-md-3">
            </div>
        </div>
    )
}

export default Devices;