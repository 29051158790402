import { React, useState, forwardRef, useEffect } from 'react';
import { MenuItem, Select, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, FormControl, InputLabel } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { postSettings } from '../RecommendationsBase/RecommendationsBase';
import { ePage } from './Routes';
import { eStorage, getStorageJSON } from '../../Services/StorageService';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalRecommendation(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [change, setChange] = useState('');
    const [invest, setInvest] = useState('');

    useEffect(() => {
        if (props.open) {
            const storageRecommendation = getStorageJSON(eStorage.criteria);
            if (storageRecommendation) {
                setChange(storageRecommendation.pureBehavior);
                setInvest(storageRecommendation.costLevel);
            }
        }
    }, [props.open]);

    const handleClose = () => {
        props.setModalOpen(false);
    };

    const handleChange = (event) => {
        let value = event.target.value;
        if (value === 'pureBehavior')
            setInvest('');
        setChange(value);
    }

    const handleChangeInvest = (event) => {
        let value = event.target.value;
        setInvest(value);
    }

    const handleSubmit = () => {
        props.setIsLoading(true);
        let model = {
            costLevel: change === 'pureBehavior' ? 0 : invest,
            pureBehavior: change
        }

        let user = getStorageJSON(eStorage.user);
        postSettings(model, user.customerId).then(data => {
            if (data) {
                props.setModalOpen(false);
                navigate(ePage.recommendations);
                props.setIsLoading(false);
            }
        });
    }

    return (
        <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'sm'} keepMounted onClose={handleClose}>
            <DialogTitle className='text-center'>{t('recommendations.modal-title')}</DialogTitle>
            <hr className='m-0' />
            <DialogContent>
                <div className='row mt-2 justify-content-md-center'>
                    <div className='col-md-10'>
                        <FormControl size='small' fullWidth>
                            <InputLabel id="label-change">{t('recommendations.what-would')}</InputLabel>
                            <Select label={t('recommendations.what-would')} labelId="label-change" value={change} onChange={handleChange} >
                                <MenuItem value={'pureBehavior'}>{t('recommendations.pure-behavior')}</MenuItem>
                                <MenuItem value={'invest'}>{t('recommendations.invest')}</MenuItem>
                                <MenuItem value={'all'}>{t('recommendations.all')}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                {(change === 'invest' || change === 'all') &&
                    <div className='row mt-3 justify-content-md-center'>
                        <div className='col-md-10'>
                            <FormControl size='small' fullWidth>
                                <InputLabel id="label-invest">{t('recommendations.how-much')}</InputLabel>
                                <Select label={t('recommendations.how-much')} labelId="label-invest" value={invest} onChange={handleChangeInvest} >
                                    <MenuItem value={'1'}>{t('recommendations.low-invest')}</MenuItem>
                                    <MenuItem value={'2'}>{t('recommendations.medium-invest')}</MenuItem>
                                    <MenuItem value={'4'}>{t('recommendations.high-invest')}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                }
            </DialogContent>
            <hr className='mb-0 mt-2' />
            <DialogActions className='justify-content-md-center'>
                <Button className='btn-secondary' onClick={handleClose}>{t('general.close')}</Button>
                <Button className='btn-primary' disabled={change === '' || ((change === 'invest' || change === 'all') && invest === '')} onClick={handleSubmit}>{t('general.see-recommendations')}</Button>
            </DialogActions>
        </Dialog>
    );
}
