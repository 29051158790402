import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { IOSSlider, marks } from "../../Components/Shared/Slider";
import { eDevice } from "../../Components/Shared/Enum/EDevice";

function Matching(props) {
    const { t } = useTranslation();

    useEffect(() => {
    }, []);

    return (
        <div>
            <div className="row">
                <div className="col-md-5">
                    <h3 className={props.functions.device === eDevice.mobile ? "text-start" : "text-end"}>{t('matching-criteria.cost-savings')}</h3>
                </div>
                <div className="col-md-7">
                    <IOSSlider name="cost" value={props.model.cost} onChange={props.handleChange} marks={marks} valueLabelDisplay="off" />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-5">
                    <h3 className={props.functions.device === eDevice.mobile ? "text-start" : "text-end"}>{t('matching-criteria.reduce')}</h3>
                </div>
                <div className="col-md-7">
                    <IOSSlider name="carbon" value={props.model.carbon} onChange={props.handleChange} marks={marks} valueLabelDisplay="off" />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-5">
                    <h3 className={props.functions.device === eDevice.mobile ? "text-start" : "text-end"}>{t('matching-criteria.rating')}</h3>
                </div>
                <div className="col-md-7">
                    <IOSSlider name="rating" value={props.model.rating} marks={marks} onChange={props.handleChange} valueLabelDisplay="off" />
                </div>
            </div>
        </div>
    )
}

export default Matching;