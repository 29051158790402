import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect } from 'react';
import * as Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';
import { getYearMonthName } from "../../Util/Util";
import { eMetric } from "../../Components/Shared/Enum/EMetric";
//require("highcharts/modules/exporting")(Highcharts);
function CharMonthLastYear(props) {

    const { t } = useTranslation();

    useEffect(() => {

    }, []);

    function buildChart() {

        return {
            chart: {
                type: 'spline',
                height: '270px'
            },
            credits: { enabled: false },
            title: {
                text: null
            },
            xAxis: {
                type: 'category',
                categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13',
                    '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26',
                    '27', '28', '29', '30', '31']
            },
            yAxis: [{
                min: 0,
                title: {
                    text: props.metric === eMetric.energy ? t('dashboard.energy-consumption') : props.metric === eMetric.carbon ? t('dashboard.carbon-emission') : t('dashboard.cost-dollar'),
                    x: -15,
                },
            },
            {
                title: {
                    text: t('dashboard.temperature')
                },
                opposite: true,
            }],
            tooltip: {
                shared: true
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    }
                }
            },
            series: [{
                name: getYearMonthName(props?.periodData?.lastMonth_start),
                data: props?.periodData?.lastMonth,
                tooltip: {
                    pointFormat: '',
                },
                type: 'column'
            },
            {
                name: getYearMonthName(props?.periodData?.previousYearMonth_start),
                data: props?.periodData?.previousYearMonth,
                tooltip: {
                    pointFormat: '',
                },
                type: 'column',
                color: "rgb(3, 47, 74, 0.5)"
            },
            {
                name: t('dashboard.temperature-difference'),
                data: props?.periodData?.temperature_difference_detail,
                yAxis: 1,
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.y:.1f}°F</b>',
                },
                type: 'spline',
                color: '#FCB400'
            }
            ]
        }
    }

    return (
        <HighchartsReact  allowChartUpdate={true} highcharts={Highcharts} options={buildChart()} />
    )
}
export default CharMonthLastYear;