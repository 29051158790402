/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Button, List } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { eStorage, getStorageJSON } from "../../Services/StorageService";
import { getPointsData } from "../../Components/AchievementsBase/AchievementsBase";
import { getCurrentRankingData, getHistoricData } from "../../Components/RankingBase/RankingBase";
import { getParams } from "../../Util/Util";
import avatar from '../../Assets/avatar.png';
import ChallengeActive from "./ChallengeActive";
import { getChallengesSuscribedData, postCommentSuscribe } from "../../Components/ChallengesBase/ChallengesBase";
import Recommendations from "./Recommendations";
import { getRecommendationsCompleted, getRecommendationsData } from "../../Components/DashboardBase/DashboardBase";
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import { Link } from 'react-router-dom';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { useNavigate } from 'react-router-dom';
import RecommendationComplete from "./RecommendationComplete";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import House from "./House";
import thermostatImage from '../../Assets/Icons/thermostat.png';
import { getDevices } from "../../Components/DeviceBase/DeviceBase";

function Gamificator(props) {
    const { t } = useTranslation();
    const [points, setPoints] = useState('');
    const [user] = useState(getStorageJSON(eStorage.user));
    const [historicPosition, setHistoricPosition] = useState([]);
    const [avatarUrl, setAvatarUrl] = useState('');
    const [challenges, setChallenges] = useState([]);
    const [devices, setDevices] = useState([]);
    const [challengeImages, setChallengeImages] = useState([]);
    const [recommendationsCompleted, setRecommendationsCompleted] = useState([]);
    const [maxRecommendations] = useState(3);
    const [recommendations, setRecommendations] = useState([]);
    const [showConfetti, setShowConfetti] = useState(false);
    const { width, height } = useWindowSize();
    const navigate = useNavigate();
    const [currentRanking, setCurrentRanking] = useState({});

    useEffect(() => {
        getData();
        generateUrlAvatar();
    }, []);


    const getData = () => {
        props.functions.setIsLoading(true);
        getPointsData(user.customerId).then(data => {
            setPoints(data);
            props.functions.setIsLoading(false);

        });

        getHistoricData(user.customerId).then(data => {
            if (data !== '' && data !== undefined) {
                setHistoricPosition(data);
            }
            else {
                setHistoricPosition([{ position: 17 }]);
            }
        });

        getChallengesSuscribedData(user.customerId).then(data => {
            setChallenges(data);
            setChallengeImages(data.filter(w => w.imageRelation > 0));
        });

        let fingerprint = getStorageJSON(eStorage.fingerprint);
        if (fingerprint !== null) {
            getCurrentRankingData(user.customerId).then(data => {
                setCurrentRanking(data);
            });

            getRecommendationsData(user.customerId, maxRecommendations).then(data => {
                let onlyRecommendations = [];

                data.forEach((item) => {
                    item.recommendations.forEach((subItem) => {
                        onlyRecommendations.push(subItem);
                    });
                });
                setRecommendations(onlyRecommendations);
                props.functions.setIsLoading(false);
            });

            getRecommendationsCompleted(user.customerId).then(data => {
                setRecommendationsCompleted(data);
            });
        }

        getDevices(user.customerId).then(data => {
            setDevices(data);
        });
    }

    const generateUrlAvatar = () => {
        const user = getStorageJSON(eStorage.user);
        if (user && Object.keys(user).length > 0 && user.avatar) {
            const url = `${getParams().DATA_SERVICE_BASEURL}user/avatar/${user.avatar}`;
            setAvatarUrl(url);
        } else
            setAvatarUrl(avatar);
    }

    const handleSuscribed = () => {
        let customer = getStorageJSON(eStorage.user);
        let model = {
            challengeId: props.item.id,
            customerId: customer.customerId
        }

        postCommentSuscribe(model).then(data => {
            Notify.success('You have successfully subscribed to the challenge.', { position: 'center-bottom', });
            navigate('/challenge/' + props.item.id);
        });
    };

    const handleOpenDevice = (id, type) => {
        navigate(`/device/thermostat/${id}/${type}`);
    };

    return (
        <div className="content">
            {showConfetti &&
                <Confetti numberOfPieces={1000} width={width} height={height} />
            }
            <div className="row justify-content-center">

                <div className="col-md-7">
                    <div className="container-card card-content">
                        <House challengeImages={challengeImages}></House>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="container-card card-content">
                                <h2 className="text-start">MY POINTS</h2>
                                <div className="row">
                                    <div className="col-md-2">
                                        <img className="avatar" src={avatarUrl} alt="avatar" onError={(e) => { e.target.src = avatar }} />
                                    </div>
                                    <div className="col-md-10 text-md-start text-center">
                                        <h2 className="align-middle mt-2 mb-0">{user.name} {user.lastName}</h2>
                                        <h3>{points} Points</h3>
                                    </div>
                                </div>
                                <hr />
                                <div className="row mt-4">
                                    <div className="col-md-6 text-md-start text-center">
                                        <p className="description-text mb-2">Challenge completion progress </p>
                                    </div>
                                    <div className="col-md-4 text-md-start text-center">
                                        <p className="description-text mb-2"> <strong>75%</strong></p>
                                    </div>
                                </div>
                                <div className="row mt-2 mb-3 text-md-start text-center">
                                    <div className="col-md-6">
                                        <p className="description-text mt-0">CURRENT RANK</p>
                                    </div>
                                    <div className="col-md-4 text-md-start text-center">
                                        <p className="large-legend mt-0 mb-0">#{historicPosition[historicPosition.length - 1]?.position}</p>
                                        <Button className={'btn-primary-s'}>Share</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <div className="container-card card-content">
                                <h2 className="text-start">MY SMART DEVICES</h2>
                                <div className="row">
                                    {devices.map((item, index) => (
                                        <div key={index} className='col-6 col-md-3 text-center object-select' onClick={() => handleOpenDevice(item.id, item.type)}>
                                            <img className="icon-savings" src={thermostatImage} alt="icon-savings" />
                                            <h3 className="m-0">Thermostat</h3>
                                            <h3>{item.name}</h3>
                                        </div>
                                    ))}

                                </div>
                                <hr></hr>
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <Button className='btn-primary me-1' component={Link} to="/add-device">ADD NEW DEVICE</Button>
                                        <Button className='btn-secondary' variant="outlined" component={Link} to="/my-usage?tab=mysmartdevice">
                                            SHOW ALL DEVICES
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-3">
                <div className="col-md-7">
                    <div className="row container-blue h-100">
                        <h2 className="text-white text-start">NEW CHALLENGE - ACTIVE PRIZE ANNOUNCED</h2>
                        <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
                            {challenges.filter(w => w.suscribed === 'notsubscribed').map((value, index) => {
                                return (
                                    <div className="row" key={index}>
                                        <div className="col-md-10">
                                            {challenges.length > 0 &&
                                                <p className="description-text text-start text-white">{value.challengeDescription}</p>
                                            }
                                        </div>
                                        <div className="col-md-2">
                                            {value.suscribed === 'notsubscribed' ?
                                                <Button className={'btn-primary-s me-1'} onClick={handleSuscribed}>Suscribe</Button> :
                                                value.suscribed === 'suscribed' ?
                                                    <Button className={'btn-blue-s me-1'} component={Link} to={'/challenge/' + value.id}>Suscribed</Button> :
                                                    null
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </Carousel>
                    </div>
                </div>
                <div className="col-md-4 mt-md-3">
                    <div className="container-card card-content h-100">
                        <h2 className="text-start">YOUR RANKING VS TOP CONTESTANT</h2>
                        <p className="description-text text-start mb-0">
                            {(currentRanking.position == 1 ? t('win-prize.first-place') :
                                currentRanking.position > 1 && currentRanking.position <= 10 ? t('win-prize.second-place') :
                                    t('win-prize.third-place')).replace('#zipCode#', currentRanking.contestantZipCode).replace('#points#', Math.abs(currentRanking.points - currentRanking.contestantPoints))}
                        </p>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-3">
                <div className="col-md-7">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="container-card card-content h-100">
                                <h2 className="text-start">CHALLENGES ACTIVE</h2>
                                <p className="description-text text-start">Complete these challenges to compete for specific prizes. Each challenge has its own raking. An easy way to participate and win! </p>
                                <List component="nav" sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    {challenges.map((value, index) => {
                                        if (value.suscribed !== 'completed')
                                            return (
                                                <ChallengeActive key={index} item={value} />
                                            )
                                    })}
                                </List>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="container-card card-content">
                                <h2 className="text-start">RECOMMENDATIONS</h2>
                                <p className="description-text text-start">Complete this actoins to achieve your goals and reduce your bills. Earn points toward the overall competition. </p>
                                <Recommendations setShowConfetti={setShowConfetti} recommendations={recommendations} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="container-card card-content h-100">
                        <h2 className="text-start">ACTIONS COMPLETED</h2>
                        <p className="description-text text-start"></p>
                        <List component="nav" sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            {challenges.map((value, index) => {
                                if (value.suscribed === 'completed')
                                    return (
                                        <ChallengeActive key={index} item={value} />
                                    )
                            })}

                            {recommendationsCompleted.map((value, index) => {
                                if (index < 3)
                                    return (
                                        <RecommendationComplete key={index} item={value} />
                                    )
                            })}
                        </List>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Gamificator;