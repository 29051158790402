/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Button, MenuItem, FormControl, InputLabel, Select, TextField, Checkbox } from '@mui/material';
import { React, useEffect, useState, useRef } from 'react';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { MuiTelInput } from 'mui-tel-input';
import { deleteStorage, eStorage, getStorage, getStorageJSON, saveStorage, saveStorageJSON } from "../../Services/StorageService";
import Address from "../../Components/Shared/Address";
import { getEsiid, getRepsData, postLocation, getAddressEsiid } from "../../Components/ElectricInfoBase/ElectricInfoBase";
import { getIp, getUniqueVisitorId } from "../../Util/Util";
import { eEsiid } from "../../Components/Shared/Enum/EEsiid";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import ModalEsiid from "../../Components/Shared/ModalEsiid";
import { eAccountType } from "../../Components/Shared/Enum/EAccountType";

function Info(props) {
    const { t } = useTranslation();
    const formRef = useRef();
    const [phone, setPhone] = useState('+1');
    const [showAllAddress, setShowAllAddress] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [houseType, setHouseType] = useState('office');
    const [address, setAddress] = useState(getStorageJSON(eStorage.address));
    const [showPhoneError, setShowPhoneError] = useState(false);
    const [model, setModel] = useState({ name: '', rep: '', meterNumber: '', clientIp: getStorage(eStorage.ip), uniqueVisitorId: getStorage(eStorage.uniqueVisitor) });
    const [terms, setTerms] = useState(false);
    const [reps, setReps] = useState([]);
    const [modal, setModal] = useState({ open: false });

    useEffect(() => {
        if (model.clientIp === null)
            getIp().then(ip => {
                setModel(prevState => ({
                    ...prevState, ['clientIp']: ip
                }));
                saveStorage(eStorage.ip, ip);
            });

        if (model.uniqueVisitorId === null)
            getUniqueVisitorId().then(data => {
                setModel(prevState => ({
                    ...prevState, ['uniqueVisitorId']: data
                }));
                saveStorage(eStorage.uniqueVisitor, data);
            });

        getReps();
    }, []);

    useEffect(() => {
        if (!modal.open && modal.esiid) {
            //handleRegister();
            getAddressByEsiid(modal.esiid);
        }
    }, [modal]);

    const getReps = () => {
        getRepsData(model).then(data => {
            setReps(data);
        })
    };

    const handleChangePhone = (event, info) => {
        setPhone(event);

        setShowPhoneError(true);
        if (event.length <= 2)
            setPhone('+1');
        else
            setPhone(event);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setModel(prevState => ({
            ...prevState, [name]: value
        }));
    }

    const handleChangeHouseType = (event) => {
        setHouseType(event.target.value);
        setAddress(prevState => ({
            ...prevState, ['houseType']: event.target.value
        }));
    }

    const handleSubmit = () => {
        if (address.state !== 'Texas' && address.state !== 'TX') {
            setModal({ open: true, type: eEsiid.noTexas });

        } else {
            props.functions.setIsLoading(true);
            let model = { address: address };
            model.address.houseType = houseType;

            getEsiid(model).then(data => {
                console.log(data);
                if (data.length === 0) {
                    props.functions.setIsLoading(false)
                    setModal({ open: true, type: eEsiid.yesTexasNoEsiid, showEsiid: true });
                }
                else if (data.length === 1) {
                    handleRegister();
                } else if (data.length > 1) {
                    handleRegister();
                }
                else {
                    props.functions.setIsLoading(false)
                    let residential = data.filter(w => !w.PremiseType?.includes('Non-Residential'));
                    if (residential.length === 0)
                        setModal({ open: true, type: eEsiid.noResidential, showEsiid: true });
                    else
                        Notify.failure(t('general.register-error'), { position: 'center-bottom', });
                }
            });
        }
    }

    const changeAddress = () => {
        setShowAllAddress(false);
        deleteStorage(eStorage.address);
        setAddress(null);
    }

    const handleChangeCheckBox = (event) => {
        setTerms(event.target.checked);
    }

    const handleRegister = () => {
        let user = getStorageJSON(eStorage.user);
        let accountType = getStorage(eStorage.accountType);
        let address = getStorageJSON(eStorage.address);
        let language = getStorage(eStorage.language);
        let site = getStorage(eStorage.site);
        let moving = getStorage(eStorage.moving);
        let company = getStorageJSON(eStorage.company);

        let modelRequest = {
            customerId: user.customerId,
            name: user.name,
            lastname: user.lastName,
            email: user.email,
            site: site,
            uniqueVisitorId: model.uniqueVisitorId,
            clientIp: model.clientIp,
            referrer: '',
            esiid: model.meterNumber === 'I90986127' ? '1008901018191440924100' : user.esiid,
            meterNumber: model.meterNumber,
            rep: model.rep,
            houseType: address.houseType,
            city: address.city,
            address: address.address,
            apto: address.apto,
            zipCode: address.zipCode,
            state: address.state,
            moving: moving,
            eweMail: user.eweMail,
            serviceArea: address.serviceArea,
            lang: language,
            phone: phone,
            isCommercial: accountType === eAccountType.commercial,
            locationName: model.name,
            companyId: accountType === eAccountType.commercial ? company.id : null,
            latitude: address.latitude ? address.latitude : null,
            longitude: address.longitude ? address.longitude : null
        }
        createLocation(modelRequest);
    }

    const createLocation = (model) => {
        props.functions.setIsLoading(true);
        postLocation(model).then(data => {
            console.log(data);
            props.functions.setIsLoading(false);
            if (data.status === 'Success') {
                props.setActiveStep(1);
            } else if (data.status === "Failed") {
                if (data.code === 'FLR') {
                    setModal({ open: true, type: eEsiid.errorCombination, showEsiid: true });
                } else
                    Notify.failure(t('general.register-error'), { position: 'center-bottom', });
            }
        });
    }

    const getAddressByEsiid = (esiid) => {
        props.functions.setIsLoading(true);
        getAddressEsiid(esiid).then((data) => {
            if (data && data?.rows) {
                console.log(data);
                const row = data.rows[0];
                let address = getStorageJSON(eStorage.address);
                console.log(address);
                address.address = row.address;
                address.zipCode = row.zip;
                address.serviceArea = row.tdsp_name;
                saveStorageJSON(eStorage.address, address);
                handleRegister();
            }
        })
            .catch(console.log)
            .finally(() => props.functions.setIsLoading(false))
    }

    return (
        <>
            <ModalEsiid modal={modal} setModal={setModal} />
            <ValidatorForm ref={formRef} className='container' onSubmit={handleSubmit} onError={errors => console.log(errors)}>
                <div className='row justify-content-md-center'>
                    <div className='col-sm-12 col-md-10 col-lg-7 container-card card-content text-center'>
                        <div className="row justify-content-md-center">
                            <div className="col-md-9">
                                <div className="row mt-2 justify-content-md-center">
                                    <div className="col-md-12">
                                        <TextValidator autoComplete="nope" fullWidth size="small" name="name" value={model.name} onChange={handleChange} label={t('site.site-name')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('site.site-name'))]} />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-6">
                                        <MuiTelInput preferredCountries={['US']} onlyCountries={['US']} helperText={phone.length !== 15 && showPhoneError ? t('general.phone-invalid') : ""}
                                            error={phone.length !== 15 && showPhoneError} forceCallingCode={true} fullWidth size="small" name="phone" value={phone} onChange={handleChangePhone} label={t('general.cell-phone')} />
                                    </div>
                                    {!showAllAddress &&
                                        <div className="col-md-6">
                                            <FormControl size="small" fullWidth>
                                                <InputLabel id="type-housing">Location Type</InputLabel>
                                                <Select labelId="type-housing" name="houseType" value={houseType} defaultValue={houseType} onChange={handleChangeHouseType} label={t('general.type-housing')}>
                                                    <MenuItem value='office'>Office</MenuItem>
                                                    <MenuItem value='warehouse'>Warehouse</MenuItem>
                                                    <MenuItem value='factory'>Factory</MenuItem>
                                                    <MenuItem value='retail'>Retail</MenuItem>
                                                    <MenuItem value='mixuse'>Mix Use</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                    }
                                </div>
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <Address disabled={disabled} showAllAddress={showAllAddress} setAddress={setAddress} address={address} />
                                        {showAllAddress && <h3 className="mt-3" onClick={() => changeAddress()}><strong className="click-here">{t('general.change-address')}</strong></h3>}
                                    </div>
                                </div>
                                <div className="row mt-2 justify-content-md-center">
                                    <div className="col-md-7">
                                        <SelectValidator className="text-start" fullWidth size="small" name="rep" value={model.rep} onChange={handleChange} label={t('general.electricity-company')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.electricity-company'))]}>
                                            {reps.map((item, index) => (
                                                <MenuItem key={index} value={item.uid}>{item.name}</MenuItem>
                                            ))}
                                        </SelectValidator>
                                    </div>
                                    <div className="col-md-5">
                                        <TextValidator autoComplete="nope" fullWidth size="small" name="meterNumber" value={model.meterNumber} onChange={handleChange} label={t('general.meter-number')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.meter-number'))]} />
                                    </div>
                                </div>
                                <div className="row mt-2 justify-content-md-center">
                                    <div className="col-10">
                                        <TextField fullWidth size="small" className="text-terms" multiline rows={3} defaultValue={t('electric-info.terms-meter')} label={t('general.terms')} />
                                    </div>
                                    <div className="col-2">
                                        <Checkbox name="terms" checked={terms} onChange={handleChangeCheckBox} />
                                    </div>
                                </div>
                                <hr />
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <Button className='btn-primary' type="submit" disabled={!terms}>
                                            {t('general.continue')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ValidatorForm>
        </>
    )
}

export default Info;