/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import billImage from '../../Assets/Icons/bill.png';
import { Button } from '@mui/material';
import ImageUploading from 'react-images-uploading';
import { postImage, postUploadCompleted } from "../../Components/ElectricityBillBase/ElectricityBillBase";
import { eStorage, getStorageJSON } from "../../Services/StorageService";
import { useNavigate } from "react-router-dom";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { ePage } from "../../Components/Shared/Routes";
import { eModal } from "../../Services/ModalService";

function ElectricityBill(props) {
    const { t } = useTranslation();
    const [user] = useState(getStorageJSON(eStorage.user));
    const [imageList, setImageList] = useState([]);
    const [images, setImages] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        props.functions.setTitle(t('general.electricity-bill'));
    }, []);

    const onChange = (event) => {
        setImageList([...imageList, ...event]);
    };

    const removeImage = (index) => {
        setImageList([
            ...imageList.slice(0, index),
            ...imageList.slice(index + 1)
          ]);
    };

    const uploadImages = () => {
        props.functions.setIsLoading(true);
        let counter = 0;
        imageList.map((image) => {
            counter++;
            postImage(image, user.customerId).then(data => {
                if (counter === imageList.length)
                    uploadCompleted();
            });
        })
    };

    const uploadCompleted = () => {
        postUploadCompleted(user.customerId).then(data => {
            navigate(ePage.home);
            Notify.success(t('general.upload-success'), { position: 'center-bottom', });
            props.functions.setIsLoading(false);
        });
    }

    const handleOpenModal = (modal) => {
        props.functions.modalParameters.setType(modal);
        props.functions.modalParameters.set(true);
    }

    return (
        <div className="content">
            <div className='container'>
                <h1>{t('electricity-bill.title')}</h1>
                <div className='row justify-content-md-center'>
                    <div className="col-md-10">
                        <div className="container-card card-content">
                            <div className="row">
                                <div className="col-md-3">
                                    <img alt="" className="img-fluid" src={billImage} />
                                </div>
                                <ImageUploading multiple value={images} onChange={onChange} maxNumber={4} dataURLKey="data_url" >
                                    {({ onImageUpload }) => (
                                        <div className="col-md-9">
                                            <Button className='btn-secondary-s me-1 mt-3' onClick={onImageUpload}>{t('general.add-image')}</Button>
                                            <Button className='btn-secondary-s mt-3' onClick={() => setImageList([])}>{t('general.remove-images')}</Button>
                                            <div className="row">
                                                {imageList.map((image, index) => (
                                                    <div key={index} className="col-md-3">
                                                        <img alt="" className="img-fluid m-3 h-50" src={image['data_url']} />
                                                        <Button className='btn-tertiary-s' onClick={() => removeImage(index)}>{t('general.remove-image')}</Button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </ImageUploading>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <h2>{t('electricity-bill.subtitle')}</h2>
                                    <hr />
                                    <Button className='btn-primary me-2 mt-2' disabled={imageList.length === 0} onClick={uploadImages}>
                                        {t('general.upload-images')}
                                    </Button>
                                    <Button className='btn-secondary mt-2' onClick={() => handleOpenModal(eModal.skypMeter)}>{t('general.ill-skip')}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ElectricityBill;