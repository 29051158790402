import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect } from 'react';
import * as Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';
import { eMetric } from "../../Components/Shared/Enum/EMetric";

function ChartLastYear(props) {

    useEffect(() => {

    }, []);

    function buildChart() {

        return {
            chart: {
                type: 'spline',
                height: '270px'
            },
            credits: { enabled: false },
            title: {
                text: null
            },
            xAxis: {
                type: "datetime",
                title: {
                    text: 'Monthly Dates'
                },
                labels: {
                    formatter: function () {
                        return Highcharts.dateFormat('%d %b %Y', +this.value);
                    }
                },
                tickInterval: 24 * 3600 * 1000
            },
            yAxis: [{
                min: 0,
                title: {
                    text: props.metric === eMetric.energy ? 'Energy Consumption' : props.metric === eMetric.carbon ? 'Carbon Emission' : 'Cost Dollar',
                    x: -15,
                },
            },
            {
                title: {
                    text: 'Temperature'
                },
                opposite: true,
            }],
            tooltip: {
                shared: true
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    }
                }
            },
            series: [{
                name: '2023',
                data: props?.periodData?.lastYear,
                pointStart: Date.UTC(2023, 0, 1),
                pointInterval: 24 * 3600 * 1000, // one day
                tooltip: {
                    pointFormat: '',
                },
                type: 'column'
            },
                // {
                //     name: 'Temperature difference',
                //     data: props?.periodData?.temperature_difference_detail,
                //     yAxis: 1,
                //     tooltip: {
                //         pointFormat: '{series.name}: <b>{point.y:.1f}°F</b>',
                //     },
                //     type: 'spline',
                //     color: '#FCB400'
                // }
            ]
        }
    }

    return (
        <HighchartsReact allowChartUpdate={true} highcharts={Highcharts} options={buildChart()} />
    )
}
export default ChartLastYear;