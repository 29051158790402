/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useParams } from "react-router-dom";
import { getEvents } from "../../Components/DeviceBase/DeviceBase";

function Triggers(props) {
    const { t } = useTranslation();
    const { id } = useParams();
    const [events, setEvents] = useState([]);

    useEffect(() => {
        getEvents(id).then(data => {
            setEvents(data);
        });
    }, []);


    return (
        <div className='content'>
            <h2>Events device</h2>
            <div className='row justify-content-center mt-3'>
                <div className='col-md-8'>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell scope="col">Event</TableCell>
                                    <TableCell scope="col">Value</TableCell>
                                    <TableCell scope="col">Date</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {events.map((item, index) =>
                                    <TableRow key={index}>
                                        <TableCell>{item.change}</TableCell>
                                        <TableCell>{item.value}</TableCell>
                                        <TableCell>{new Date(item.created).toLocaleDateString('en-US', {
                                            year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric',
                                            minute: 'numeric'
                                        })}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
    )
}

export default Triggers;