/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Button, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { React, useEffect, useState, useRef } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { MuiTelInput } from 'mui-tel-input';
import Address from "../../Components/Shared/Address";
import { getEsiid, isPhoneAvailable, postAddress } from "../../Components/ElectricInfoBase/ElectricInfoBase";
import { deleteStorage, eStorage, getStorageJSON } from "../../Services/StorageService";
import { eEsiid } from "../../Components/Shared/Enum/EEsiid";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import ModalSelectAddress from "../../Components/Shared/ModalSelectAddress";
import { addPropertyStorage } from './../../Services/StorageService';

function AddressInfo(props) {
    const { t } = useTranslation();
    const formRef = useRef();
    const [phone, setPhone] = useState('+1');
    const [showAllAddress, setShowAllAddress] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [houseType, setHouseType] = useState('house');
    const [address, setAddress] = useState(getStorageJSON(eStorage.address));
    const [showPhoneError, setShowPhoneError] = useState(false);
    const [modalSelectAddress, setModalSelectAddress] = useState({ open: false, adresses: [] });


    useEffect(() => {
        getStorage();
    }, []);

    const getStorage = () => {
        if (address !== null && address !== undefined && address?.address !== undefined && address?.address !== null) {
            setShowAllAddress(true);
            setDisabled(true);
            setAddress(prevState => ({
                ...prevState, ['state']: 'TX'
            }));
        }
        let user = getStorageJSON(eStorage.user);
        if (user.phone !== undefined)
            setPhone(user.phone);
        if (address?.phone !== undefined && address?.phone !== null)
            setPhone(address.phone);
    };

    const handleSubmit = () => {
        setShowPhoneError(true);
        if (phone.length === 15) {
            if (address.state !== 'Texas' && address.state !== 'TX') {
                props.setModal({ open: true, type: eEsiid.noTexas });
            } else {
                props.functions.setIsLoading(true);
                isPhoneAvailable(phone.replaceAll(' ', '')).then(data => {
                    if (data || phone.replaceAll(' ', '') == '+18327292680') {
                        let model = { address: address };
                        model.phone = phone;
                        model.address.houseType = houseType;

                        getEsiid(model).then(data => {
                            console.log(model);
                            if (data.length === 0) {
                                props.setModal({ open: true, type: eEsiid.yesTexasNoEsiid });
                            }
                            else if (data.length === 1) {
                                saveAddress(data.addressSaved, data[0].PremiseType);
                            } else if (data.length > 1) {
                                setModalSelectAddress({ open: true, adresses: data });
                            }
                            else {
                                Notify.failure(t('general.register-error'), { position: 'center-bottom', });
                            }
                            props.functions.setIsLoading(false)
                        });
                    } else {
                        Notify.failure(t('general.phone-used'), { position: 'center-bottom', });
                        props.functions.setIsLoading(false)
                    }
                }).catch(error => {
                    props.functions.setIsLoading(false)
                    Notify.failure(t('general.phone-used'), { position: 'center-bottom', });
                });
            }
        }
    }


    const saveAddress = (address, type) => {
        let user = getStorageJSON(eStorage.user);
        let model = {
            customerId: user.customerId,
            houseType: address.houseType,
            city: address.city,
            address: address.address,
            apto: address.apto,
            zipCode: address.zipCode,
            state: address.state,
            serviceArea: address.serviceArea,
            phone: phone,
            addressType: type,
            latitude: address.latitude ? address.latitude : null,
            longitude: address.longitude ? address.longitude : null

        }
        postAddress(model).then(data => {
            addPropertyStorage(eStorage.address, 'phone', phone);
            setTimeout(() => {
                setAddress(getStorageJSON(eStorage.address));
            });
            props.setActiveStep(2);
        });
    }

    const handleChangePhone = (event, info) => {
        setPhone(event);

        setShowPhoneError(true);
        if (event.length <= 2)
            setPhone('+1');
        else
            setPhone(event);
    }

    const handleChange = (event) => {
        setHouseType(event.target.value);
    }

    const changeAddress = () => {
        setShowAllAddress(false);
        deleteStorage(eStorage.address);
        setAddress(null);

    }

    return (
        <ValidatorForm ref={formRef} className='container' onSubmit={handleSubmit} onError={errors => console.log(errors)}>
            <ModalSelectAddress modal={modalSelectAddress} saveAddress={saveAddress} setModal={setModalSelectAddress} />
            <h1>{t('electric-info.subtitle-adress')}</h1>
            <div className='row justify-content-md-center'>
                <div className='col-sm-12 col-md-10 col-lg-6 container-card card-content text-center'>
                    <div className="row justify-content-md-center">
                        <div className="col-md-11">
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <MuiTelInput preferredCountries={['US']} onlyCountries={['US']} helperText={phone.length !== 15 && showPhoneError ? t('general.phone-invalid') : ""}
                                        error={phone.length !== 15 && showPhoneError} forceCallingCode={true} fullWidth size="small" name="phone" value={phone} onChange={handleChangePhone} label={t('general.cell-phone')} />
                                </div>
                            </div>
                            {!showAllAddress &&
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="type-housing">{t('general.type-housing')}</InputLabel>
                                            <Select labelId="type-housing" name="houseType" value={houseType} onChange={handleChange} label={t('general.type-housing')}>
                                                <MenuItem value='house'>{t('general.house')}</MenuItem>
                                                <MenuItem value='townhouse'>{t('general.town-house')}</MenuItem>
                                                <MenuItem value='apto'>{t('general.apto')}</MenuItem>
                                                <MenuItem value='mobile'>{t('general.mobile-home')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            }
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <Address disabled={disabled} showAllAddress={showAllAddress} setAddress={setAddress} address={address} />
                                    {showAllAddress && <h3 className="mt-3" onClick={() => changeAddress()}><strong className="click-here">{t('general.change-address')}</strong></h3>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <Button className='btn-primary' type="submit" disabled={address === null}>
                                {t('general.continue')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </ValidatorForm>
    )
}

export default AddressInfo;