/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Stack, Stepper, Step, StepLabel } from '@mui/material';
import QontoStepIcon from "../../Components/Shared/Stepper";
import { React, useEffect, useState } from 'react';
import StepConnectorCustom from "../../Components/Shared/StepConnector";
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { eStorage, getStorage, getStorageJSON } from "../../Services/StorageService";
import { postSmartFingerprint } from "../../Components/SmartFingerprintBase/SmartFingerprintBase";
import { ePage } from "../../Components/Shared/Routes";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import Schedules from "./Schedules";
import HomeFeatures from "./HomeFeatures";
import { postGeniusFingerprint } from "../../Components/GeniusFingerprintBase/GeniusFingerprintBase";

const steps = ['Schedules & Set Points', 'Home Features'];

function Inputs(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [plan] = useState(getStorageJSON(eStorage.plan));
    const [currentAvg, setCurrentAvg] = useState(true);
    const [user] = useState(getStorageJSON(eStorage.user));
    const [actionButton, setActionButton] = useState('Save');
    const [simpleSchedule, setsimpleSchedule] = useState(
        {
            weekday_wakeup: '06:00',
            weekday_leave: '07:00',
            weekday_sleep: '22:00',
            weekday_return: '18:00',
            weekend_wakeup: '08:00',
            weekend_leave: '11:00',
            weekend_sleep: '22:00',
            weekend_return: '20:00',
        });

    const [selectedValues, setSelectedValues] = useState(
        {
            coolingTemperature: 75,
            heatingTemperature: 72,
            numbersOfOccupant: 1,
            currentElectricityProvider: plan?.rep,
            currentPlanEndDate: null,
            currentPlan: plan?.current,
            currentPlanCancellationFee: '',
            currentPlanAverageBill: 0,
            currentPlanRenewable: "IDONTKNOW",
            remodelHouse: 'no',
            replacedWaterHeater: 'Less than 5',
            yearBuilt: '2005',
            homeSize: 0,
            smartThermostats: 'no',
            haveAPool: 'no',
            refrigerators: 1,
            solarPanels: 'no',
            electricalVehicles: 'no',
            electricityStorage: 'no',
        });
    const [domainValues, setDomainValues] = useState({
        domainKeyFuel_heating: 'Electric',
        domainKeyWater_heating: 'Electric',
        domainKeyHome_LightBulbs: 'LED',
        domainKeyHome_HVAC: 'Less than 5',
        domainKeyHome_WaterHeaterAge: 'Less than 5'
    });

    useEffect(() => {
        props.functions.setTitle('INPUTS');
        setValues(getStorageJSON(eStorage.fingerprint));
    }, []);

    const setValues = (fingerprint) => {
        if (fingerprint?.simpleSchedule !== null && fingerprint?.simpleSchedule !== undefined)
            setsimpleSchedule(fingerprint.simpleSchedule);

        if (fingerprint?.domainValues !== null && fingerprint?.domainValues !== undefined) {
            setDomainValues(fingerprint.domainValues);
            if (fingerprint.domainValues.domainKeyHome_WaterHeaterAge === undefined || fingerprint.domainValues.domainKeyHome_WaterHeaterAge === 'yes')
                setDomainValues(prevState => ({ ...prevState, ['domainKeyHome_WaterHeaterAge']: 'Less than 5' }));
        }

        if (fingerprint?.selectedValues !== null && fingerprint?.selectedValues !== undefined) {
            setSelectedValues(fingerprint.selectedValues);
            if (fingerprint.selectedValues.yearBuilt === undefined)
                setSelectedValues(prevState => ({ ...prevState, ['yearBuilt']: '2005' }));
            if (fingerprint.selectedValues.smartThermostats === undefined)
                setSelectedValues(prevState => ({ ...prevState, ['smartThermostats']: 'no' }));
            if (fingerprint.selectedValues.haveAPool === undefined)
                setSelectedValues(prevState => ({ ...prevState, ['haveAPool']: 'no' }));
            if (fingerprint.selectedValues.refrigerators === undefined)
                setSelectedValues(prevState => ({ ...prevState, ['refrigerators']: 1 }));
            if (fingerprint.selectedValues.solarPanels === undefined)
                setSelectedValues(prevState => ({ ...prevState, ['solarPanels']: 'no' }));
            if (fingerprint.selectedValues.electricalVehicles === undefined)
                setSelectedValues(prevState => ({ ...prevState, ['electricalVehicles']: 'no' }));
        }
    }

    const handleNextStep = () => {
        if (activeStep < 2)
            setActiveStep(activeStep + 1);
    }

    const handleChangesSchedule = (event) => {
        const { name, value } = event.target;
        setsimpleSchedule(prevState => ({
            ...prevState, [name]: value
        }));
    }

    const handleChangesSelected = (event) => {
        const { name, value } = event.target;
        setSelectedValues(prevState => ({
            ...prevState, [name]: value
        }));
    }

    const handleChangesDomain = (event) => {
        const { name, value } = event.target;
        setDomainValues(prevState => ({
            ...prevState, [name]: value
        }));
    }

    const handleChangeAvg = (event) => {
        setCurrentAvg(event.target.checked);
    }

    const handleSubmitSmart = () => {
        props.functions.setIsLoading(true);

        let model = {
            requestId: getStorage(eStorage.requestId),
            selectedValues: selectedValues,
            simpleSchedule: simpleSchedule,
            domainValues: getStorageJSON(eStorage.fingerprint)?.domainValues
        }
        model.customerId = getStorageJSON(eStorage.user).customerId;

        postSmartFingerprint(model).then(data => {
            props.functions.setFingerprint(data);
            Notify.success(t('general.successfulUpdate'), { position: 'center-bottom', });
            handleNextStep();
        }).catch(console.log)
            .finally(() => props.functions.setIsLoading(false));

    }

    const handleSubmitGenius = () => {
        props.functions.setIsLoading(true);

        let model = {
            requestId: getStorage(eStorage.requestId),
            selectedValues: selectedValues,
            simpleSchedule: simpleSchedule,
            domainValues: domainValues
        }
        model.customerId = getStorageJSON(eStorage.user).customerId;

        postGeniusFingerprint(model).then(data => {
            props.functions.setFingerprint(data);
            Notify.success(t('general.successfulUpdate'), { position: 'center-bottom', });
            props.functions.setIsLoading(true);
            navigate(ePage.myUsage);

        }).catch(console.log)
            .finally(() => props.functions.setIsLoading(false))
    }

    return (
        <div className="content">
            {activeStep === 0 && <Schedules handleChangesSelected={handleChangesSelected} handleChangesSchedule={handleChangesSchedule} simpleSchedule={simpleSchedule} selectedValues={selectedValues} functions={props.functions} setActiveStep={setActiveStep} />}
            {activeStep === 1 && <HomeFeatures handleChangesDomain={handleChangesDomain} handleChangesSelected={handleChangesSelected} handleChangesSchedule={handleChangesSchedule} simpleSchedule={simpleSchedule} selectedValues={selectedValues} domainValues={domainValues} functions={props.functions} setActiveStep={setActiveStep} />}

            <div className="row mt-3">
                <div className="col-md-12">
                    {activeStep < 1 && <Button className='btn-primary' onClick={handleSubmitSmart}>{t('general.next')}</Button>}
                    {activeStep === 1 &&
                        <Button className='btn-primary mt-2 mt-md-0' onClick={handleSubmitGenius}>
                            {t('general.next')}
                        </Button>}
                </div>
            </div>

            <Stack className="mt-3 mb-3" sx={{ width: '100%' }}>
                <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnectorCustom />}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={QontoStepIcon} onClick={() => setActiveStep(index)}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Stack>
        </div>
    )
}

export default Inputs;