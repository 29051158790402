import { eAction, eCategory, eEvent, submitTrackingEvent } from "../../Services/EventService";
import { eStorage, getStorage, getStorageJSON, saveStorageJSON } from "../../Services/StorageService";
import { calcEngineApiInstance, dataServiceApiInstance } from "../../Util/Api";
import { eAccountType } from "../Shared/Enum/EAccountType";

export const getDataReady = () => {
    let requestId = getStorage(eStorage.requestId);
    let accountType = getStorage(eStorage.accountType);
    let url = accountType === eAccountType.commercial ? 'V2/status/progressStatus?requestId=' : 'status/progressStatus?requestId=';

    const response = dataServiceApiInstance.get(url + requestId)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const postFingerprint = () => {
    let accountType = getStorage(eStorage.accountType);

    let preferences = {
        requestId: getStorage(eStorage.requestId)
    }
    
    if (accountType === eAccountType.commercial)
        preferences.locationId = getStorageJSON(eStorage.location).id;
    else
        preferences.customerId = getStorageJSON(eStorage.user).customerId;

    let url = accountType === eAccountType.commercial ? 'V2/basicFingerprint' : 'basicFingerprint';

    const response = calcEngineApiInstance.post(url, preferences)
        .then(({ data }) => {
            submitTrackingEvent(eEvent.trendsCompleted, eCategory.fingerprint, eAction.created);

            let fingerprint = preferences;
            fingerprint.level = data.fingerprint;
            if (data.fingerprint === 'basic')
                saveStorageJSON(eStorage.fingerprint, fingerprint);
            return fingerprint;
        });
    return response;
}


export const getGenerateHeatMaps = () => {
    let user = getStorageJSON(eStorage.user);

    const response = dataServiceApiInstance.get(`commercial/consumption/csv/generate/lastYearDaily/${user.customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}