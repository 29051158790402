/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, forwardRef, useEffect, useRef } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, FormControl, InputLabel, Select, MenuItem, TextField, Checkbox } from '@mui/material';
import { useTranslation } from "react-i18next";
import Address from '../../Components/Shared/Address';
import { ValidatorForm, SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import { eStorage, getStorage, getStorageJSON, saveStorage } from '../../Services/StorageService';
import { getEsiid, getRepsData, postMoveOut } from '../../Components/ElectricInfoBase/ElectricInfoBase';
import { eEsiid } from '../../Components/Shared/Enum/EEsiid';
import ModalEsiid from '../../Components/Shared/ModalEsiid';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { ePage } from '../../Components/Shared/Routes';
import { useNavigate } from "react-router-dom";
import { getIp, getUniqueVisitorId } from "../../Util/Util";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalAddress(props) {
    const { t } = useTranslation();
    const [address, setAddress] = useState(null);
    const [showAllAddress, setShowAllAddress] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [houseType, setHouseType] = useState('house');
    const [model, setModel] = useState({ rep: '', meterNumber: '', clientIp: getStorage(eStorage.ip), uniqueVisitorId: getStorage(eStorage.uniqueVisitor) });
    const formRef = useRef();
    const [terms, setTerms] = useState(false);
    const [reps, setReps] = useState([]);
    const [showPhoneError, setShowPhoneError] = useState(false);
    const [modal, setModal] = useState({ open: false });
    const navigate = useNavigate();

    useEffect(() => {
        if (model.clientIp === null)
        getIp().then(ip => {
            setModel(prevState => ({
                ...prevState, ['clientIp']: ip
            }));
            saveStorage(eStorage.ip, ip);
        });

    if (model.uniqueVisitorId === null)
        getUniqueVisitorId().then(data => {
            setModel(prevState => ({
                ...prevState, ['uniqueVisitorId']: data
            }));
            saveStorage(eStorage.uniqueVisitor, data);
        });
        
        getReps();
    }, []);

    const getReps = () => {
        getRepsData(model).then(data => {
            setReps(data);
        })
    };

    const handleClose = () => {
        props.setModal({ open: false, address: null });
    };

    const handleChangeHouseType = (event) => {
        setAddress(prevState => ({
            ...prevState, ['houseType']: event.target.value
        }));
    }

    const handleSubmit = () => {
        if (address.state !== 'Texas' && address.state !== 'TX') {
            setModal({ open: true, type: eEsiid.noTexas });

        } else {
            props.functions.setIsLoading(true);
            let model = { address: address };
            model.address.houseType = houseType;

            getEsiid(model).then(data => {
                if (data.length === 0) {
                    setModal({ open: true, type: eEsiid.yesTexasNoEsiid });
                    props.functions.setIsLoading(false);
                }
                else if (data.length === 1) {
                    moveOut();
                } else if (data.length > 1) {
                    moveOut();
                }
                else {
                    let residential = data.filter(w => !w.PremiseType?.includes('Non-Residential'));
                    if (residential.length === 0)
                        setModal({ open: true, type: eEsiid.noResidential });
                    else
                        Notify.failure(t('general.register-error'), { position: 'center-bottom', });

                    props.functions.setIsLoading(false);
                }
            });
        }
    }

    const moveOut = () => {
        let user = getStorageJSON(eStorage.user);
        let address = getStorageJSON(eStorage.address);
        let language = getStorage(eStorage.language);
        let requestId = getStorage(eStorage.requestId);
        let site = getStorage(eStorage.site);
        let moving = getStorage(eStorage.moving);

        let modelRequest = {
            requestId: requestId,
            customerId: user.customerId,
            name: user.name,
            lastname: user.lastName,
            email: user.email,
            site: site,
            uniqueVisitorId: model.uniqueVisitorId,
            clientIp: model.clientIp,
            referrer: '',
            esiid: user.esiid,
            meterNumber: model.meterNumber,
            rep: model.rep,
            houseType: address.houseType,
            city: address.city,
            address: address.address,
            apto: address.apto,
            zipCode: address.zipCode,
            state: address.state,
            moving: moving,
            eweMail: '',
            serviceArea: address.serviceArea,
            lang: language,
            phone: user.phone,
            addressName: model.addressName
        }

        props.functions.setIsLoading(true);
        postMoveOut(modelRequest).then(data => {
            props.functions.setIsLoading(false);
            if (data.status === 'Success') {
                props.setModal({ open: false });
                navigate(ePage.creatingAgreement);
            } else if (data.status === "Failed") {
                if (data.code === 'FLR') {
                    props.setModal({ open: true, type: eEsiid.errorCombination });
                } else
                    Notify.failure(t('general.register-error'), { position: 'center-bottom', });
            }
        });
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setModel(prevState => ({
            ...prevState, [name]: value
        }));
    }

    const handleChangeCheckBox = (event) => {
        setTerms(event.target.checked);
    }

    return (
        <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'md'} keepMounted onClose={handleClose}>
            <ModalEsiid modal={modal} setModal={setModal} />
            <ValidatorForm ref={formRef} onSubmit={handleSubmit} onError={errors => console.log(errors)}>
                <DialogTitle className='text-center'>{t('profile.add-address')}</DialogTitle>
                <hr className='m-0' />
                <DialogContent>
                    {props.modal.address !== null &&
                        <div className="row mt-2 justify-content-md-center">
                            <div className="col-md-8 text-center">
                                <strong>{t('general.current-address')}: </strong>
                                {props.modal.address.address}, {props.modal.address.city} {props.modal.address.state}, {props.modal.address.zipCode}
                            </div>
                        </div>
                    }
                    <div className='row mt-2 mb-4 justify-content-md-center'>
                        <div className="col-md-8">
                            {t('agreement.move-out-modal-description')}
                        </div>
                    </div>
                    <div className="row mt-2 justify-content-md-center">
                        <div className="col-md-6">
                            <TextValidator autoComplete="nope" fullWidth size="small" name="addressName" value={model.addressName} onChange={handleChange} label={t('general.address-name')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.address-name'))]} />
                        </div>
                    </div>
                    <div className="row mt-2 justify-content-md-center">
                        <div className="col-md-6">
                            <FormControl size="small" fullWidth>
                                <InputLabel id="type-housing">{t('general.type-housing')}</InputLabel>
                                <Select labelId="type-housing" required name="houseType" value={houseType} defaultValue={houseType} onChange={handleChangeHouseType} label={t('general.type-housing')}>
                                    <MenuItem value='house'>{t('general.house')}</MenuItem>
                                    <MenuItem value='townhouse'>{t('general.town-house')}</MenuItem>
                                    <MenuItem value='apto'>{t('general.apto')}</MenuItem>
                                    <MenuItem value='mobile'>{t('general.mobile-home')}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row mt-2 justify-content-md-center">
                        <div className="col-md-6">
                            <Address disabled={disabled} showAllAddress={showAllAddress} setAddress={setAddress} address={address} />
                        </div>
                    </div>
                    <div className="row mt-2 justify-content-md-center">
                        <div className="col-md-6">
                            <SelectValidator className="text-start" fullWidth size="small" name="rep" value={model.rep} onChange={handleChange} label={t('general.electricity-company')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.electricity-company'))]}>
                                {reps.map((item, index) => (
                                    <MenuItem key={index} value={item.uid}>{item.name}</MenuItem>
                                ))}
                            </SelectValidator>
                        </div>
                    </div>
                    <div className="row mt-2 justify-content-md-center">
                        <div className="col-md-6">
                            <TextValidator autoComplete="nope" fullWidth size="small" name="meterNumber" value={model.meterNumber} onChange={handleChange} label={t('general.meter-number')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.meter-number'))]} />
                        </div>
                    </div>
                    <div className="row mt-2 justify-content-md-center">
                        <div className="col-5">
                            <TextField fullWidth size="small" className="text-terms" multiline rows={3} defaultValue={t('electric-info.terms-meter')} label={t('general.terms')} />
                        </div>
                        <div className="col-1">
                            <Checkbox name="terms" checked={terms} onChange={handleChangeCheckBox} />
                        </div>
                    </div>
                </DialogContent>
                <hr className='mb-0 mt-2' />
                <DialogActions className='justify-content-md-center'>
                    <Button className='btn-secondary me-1' onClick={handleClose}>{t('general.close')}</Button>
                    <Button className='btn-primary' type="submit" disabled={!terms}>
                        {t('general.continue')}
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}
