
import { dataServiceApiInstance } from "../../Util/Api";

export const getLocationsData = (companyId) => {
    const response = dataServiceApiInstance.get(`location/${companyId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const locationDefault = (locationId) => {
    const response = dataServiceApiInstance.post(`location/location/default/${locationId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}