import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import { MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import lightningImage from '../../../Assets/Icons/lightning.png';
import smartImage from '../../../Assets/Icons/smart-thermostat.png';
import poolImage from '../../../Assets/Icons/pool.png';
import refrigeratorImage from '../../../Assets/Icons/refrigerator.png';
import panelImage from '../../../Assets/Icons/solar-panel.jpg';
import vehicleImage from '../../../Assets/Icons/electric-vehicle.png';
import { getLightData } from "../../../Components/GeniusFingerprintBase/GeniusFingerprintBase";
import { eStorage, getStorage } from "../../../Services/StorageService";
import { getRefrigerators } from "../../../Util/Util";

function Characteristics(props) {
    const { t } = useTranslation();
    const [language] = useState(getStorage(eStorage.language));
    const [lighting, setLighting] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        getLightData().then(data => {
            setLighting(data);
        });
    };

    return (
        <div className='container'>
            <div className='row justify-content-md-center'>
                <div className="col-sm-12 col-md-10 col-lg-7">
                    <div className="container-card card-content">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4">
                                        <img alt="" className="icon-savings" src={lightningImage} />
                                    </div>
                                    <div className="col-9 col-md-8 mt-2 d-flex align-items-center justify-content-center">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="lighting">{t('genius-fingerprint-data.lighting')}</InputLabel>
                                            <Select labelId="lighting" name="domainKeyHome_LightBulbs" value={props.domainValues.domainKeyHome_LightBulbs} onChange={props.handleChangesDomain} label={t('genius-fingerprint-data.lighting')}>
                                                {lighting.map((item, index) => (
                                                    <MenuItem key={index} value={item.key}>{item.values[language]}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4 mt-2">
                                        <img alt="" className="icon-savings" src={smartImage} />
                                    </div>
                                    <div className="col-9 col-md-8 d-flex align-items-center justify-content-center">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="smart">{t('genius-fingerprint-data.smart')}</InputLabel>
                                            <Select labelId="smart" name="smartThermostats" value={props.selectedValues.smartThermostats} onChange={props.handleChangesSelected} label={t('genius-fingerprint-data.smart')}>
                                                <MenuItem value={'yes'}>{t('general.yes')}</MenuItem>
                                                <MenuItem value={'no'}>{t('general.no')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4">
                                        <img alt="" className="icon-savings" src={panelImage} />
                                    </div>
                                    <div className="col-9 col-md-8 mt-2 d-flex align-items-center justify-content-center">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="solar-panel">{t('genius-fingerprint-data.solar-panel')}</InputLabel>
                                            <Select labelId="solar-panel" name="solarPanels" value={props.selectedValues.solarPanels} onChange={props.handleChangesSelected} label={t('genius-fingerprint-data.solar-panel')}>
                                                <MenuItem value={'yes'}>{t('general.yes')}</MenuItem>
                                                <MenuItem value={'no'}>{t('general.no')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4 mt-2">
                                        <img alt="" className="icon-savings" src={refrigeratorImage} />
                                    </div>
                                    <div className="col-9 col-md-8 mt-2 d-flex align-items-center justify-content-center">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="refrigerators">{t('genius-fingerprint-data.refrigerators')}</InputLabel>
                                            <Select labelId="refrigerators" name="refrigerators" value={props.selectedValues.refrigerators} onChange={props.handleChangesSelected} label={t('genius-fingerprint-data.refrigerators')}>
                                                {getRefrigerators().map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Characteristics;