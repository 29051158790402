import { dataServiceApiInstance } from "../../Util/Api";

export const getBadgesData = () => {
    const response = dataServiceApiInstance.get(`/actionplan/all_badges_details/?badge=all`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getBadgesCustomerData = (customerId) => {
    const response = dataServiceApiInstance.get(`/actionplan/badges?customerId=${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}
