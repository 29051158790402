import { eAccountType } from "../Components/Shared/Enum/EAccountType";

export const eStorage = {
    bearer: 'bearer',
    refreshToken: 'refreshToken',
    isSession: 'isSession',
    user: 'user',
    requestId: 'requestId',
    address: 'address',
    fingerprint: 'fingerprint',
    language: 'language',
    i18nextLng: 'i18nextLng',
    plan: 'plan',
    criteria: 'criteria',
    planSelected: 'planSelected',
    site: 'site',
    moving: 'moving',
    ip: 'ip',
    uniqueVisitor: 'uniqueVisitor',
    pathSelected: 'pathSelected',
    recommendationCriteria: 'recommendationCriteria',
    recommendations: 'recommendations',
    otherPlan: 'otherPlan',
    planPath: 'planPath',
    phoneRecover: 'phoneRecover',
    matchingPreferences: 'matchingPreferences',
    attemptRefresh: 'attemptRefresh',
    subscriptionSelected: 'subscriptionSelected',
    energyVariation: 'energyVariation',
    carbonVariation: 'carbonVariation',
    goals: 'goals',
    company: 'company',
    accountType: 'accountType',
    location: 'location',
    locationId: 'locationId',
    checkout: 'checkout',
    renew: 'renew'
}

export const saveStorage = (key, value) => {
    localStorage.setItem(key, value);
}

export const saveStorageJSON = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
}

export const saveRangeStorage = (values) => {
    values.forEach(function (item) {
        localStorage.setItem(item.key, item.value);
    });
}

export const getStorage = (key) => {
    return localStorage.getItem(key);
}

export const getStorageJSON = (key) => {
    return JSON.parse(localStorage.getItem(key));
}

export const clearStorage = () => {
    localStorage.clear();
}

export const deleteStorage = (key) => {
    localStorage.removeItem(key);
}

export const saveLoginStorage = (data) => {
    data.userInfo.agreementStatus = data?.agreement_status;
    data.userInfo.customerId = data.customerId;
    data.address.serviceArea = data?.serviceArea;
    let plan = {
        rep: data.rep,
        repName: data.repName,
        current: data.preferences?.selectedValues?.currentPlan
    };

    let fingerprint = data.preferences;
    fingerprint.level = data.preferences_level;

    let items = [
        { key: eStorage.isSession, value: true },
        { key: eStorage.bearer, value: data.bearer },
        { key: eStorage.refreshToken, value: data.refreshToken },
        { key: eStorage.language, value: data.preferredLang },
        { key: eStorage.user, value: JSON.stringify(data.userInfo) },
        { key: eStorage.address, value: JSON.stringify(data.address) },
        { key: eStorage.requestId, value: data.requestId },
        { key: eStorage.plan, value: JSON.stringify(plan) },
        { key: eStorage.fingerprint, value: JSON.stringify(fingerprint) },
        { key: eStorage.criteria, value: data?.criteria ? JSON.stringify(data.criteria) : null },
        { key: eStorage.matchingPreferences, value: data?.matchingPreferences ? JSON.stringify(data.matchingPreferences) : null },
        { key: eStorage.goals, value: data?.goals ? JSON.stringify(data.goals) : null }
    ];

    saveRangeStorage(items);
}

export const saveLoginCommercialStorage = (data) => {

    data.userInfo.customerId = data.customerId;
    let fingerprint = null;

    if (data?.location) {
        fingerprint = data.location.preferences;
        fingerprint.level = data?.location.preferences_level;
    }

    let items = [,
        { key: eStorage.company, value: JSON.stringify(data.company) },
        { key: eStorage.isSession, value: true },
        { key: eStorage.bearer, value: data.bearer },
        { key: eStorage.refreshToken, value: data.refreshToken },
        { key: eStorage.language, value: data.preferredLang },
        { key: eStorage.user, value: JSON.stringify(data.userInfo) },
        { key: eStorage.accountType, value: eAccountType.commercial }
    ];

    if (data?.location) {
        items.push({ key: eStorage.location, value: JSON.stringify(data.location) });
        items.push({ key: eStorage.requestId, value: data.requestId });
        items.push({ key: eStorage.fingerprint, value: JSON.stringify(fingerprint) });
        items.push({ key: eStorage.goals, value: data?.goals ? JSON.stringify(data.goals) : null });
    }

    saveRangeStorage(items);
}

export const saveRegisterStorage = (data, model) => {

    let userInfo = {
        customerId: data.customerId,
        email: model.email,
        name: model.name,
        lastName: model.lastname,
        eweMail: data.eweMail
    }

    let items = [
        { key: eStorage.isSession, value: true },
        { key: eStorage.accountType, value: model.isCommercial ? 'commercial' : 'residential' },
        { key: eStorage.bearer, value: data.bearer },
        { key: eStorage.language, value: model.preferredLang },
        { key: eStorage.user, value: JSON.stringify(userInfo) }
    ];

    if (model.isCommercial) {
        let companyInfo = {
            id: data.companyId,
            name: model.companyName,
        }

        items.push({ key: eStorage.company, value: JSON.stringify(companyInfo) });
    } else {
        items.push({ key: eStorage.requestId, value: data.requestId });
    }

    saveRangeStorage(items);
}

export const addPropertyStorage = (key, property, value) => {
    let current = JSON.parse(localStorage.getItem(key));
    current[property] = value;
    localStorage.setItem(key, JSON.stringify(current));
}
