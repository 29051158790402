import { useTranslation } from "react-i18next";
import { React, useEffect } from 'react';
import * as Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';
import solidGauge from "highcharts/modules/solid-gauge";
import highchartsMore from "highcharts/highcharts-more"
highchartsMore(Highcharts);
solidGauge(Highcharts);

function ChartGoalCalendar(props) {
    const { t } = useTranslation();

    useEffect(() => {
        console.log(props.goalPercentage.goalEnergyPercentage);
    }, []);

    function buildChart() {

        return {
            chart: {
                type: 'solidgauge',
                height: props.size === 'sm' ? '65px' : '320px'
            },
            credits: {
                enabled: false
            },
            title: {
                text: null,
            },
            tooltip: {
                enabled: props.size === 'sm' ? false : true,
                borderWidth: 0,
                backgroundColor: 'none',
                shadow: false,
                style: {
                    fontSize: '14px'
                },
                valueSuffix: '%',
                pointFormat: '{series.name}<br><span style="font-size:1.5em; color: {point.color}; font-weight: bold">{point.y}</span>',
                positioner: function (labelWidth) {
                    return {
                        x: (this.chart.chartWidth - labelWidth) / 2,
                        y: (this.chart.plotHeight / 2) - 20
                    };
                }
            },

            pane: {
                startAngle: 0,
                endAngle: 360,
                background: [{ // Track for Move
                    outerRadius: '112%',
                    innerRadius: '88%',
                    backgroundColor: Highcharts.color(Highcharts.getOptions().colors[0])
                        .setOpacity(0.3)
                        .get(),
                    borderWidth: 0
                }, { // Track for Exercise
                    outerRadius: '87%',
                    innerRadius: '63%',
                    backgroundColor: Highcharts.color(Highcharts.getOptions().colors[1])
                        .setOpacity(0.3)
                        .get(),
                    borderWidth: 0
                }, { // Track for Stand
                    outerRadius: '62%',
                    innerRadius: '38%',
                    backgroundColor: Highcharts.color(Highcharts.getOptions().colors[2])
                        .setOpacity(0.3)
                        .get(),
                    borderWidth: 0
                }]
            },

            yAxis: {
                min: 0,
                max: 100,
                lineWidth: 0,
                tickPositions: []
            },

            plotOptions: {
                solidgauge: {
                    dataLabels: {
                        enabled: false
                    },
                    linecap: 'round',
                    stickyTracking: false,
                    rounded: true
                }
            },

            series: [{
                name: 'Energy',
                data: [{
                    color: '#95CEFF',
                    radius: '112%',
                    innerRadius: '88%',
                    y: Math.round(props.goalPercentage[0].goalEnergyPercentage)

                }]
            }, {
                name: 'Carbon',
                data: [{
                    color: '#3F4C61',
                    radius: '87%',
                    innerRadius: '63%',
                    y: Math.round(props.goalPercentage[0].goalCarbonPercentage)
                }]
            }, {
                name: 'Cost',
                data: [{
                    color: '#a7d036',
                    radius: '62%',
                    innerRadius: '38%',
                    y: Math.round(props.goalPercentage[0].goalCostPercentage)
                }]
            }]
        }

    }

    return (
        <HighchartsReact allowChartUpdate={true} highcharts={Highcharts} options={buildChart()} />
    )
}
export default ChartGoalCalendar;