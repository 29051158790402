/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Button, TextField, Checkbox } from '@mui/material';
import LogoPrincipal from '../../Assets/logo-enerwisely.png';
import { Link } from 'react-router-dom';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { React, useEffect, useState, useRef, useContext } from 'react';
import { isMailAvailable, postRegisterBasic, validateReferrer } from "../../Components/ElectricInfoBase/ElectricInfoBase";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { getIp, getUniqueVisitorId } from "../../Util/Util";
import LocaleContext from "../../Util/LocaleContext";
import { eStorage, getStorage, getStorageJSON, saveStorage } from "../../Services/StorageService";
import { eSite } from "../../Components/Shared/Enum/ESite";
import { useNavigate } from "react-router-dom";
import { ePage } from "../../Components/Shared/Routes";
import { useSearchParams } from "react-router-dom";
import MixedTooltip from "../shared/MixedTooltip";
import { MaterialUISwitch } from "../../Components/Shared/Switch";
import { eAccountType } from "../../Components/Shared/Enum/EAccountType";

let filterTimeout;
let validCode = true;
function UserInfo(props) {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const formRef = useRef();
    const [model, setModel] = useState({ companyName: '', name: '', lastname: '', email: '', referrer: '', clientIp: '', uniqueVisitorId: '', preferredLang: '', sitePath: '', isCommercial: false, role: 'admin', nickname: '', useNickname: false });
    const [terms, setTerms] = useState(false);
    const [site] = useState(getStorage(eStorage.site));
    const { locale } = useContext(LocaleContext);
    const [codeState, setCodeState] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        getIp().then(ip => {
            setModel(prevState => ({
                ...prevState, ['clientIp']: ip
            }));
            saveStorage(eStorage.ip, ip);
        }).catch(console.log);

        getUniqueVisitorId().then(data => {
            setModel(prevState => ({
                ...prevState, ['uniqueVisitorId']: data
            }));
            saveStorage(eStorage.uniqueVisitor, data);
        }).catch(console.log);

        let urlSite = searchParams.get("site");
        if (!urlSite) {
            searchParams.set('site', eSite.fingerprint);
            urlSite = eSite.fingerprint;
        }

        if (!site && urlSite) {
            const site = eSite.site ? eSite.site : 'site';
            saveStorage(site, urlSite);
        }

        let referralCode = searchParams.get("referralCode");
        if (referralCode !== null)
            setModel(prevState => ({
                ...prevState, ['referrer']: referralCode
            }));
        saveStorage(eStorage.accountType, eAccountType.residential);
    }, []);

    useEffect(() => {
        if (locale !== undefined)
            setModel(prevState => ({
                ...prevState, ['preferredLang']: locale.split('-')[0]
            }));
    }, [locale]);

    const handleSubmit = () => {
        if (!codeState) {
            return;
        }
        props.functions.setIsLoading(true);
        isMailAvailable(model.email).then(data => {
            if (data) {
                register();
            } else {
                props.functions.setIsLoading(false);
                Notify.failure(t('general.email-used'), { position: 'center-bottom', });
            }
        }).catch(error => {
            props.functions.setIsLoading(false);
            Notify.failure(t('general.email-used'), { position: 'center-bottom', });
        });
    }

    const register = () => {
        model.email = model.email.replaceAll(' ', '');
        model.name = model.name.trim();
        model.lastname = model.lastname.trim();
        model.sitePath = getStorage(eStorage.site);

        postRegisterBasic(model).then(data => {
            if (data.status === "EMAILUSED") {
                Notify.failure(t('general.email-used'), { position: 'center-bottom', });
                props.functions.setIsLoading(false);
            } else if (data.status === "Success") {
                props.functions.setIsSession(true);
                props.functions.setUser(getStorageJSON(eStorage.user));
                props.functions.setIsLoading(false);
                props.setModel(prevState => ({
                    ...prevState, model
                }));

                if (model.isCommercial) {
                    navigate(ePage.addLocation);
                } else {
                    let target = searchParams.get("target");

                    if (site === eSite.quickMatch)
                        navigate(ePage.quickMatch);
                    else if (target !== null)
                        navigate(`/${target}`);

                    props.setActiveStep(1);
                }
            } else {
                Notify.failure(t('general.register-error'), { position: 'center-bottom', });
                props.functions.setIsLoading(false);
            }
        }).finally(() => props.functions.setIsLoading(false));
    }

    const handleChange = (event) => {
        const { name, value, checked } = event.target;

        if (name === 'isCommercial')
            saveStorage(eStorage.accountType, checked ? eAccountType.commercial : eAccountType.residential);

        setModel(prevState => ({
            ...prevState, [name]: name !== 'isCommercial' ? value.trim() : checked
        }));
    }


    const handleChangeCode = (event) => {
        const { name, value } = event.target;
        setModel(prevState => ({
            ...prevState, [name]: value.trim()
        }));

        clearTimeout(filterTimeout);
        if (value) {
            filterTimeout = setTimeout(() => {
                validateReferrer(value).then(response => {
                    validCode = response;
                    setCodeState(response);
                }).catch(err => console.log);
            }, 500);
        } else {
            validCode = true;
            setCodeState(true);
        }
    }

    const handleChangeCheckBox = (event) => {
        setTerms(event.target.checked);
    }

    const handleChangeNickname = (event) => {
        setModel(prevState => ({
            ...prevState, ['useNickname']: event.target.checked
        }));
    }

    return (
        <ValidatorForm ref={formRef} className='container' onSubmit={handleSubmit} onError={errors => console.log(errors)}>
            <h1>{t('electric-info.subtitle')}</h1>
            <div className='row justify-content-md-center'>
                <div className='col-sm-12 col-md-10 col-lg-7 container-card card-content text-center'>
                    <div className="row justify-content-md-center">
                        <div className="col-md-4 d-flex align-items-center justify-content-center">
                            <img className="logo-login" src={LogoPrincipal} alt="logo" />
                        </div>
                        <div className="col-md-6">
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <span className="input-font">{t('general.residential')}</span>
                                    <MaterialUISwitch checked={model.isCommercial} name="isCommercial" onChange={handleChange} />
                                    <span className="input-font">{t('general.commercial')}</span>
                                </div>
                            </div>
                            {model.isCommercial &&
                                <div className="row mt-2">
                                    <div className="col-md-12">
                                        <TextValidator autoComplete="nope" fullWidth size="small" name="companyName" value={model.companyName} onChange={handleChange} label={t('general.business-name')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.business-name'))]} />
                                    </div>
                                </div>
                            }
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <TextValidator autoComplete="nope" fullWidth size="small" name="name" value={model.name} onChange={handleChange} label={model.isCommercial ? t('general.legal-name') : t('general.name')} validators={['required']} errorMessages={[t('general.required').replace('#field#', model.isCommercial ? t('general.legal-name') : t('general.name'))]} />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <TextValidator autoComplete="nope" fullWidth size="small" name="lastname" value={model.lastname} onChange={handleChange} label={model.isCommercial ? t('general.legal-last-name') : t('general.last-name')} validators={['required']} errorMessages={[t('general.required').replace('#field#', model.isCommercial ? t('general.legal-last-name') : t('general.last-name'))]} />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <TextValidator autoComplete="nope" fullWidth size="small" name="email" value={model.email} onChange={handleChange} label={t('general.email')} validators={['required', 'isEmail']} errorMessages={[t('general.required').replace('#field#', t('general.email')), t('general.is-email').replace('#field#', t('general.email'))]} />
                                </div>
                            </div>
                            {!model.isCommercial &&
                                <>
                                    <div className="row mt-2">
                                        <div className="col-md-12">
                                            <TextValidator autoComplete="nope" fullWidth size="small" name="nickname" value={model.nickname} onChange={handleChange} label={t('general.nickname')} />
                                        </div>
                                    </div>
                                    <div className="row text-start">
                                        <div className="col-md-12">
                                            <Checkbox name="useNickname" onChange={handleChangeNickname} /><span className="input-font">{t('general.preferNickname')}</span>
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="row">
                                <div className="col-10 col-md-10">
                                    {!model.isCommercial && <TextValidator autoComplete="nope" fullWidth size="small" name="referrer" value={model.referrer} onChange={handleChangeCode} label={t('general.referral-code')} />}
                                    {model.isCommercial && <TextValidator autoComplete="nope" fullWidth size="small" name="referrer" value={model.referrer} onChange={handleChangeCode} label={t('general.referral-code-required')} validators={['required']}
                                        errorMessages={[t('general.required').replace('#field#', t('general.referral-code-required'))]} />}
                                </div>
                                <div className="col-2 col-md-2">
                                    <MixedTooltip className="ms-2 ms-md-4" customClass="mt-2" title={t('tooltips.referral-code')} />
                                </div>
                                {!codeState && <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained" style={{ height: '16px', fontSize: '12px', display: 'inline', textAlign: 'left' }}>{t('general.error-referral-code')} </p>}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center mt-2">
                        <div className="col-10 col-md-9">
                            <TextField fullWidth size="small" className="text-terms" multiline rows={3} defaultValue={t('electric-info.terms-create-account')} label={t('general.terms')} />
                        </div>
                        <div className="col-2 col-md-1">
                            <Checkbox name="term" checked={terms} onChange={handleChangeCheckBox} />
                        </div>
                    </div>
                    <hr />
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <Button className='btn-primary' type="submit" disabled={!terms}>
                                {t('general.create-account-enerwisely')}
                            </Button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Button className='btn-basic mt-2' component={Link} to="/login">{t('general.have-account')}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </ValidatorForm>
    )
}

export default UserInfo;