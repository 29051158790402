/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState, useRef } from 'react';
import Address from "../../Components/Shared/Address";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MuiTelInput } from 'mui-tel-input';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Button, FormGroup, InputAdornment,FormControlLabel, Checkbox, IconButton } from '@mui/material';
import { TextValidator } from 'react-material-ui-form-validator';
import MixedTooltip from "../shared/MixedTooltip";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


function OrderInfo(props) {
    const { t } = useTranslation();
    const formRef = useRef();
    const [showAllAddress, setShowAllAddress] = useState(false);
    const [password, setPassword] = useState("password");
    const [ssn, setSsn]= useState('');

    useEffect(() => {
        if (props.address !== null) {
            setShowAllAddress(true);
        }

        if (props.user.ssn){
            let text = '';
            for(let i = 0; i < props.user.ssn.length;i++) {
                text+='*';
            }
            text+= props.user.ssn.substr(-2);
            setSsn(text);
        }
    }, []);

    const handleChangeDate = (value) => {
        props.setUser(prevState => ({
            ...prevState, ['birthdate']: value
        }));
    }

    const handleChangeUser = (event) => {
        const { name, value } = event.target;
        props.setUser(prevState => ({
            ...prevState, [name]: value
        }));
    }

    const handleBackStep = () => {
        props.setActiveStep(props.activeStep - 1);
    };

    const handleSubmit = () => {
        props.handleSubmit();
    }

    const handleChangeCheckBox = (event) => {
        props.setBillingAddress(prevState => ({
            ...prevState, ['sameAddress']: event.target.checked
        }));
    }

    const handleClickShowPassword = () => {
        setPassword(password === 'password' ? 'text' : 'password');
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div>
            <ValidatorForm
                ref={formRef}
                onSubmit={handleSubmit}
                onError={errors => console.log(errors)}
            >
                <div className="row justify-content-md-center">
                    <h2 className="mb-3">{t('general.review-order')}</h2>
                    <div className="col-md-4">
                        <TextValidator fullWidth size="small" name="name" value={props.user.name} onChange={handleChangeUser} label={t('general.name')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.name'))]} disabled={true} />
                    </div>
                    <div className="col-md-4 mt-2 mt-md-0">
                        <TextValidator fullWidth size="small" name="lastname" value={props.user.lastName} onChange={handleChangeUser} label={t('general.last-name')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.last-name'))]} disabled={true} />
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-4 mt-2">
                        <TextValidator fullWidth size="small" name="email" value={props.user.email} onChange={handleChangeUser} label={t('general.email')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.email'))]} disabled={true} />
                    </div>
                    <div className="col-md-4 mt-2">
                        <MuiTelInput preferredCountries={['US']} onlyCountries={['US']} forceCallingCode={true} fullWidth size="small" name="phone" value={props.user.phone} onChange={handleChangeUser} label={t('general.cell-phone')} required />
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-4 mt-2">
                        <TextValidator type="text" fullWidth size="small" name="ssn" maxlength="9" length="9" value={ssn} onChange={handleChangeUser} label={t('general.security-number')} disabled={true} />
                    </div>
                    <div className="col-md-4 mt-2">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker label={t('general.date-birth')} value={props.user.birthdate} onChange={handleChangeDate} renderInput={(params) => <TextValidator style={{ width: '100%' }} size="small" {...params} />} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.date-birth'))]} disabled={true} />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-4 mt-2">
                        <div className="row">
                            <div className="col-10 col-md-10">
                                <TextValidator fullWidth size="small" name="referrer" label={t('general.referral-code')} />
                            </div>
                            <div className="col-2 col-md-1">
                                <MixedTooltip className="ms-2 ms-md-4" customClass="mt-2" title={t('tooltips.referral-code')} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mt-2">

                    </div>
                </div>

                <div className="row justify-content-md-center mt-4">
                    <div className="col-md-4 mt-2">
                        <h2 className="mb-3">{t('general.service-address')}</h2>
                        <Address disabled={true} showAllAddress={showAllAddress} setAddress={props.setAddress} address={props.address} />
                    </div>
                    <div className="col-md-4 mt-2">
                        <h2 className="mb-3">{t('general.billing-address')}</h2>
                        <div className="row">
                            <div className="col-md-12">
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={props.billingAddress.sameAddress} onChange={handleChangeCheckBox} />} label={t('general.same-address')} />
                                </FormGroup>
                            </div>
                        </div>
                        {!props.billingAddress.sameAddress &&
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <Address setAddress={props.setBillingAddress} address={props.billingAddress} />
                                </div>
                            </div>
                        }
                        <div className="row mt-2">
                            <div className="col-md-12">
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker disablePast label={t('general.date-start')} value={props.service.serviceDate} onChange={handleChangeDate} renderInput={(params) => <TextValidator style={{ width: '100%' }} size="small" {...params} />} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.date-start'))]} disabled={true} />
                                </LocalizationProvider>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-12">
                        {props.activeStep > 0 && <Button className='btn-secondary me-2' onClick={handleBackStep}>{t('general.back')}</Button>}
                        {props.activeStep < 3 && <Button className='btn-primary' type="submit">{t('general.next')}</Button>}
                        {(props.activeStep === 3 || props.activeStep === 4) && <Button className='btn-primary' type="submit">{t('general.continue')}</Button>}
                    </div>
                </div>
            </ValidatorForm>
        </div>
    )
}

export default OrderInfo;