import { useCallback, useRef, useEffect, useState } from "react";
import { eStorage, getStorage } from "../Services/StorageService";
import { orchestratorApiInstance } from "./Api";
import Payment from "payment";

export const getParams = () => {
  let params;
  if (window['runtimeEnvironment'] !== undefined && window['runtimeEnvironment'] === 'production') {
    params = {
      REACT_APP_SITE: window['runtimeSettings'].SITE,
      REACT_APP_EMMENV: window['runtimeSettings'].EMMENV,
      REACT_APP_GATOKEN: window['runtimeSettings'].GATOKEN,
      REACT_APP_FACEBOOK_PIXEL: window['runtimeSettings'].FACEBOOK_PIXEL,
      REACT_APP_GOOGLEAPI_KEY: window['runtimeSettings'].GOOGLEAPI_KEY,
      EMM_REDIRECT_URL: window['runtimeSettings'].EMM_REDIRECT_URL,
      EMM_REDIRECT_BADGE_URL: window['runtimeSettings'].EMM_REDIRECT_URL,
      CONTENT_URL: window['runtimeSettings'].CONTENT_URL,
      DATA_SERVICE_BASEURL: window['runtimeSettings'].DATA_BASEURL,
      CALCENGINE_SERVICE_BASEURL: window['runtimeSettings'].CALCENGINE_BASEURL,
      ORCHESTRATOR_SERVICE_BASEURL: window['runtimeSettings'].ORCHESTRATOR_BASEURL,
      CHARIOT_BASEURL: window['runtimeSettings'].CHARIOT_BASEURL,
      PAYLESS_BASEURL: window['runtimeSettings'].PAYLESS_BASEURL,
      PULSE_BASEURL: window['runtimeSettings'].PULSE_BASEURL,
      TEXPO_BASEURL: window['runtimeSettings'].TEXPO_BASEURL,
      INFINITE_BASEURL: window['runtimeSettings'].INFINITE_BASEURL,
      CONSTELLATION_BASEURL: window['runtimeSettings'].CONSTELLATION_BASEURL,
      FLAGSHIP_BASEURL: window['runtimeSettings'].FLAGSHIP_BASEURL,
      RHYTHM_BASEURL: window['runtimeSettings'].RHYTHM_BASEURL,
      SDM_BASEURL: window['runtimeSettings'].SDM_BASEURL,
      NEST_PATH_SDM: window['runtimeSettings'].NEST_PATH_SDM,
      RESIDEO_PATH_SDM: window['runtimeSettings'].RESIDEO_PATH_SDM,
      EMM_OUTAGE: window['runtimeSettings'].EMM_OUTAGE,
      SMT_OUTAGE: window['runtimeSettings'].SMT_OUTAGE,
      ANDROID_VERSION: window['runtimeSettings'].ANDROID_VERSION,
      ANDROID_URL: window['runtimeSettings'].ANDROID_URL,
      IOS_VERSION: window['runtimeSettings'].IOS_VERSION,
      IOS_URL: window['runtimeSettings'].IOS_URL
    };
  } else if (process.env !== undefined && process.env.NODE_ENV === 'prod') {
    params = {
      REACT_APP_SITE: process.env.REACT_APP_SITE,
      REACT_APP_EMMENV: process.env.REACT_APP_EMMENV,
      REACT_APP_GATOKEN: process.env.REACT_APP_GATOKEN,
      REACT_APP_FACEBOOK_PIXEL: process.env.FACEBOOK_PIXEL,
      REACT_APP_GOOGLEAPI_KEY: process.env.REACT_APP_GOOGLEAPI_KEY,
      EMM_REDIRECT_URL: process.env.REACT_APP_EMM_REDIRECT_URL,
      EMM_REDIRECT_BADGE_URL: process.env.REACT_APP_EMM_REDIRECT_BADGE_URL,
      CONTENT_URL: process.env.REACT_APP_CONTENT_URL,
      DATA_SERVICE_BASEURL: process.env.REACT_APP_DATA_BASEURL,
      CALCENGINE_SERVICE_BASEURL: process.env.REACT_APP_CALCENGINE_BASEURL,
      ORCHESTRATOR_SERVICE_BASEURL: process.env.REACT_APP_ORCHESTRATOR_BASEURL,
      CHARIOT_BASEURL: process.env.REACT_APP_CHARIOT_BASEURL,
      PAYLESS_BASEURL: process.env.REACT_APP_PAYLESS_BASEURL,
      PULSE_BASEURL: process.env.REACT_APP_PULSE_BASEURL,
      TEXPO_BASEURL: process.env.REACT_APP_TEXPO_BASEURL,
      INFINITE_BASEURL: process.env.REACT_APP_INFINITE_BASEURL,
      CONSTELLATION_BASEURL: process.env.REACT_APP_CONSTELLATION_BASEURL,
      FLAGSHIP_BASEURL: process.env.REACT_APP_FLAGSHIP_BASEURL,
      RHYTHM_BASEURL: process.env.REACT_APP_RHYTHM_BASEURL,
      SDM_BASEURL: process.env.REACT_APP_SDM_BASEURL,
      NEST_PATH_SDM: process.env.REACT_APP_NEST_PATH_SDM,
      RESIDEO_PATH_SDM: process.env.REACT_APP_RESIDEO_PATH_SDM,
      EMM_OUTAGE: process.env.REACT_APP_EMM_OUTAGE,
      SMT_OUTAGE: process.env.REACT_APP_SMT_OUTAGE,
      ANDROID_VERSION: process.env.REACT_APP_ANDROID_VERSION,
      ANDROID_URL: process.env.REACT_APP_ANDROID_URL,
      IOS_VERSION: process.env.REACT_APP_IOS_VERSION,
      IOS_URL: process.env.REACT_APP_IOS_URL
    };
  } else {
    params = {
      REACT_APP_SITE: 'MatchMaker',
      REACT_APP_EMMENV: 'dev',
      REACT_APP_GATOKEN: 'UA-151444256-1',
      REACT_APP_FACEBOOK_PIXEL: '1071057423347746',
      REACT_APP_GOOGLEAPI_KEY: 'AIzaSyC9ZGYBqk-ur-Ma68Phdnfrfql0p7L983s',
      EMM_REDIRECT_URL: 'https://emm.enerwisely.com//',
      EMM_REDIRECT_BADGE_URL: 'https://badge.enerwisely.com/',
      CONTENT_URL: 'http://dev-enerqbits.pantheonsite.io',
      DATA_SERVICE_BASEURL: 'https://emm.enerwisely.com/dataservices/' ,
      CALCENGINE_SERVICE_BASEURL: 'https://emm.enerwisely.com/calcengine/' ,
      ORCHESTRATOR_SERVICE_BASEURL: 'https://emm.enerwisely.com/orchestrator/' ,
      CHARIOT_BASEURL: 'https://emm.enerwisely.com/chariot/',
      PAYLESS_BASEURL: 'https://emm.enerwisely.com/paylessintegration/',
      PULSE_BASEURL: 'https://emm.enerwisely.com/home/pulseintegration/',
      TEXPO_BASEURL: 'https://emm.enerwisely.com/home/texpointegration/',
      INFINITE_BASEURL: 'https://emm.enerwisely.com/home/infiniteintegration/',
      CONSTELLATION_BASEURL: 'https://emm.enerwisely.com/home/constellationintegration/',
      FLAGSHIP_BASEURL: 'https://emm.enerwisely.com/home/flagshipintegration/',
      RHYTHM_BASEURL: 'https://emm.enerwisely.com/home/rhythmintegration/',
      SDM_BASEURL: 'https://emm.enerwisely.com/smartdevices/',
      NEST_PATH_SDM:'https://nestservices.google.com/partnerconnections/778f4403-ddc9-4a2f-b908-539b799efe72/auth?redirect_uri=https://emm.enerwisely.com/connect-device-nest&access_type=offline&prompt=consent&client_id=512129108373-dpi7dl3b3o6oijajlr8r3d3lpgtd7a8l.apps.googleusercontent.com&response_type=code&scope=https://www.googleapis.com/auth/sdm.service',
      RESIDEO_PATH_SDM:'https://api.honeywell.com/oauth2/authorize?response_type=code&redirect_uri=https://emm.enerwisely.com/connect-device-resideo&client_id=VwhMo2RFbegPJScL2LFBabQDYCGG6JJB',
      EMM_OUTAGE: false,
      SMT_OUTAGE: false,
      ANDROID_VERSION: "1.3.0",
      ANDROID_URL: "https://play.google.com/store/apps/details?id=com.enerwisely.app",
      IOS_VERSION: "1.5.0",
      IOS_URL: "https://apps.apple.com/us/app/enerwisely/id6446202428"
    };
  }
  return params;
};

export const log = (message) => {
  console.log(message);
};

export const getIp = async () => {
  let response = await fetch('https://api.ipify.org?format=json')
  response = await response.json();
  return response.ip;
}

export const getYearMonthName = (date) => {
  let language = getStorage(eStorage.language);

  if (date === undefined)
    return "";

  let monthSplit = date.split('/');
  let monthName = '';

  switch (monthSplit[0]) {
    case '01': language === 'en' ? monthName = 'January' : monthName = 'Enero'; break;
    case '02': language === 'en' ? monthName = 'February' : monthName = 'Febrero'; break;
    case '03': language === 'en' ? monthName = 'March' : monthName = 'Marzo'; break;
    case '04': language === 'en' ? monthName = 'April' : monthName = 'Abril'; break;
    case '05': language === 'en' ? monthName = 'May' : monthName = 'Mayo'; break;
    case '06': language === 'en' ? monthName = 'June' : monthName = 'Junio'; break;
    case '07': language === 'en' ? monthName = 'July' : monthName = 'Julio'; break;
    case '08': language === 'en' ? monthName = 'August' : monthName = 'Agosto'; break;
    case '09': language === 'en' ? monthName = 'September' : monthName = 'Septiembre'; break;
    case '10': language === 'en' ? monthName = 'October' : monthName = 'Octubre'; break;
    case '11': language === 'en' ? monthName = 'November' : monthName = 'Noviembre'; break;
    case '12': language === 'en' ? monthName = 'December' : monthName = 'Diciembre'; break;
    default: language === 'en' ? monthName = 'unknown' : monthName = 'desconocido'; break;
  }

  let currentMonth = new Date().getMonth();
  let year = new Date().getFullYear();
  if (monthSplit.length === 3)
    monthName = `${monthName} ${monthSplit[2]}`;
  else if (monthSplit.length === 2) {
    if (monthSplit[0] === '12' && (currentMonth === 0 || currentMonth === 1))
      year = year - 1;
    monthName = `${monthName} ${year}`;
  }

  return monthName;
}

export const getSchedule = () => [
  { "value": "00:00", "name": "12:00 AM" },
  { "value": "00:30", "name": "12:30 AM" },
  { "value": "01:00", "name": "01:00 AM" },
  { "value": "01:30", "name": "01:30 AM" },
  { "value": "02:00", "name": "02:00 AM" },
  { "value": "02:30", "name": "02:30 AM" },
  { "value": "03:00", "name": "03:00 AM" },
  { "value": "03:30", "name": "03:30 AM" },
  { "value": "04:00", "name": "04:00 AM" },
  { "value": "04:30", "name": "04:30 AM" },
  { "value": "05:00", "name": "05:00 AM" },
  { "value": "05:30", "name": "05:30 AM" },
  { "value": "06:00", "name": "06:00 AM" },
  { "value": "06:30", "name": "06:30 AM" },
  { "value": "07:00", "name": "07:00 AM" },
  { "value": "07:30", "name": "07:30 AM" },
  { "value": "08:00", "name": "08:00 AM" },
  { "value": "08:30", "name": "08:30 AM" },
  { "value": "09:00", "name": "09:00 AM" },
  { "value": "09:30", "name": "09:30 AM" },
  { "value": "10:00", "name": "10:00 AM" },
  { "value": "10:30", "name": "10:30 AM" },
  { "value": "11:00", "name": "11:00 AM" },
  { "value": "11:30", "name": "11:30 AM" },
  { "value": "12:00", "name": "12:00 PM" },
  { "value": "12:30", "name": "12:30 PM" },
  { "value": "13:00", "name": "01:00 PM" },
  { "value": "13:30", "name": "01:30 PM" },
  { "value": "14:00", "name": "02:00 PM" },
  { "value": "14:30", "name": "02:30 PM" },
  { "value": "15:00", "name": "03:00 PM" },
  { "value": "15:30", "name": "03:30 PM" },
  { "value": "16:00", "name": "04:00 PM" },
  { "value": "16:30", "name": "04:30 PM" },
  { "value": "17:00", "name": "05:00 PM" },
  { "value": "17:30", "name": "05:30 PM" },
  { "value": "18:00", "name": "06:00 PM" },
  { "value": "18:30", "name": "06:30 PM" },
  { "value": "19:00", "name": "07:00 PM" },
  { "value": "19:30", "name": "07:30 PM" },
  { "value": "20:00", "name": "08:00 PM" },
  { "value": "20:30", "name": "08:30 PM" },
  { "value": "21:00", "name": "09:00 PM" },
  { "value": "21:30", "name": "09:30 PM" },
  { "value": "22:00", "name": "10:00 PM" },
  { "value": "22:30", "name": "10:30 PM" },
  { "value": "23:00", "name": "11:00 PM" },
  { "value": "23:30", "name": "11:30 PM" }];

export const getTemperatures = () => {
  let temperatures = [];
  for (let i = 65; i <= 80; i++) {
    temperatures.push({ value: i, name: i });
  }
  return temperatures;
}
export const getOccupants = () => {
  let occupants = [];
  for (let j = 0; j <= 10; j++) {
    occupants.push({ value: j, name: j });
  }
  return occupants;
}
export const getRenewable = () => {
  let renewable = [];
  for (let i = 1; i <= 100; i++) {
    renewable.push({ name: i + "%", value: i });
  }
  renewable.unshift({ value: "IDONTKNOW", name: "I don't know" });

  return renewable;
}

export const getYears = () => {
  return [
    { value: '2015', name: '0-10' },
    { value: '2005', name: '10-20' },
    { value: '1995', name: '20-30' },
    { value: '1985', name: '30-40' },
    { value: '1975', name: '40-50' },
    { value: '1965', name: '50-60' },
    { value: '1955', name: 'More than 60' },
  ]
}


export const getYearsExtend = () => {
  let years = [];
  for (let i = 2019; i >= 1900; i--) {
    years.push({ name: i, value: i });
  }
  return years;
}

export const getRefrigerators = () => {
  return [
    { value: 1, name: '1' },
    { value: 2, name: '2' },
    { value: 3, name: '3' },
    { value: 4, name: '4' },
    { value: 5, name: '5' }
  ]
}

export const getState = () => {
  return [
    {
      "name": "Alabama",
      "value": "AL"
    },
    {
      "name": "Alaska",
      "value": "AK"
    },
    {
      "name": "American Samoa",
      "value": "AS"
    },
    {
      "name": "Arizona",
      "value": "AZ"
    },
    {
      "name": "Arkansas",
      "value": "AR"
    },
    {
      "name": "California",
      "value": "CA"
    },
    {
      "name": "Colorado",
      "value": "CO"
    },
    {
      "name": "Connecticut",
      "value": "CT"
    },
    {
      "name": "Delaware",
      "value": "DE"
    },
    {
      "name": "District Of Columbia",
      "value": "DC"
    },
    {
      "name": "Federated States Of Micronesia",
      "value": "FM"
    },
    {
      "name": "Florida",
      "value": "FL"
    },
    {
      "name": "Georgia",
      "value": "GA"
    },
    {
      "name": "Guam Gu",
      "value": "GU"
    },
    {
      "name": "Hawaii",
      "value": "HI"
    },
    {
      "name": "Idaho",
      "value": "ID"
    },
    {
      "name": "Illinois",
      "value": "IL"
    },
    {
      "name": "Indiana",
      "value": "IN"
    },
    {
      "name": "Iowa",
      "value": "IA"
    },
    {
      "name": "Kansas",
      "value": "KS"
    },
    {
      "name": "Kentucky",
      "value": "KY"
    },
    {
      "name": "Louisiana",
      "value": "LA"
    },
    {
      "name": "Maine",
      "value": "ME"
    },
    {
      "name": "Marshall Islands",
      "value": "MH"
    },
    {
      "name": "Maryland",
      "value": "MD"
    },
    {
      "name": "Massachusetts",
      "value": "MA"
    },
    {
      "name": "Michigan",
      "value": "MI"
    },
    {
      "name": "Minnesota",
      "value": "MN"
    },
    {
      "name": "Mississippi",
      "value": "MS"
    },
    {
      "name": "Missouri",
      "value": "MO"
    },
    {
      "name": "Montana",
      "value": "MT"
    },
    {
      "name": "Nebraska",
      "value": "NE"
    },
    {
      "name": "Nevada",
      "value": "NV"
    },
    {
      "name": "New Hampshire",
      "value": "NH"
    },
    {
      "name": "New Jersey",
      "value": "NJ"
    },
    {
      "name": "New Mexico",
      "value": "NM"
    },
    {
      "name": "New York",
      "value": "NY"
    },
    {
      "name": "North Carolina",
      "value": "NC"
    },
    {
      "name": "North Dakota",
      "value": "ND"
    },
    {
      "name": "Northern Mariana Islands",
      "value": "MP"
    },
    {
      "name": "Ohio",
      "value": "OH"
    },
    {
      "name": "Oklahoma",
      "value": "OK"
    },
    {
      "name": "Oregon",
      "value": "OR"
    },
    {
      "name": "Palau",
      "value": "PW"
    },
    {
      "name": "Pennsylvania",
      "value": "PA"
    },
    {
      "name": "Puerto Rico",
      "value": "PR"
    },
    {
      "name": "Rhode Island",
      "value": "RI"
    },
    {
      "name": "South Carolina",
      "value": "SC"
    },
    {
      "name": "South Dakota",
      "value": "SD"
    },
    {
      "name": "Tennessee",
      "value": "TN"
    },
    {
      "name": "Texas",
      "value": "TX"
    },
    {
      "name": "Utah",
      "value": "UT"
    },
    {
      "name": "Vermont",
      "value": "VT"
    },
    {
      "name": "Virgin Islands",
      "value": "VI"
    },
    {
      "name": "Virginia",
      "value": "VA"
    },
    {
      "name": "Washington",
      "value": "WA"
    },
    {
      "name": "West Virginia",
      "value": "WV"
    },
    {
      "name": "Wisconsin",
      "value": "WI"
    },
    {
      "name": "Wyoming",
      "value": "WY"
    }
  ];
}

export const useStateCallback = (initialState) => {
  const [state, setState] = useState(initialState);
  const cbRef = useRef(null); // init mutable ref container for callbacks

  const setStateCallback = useCallback((state, cb) => {
    cbRef.current = cb; // store current, passed callback in ref
    setState(state);
  }, []); // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cb.current is `null` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = null; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
}


export const useAsyncState = (initialState) => {
  const [state, setState] = useState(initialState);
  const resolveState = useRef();
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (resolveState.current) {
      resolveState.current(state);
    }
  }, [state]);

  const setAsyncState = useCallback(
    newState =>
      new Promise(resolve => {
        if (isMounted.current) {
          resolveState.current = resolve;
          setState(newState);
        }
      }),
    []
  );

  return [state, setAsyncState];
}

export const getUniqueVisitorId = () => {
  const response = orchestratorApiInstance.get(`smt2/uniqueVisitorIdentifier`)
    .then(({ data }) => {
      return data.uniqueVisitorIdentifier;
    });
  return response;
}


function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = Payment.fns.cardType(allValues.number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatFormData(data) {
  return Object.keys(data).map(d => `${d}: ${data[d]}`);
}

export function hourToDecimal(hour) {
  let splitHour = hour.split(':');
  let minuts = (Number(splitHour[1]) * 100) / 60;

  return Number(splitHour[0]) + minuts;
}

export function lbsToTonnes(value) {
  return value * 0.00045359237;
}

export function isWhatPercentOf(x, y) {
  let result = Math.trunc((x / y) * 100);
  return result;
}

export const validateUserAgreement = (user) => {
  if (user) {
    const { agreementAccepted, agreementStatus } = user;
    if (agreementAccepted && agreementStatus && agreementStatus?.toLowerCase()?.includes('active')) {
      return true;
    }
  }
  return false;
}

export const formatToLocaleUs = (number, fraction = 0) => {
  const format = !number || isNaN(number) ? 0 : number;
  return format.toLocaleString('en-US', { maximumFractionDigits: fraction })
}