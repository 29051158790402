import { eStorage, getStorage, getStorageJSON } from "../../Services/StorageService";
import { dataServiceApiInstance } from "../../Util/Api";
import { eAccountType } from "../Shared/Enum/EAccountType";

export const ePeriod = {
    lastWeekVSPrevious: 'lastWeekvsPrevious',
    lastMonthVSPrevious: 'lastMonthvsPrevious',
    lastWeekVSLastYear: 'lastWeekvsLastYear',
    lastMonthVSLastYear: 'lastMonthvsLastYear',
    lastYear: 'lastYear',
}

export const getEnergyIntensity = (id) => {
    let accountType = getStorage(eStorage.accountType);
    let url = accountType === eAccountType.commercial ? 'V2/actionplan/energy_intensity?locationId' : 'actionplan/energy_intensity?customerId=';

    const response = dataServiceApiInstance.get(url + id)
        .then((response) => {
            if (response) {
                const { data } = response;
                return data;
            }
            return null;
        });
    return response;
}

export const getCarbonFootprint = (id) => {
    let accountType = getStorage(eStorage.accountType);
    let url = accountType === eAccountType.commercial ? 'V2/fingerprint/carbon-footprint/' : 'fingerprint/carbon-footprint/';

    let language = getStorage(eStorage.language);
    const response = dataServiceApiInstance.get(`${url}${id}?lang=${language}`)
        .then((response) => {
            if (response) {
                const {data} = response;
                return data;
            }
            return null;
        });
    return response;
}

export const getPeriodData = (id, ePeriod, viewType) => {
    let requestId = getStorage(eStorage.requestId);
    let accountType = getStorage(eStorage.accountType);
    let url = accountType === eAccountType.commercial ? 'V2/periodToPeriod/' : 'periodToPeriod/';

    const response = dataServiceApiInstance.get(`${url}${ePeriod}/${id}?view=${viewType}&requestId=${requestId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getPoints = (id) => {
    let accountType = getStorage(eStorage.accountType);
    let url = accountType === eAccountType.commercial ? 'V2/actionplan/currentPoints?locationId=' : 'actionplan/currentPoints?customerId=';

    const response = dataServiceApiInstance.get(url + id)
        .then(response => {
            if (response) {
                const { data } = response;
                return data;
            }
        });
    return response;
}

export const getAppVersion = () => {
    const response = dataServiceApiInstance.get(`/settings/?settingsId=appVersion`)
        .then((response) => {
            if (response) {
                const { data } = response;
                return data;
            }
        });
    return response;
}

export const getBadgesData = (customerId) => {
    const response = dataServiceApiInstance.get(`/actionplan/badges?customerId=${customerId}`)
        .then((response) => {
            if (response) {
                const { data } = response;
                return data;
            }
            return [];
        });
    return response;
}

export const getDisaggregationData = (viewType, customerId) => {
    let language = getStorage(eStorage.language);
    const response = dataServiceApiInstance.get(`/fingerprint/disaggregation/${customerId}?lang=${language}&view=${viewType}`)
        .then((response) => {
            if (response) {
                const { data } = response;
                return data;
            }
            return {};
        });
    return response;
}

export const getRecommendationsData = (customerId, maxRecommendations) => {
    let language = getStorage(eStorage.language);
    let requestId = getStorage(eStorage.requestId);
    let criteria = getStorageJSON(eStorage.criteria);

    if (criteria === null) {
        criteria = {
            pureBehavior: 'pureBehavior',
            costLevel: 0
        }
    }
    const response = dataServiceApiInstance.get(`/actionplan/recommendations?customerId=${customerId}&lang=${language}&requestId=${requestId}&maxRecommendations=${maxRecommendations}&pureBehavior=${criteria.pureBehavior}&costLevel=${criteria.costLevel}`)
        .then((response) => {
            if (response) {
                const { data } = response;
                if (data.status === 'SUCCESS')
                    return data.results;
            }
            return [];
        });
    return response;
}

export const getRecommendationsCompleted = (customerId) => {
    const response = dataServiceApiInstance.get(`actionplan/recommendationsCompleted?customerId=${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getCommercialConsumption = (locationId) => {
    const response = dataServiceApiInstance.get(`V2/commercial/consumption/energy/lastYearDaily/${locationId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getCommercialCarbon = (locationId) => {
    const response = dataServiceApiInstance.get(`V2/commercial/consumption/carbon/lastYearDaily/${locationId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getCommercialFuelMix = (locationId) => {
    const response = dataServiceApiInstance.get(`V2/commercial/consumption/fuelMix/lastYearDaily/${locationId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getGeneralStats = (locationId) => {
    const response = dataServiceApiInstance.get(`V2/stats/${locationId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getGoalsByCompany = (companyId) => {
    const response = dataServiceApiInstance.get(`goals/goalsByCompany/${companyId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getGoals = (locationId) => {
    const response = dataServiceApiInstance.get(`goals/goalsByLocation/${locationId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}


export function progressPergentage(lastValue, previousValue, goal) {
    // let goalValue = (goal * lastValue) / 100;
    // goalValue = goalValue === 0 ? 1 : goalValue;
    // let difference = lastValue - goalValue;
    // let result = Math.trunc((currentValue / difference) * 100);
    // return currentValue > lastValue ? result * -1 : result;
    let goalTarget = (goal/100) * previousValue * -1;
    let periodChange =  lastValue - previousValue;
    let result = Math.trunc((periodChange / goalTarget) * 100);
    return isNaN(result) ? 0 : result;
}

export const getChartsData = (locationId) => {
    const response = dataServiceApiInstance.get(`location/chart/heatMap/${locationId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getChartsByLocation = (locationId) => {
    const response = dataServiceApiInstance.get(`location/charts/${locationId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getDashboardLocationsWithData = (locations) => {
    let language = getStorage(eStorage.language);
    const body = {
        locations,
        lang: language,
        mobile: false
    }
    const response = dataServiceApiInstance.post(`commercial/dashboard/general/locations`, body)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getPeriodDataLocationsDashboard = (locations) => {
    let language = getStorage(eStorage.language);
    const requestId = getStorage(eStorage.requestId);
    const body = {
        locations,
        lang: language,
        requestId,
        mobile: false
    }

    const response = dataServiceApiInstance.post(`commercial/dashboard/general/period-data`, body)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getGoalsPercentage = (locationId) => {
    let date = new Date();
    date = date.toISOString().substring(0, 10);
    const response = dataServiceApiInstance.get(`location/goalsPercentage/${locationId}?date=${date}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}