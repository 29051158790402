import { dataServiceApiInstance } from "../../Util/Api";


export const postImage = (image, customerId) => {
    let formData = new FormData();
    formData.append('file', image.file);
    const response = dataServiceApiInstance.post(`ebill/${customerId}`, formData)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const postUploadCompleted = (customerId) => {
    const response = dataServiceApiInstance.post(`ebill/completed/${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}