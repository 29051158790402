/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
import infoImage from "../../Assets/round-info-button.svg";
import infoImageBlue from "../../Assets/round-info-button-blue.svg";
import infoImageWhite from "../../Assets/round-info-button-white.svg";

function MixedTooltip(props) {
    const [showTooltip, setShowTooltip] = useState(false);
    const [customClass, setCustomClass] = useState('icon-info');

    useEffect(() => {
        if (props.customClass)
            setCustomClass(`icon-info ${props.customClass}`);
    }, []);

    return (
        <span> <Tooltip
            style={{fontSize:"20px"}}
            title={props.title}
            open={showTooltip}
            onOpen={() => setShowTooltip(true)}
            onClose={() => setShowTooltip(false)}
        >
            <img alt="tooltip" onClick={() => setShowTooltip(!showTooltip)} className={customClass} src={props.color === 'blue' ? infoImageBlue : props.color === 'white' ? infoImageWhite : infoImage} />
        </Tooltip>
        </span>
    );
}

export default MixedTooltip;