/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import LogoPrincipal from '../../Assets/logo-enerwisely.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { React, useEffect, useState } from 'react';
import { getCustomerChallengeCustom, postCommentSuscribe } from "../../Components/ChallengesBase/ChallengesBase";
import imageLoading from "../../Assets/loading.gif";
import completedImage from '../../Assets/Icons/completed.png';
import { Button } from "@mui/material";

function Suscribe(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [complete, setComplete] = useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const customerIdParam = searchParams.get('customerId');
        const challengeIdParam = searchParams.get('challengeId');

        if (customerIdParam && challengeIdParam) {
            handleSuscribed(challengeIdParam, customerIdParam);
        }

    }, [location.search]);

    const handleSuscribed = (challengeId, customerId) => {
        let model = {
            challengeId: challengeId,
            customerId: customerId
        }

        postCommentSuscribe(model).then(data => {
            console.log(data);
            setComplete(true);
        });
    };


    return (
        <div className='container'>
            <div className='row justify-content-md-center mb-1'>
                <div className='col-md-8 col-lg-6 container-card card-content text-center'>
                    <img className="logo-login mt-3" src={LogoPrincipal} alt="logo" />

                    <div className="row">
                        <div className="col-12">
                            <img alt="" className={complete ? "icon-switch mt-3 mb-3" : "icon-affect"} src={complete ? completedImage : imageLoading} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <p>CONGRATULATIONS!</p>
                            <p className="description-text">You have successfully subscribed to the challenge. Please prepare to enjoy the benefits.</p>
                        </div>
                    </div>
                    <hr />
                        <div className="row">
                            <div className="col-md-12">
                                <Button className='btn-primary' component={Link} to={'/home'}>
                                    GO TO ENERWISELY
                                </Button>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default Suscribe;