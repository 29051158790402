
import { useTranslation } from 'react-i18next';
import { Dialog, Slide, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { forwardRef, useState } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalServiceArea(props) {
  const { t } = useTranslation();

  const [checked, setChecked] = useState(2);
  const [selected, setSelected] = useState({});

  const handleClose = () => {
    props.setModalOpen(false);
  };

  const handleApplyChange = () => {
    if (Object.keys(selected).length > 0) {
      props.setServiceArea(selected.uid);
      handleClose();
    }
  }

  const onChange = (e, i) => {
    setChecked(i);
    setSelected(props.serviceareas[i]);
  }


  return (
    <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'sm'} keepMounted onClose={handleClose}>
      <DialogTitle className='text-center'>{t('general.service-area')}</DialogTitle>
      <hr className='m-0' />
      <DialogContent>
        <div className="row justify-content-center">
          <p>{t('browser.multipleZipCode').replace('#zipCode', props.zipCode)}</p>
          {
            props.serviceareas.map((option, i) => {
              return <div key={i} className='mt-2'>
                <label key={i} style={{ width: '100%' }}>
                  <input
                    type="radio"
                    checked={checked === i ? true : false}
                    key={i}
                    onChange={(e) => onChange(e, i)}
                    value={option.uid} />&nbsp;&nbsp;
                  {option.name}
                </label>
              </div>
            })
          }
        </div>
      </DialogContent>
      <hr className='mb-0 mt-2' />
      <DialogActions className='justify-content-md-center'>
        <Button className='btn-primary' onClick={handleApplyChange}>{t('sidebar.apply')}</Button>
        <Button className='btn-secondary' onClick={handleClose}>{t('profile.close')}</Button>
      </DialogActions>
    </Dialog>
  );
}