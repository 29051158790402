/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import "bootstrap/dist/css/bootstrap.min.css";
import MeterImage from '../../Assets/Icons//meter.jpg';
import { useTranslation } from "react-i18next";
import { Button, TextField, Checkbox, MenuItem } from '@mui/material';
import { React, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { getRepsData, postLocation, postRegisterUser } from "../../Components/ElectricInfoBase/ElectricInfoBase";
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { eStorage, getStorage, getStorageJSON, saveStorage } from "../../Services/StorageService";
import { getIp, getUniqueVisitorId } from "../../Util/Util";
import { useNavigate } from "react-router-dom";
import { ePage } from "../../Components/Shared/Routes";
import { eEsiid } from "../../Components/Shared/Enum/EEsiid";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { eDevice } from "../../Components/Shared/Enum/EDevice";

function MeterInfo(props) {
    const { t } = useTranslation();
    const formRef = useRef();
    const [model, setModel] = useState({ rep: '', meterNumber: '', clientIp: getStorage(eStorage.ip), uniqueVisitorId: getStorage(eStorage.uniqueVisitor) });
    const [reps, setReps] = useState([]);
    const [terms, setTerms] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = () => {
        let user = getStorageJSON(eStorage.user);
        let address = getStorageJSON(eStorage.address);
        let language = getStorage(eStorage.language);
        let requestId = getStorage(eStorage.requestId);
        let site = getStorage(eStorage.site);
        let moving = getStorage(eStorage.moving);

        let modelRequest = {
            requestId: requestId,
            customerId: user.customerId,
            name: user.name,
            lastname: user.lastName,
            email: user.email,
            site: site,
            uniqueVisitorId: model.uniqueVisitorId,
            clientIp: model.clientIp,
            referrer: '',
            esiid: user.esiid,
            meterNumber: model.meterNumber,
            rep: model.rep,
            houseType: address.houseType,
            city: address.city,
            address: address.address,
            apto: address.apto,
            zipCode: address.zipCode,
            state: address.state,
            moving: moving,
            eweMail: '',
            serviceArea: address.serviceArea,
            lang: language,
            phone: user.phone
        }

        props.setActiveStep(1);
        register(modelRequest);
    }

    const register = (model) => {
        props.functions.setIsLoading(true);
        postRegisterUser(model).then(data => {
            props.functions.setIsLoading(false);
            if (data.status === 'Success') {
                navigate(ePage.creatingAgreement);
            } else if (data.status === "Failed") {
                if (data.code === 'FLR') {
                    props.setModal({ open: true, type: eEsiid.errorCombination });
                } else
                    Notify.failure(t('general.register-error'), { position: 'center-bottom', });
            }
        });
    }

    useEffect(() => {
        getReps();

        if (model.clientIp === null)
            getIp().then(ip => {
                setModel(prevState => ({
                    ...prevState, ['clientIp']: ip
                }));
                saveStorage(eStorage.ip, ip);
            });

        if (model.uniqueVisitorId === null)
            getUniqueVisitorId().then(data => {
                setModel(prevState => ({
                    ...prevState, ['uniqueVisitorId']: data
                }));
                saveStorage(eStorage.uniqueVisitor, data);
            });
    }, []);

    const getReps = () => {
        getRepsData(model).then(data => {
            setReps(data);
        })
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setModel(prevState => ({
            ...prevState, [name]: value
        }));
    }

    const handleChangeCheckBox = (event) => {
        setTerms(event.target.checked);
    }

    return (
        <ValidatorForm ref={formRef} className='container' onSubmit={handleSubmit} onError={errors => console.log(errors)}>
            <h1>{t('electric-info.subtitle-meter')}</h1>
            <div className='row justify-content-md-center'>
                <div className='col-sm-12 col-md-10 col-lg-7 container-card card-content text-center'>
                    <div className="row justify-content-md-center">
                        <div className="col-md-5 d-flex align-items-center justify-content-center">
                            <img className="img-fluid" src={MeterImage} alt="logo" />
                        </div>
                        <div className="col-md-6">
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <SelectValidator className="text-start" fullWidth size="small" name="rep" value={model.rep} onChange={handleChange} label={t('general.electricity-company')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.electricity-company'))]}>
                                        {reps.map((item, index) => (
                                            <MenuItem key={index} value={item.uid}>{item.name}</MenuItem>
                                        ))}
                                    </SelectValidator>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <TextValidator autoComplete="nope" fullWidth size="small" name="meterNumber" value={model.meterNumber} onChange={handleChange} label={t('general.meter-number')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.meter-number'))]} />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className={props.functions.device === eDevice.mobile ? "col-10" : "col-md-10"}>
                                    <TextField fullWidth size="small" className="text-terms" multiline rows={3} defaultValue={t('electric-info.terms-meter')} label={t('general.terms')} />
                                </div>
                                <div className={props.functions.device === eDevice.mobile ? "col-2" : "col-md-2"}>
                                    <Checkbox name="terms" checked={terms} onChange={handleChangeCheckBox} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <Button className='btn-primary' type="submit" disabled={!terms}>
                                {t('general.continue')}
                            </Button>
                            <Button className='btn-secondary ms-2' component={Link} to="/electricity-bill">{t('general.need-help')}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </ValidatorForm>
    )
}

export default MeterInfo;