
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React } from 'react';
import * as Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';

function RecommendChart(props) {
    const { t } = useTranslation();

    function buildChart() {
        return {
            chart: {
                type: 'spline',
                height: '250px',
            },
            exporting: {
                enabled: false
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: props.planSelected.monthlyBills.labels
            },
            yAxis: [{
                title: {
                    text: '$ Dollars',
                    unit: '$'
                }
            }, {
                opposite: true, //optional, you can have it on the same side.
                title: {
                    text: 'kWh',
                    unit: 'kwh'
                },
                min: 0
            }],
            tooltip: {
                shared: true,
                valueDecimals: 2
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                },
                series: {
                    marker: {
                        enabled: false
                    }
                }
            },
            series: [
                {
                    name: t('recommend.this-plan'),
                    yAxis: 0, //if using primary (0) yAxis can leave this out.
                    data: props.planSelected.monthlyBills.data,
                    tooltip: {
                        valuePrefix: '$'
                    },
                    color: '#de3daf'
                },
                {
                    name: t('recommend.consumption'),
                    dashStyle: 'dash',
                    yAxis: 1,
                    data: props.currentPlan.consumption,
                    tooltip: {
                        valueSuffix: ' kWh'
                    },
                    color: '#212e3d'
                }
            ]
        }
    }

    return (
        <HighchartsReact  allowChartUpdate={false} highcharts={Highcharts} options={buildChart()} />
    )
}

export default RecommendChart;