import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Button } from '@mui/material';
import plansImage from '../../Assets/Icons/plans.png';
import productImage from '../../Assets/Icons/products.png';
import { Link } from 'react-router-dom';

function SelectShop(props) {
    const { t } = useTranslation();

    useEffect(() => {

    }, []);

    return (
        <div className="content">
            <div className='container'>
          
                <h1>{t('shop.you-want')}</h1>
                <div className='row justify-content-md-center'>
                    <div className="col-md-12 col-lg-8">
                        <div className="row">
                            <div className='col-md-6 mt-4  mb-1'>
                                <div className="container-card card-content h-100">
                                    <h2 className="text-center">{t('shop.electricty')}</h2>
                                    <div className="card-content">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <img alt="" className="card-icon" src={plansImage} />
                                            </div>
                                            <div className="col-md-8">
                                                <p className="col-md-12 description-text mt-2">
                                                    {t('shop.recommendations')}
                                                </p>
                                                <Button className='btn-primary mt-2' component={Link} to="/recommend">
                                                   {t('shop.showplans')}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 mt-4  mb-1'>
                                <div className="container-card card-content h-100">
                                    <h2 className="text-center">{t('shop.products')}</h2>
                                    <div className="card-content">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <img alt="" className="card-icon" src={productImage} />
                                            </div>
                                            <div className="col-md-8">
                                                <p className="col-md-12 description-text mt-2">
                                                    {t('shop.bestproducts')}
                                                </p>
                                                <Button className='btn-primary mt-2' component={Link} to="/shop">
                                                    {t('shop.shopproducts')}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectShop;