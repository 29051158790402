/* eslint-disable jsx-a11y/anchor-is-valid */
import "bootstrap/dist/css/bootstrap.min.css";
import expirationImage from '../../Assets/Icons/expiration.png';
import energyAudit from '../../Assets/Icons/navigation-expiration.png';
import homeImage from '../../Assets/Icons/energy-profile.png';
import inputsImage from '../../Assets/Icons/inputs.png';
import shopImage from '../../Assets/Icons/shop.png';
import owlImage from '../../Assets/Icons/white_owl.png';
import homeWhiteImage from '../../Assets/Icons/home-white.png';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { React, useEffect, useState } from 'react';

function Sidebar(props) {
    const { t } = useTranslation();
    const [userType, setUserType] = useState('');

    useEffect(() => {

    }, []);


    return (
        <div className="col-auto">
            <div id="sidebar" className={props.menuOpen ? "sidebar show border-end" : "sidebar hide border-end"}>
                <div className="list-group border-0 rounded-0 text-sm-start min-vh-100">
                    <Button onClick={() => props.setMenuOpen(false)} component={Link} to="/home" className="list-group-item first border-end-0 d-inline-block text-truncate text-capitalize"><img src={homeWhiteImage} alt="icon" /> <span>Inicio</span> </Button>
                    <ul className="submenu nav flex-column ms-1">
                        <li className="w-100">
                            <img src={inputsImage} alt="icon" />
                            <Button onClick={() => props.setMenuOpen(false)} component={Link} to="/inputs" className="nav-link-img px-0"><span className="d-sm-inline">Inputs</span></Button>
                        </li>
                    </ul>
                    <ul className="submenu nav flex-column ms-1">
                        <li className="w-100">
                            <img src={energyAudit} alt="icon" />
                            <Button onClick={() => props.setMenuOpen(false)} component={Link} to="/win-prize" className="nav-link-img px-0"><span className="d-sm-inline">Win Prize</span></Button>
                        </li>
                    </ul>
                    <ul className="submenu nav flex-column ms-1">
                        <li className="w-100">
                            <img src={homeImage} alt="icon" />
                            <Button onClick={() => props.setMenuOpen(false)} component={Link} to="/my-usage" className="nav-link-img px-0"><span className="d-sm-inline">My Usage</span></Button>
                        </li>
                    </ul>
                    <ul className="submenu nav flex-column ms-1">
                        <li className="w-100">
                            <img src={shopImage} alt="icon" />
                            <Button onClick={() => props.setMenuOpen(false)} component={Link} to="/select-shop" className="nav-link-img px-0"><span className="d-sm-inline">Shop</span></Button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Sidebar;