/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import { useRef } from 'react';
import CreditCard from "../../../Components/Shared/CreditCard";
import { Button } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";


function Payment(props) {
    const { t } = useTranslation();
    const formRef = useRef();

    useEffect(() => {
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        props.setPayment(prevState => ({
            ...prevState, [name]: value
        }));
    }

    const handleSubmit = () => {
        props.setActiveStep(props.activeStep + 1);
    }

    const handleBackStep = () => {
        props.setActiveStep(props.activeStep - 1);
    };

    return (
        <>
            <div className="row justify-content-md-center">
                <h2 className="mb-3">{t('general.payment-info')}</h2>
                <div className="col-md-10">
                    <h3 className="text-CENTER"><strong>${props.planSelected.enrollmentMinimum.toFixed(2)}</strong> {t('payless.payment-minimum')}</h3>
                    <h3 className="text-start">{t('payless.payment-info')}</h3>
                </div>
            </div>
            <ValidatorForm className="mt-3" ref={formRef} onSubmit={handleSubmit} onError={errors => console.log(errors)}>
                <CreditCard handleChange={handleChange} payment={props.payment} setPayment={props.setPayment} handleSubmit={handleSubmit}></CreditCard>
                <div className="row mt-3">
                    <div className="col-md-12">
                        {props.activeStep > 0 && <Button className='btn-secondary mt-2 me-2' onClick={handleBackStep}>{t('general.back')}</Button>}
                        <Button className='btn-primary mt-2' type='submit'>{t('general.next')}</Button>
                    </div>
                </div>
            </ValidatorForm >
        </>
    )
}

export default Payment;