import { eStorage, getStorageJSON } from "../../Services/StorageService";
import { constellationApiInstance } from "../../Util/Api";


export const getSwitchTypes = () => {
    let serviceArea = getStorageJSON(eStorage.address).serviceArea;
    let esiid = getStorageJSON(eStorage.user).esiid;

    const response = constellationApiInstance.get(`/GetSwitchTypes?serviceArea=${serviceArea}&esiid=${esiid}`)
        .then(({ data }) => {

            return data;
        });
    return response;
}