import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { eStorage, getStorage } from './StorageService';

export const eEvent = {
    agreementAccepted: 'agreementAccepted',
    customerDeleted: 'customerDeleted',
    agreementCreated: 'agreementCreated',
    trendsCompleted: 'trendsCompleted',
    userCreated: 'userCreated',
    efficiencyCompleted: 'efficiencyCompleted',
    forgotPassword: 'forgotPassword',
    recommendations: 'recommendations',
    impactCompleted: 'impactCompleted',
    orderCreated: 'orderCreated',
    orderCompleted: 'orderCompleted',
    login: 'login'
}

export const eCategory = {
    account: 'Account',
    fingerprint: 'Fingerprint',
    matchMaker: 'MatchMaker',
    plan: 'Plan'
}

export const eAction = {
    other: 'Other',
    delete: 'Delete',
    created: 'Created',
    updated: 'Updated',
    viewed: 'Viewed'
}

export const submitTrackingEvent = (eventName, category, action) => {

    const customer = getStorage(eStorage.user);
    let customerId = customer ? customer.customerId : null;

    let event = {
        category: category,
        action: action,
        label: eventName,
        customerId: customerId
    }

    ReactPixel.track(eventName, event);
    ReactGA.event(event);

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: eventName
    })
}