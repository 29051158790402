/* eslint-disable react-hooks/exhaustive-deps */
import { React, forwardRef, useEffect, useState, useRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, TextField } from '@mui/material';
import { useTranslation } from "react-i18next";
import { eEsiid } from './Enum/EEsiid';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { addPropertyStorage } from './../../Services/StorageService';
import { eStorage } from './../../Services/StorageService';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalEsiid(props) {
    const { t } = useTranslation();
    const [data, setData] = useState({ description: '', buttonOne: '', buttonOneLink: '', buttonTwo: '', buttonTwoLink: '' });
    const formRef = useRef();
    const [esiid, setEsiid] = useState('');
    const [showInput, setShowInput]= useState(false);

    useEffect(() => {
        switch (props.modal.type) {
            case eEsiid.noTexas:
                setData({
                    description: t('modal-address.no-texas'),
                    buttonOne: t('general.accept'),
                    buttonTwo: ''
                });
                break;
            case eEsiid.yesTexasNoEsiid:
                setData({
                    description: t('modal-address.texas-no-esiid'),
                    buttonOne: t('general.accept'),
                    buttonTwo: ''
                });
                break;
            case eEsiid.noResidential:
                setData({
                    description: t('modal-address.no-residential'),
                    buttonOne: t('general.accept'),
                    buttonTwo: ''
                });
                break;
            case eEsiid.errorCombination:
                setData({
                    description: t('modal-address.error-combination'),
                    buttonOne: t('general.accept'),
                    buttonTwo: ''
                });
                break;
            case eEsiid.unableEsiid:
                setData({
                    description: t('modal-address.unable-esiid'),
                    buttonOne: t('general.accept'),
                    buttonTwo: ''
                });
                break;
            default: break;
        }
        setShowInput(false);
        setEsiid('');
    }, [props.modal]);

    const handleClose = () => {
        addPropertyStorage(eStorage.user, 'esiid', esiid);
        const params = { open: false };
        if (esiid && esiid.length <= 22) {
            params['esiid'] = esiid;
        }
        props.setModal(params);
    };

    const handleChange = (event) => {
        const { value } = event.target;
        if (value.length <= 22)
            setEsiid(value);
    }

    const toggleInput = (event)=>{
        event.preventDefault()
        setShowInput(!showInput);
        return false;
    }

    return (
        <Dialog open={props.modal.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'sm'} keepMounted onClose={handleClose}>
            <DialogTitle className='text-center'>{t('general.address-error')}</DialogTitle>
            <hr className='m-0' />
            <DialogContent>
                <div className='row mt-2 justify-content-md-center'>
                    <div className='col-md-10'>
                        <h3>{data.description}</h3>
                    </div>
                </div>
                {
                    props.modal.showEsiid && <div className='row mt-2 justify-content-md-center'>
                        <div className='col-md-10'>
                            
                            <h3 className='mb-2'><strong className="click-here" onClick={toggleInput}>{t('general.can_input_esiid')}</strong></h3>
                            {showInput && <TextField autoComplete="nope" fullWidth size="small" maxLength={11} name="esiid" value={esiid} onChange={handleChange} label="ESI ID" />}
                        </div>
                    </div>
                }
            </DialogContent>
            <hr className='mb-0 mt-2' />
            <DialogActions className='justify-content-md-center'>
                {data.buttonTwo !== '' && <Button className='btn-secondary' onClick={handleClose}>{data.buttonTwo}</Button>}
                <Button className='btn-primary' onClick={handleClose}>{data.buttonOne}</Button>
            </DialogActions>
        </Dialog >
    );
}
