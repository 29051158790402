
import { dataServiceApiInstance, orchestratorApiInstance } from "../../Util/Api";

export const getUsersData = (companyId) => {
    const response = dataServiceApiInstance.get(`user/${companyId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const postUser = (model) => {
    model.email = model.email.toLowerCase();
    const response = orchestratorApiInstance.post(`V2/smt2/customer`, model)
        .then(({ data }) => {
            return data;
        });
        return response;
    }