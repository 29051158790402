
import { useTranslation } from 'react-i18next';
import { Dialog, Slide, RadioGroup, Radio, FormControlLabel, FormControlLabelFormControlLabel, FormControl, DialogTitle, DialogContent, DialogActions, Button, TextField, Checkbox } from '@mui/material';
import { forwardRef } from 'react';
import { React, useEffect, useState } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalQuiz(props) {
  const { t } = useTranslation();
  const [response, setResponse] = useState();
  const handleClose = () => {
    props.setModalOpen(false);
  };

  useEffect(() => {
  }, [props?.quiz]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setResponse(prevState => ({
      ...prevState, [name]: value
    }));
  }

  const handleComplete = () => {
    props.setModalOpen(false);
    let quizResponse = []
    props?.quiz?.questions?.map((item, index) => {
        item.questionResponse = response[item.question];
        quizResponse.push(item);
    });
    props.setQuizResponse(quizResponse);
    console.log(quizResponse);
  }

  return (
    <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'md'} keepMounted onClose={handleClose}>
      <DialogTitle className='text-center'>Quiz</DialogTitle>
      <hr className='m-0' />
      <DialogContent>
        <div className="row justify-content-center">
          <div className='col-12'>
            {props?.quiz?.questions?.map((value, index) => {

              return (<div key={index}>
                <div className='row justify-content-md-center mt-4'>
                  <div className='col-md-9'>
                    {index + 1}. {value.questionValue}
                  </div>
                </div>
                <div className='row justify-content-md-center'>
                  <div className='col-md-9 text-center'>
                    {value.typeValue === 'short' &&
                      < TextField fullWidth label="Response" name={value.name} onChange={handleChange} variant="outlined" />
                    }
                    {value.typeValue === 'paragraph' &&
                      <TextField multiline fullWidth rows={3} label="Response" name={value.name} onChange={handleChange} variant="outlined" />
                    }
                    {value.typeValue === 'multiple' &&
                      <FormControl className='text-start'>
                        <RadioGroup name={value.question} onChange={handleChange}>
                          <FormControlLabel value="1" control={<Radio />} label={value.options[0]?.value} />
                          <FormControlLabel value="2" control={<Radio />} label={value.options[1]?.value} />
                          {value.options[2]?.value &&
                            <FormControlLabel value="3" control={<Radio />} label={value.options[2]?.value} />}
                        </RadioGroup>
                      </FormControl>
                    }
                  </div>
                </div>
              </div>
              )
            })}
          </div>
        </div>
      </DialogContent>
      <hr className='mb-0 mt-2' />
      <DialogActions className='justify-content-md-center'>
        <Button className='btn-secondary' onClick={handleComplete}>Complete</Button>
      </DialogActions>
    </Dialog>
  );
}