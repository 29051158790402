/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useState } from 'react';
import { ePeriod } from "../../../Components/DashboardBase/DashboardBase";
import { eStorage, getStorageJSON } from "../../../Services/StorageService";
import ChartWeekPrevious from "../../Dashboard/ChartWeekPrevious";
import ChartMonthPrevious from "../../Dashboard/ChartMonthPrevious";
import ChartWeekLastYear from "../../Dashboard/ChartWeekLastYear";
import CharMonthLastYear from "../../Dashboard/ChartMonthLastYear";
import { eDevice } from "../../../Components/Shared/Enum/EDevice";
import { Box, Tab, ButtonGroup, Button } from "@mui/material";
import { TabContext, TabPanel, TabList } from "@mui/lab";
import useImage from '../../../Assets/Icons/energy-used.png';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import PaidIcon from '@mui/icons-material/Paid';
import FactoryIcon from '@mui/icons-material/Factory';

function ChartConsumptionContainer(props) {
    const { t } = useTranslation();

    const handleChange = () => {

    }

    return <>
        {(props.functions.device !== eDevice.mobile && props.functions.device !== eDevice.tablet) &&
            <>
                {props.period === ePeriod.lastWeekVSPrevious && <ChartWeekPrevious metric={props.metric} periodData={props.periodData.lastWeekVSPrevious} />}
                {props.period === ePeriod.lastMonthVSPrevious && <ChartMonthPrevious metric={props.metric} periodData={props.periodData.lastMonthVSPrevious} />}
                {props.period === ePeriod.lastWeekVSLastYear && <ChartWeekLastYear metric={props.metric} periodData={props.periodData.lastWeekVSLastYear} />}
                {props.period === ePeriod.lastMonthVSLastYear && <CharMonthLastYear metric={props.metric} periodData={props.periodData.lastMonthVSLastYear} />}
            </>
        }

        {(props.functions.device === eDevice.mobile || props.functions.device === eDevice.tablet) &&
            <>
                <div className="row">
                    <div className="col-6 text-start">
                        <ButtonGroup size="small" aria-label="small button group">
                            <Button key="one"><ElectricBoltIcon /></Button>
                            <Button key="two"><FactoryIcon /></Button>
                            <Button key="three"><PaidIcon /></Button>
                        </ButtonGroup>
                        <p className="small-legend">
                            <strong> {Math.abs(Math.round(props.currentView.temperature_difference))}°F </strong> {props.currentView.temperature_difference > 0 ? t('dashboard.hotter') : t('dashboard.cooler')}
                        </p>
                    </div>
                    <div className="col-6">
                        <h4 className="label-blue-s"><img className="icon-savings-s" alt="icon-savings" src={useImage} />
                            {props.period === ePeriod.lastWeekVSPrevious || props.period == ePeriod.lastWeekVSLastYear ?
                                Math.abs(Math.round(props.currentView.lastWeek_total)) : Math.abs(Math.round(props.currentView.lastMonth_total))
                            }
                            &nbsp;kWh
                        </h4>
                        <p className="small-legend">
                            <strong>{props.period === ePeriod.lastWeekVSPrevious || props.period == ePeriod.lastWeekVSLastYear ?
                                Math.abs(Math.round(((props.currentView.lastWeek_total - props.currentView.previousWeek_total) / props.currentView.lastWeek_total) * 100)) :
                                props.period === ePeriod.lastMonthVSLastYear ?
                                    Math.abs(Math.round(((props.currentView.lastMonth_total - props.currentView.previousYearMonth_total) / props.currentView.lastMonth_total) * 100)) :
                                    Math.abs(Math.round(((props.currentView.lastMonth_total - props.currentView.previousMonth_total) / props.currentView.lastMonth_total) * 100))
                            }%
                                {props.period === ePeriod.lastWeekVSPrevious || props.period == ePeriod.lastWeekVSLastYear ?
                                    (props.currentView.lastWeek_total > props.currentView.previousWeek_total ? '+' : '-') :
                                    props.period === ePeriod.lastMonthVSLastYear ?
                                        (props.currentView.lastMonth_total > props.currentView.previousYearMonth_total ? '+' : '-') :
                                        (props.currentView.lastMonth_total > props.currentView.previousMonth_total ? '+' : '-')
                                }
                            </strong>  {t('general.vs-period')}
                        </p>
                    </div>
                </div>
                <hr className="mt-2 mb-2" />
                <div className="row">
                    <h2 className="text-start mt-2 mb-0">{t('general.consumption')}</h2>
                    <TabContext value={props.period}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="Weeks" value={ePeriod.lastWeekVSPrevious} />
                                <Tab label="Months" value={ePeriod.lastMonthVSPrevious} />
                                <Tab label="week-year" value={ePeriod.lastWeekVSLastYear} />
                                <Tab label="month-year" value={ePeriod.lastMonthVSLastYear} />
                            </TabList>
                        </Box>
                        <TabPanel className="p-0 m-0" value={ePeriod.lastWeekVSPrevious}><ChartWeekPrevious metric={props.metric} goals={props.goals} periodData={props.periodData.lastWeekVSPrevious} /></TabPanel>
                        <TabPanel className="p-0 m-0" value={ePeriod.lastMonthVSPrevious}><ChartMonthPrevious metric={props.metric} goals={props.goals} periodData={props.periodData.lastMonthVSPrevious} /></TabPanel>
                        <TabPanel className="p-0 m-0" value={ePeriod.lastWeekVSLastYear}><ChartWeekLastYear metric={props.metric} goals={props.goals} periodData={props.periodData.lastWeekVSLastYear} /></TabPanel>
                        <TabPanel className="p-0 m-0" value={ePeriod.lastMonthVSLastYear}><CharMonthLastYear metric={props.metric} goals={props.goals} periodData={props.periodData.lastMonthVSLastYear} /></TabPanel>
                    </TabContext>
                </div>
            </>
        }
    </>
}

export default ChartConsumptionContainer;