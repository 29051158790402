/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { getAllNotifications, postDelete } from "../../Components/NotificationBase/NotificationBase";
import { eStorage, getStorageJSON } from "../../Services/StorageService";
import { List, Divider, Button, IconButton } from '@mui/material';
import { Link } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';

function Notifications(props) {
    const { t } = useTranslation();
    const [notifications, setNotifications] = useState([]);
    const [user] = useState(getStorageJSON(eStorage.user));

    useEffect(() => {
        getNotifications();
    }, []);

    const getNotifications = () => {
        props.functions.setIsLoading(true);
        let customer = getStorageJSON(eStorage.user);

        getAllNotifications(customer.customerId).then(data => {
            setNotifications(data);
            console.log(data);
            props.functions.setIsLoading(false);
        });
    }

    const handleDelete = (id) => {
        postDelete(id).then(data => {
            getNotifications();
        });
    };

    return (
        <div className='content'>
            <div className='container mb-4'>
                <div className="row justify-content-md-center">
                    <div className="col-md-8">
                        <div className="container-card card-content">
                            <h2 className="text-start">NOTIFICATIONS</h2>
                            <List dense sx={{ width: '100%' }}>
                                {notifications.map((value, index) => {
                                    return (
                                        <>
                                            <div className="row mt-1 mb-2">
                                                <div className="col-md-3 text-start">
                                                    <strong className="description-text m-0">{value.title}</strong>
                                                </div>
                                                <div className="col-md-9">
                                                    <div className="row">
                                                        <div className="col-md-9 text-start">
                                                            <p className="description-text m-0">{value.text}</p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <Button className={'btn-primary-s me-1'} component={Link} to={value.redirect} >See More</Button>
                                                            <IconButton onClick={() => handleDelete(value.id)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider />
                                        </>
                                    )
                                })}
                            </List>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notifications;