import axios from 'axios';
import { getParams } from './Util';
import { eStorage, getStorage, saveStorage } from '../Services/StorageService';

const requestInterceptor = (config) => {
  const token = getStorage(eStorage.bearer);
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  config.headers['Content-Type'] = 'application/json';
  return config
}

const requestInterceptorError = async (error, axiosInstance) => {
  if (error && error.response && (parseInt(error.response.status) === 401)) {
    const originalRequest = error.config;
    const refreshToken = getStorage(eStorage.refreshToken);
    let attemptRefresh = getStorage(eStorage.attemptRefresh);
    let attempt = attemptRefresh ? parseInt(attemptRefresh) : 0;

    if (attempt >= 3) {
      saveStorage(eStorage.attemptRefresh, 0);
      redirect();
    }

    if (!refreshToken) {
      redirect();
    }

    const payload = {
      refreshToken,
    };

    let apiResponse = await axios.post(
      `${getParams().ORCHESTRATOR_SERVICE_BASEURL}auth/refreshToken`,
      payload
    );
    if (apiResponse.data.bearer) {
      saveStorage(eStorage.bearer, apiResponse.data.bearer);
      saveStorage(eStorage.attemptRefresh, 0);
      if (apiResponse.data.bearer) {
        saveStorage(eStorage.refreshToken, apiResponse.data.refreshToken?? refreshToken);
        // Retry the original request
        return axiosInstance(originalRequest);
      }
    } else {
      redirect();
    }
    saveStorage(eStorage.attemptRefresh, attemptRefresh++);
  } else {
    return Promise.reject(error);
  }
}

const redirect = () => {
  localStorage.clear();
  window.location.pathname = '/login';
}

const responseInterceptor = (response) => {
  return response
}

const getOrchestratorAxios = () => {
  const instance = axios.create({
    baseURL: getParams().ORCHESTRATOR_SERVICE_BASEURL,
    timeout: 120000,
  });
  instance.interceptors.request.use(requestInterceptor,);
  instance.interceptors.response.use(responseInterceptor, (error) => requestInterceptorError(error, instance));
  return instance;
};


const getDataServiceAxios = () => {
  const instance = axios.create({
    baseURL: getParams().DATA_SERVICE_BASEURL,
    timeout: 200000
  });
  instance.interceptors.request.use(requestInterceptor);
  instance.interceptors.response.use(responseInterceptor, (error) => requestInterceptorError(error, instance));
  return instance;
};

const getSmartDeviceAxios = () => {
  const instance = axios.create({
    baseURL: getParams().SDM_BASEURL,
    timeout: 200000
  });
  instance.interceptors.request.use(requestInterceptor);
  instance.interceptors.response.use(responseInterceptor, (error) => requestInterceptorError(error, instance));
  return instance;
};

const getCalcEngineAxios = () => {
  const instance = axios.create({
    baseURL: getParams().CALCENGINE_SERVICE_BASEURL,
    timeout: 100000
  });
  instance.interceptors.request.use(requestInterceptor);
  instance.interceptors.response.use(responseInterceptor, (error) => requestInterceptorError(error, instance));
  return instance;
};

const getConstellationAxios = () => {
  const instance = axios.create({
    baseURL: getParams().CONSTELLATION_BASEURL,
    timeout: 100000
  });
  instance.interceptors.request.use(requestInterceptor);
  instance.interceptors.response.use(responseInterceptor, (error) => requestInterceptorError(error, instance));
  return instance;
};

const getFlagshipAxios = () => {
  const instance = axios.create({
    baseURL: getParams().FLAGSHIP_BASEURL,
    timeout: 100000
  });
  instance.interceptors.request.use(requestInterceptor);
  instance.interceptors.response.use(responseInterceptor, (error) => requestInterceptorError(error, instance));
  return instance;
};

const getPulseAxios = () => {
  const instance = axios.create({
    baseURL: getParams().PULSE_BASEURL,
    timeout: 100000
  });
  instance.interceptors.request.use(requestInterceptor);
  instance.interceptors.response.use(responseInterceptor, (error) => requestInterceptorError(error, instance));
  return instance;
};

const getTexpoAxios = () => {
  const instance = axios.create({
    baseURL: getParams().TEXPO_BASEURL,
    timeout: 100000
  });
  instance.interceptors.request.use(requestInterceptor);
  instance.interceptors.response.use(responseInterceptor, (error) => requestInterceptorError(error, instance));
  return instance;
};

const getPaylessAxios = () => {
  const instance = axios.create({
    baseURL: getParams().PAYLESS_BASEURL,
    timeout: 100000
  });
  instance.interceptors.request.use(requestInterceptor);
  instance.interceptors.response.use(responseInterceptor, (error) => requestInterceptorError(error, instance));
  return instance;
};

const getChariotAxios = () => {
  const instance = axios.create({
    baseURL: getParams().CHARIOT_BASEURL,
    timeout: 100000
  });
  instance.interceptors.request.use(requestInterceptor);
  instance.interceptors.response.use(responseInterceptor, (error) => requestInterceptorError(error, instance));
  return instance;
};

const getRhythmAxios = () => {
  const instance = axios.create({
    baseURL: getParams().RHYTHM_BASEURL,
    timeout: 100000
  });
  instance.interceptors.request.use(requestInterceptor);
  instance.interceptors.response.use(responseInterceptor, (error) => requestInterceptorError(error, instance));
  return instance;
};

export const orchestratorApiInstance = getOrchestratorAxios();
export const dataServiceApiInstance = getDataServiceAxios();
export const smartDeviceApiInstance = getSmartDeviceAxios();
export const calcEngineApiInstance = getCalcEngineAxios();
export const constellationApiInstance = getConstellationAxios();
export const flagshipApiInstance = getFlagshipAxios();
export const pulseApiInstance = getPulseAxios();
export const texpoApiInstance = getTexpoAxios();
export const chariotApiInstance = getChariotAxios();
export const rhythmApiInstance = getRhythmAxios();
export const paylessApiInstance = getPaylessAxios();