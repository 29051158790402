/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect, useState, useRef } from 'react';
import { getDevice, postCommand, postValues } from '../../Components/DeviceBase/DeviceBase';
import { useParams } from "react-router-dom";
import { eStorage, getStorageJSON } from '../../Services/StorageService';
import { CircularSliderWithChildren } from "react-circular-slider-svg";
import { ButtonGroup, Button, Tooltip } from "@mui/material";
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import DeblurIcon from '@mui/icons-material/Deblur';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { eCommand } from "../../Components/Shared/Enum/ECommand";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { eMode } from "../../Components/Shared/Enum/EMode";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import NumberControl from "../../Components/Shared/NumberControl";


function ThermostatDevice(props) {
    const [device, setDevice] = useState({});
    const [model, setModel] = useState({ minLimit: 50, maxLimit: 90 });
    const [value, setValue] = useState(0);
    const [value2, setValue2] = useState(0);
    const [mode, setMode] = useState('');
    const { id } = useParams();
    const { type } = useParams();
    const formRef = useRef();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        let customer = getStorageJSON(eStorage.user);
        props.functions.setIsLoading(true);
        let typeApi = type === 'GOOGLE' ? 'google' : 'resideo';
        console.log(type);
        getDevice(customer.customerId, id, typeApi).then(data => {
            console.log(data);
            setDevice(data);

            if (data?.mode === 'MANUAL_ECO') {
                setMode('MANUAL_ECO');
                setValue(toFahrenheith(data?.heatCelsius)?.toFixed(1));
                setValue2(toFahrenheith(data?.coolCelsius)?.toFixed(1));
            }
            else {
                setMode(data?.mode);
                if (data?.mode === 'HEAT')
                    setValue(toFahrenheith(data?.heatCelsius)?.toFixed(1));
                else if (data?.mode === 'COOL'){
                    console.log(data?.coolCelsius);
                    console.log(toFahrenheith(data?.coolCelsius));
                    setValue2(toFahrenheith(data?.coolCelsius)?.toFixed(1));
                }
                else if (data?.mode === 'HEATCOOL') {
                    setValue(toFahrenheith(data?.heatCelsius)?.toFixed(1));
                    setValue2(toFahrenheith(data?.coolCelsius)?.toFixed(1));
                }
            }
            props.functions.setIsLoading(false);
        });
    }

    const toFahrenheith = (current) => {
        if (current > 0) {
            if (type === "GOOGLE") {
                let operation = (current * 9 / 5) + 32;
                return operation;
            }
            else {
                return current;
            }
        }
    }

    const changeTemperature = () => {
        props.functions.setIsLoading(true);
        let customer = getStorageJSON(eStorage.user);
        let type = '';
        let valueRequest = 0;
        if (mode === 'HEAT') {
            type = eCommand.setHeat
            valueRequest = value;
        }
        else if (mode === 'COOL') {
            type = eCommand.setCool;
            valueRequest = value2;
        }
        else if (mode === 'HEATCOOL') {
            type = eCommand.setRange;
            valueRequest = value;
        }

        let model = {
            deviceId: id,
            customerId: customer.customerId,
            value1: type === 'GOOGLE' ? (valueRequest - 32) * 5 / 9 : valueRequest,
            value2: type === 'GOOGLE' ? (value2 - 32) * 5 / 9 : value2,
            type: type
        }

        let typeApi = type === 'GOOGLE' ? 'google' : 'resideo';
        postCommand(model, typeApi).then(data => {
            getData();
            Notify.success('The temperature was changed correctly.', { position: 'center-bottom', });
        }).catch(console.log);
    }

    const changeMode = (newMode) => {
        props.functions.setIsLoading(true);
        let customer = getStorageJSON(eStorage.user);
        let model = {
            deviceId: id,
            customerId: customer.customerId,
            value1: newMode,
            type: newMode === 'MANUAL_ECO' ? eCommand.setEcoMode : eCommand.setMode
        }

        let typeApi = type === 'GOOGLE' ? 'google' : 'resideo';
        postCommand(model, typeApi).then(data => {
            getData();
            Notify.success('The mode was changed correctly.', { position: 'center-bottom', });
        }).catch(console.log);
    }

    const handleChanges = (event) => {
        const { name, value } = event.target;
        setModel(prevState => ({
            ...prevState, [name]: value
        }));
    }

    const handleChangeHeat = (event) => {
        setValue(event);
        changeTemperature();
    }

    const handleChangeCool = (event) => {
        setValue2(event);
        changeTemperature();

    }

    const handleCircularSliderValue = (event) => {
        const val = parseFloat(event.toFixed(1));
        setValue(val);
    }

    const handleCircularSliderValue2 = (event) => {
        const val = parseFloat(event.toFixed(1));
        setValue2(val);
    }

    const updateValues = (mode) => {
        props.functions.setIsLoading(true);
        let customer = getStorageJSON(eStorage.user);
        let modelRequest = {
            customerId: customer.customerId,
            minLimit: parseInt(model.minLimit).toFixed(1),
            maxLimit: parseInt(model.maxLimit).toFixed(1)
        }

        postValues(modelRequest).then(data => {
            props.functions.setIsLoading(false);
            Notify.success('The values min and max changed correctly.', { position: 'center-bottom', });
        }).catch(console.log);
    }

    return (
        <div className='content'>
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="container-card card-content">
                        {Object.keys(device).length > 0 &&
                            <h2>{device?.name}</h2>
                        }
                        <div className="row justify-content-center">
                            <div className="col-md-5">
                                <div className="row justify-content-center">
                                    <div className="col-md-12 control-temperature">
                                        {device && Object.keys(device).length > 0 && (mode === 'HEAT') && (
                                            <CircularSliderWithChildren
                                                onControlFinished={() => changeTemperature()}
                                                handle1={{
                                                    value: value,
                                                    onChange: handleCircularSliderValue
                                                }}
                                                arcColor="#FD5008"
                                                startAngle={40}
                                                endAngle={320}
                                                minValue={50}
                                                maxValue={90}
                                                size={300}
                                            >
                                                <div className="text-center mt-5">
                                                    <p className='large-legend-heat'>
                                                        {value} °F
                                                    </p>
                                                </div>
                                            </CircularSliderWithChildren>
                                        )}
                                        {device && Object.keys(device).length > 0 && (mode === 'COOL') && (
                                            <CircularSliderWithChildren
                                                onControlFinished={() => changeTemperature()}
                                                handle1={{
                                                    value: value2,
                                                    onChange: handleCircularSliderValue2
                                                }}
                                                arcColor="#67A4DC"
                                                startAngle={40}
                                                endAngle={320}
                                                minValue={50}
                                                maxValue={90}
                                                size={300}
                                            >
                                                <div className="text-center mt-5">
                                                    <p className='large-legend-cool'>
                                                        {value2} °F
                                                    </p>
                                                </div>
                                            </CircularSliderWithChildren>
                                        )}
                                        {device && Object.keys(device).length > 0 && (mode === 'HEATCOOL') && (
                                            <CircularSliderWithChildren
                                                onControlFinished={() => changeTemperature()}
                                                handle1={{
                                                    value: value,
                                                    onChange: handleCircularSliderValue
                                                }}
                                                handle2={{
                                                    value: value2,
                                                    onChange: handleCircularSliderValue2
                                                }}
                                                arcColor="#9DAAB2"
                                                startAngle={40}
                                                endAngle={320}
                                                minValue={50}
                                                maxValue={90}
                                                size={300}
                                            >
                                                <div className="text-center mt-5">
                                                    <p className='large-legend-cool-md'>
                                                        {value2} °F
                                                    </p>
                                                    <p className='large-legend-heat'>
                                                        {value} °F
                                                    </p>
                                                </div>
                                            </CircularSliderWithChildren>
                                        )}
                                        {device && Object.keys(device).length > 0 && (mode === 'MANUAL_ECO') && (
                                            <CircularSliderWithChildren
                                                handle1={{
                                                    value: value
                                                }}
                                                arcColor="#9DAAB2"
                                                startAngle={40}
                                                endAngle={320}
                                                minValue={50}
                                                maxValue={90}
                                                size={300}
                                            >
                                                <div className="text-center mt-5">
                                                    <p className='large-legend-eco-md'>
                                                        ECO
                                                    </p>
                                                </div>
                                            </CircularSliderWithChildren>
                                        )}
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    {(mode === 'HEAT' || mode === 'HEATCOOL') &&
                                        <div className="col-md-5">
                                            <NumberControl
                                                className='input-goal'
                                                max={90}
                                                min={50}
                                                step={1}
                                                value={value}
                                                onChange={handleChangeHeat}
                                            />
                                        </div>
                                    }
                                    {(mode === 'COOL' || mode === 'HEATCOOL') &&
                                        <div className="col-md-5">
                                            <NumberControl
                                                className='input-goal'
                                                max={90}
                                                min={50}
                                                step={1}
                                                value={value2}
                                                onChange={handleChangeCool}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        {Object.keys(device).length > 0 &&
                                            <ButtonGroup variant="outlined">
                                                <Tooltip title="Heat" placement="top">
                                                    <Button className={mode === 'HEAT' ? 'btn-primary-group' : 'btn-secondary-group'} onClick={() => changeMode(eMode.heat)}><LocalFireDepartmentIcon /></Button>
                                                </Tooltip>
                                                <Tooltip title="Cool" placement="top">
                                                    <Button className={mode === 'COOL' ? 'btn-primary-group' : 'btn-secondary-group'} onClick={() => changeMode(eMode.cool)}><AcUnitIcon /></Button>
                                                </Tooltip>
                                                <Tooltip title="Heat Cool" placement="top">
                                                    <Button className={mode === 'HEATCOOL' ? 'btn-primary-group' : 'btn-secondary-group'} onClick={() => changeMode(eMode.heatCool)}><DeblurIcon /></Button>
                                                </Tooltip>
                                                {type === 'GOOGLE' &&
                                                    <Tooltip title="Eco" placement="top">
                                                        <Button className={mode === 'MANUAL_ECO' ? 'btn-primary-group' : 'btn-secondary-group'} onClick={() => changeMode(eMode.ecoMode)}><EnergySavingsLeafIcon /></Button>
                                                    </Tooltip>
                                                }
                                                <Tooltip title="Power Off" placement="top">
                                                    <Button className={mode === 'OFF' ? 'btn-primary-group' : 'btn-secondary-group'} onClick={() => changeMode(eMode.off)}><PowerSettingsNewIcon /></Button>
                                                </Tooltip>
                                            </ButtonGroup>
                                        }
                                    </div>
                                </div>
                                <div className="row mt-3 green-text">
                                    <p className="css-83ijpv-MuiTypography-root mb-1">
                                        Actual conditions
                                    </p>
                                    <div className="col-md-6">
                                        {Object.keys(device).length > 0 &&
                                            <h3 className="m-0">{toFahrenheith(device?.ambientTemperature).toFixed(1)} °F</h3>
                                        }
                                        <p className="css-83ijpv-MuiTypography-root">
                                            Interior
                                        </p>
                                    </div>
                                    <div className="col-md-6">
                                        {Object.keys(device).length > 0 &&
                                            <h3 className="m-0">{device?.ambientHumidity} %</h3>
                                        }
                                        <p className="css-83ijpv-MuiTypography-root">
                                            Humidity
                                        </p>
                                    </div>
                                </div>
                                <hr />
                                <ValidatorForm ref={formRef} className='row' onSubmit={updateValues} onError={errors => console.log(errors)}>
                                    <p className="css-83ijpv-MuiTypography-root">
                                        Values allowed by automations
                                    </p>
                                    <div className="col-md-6">
                                        <TextValidator type={'number'} autoComplete="nope" fullWidth size="small" name="minLimit" value={model.minLimit} onChange={handleChanges} label='Min limit' validators={['required', 'minNumber:50', 'maxNumber:90']} errorMessages={['Min limit is required', 'Min number is 50', 'Max number is 90']} />
                                    </div>
                                    <div className="col-md-6">
                                        <TextValidator type={'number'} autoComplete="nope" fullWidth size="small" name="maxLimit" value={model.maxLimit} onChange={handleChanges} label='Max limit' validators={['required', 'minNumber:50', 'maxNumber:90']} errorMessages={['Max limit is required', 'Min number is 50', 'Max number is 90']} />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Button type="submit" className='btn-primary mt-2'>Save</Button>
                                        </div>
                                    </div>
                                </ValidatorForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default ThermostatDevice;