import { useTranslation } from "react-i18next";
import { React, useEffect } from 'react';
import * as Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';
import { eStorage, getStorage } from "../../../Services/StorageService";
import { eMetric } from "../../../Components/Shared/Enum/EMetric";

function ChartWeek(props) {
    const { t } = useTranslation();

    useEffect(() => {

    }, []);

    function buildChart() {
        const categories = getStorage(eStorage.language) === 'es' ?
            ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"] : ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

        return {
            chart: {
                type: 'spline',
                height: '320px'
            },
            credits: { enabled: false },
            title: {
                text: null
            },
            xAxis: {
                type: 'category',
                categories: categories
            },
            yAxis:
            {
                offset: 2,
                min: 0,
                title: {
                    text: props.metric === eMetric.energy ? t('dashboard.energy-consumption') : props.metric === eMetric.carbon ? t('dashboard.carbon-emission') : t('dashboard.cost-dollar'),
                    x: -15,
                },
                labels: {
                    align: 'left'
                    , x: -20
                }
            }
            ,
            tooltip: {
                shared: true
            },
            series: [{
                name: props?.periodData?.lastWeek_start + " - " + props?.periodData?.lastWeek_end,
                data: props?.periodData?.lastWeek,
                yAxis: 0,
                tooltip: {
                    pointFormat: '',
                }
            },
            {
                name: props?.periodData?.previousWeek_start + " - " + props?.periodData?.previousWeek_end,
                data: props?.periodData?.previousWeek,
                yAxis: 0,
                tooltip: {
                    pointFormat: '',
                },
                color: "rgb(3, 47, 74, 0.5)"
            },
            {
                name: 'Goal',
                data: props?.periodData?.previousWeek?.map(x => x * ((props.metric === eMetric.energy ? (100 - props.goals?.energy) : props.metric === eMetric.carbon ? (100 - props.goals?.carbon) : (100 - props.goals?.bill)) / 100)),
                yAxis: 0,
                tooltip: {
                    pointFormat: '',
                },
                color: '#a7d036'
            }]
        }

    }

    return (
        <HighchartsReact highcharts={Highcharts} options={buildChart()} />
    )
}
export default ChartWeek;