
import { useTranslation } from 'react-i18next';
import { Dialog, Slide, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { forwardRef, useEffect } from 'react';
import imageMaintenance from "../../Assets/page-maintenance.png";
import { Link } from 'react-router-dom';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalUpdatePhone(props) {
    const { t } = useTranslation();

    useEffect(() => {
    }, []);

    const handleClose = () => {
        props.setModal({ open: false });
    };

    return (
        <Dialog open={props.modalOpen} TransitionComponent={Transition} fullWidth={true} maxWidth={'md'} keepMounted onClose={handleClose}>
            <DialogTitle className='text-center'>{t('general.update-phone')}</DialogTitle>
            <hr className='m-0' />
            <DialogContent>
                <div className="row justify-content-center mt-2">
                    <div className='col-md-8'>
                        <h3 className='text-center'>{t('general.update-phone-title')}</h3>
                    </div>
                </div>
            </DialogContent>
            <hr className='mb-0 mt-2' />
            <DialogActions className='justify-content-md-center'>
                <Button className='btn-primary' component={Link} to="/phone-number">{t('modal.lets-it')}</Button>
            </DialogActions>
        </Dialog>
    );
}