import "bootstrap/dist/css/bootstrap.min.css";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
import HighchartsHeatmap from "highcharts/modules/heatmap";
import Data from "highcharts/modules/data";
import { React, useEffect, useState } from 'react';
import { getParams, hourToDecimal } from "../../../Util/Util";
HighchartsHeatmap(Highcharts);
Data(Highcharts)

function ChartHeatMap(props) {
    const [location, setLocation] = useState(null);
    useEffect(() => {

        console.log(props?.values?.greater);

        if (props.location && props.location.id) {
            setLocation(props.location);
        }
    }, [props.location]);

    function buildChart() {
        const url = `${getParams().DATA_SERVICE_BASEURL}content/${props.location.id}_${props.type}.csv`;
        const config = {
            data: {
                csvURL: url
            },
            credits: {
                enabled: false
            },
            chart: {
                type: 'heatmap',
                inverted: true,
                marginBottom: 80,
            },
            legend: {
                align: 'center',
                verticalAlign: 'bottom',
                x: 0,
                y: 18
            },
            boost: {
                useGPUTranslations: true
            },
            title: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                min: props.values.startDate,
                max: props.values.endDate,
                tickInterval: 30 * 24 * 3600 * 1000,
                labels: {
                    staggerLines: 1,
                    align: 'left',
                    x: 5,
                    y: 4,
                    formatter: function () {
                        return Highcharts.dateFormat('%b', this.value);
                    },
                    style: {
                        textOutline: '2px',
                        color: '#3F4C61'
                    }
                },
                showLastLabel: true,
                tickLength: props.values.greater
            },
            yAxis: {
                title: {
                    text: props.type === 'energy' ? 'Base Load - Peak Load (kWh)' : props.type === 'fuelMix' ? 'Grid Content Renewable' : 'Carbon emission (tonnes)',
                    y: -3
                },
                minPadding: 0,
                maxPadding: 0,
                startOnTick: false,
                endOnTick: false,
                tickPositions: [0, 6, 12, 18, 24],
                tickWidth: 1,
                min: 0,
                max: 24,
                reversed: false,
                plotLines: [{
                    color: '#3F4C61',
                    width: 1,
                    value: hourToDecimal(props.start),
                    zIndex: 10,
                    dashStyle: 'LongDashDot'
                },
                {
                    color: '#3F4C61',
                    width: 1,
                    value: hourToDecimal(props.end),
                    zIndex: 10,
                    dashStyle: 'LongDashDot'
                }]
            },
            colorAxis: {
                stops: props.type === 'energy' ? [
                    [0, '#8BBC04'],
                    [0.1, '#AAD929'],
                    [0.2, '#BADF54'],
                    [0.3, '#D7F18C'],
                    [0.4, '#EEFDC4'],
                    [0.5, '#ffffff'],
                    [0.6, '#FFBEBE'],
                    [0.7, '#FF8E8E'],
                    [0.8, '#F76565'],
                    [0.9, '#E30B0B'],
                    [1, '#E30B0B']
                ]
                    : [
                        [0, 'rgb(167, 208, 54)'],
                        [1, '#804000']
                    ],

                startOnTick: true,
                endOnTick: true,
                showInLegend: true,
            },
            series: [{
                boostThreshold: 0,
                borderWidth: 0,
                nullColor: '#EFEFEF',
                colsize: 24 * 36e5, // one day
                turboThreshold: Number.MAX_VALUE,
                tooltip: {
                    headerFormat: props.type === 'energy' ? 'Energy Consumption<br/>' : 'Carbon Emissions<br/>',
                    pointFormat: '{point.x:%e %b, %Y} {point.y}:00: <b>{point.value} ' + (props.type === 'energy' ? 'kWh</b>' : 'tonnes</b>')
                },
            }]
        }
        return config;
    }

    return (
        <>
            {location && props.values && <HighchartsReact allowChartUpdate={false} highcharts={Highcharts} options={buildChart()} />}
        </>
    )
}
export default ChartHeatMap;