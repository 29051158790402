/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { Link } from 'react-router-dom';
import { GetCustomerOrder } from './../../Components/ProductOrderBase/ProductOrderBase';


function Order(props) {
    const { t } = useTranslation();
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        props.functions.setIsLoading(true);
        GetCustomerOrder()
        .then(resp=>{
            console.log(resp);
            setOrders(resp);
        })
        .catch(console.log)
        .finally(() => props.functions.setIsLoading(false))
    }, []);

    const onChangeSearch = () => {

    }

    return (
        <div className='content'>
            <h2 className="text-center">ORDERS</h2>
            <div className="row justify-content-center">
{orders && orders.map((item, index)=>(<>
    <div className="col-md-6" key={index}>
        <div className="container-card card-content">
            <hr />
            <h3 className="mb-0"> <strong>Order Id: </strong> {item.id}</h3>
            <span className="css-83ijpv-MuiTypography-root">{(new Date(item.created).toLocaleDateString())}</span>
            <div className="row justify-content-center">
                <div className="col-md-5 text-end">
                    <img className="product-order" src="https://dev.energymatching.com/dataservices/product/image/7f5c7e6c0461b20107f463e741624eac856df8b6"></img>
                </div>
                <div className="col-md-5 text-start">
                    <p className="description-text m-0">
                        {item.productName}
                    </p>
                    <p className="description-text mb-1"><strong> ${item.amount}</strong></p>
                    <Button className={'btn-primary-s'} component={Link} to="/connect-device">Connect Device</Button>
                </div>
            </div>
        </div>
    </div>
</>))}
{orders.length == 0 && <>
                    <div className="col-md-6">
                        <div className="container-card card-content">
                            <hr />
                            <div className="row justify-content-center">
                                <div className="col-md-5 text-end">
                                    <img className="product-order" src="https://dev.energymatching.com/dataservices/product/image/7f5c7e6c0461b20107f463e741624eac856df8b6"></img>
                                </div>
                                <div className="col-md-5 text-start">
                                    <p className="description-text m-0">
                                        you don't have orders
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
</>}
            </div>
        </div>
    )
}

export default Order;