/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useRef, useState } from 'react';
import { TextField, Select, FormControl, InputLabel, MenuItem, Switch, Button } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

function ServiceInfo(props) {
    const { t } = useTranslation();
    const formRef = useRef();
    const [disableButton, setDisableButton] = useState(true);
    const [rangeDates, setRangeDates] = useState({ start: new Date(), end: new Date() });

    useEffect(() => {
        validButtonNext();
    }, [props.terms]);

    useEffect(() => {
        let start = new Date();
        let end = new Date();
        end.setFullYear(end.getFullYear() + 1);
        if (Array.isArray(props.rangeDates) && props.rangeDates.length > 0) {
            if (props.rangeDates.length === 1) {
                start = new Date(props.rangeDates[0]);
            }

            if (props.rangeDates.length === 2) {
                start = new Date(props.rangeDates[0]);
                end = new Date(props.rangeDates[1]);
            }
        }
        setRangeDates({ start, end });
    }, [props.rangeDates]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        props.setService(prevState => ({
            ...prevState, [name]: value
        }));
    }

    const handleChangeDate = (value) => {
        props.setService(prevState => ({
            ...prevState, ['serviceDate']: value
        }));
    };

    const handleBackStep = () => {
        props.setActiveStep(props.activeStep - 1);
    };

    const handleSubmit = () => {
        props.setActiveStep(props.activeStep + 1);
    }

    const handleChangeCheck = (itemChange) => {
        let updated = props.terms.map(item => {
            if (item.id === itemChange.id) {
                return { ...item, value: !item.value };
            }
            return item;
        });
        props.setTerms(updated);
    }

    const validButtonNext = () => {
        if (props?.terms?.length > 0) {
            if (props.terms.filter(w => w.required).length === 0)
                setDisableButton(false);
            else if (props.terms.filter(w => w.required).length > 0 && (props.terms.filter(w => w.required).length <= props.terms.filter(w => w.value).length))
                setDisableButton(false);
            else
                setDisableButton(true);
        } else
            setDisableButton(false);
    }

    return (
        <div>
            <ValidatorForm
                ref={formRef}
                onSubmit={handleSubmit}
                onError={errors => console.log(errors)}
            >
                <div className="row justify-content-md-center">
                    <div className="col-md-4 mt-2">
                        {
                            props?.rangeDates?.length > 0
                                ? <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker disablePast minDate={rangeDates.start} maxDate={rangeDates.end} label={t('general.date-start')} value={props.service.serviceDate} onChange={handleChangeDate} renderInput={(params) => <TextValidator style={{ width: '100%' }} size="small" {...params} />} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.date-start'))]} />
                                </LocalizationProvider>
                                :
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker disablePast label={t('general.date-start')} value={props.service.serviceDate} onChange={handleChangeDate} renderInput={(params) => <TextValidator style={{ width: '100%' }} size="small" {...params} />} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.date-start'))]} />
                                </LocalizationProvider>
                        }
                    </div>
                </div>
                {props.commentaries?.map((item, index) => (
                    <div key={index} className="row mt-3 justify-content-md-center">
                        <div className="col-md-8">
                            <h3>{item?.description}</h3>
                        </div>
                    </div>
                ))}
                {props.terms?.map((item, index) => (
                    <div key={index} className="row mt-3 justify-content-md-center">
                        <div className="col-7 col-md-6">
                            <TextField fullWidth size="small" className="text-terms" multiline rows={3} defaultValue={item.description} label={t('general.terms')} />
                        </div>
                        <div className="col-5 col-md-2">
                            <span className="small-legend">{t('general.no')}</span>  <Switch size="small" required={item.required} onChange={() => handleChangeCheck(item)} />  <span className="small-legend">{t('general.yes')}</span>
                        </div>
                    </div>
                ))}
                <div className="row mt-4">
                    <div className="col-md-12">
                        {props.activeStep > 0 && <Button className='btn-secondary me-2' onClick={handleBackStep}>{t('general.back')}</Button>}
                        <Button className='btn-primary' disabled={disableButton} onClick={handleSubmit}>{t('general.next')}</Button>
                    </div>
                </div>
            </ValidatorForm>
        </div>
    )
}

export default ServiceInfo;