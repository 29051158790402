/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, forwardRef, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { useTranslation } from "react-i18next";
import { eStorage, getStorageJSON } from '../../Services/StorageService';
import { TextValidator } from 'react-material-ui-form-validator';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useRef } from 'react';
import { changePasswordService } from './../../Components/ProfileBase/ProfileBase';
import { Notify } from 'notiflix/build/notiflix-notify-aio';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalChangePassword(props) {
  const { t } = useTranslation();
  const formRef = useRef();
  const [user] = useState(getStorageJSON(eStorage.user));
  const [data, setData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
    if (value !== data.newPassword) {
      return false;
    }
    return true;
  });

  useEffect(() => {
  }, []);

  const handleChange = (event) => {

    const { name, value } = event.target;
    setData(prevState => ({
      ...prevState, [name]: value
    }));
  }

  const handleClose = () => {
    props.setModalOpen(false);
  };

  const handleSubmit = () => {
    const changePasswordParams = {
      email: user.eweMail,
      currentPassword: data.currentPassword,
      password: data.newPassword
    }
    changePasswordService(changePasswordParams)
    .then((response)=>{
      if (response){
        Notify.success(t('profile.passwordChangeSuccess'), { position: 'center-bottom', });
      } else {
        Notify.failure(t('profile.passwordChangeError'), { position: 'center-bottom', });
      }
    }).catch(()=>{
      Notify.failure(t('profile.passwordChangeError'), { position: 'center-bottom', });
    })
    .finally(()=>{
      props.setModalOpen(false);
    });
  }

  return (
    <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'md'} keepMounted onClose={handleClose}>
      <ValidatorForm ref={formRef} onSubmit={handleSubmit} onError={errors => console.log(errors)}>
        <DialogTitle className='text-center'>{t('general.changeYourPassword')}</DialogTitle>
        <hr className='m-0' />
        <DialogContent>
          <div className="row justify-content-md-center">
            <div className="col-md-4"><label>{t("general.currentPassword")}: </label></div>
            <div className="col-md-5"><TextValidator fullWidth size="small" name="currentPassword" type="password" value={data.currentPassword} onChange={handleChange} label={t('general.currentPassword')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.currentPassword'))]} /></div>
          </div>
          <div className="row justify-content-md-center mt-3">
            <div className="col-md-4"><label>{t('profile.newPassword')}: </label></div>
            <div className="col-md-5"><TextValidator fullWidth size="small" name="newPassword" type="password" value={data.newPassword} onChange={handleChange} label={t('profile.newPassword')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('profile.newPassword'))]} /></div>
          </div>
          <div className="row justify-content-md-center mt-3">
            <div className="col-md-4"><label>{t('profile.confirmNewPassword')}: </label></div>
            <div className="col-md-5"><TextValidator fullWidth size="small" name="confirmPassword" type="password" value={data.confirmPassword} label={t('profile.confirmNewPassword')} onChange={handleChange} validators={['isPasswordMatch', 'required']} errorMessages={[t('general.passwordMismatch'), t('general.required').replace('#field#', t('general.newPassword'))]} /></div>
          </div>
        </DialogContent>
        <hr className='mb-0 mt-2' />
        <DialogActions className='justify-content-md-center'>
          <Button className='btn-secondary' onClick={handleClose}>{t('general.cancel')}</Button>
          <Button className='btn-secondary' type='submit'>{t('profile.password')}</Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}