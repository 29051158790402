import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { ePage } from "../../../Components/Shared/Routes";
import { getUsersData } from "../../../Components/UserBase/UserBase";
import { eStorage, getStorageJSON } from "../../../Services/StorageService";

function Users(props) {
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const [company] = useState(getStorageJSON(eStorage.company));

    useEffect(() => {
        props.functions.setTitle(t('general.users'));

        getUsersData(company.id).then(data => {
            setUsers(data);
            console.log(data);
        });
    }, []);

    return (
        <div className='content'>
            <div className='container mb-4'>
                <div className="row">
                    <div className="col-md-12">
                        <Button className='btn-primary' component={Link} to={ePage.addUser}>
                            {t('users.add-user')}
                        </Button>
                    </div>
                </div>
                <div className="row mt-2 justify-content-md-center">
                    <div className='col-md-10'>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell scope="col"><strong>{t('users.name')}</strong></TableCell >
                                        <TableCell scope="col"><strong>{t('users.role')}</strong></TableCell >
                                        <TableCell scope="col"><strong>{t('general.email')}</strong></TableCell >
                                        <TableCell scope="col"><strong>{t('users.status')}</strong></TableCell >
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users?.map((item, index) =>
                                        <TableRow  key={index} >
                                            <TableCell>{item.name} {item.lastname}</TableCell>
                                            <TableCell>{item.role}</TableCell>
                                            <TableCell>{item.email}</TableCell>
                                            <TableCell>{t('profile.active')}</TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </div>
    )

}


export default Users;