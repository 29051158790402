import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import { getCurrentPlan, getRecommendBase, getRecommendSortBy, postReScore } from "../../Components/RecommendBase/RecommendBase";
import PlanCard from "../../Components/Shared/PlanCard";
import { eStorage, getStorage, getStorageJSON } from "../../Services/StorageService";
import { Button, FormControl, Select, InputLabel, Table, TableBody, TableCell, TableContainer, MenuItem, TableRow, Stack, Stepper, Step, StepLabel } from "@mui/material";
import StepConnectorCustom from "../../Components/Shared/StepConnector";
import QontoStepIcon from "../../Components/Shared/Stepper";
import RecommendChart from "./RecommendChart";
import pollutionImage from '../../Assets/Icons/pollution.png';
import treesImage from '../../Assets/Icons/trees.png';
import ModalFilter from "./ModalFilter";
import ModalMatchingCriteria from "./ModalMatchingCriteria";
import { eFilter } from "../../Components/BrowserBase/BrowserBase";
import { useNavigate } from "react-router-dom";
import { ePage } from "../../Components/Shared/Routes";
import './style.css';
import { Link } from 'react-router-dom';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { eDevice } from "../../Components/Shared/Enum/EDevice";

function Recommend(props) {
    const { t } = useTranslation();
    const [language] = useState(getStorage(eStorage.language));
    const [plans, setPlans] = useState([]);
    const [sortBy, setSortBy] = useState([]);
    const [sortBySelected, setSortBySelected] = useState('score');
    const [currentPlan, setCurrentPlan] = useState({});
    const [planSelected, setPlanSelected] = useState({});
    const [planSelectedIndex, setPlanSelectedIndex] = useState(0);
    const [user] = useState(getStorageJSON(eStorage.user));
    const [modalFilterOpen, setModalFilterOpen] = useState(false);
    const [modalMatchingOpen, setModalMatchingOpen] = useState(false);
    const [matchingCriteriaFilter, setMatchingCriteriaFilter] = useState({});
    const [filtersParams, setFiltersParams] = useState('');
    const [savings, setSavings] = useState(0);
    const [currentSlide, setCurrentSlide] = useState(0);
    const navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const steps = [`${t('general.option')} 1`, `${t('general.option')} 2`, `${t('general.option')} 3`];

    useEffect(() => {
        props.functions.setTitle(t('general.recommend'));
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchingCriteriaFilter, filtersParams, sortBySelected]);

    useEffect(() => {
        setSavings(currentPlan.totalCost - planSelected.total_cost - currentPlan.cancellationFee);
    }, [currentPlan, planSelected]);

    const getData = () => {
        props.functions.setIsLoading(true);
        getRecommendBase(sortBySelected, filtersParams).then(data => {
            if (Array.isArray(data)) {
                setPlans(data);
                setPlanSelected(data[planSelectedIndex]);
            } else {
                setPlans([]);
            }
            props.functions.setIsLoading(false);
        }).catch(console.log);

        getCurrentPlan(user.customerId).then(data => {
            setCurrentPlan(data);
        }).catch(console.log);

        getRecommendSortBy().then(data => {
            setSortBy(data);
        }).catch(console.log);
    };

    const handleChangePlan = (plan, index) => {
        setPlanSelected(plan);
        setPlanSelectedIndex(index);
    }

    const handleChangeSort = (event) => {
        setSortBySelected(event.target.value);
    }

    const viewAllPlans = () => {
        navigate(ePage.browse);
    }

    const handleChangeCriteria = (event) => {
        const model = {
            cost: Math.trunc((event.cost / 10) / 2),
            carbon: Math.trunc((event.carbon / 10) / 2),
            rating: Math.trunc((event.rating / 10) / 2)
        };

        postReScore(model)
            .then(() => {
                setMatchingCriteriaFilter(event);
                getData();
            })
            .catch(error => console.log(error));
    }

    function handleChangeFilter(evt) {
        const keys = Object.keys(evt);
        let params = '';
        keys.forEach(key => {
            if (evt[key]) {
                params += `${eFilter[key]}${evt[key]}`;
            }
        });
        setFiltersParams(params);
    }
    const nextClick = (event) => {
        if (currentSlide === plans.length - 1) return;
        const index = currentSlide + 1;
        const item = plans[index];
        handleChangePlan(item, index);
        setCurrentSlide(index);
        setActiveStep(index);
    }

    const prevClick = (event) => {
        if (currentSlide === 0) return;
        const index = currentSlide - 1;
        const item = plans[index];
        handleChangePlan(item, index);
        setCurrentSlide(index);
        setActiveStep(index);
    }
    const handleActiveStep = (index) => {
        setStep(index);
        setActiveStep(index);
        const item = plans[index];
        handleChangePlan(item, index);
        setCurrentSlide(index);
    }

    return (
        <div className="content">
            <ModalFilter open={modalFilterOpen} setModalOpen={setModalFilterOpen} handleChange={handleChangeFilter} />
            <ModalMatchingCriteria open={modalMatchingOpen} functions={props.functions} setModalOpen={setModalMatchingOpen} handleChange={handleChangeCriteria} />
            <div className="row justify-content-md-center">
                <div className="col-md-12">
                    <Button className='btn-secondary me-1 mt-1' component={Link} to="/matching-preferences">{t('general.plan-shopping-preferences')}</Button>
                    {(props.functions.device !== eDevice.mobile && props.functions.device !== eDevice.tablet) &&
                        <FormControl className="col-md-2 me-1" size="small">
                            <InputLabel id="sortby-select">{t('recommend.sortby-select')}</InputLabel>
                            <Select labelId="sortby-select" name="sortBySelected" value={sortBySelected} onChange={handleChangeSort} label={t('recommend.sortby-select')}>
                                {sortBy.map((item, index) => (
                                    <MenuItem key={index} value={item.key}>{item.values[language]}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    }
                    <Button className={(props.functions.device !== eDevice.mobile && props.functions.device !== eDevice.tablet) ? 'btn-secondary mt-1' : 'btn-secondary'} onClick={() => setModalFilterOpen(true)}>{t('general.filters')}</Button>
                </div>
            </div>
            {(props.functions.device !== eDevice.mobile && props.functions.device !== eDevice.tablet) &&
                <h1 className="m-3"><strong>{user.name}</strong>{t('recommend.subtitle')} <span className="click-here-blue" onClick={() => viewAllPlans()}>{t('recommend.see-all-plans')}</span>. </h1>
            }
            {(props.functions.device === eDevice.mobile || props.functions.device === eDevice.tablet) &&
                <h1 className="m-3"><strong>{user.name}</strong>{t('recommend.order-one')} <span className="click-here-blue" onClick={() => viewAllPlans()}>{t('recommend.see-all-plans')}</span>. </h1>
            }
            <div className='row'>
                {(props.functions.device === eDevice.mobile || props.functions.device === eDevice.tablet) &&
                    <>
                        <Stack sx={{ width: '100%' }}>
                            <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnectorCustom />}>
                                {steps.map((label, index) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={QontoStepIcon} onClick={() => handleActiveStep(index)}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </Stack>
                        <CarouselProvider
                            naturalSlideWidth={100}
                            naturalSlideHeight={props.functions.device === eDevice.mobile ? 70 : 35}
                            totalSlides={plans.length}
                            currentSlide={step}
                        >
                            <Slider>
                                {plans.map((item, indx) => (
                                    <Slide key={indx} index={indx}>
                                        <div key={indx} onClick={() => handleChangePlan(item, indx)} className="col-sm-12 col-md-12 mt-2">
                                            <PlanCard functions={props.functions} selected={planSelectedIndex === indx} data={item} recommend={true} />
                                        </div>
                                    </Slide>
                                ))}

                            </Slider>
                            <ButtonBack className='prev' onClick={prevClick}>&#10094;</ButtonBack>
                            <ButtonNext className='next' onClick={nextClick}>&#10095;</ButtonNext>
                            {/* <DotGroup /> */}
                        </CarouselProvider>
                    </>
                }
                {(props.functions.device !== eDevice.mobile && props.functions.device !== eDevice.tablet) &&
                    plans.map((item, index) => (
                        <div key={index} onClick={() => handleChangePlan(item, index)} className="col-md-4 mt-2">
                            <PlanCard functions={props.functions} selected={planSelectedIndex === index} data={item} recommend={true} />
                        </div>
                    ))
                }
            </div>
            <div className='row mt-4'>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <h2>{t('recommend.new-plan-cost')}</h2>
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="cell-small">
                                        <div className="row">
                                            <div className="col-8"><h5 className="m-0 text-capitalize">{t('recommend.average')}:</h5></div>
                                            <div className="col-4"><h5 className="m-0 text-capitalize">${planSelected.averageMonth?.toFixed(2)}</h5></div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className="cell-small">
                                        <div className="row">
                                            <div className="col-8">{t('recommend.annualized-cost')}:</div>
                                            <div className="col-4">${planSelected.annualCost?.toFixed(2)}</div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {savings > 0 && <div>
                        <h2 className="mt-4">{t('recommend.estimates')}</h2>
                        <TableContainer>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className="cell-small">
                                            <div className="row">
                                                <div className="col-8">{t('recommend.annualized-cost')}:</div>
                                                <div className="col-4">${currentPlan.totalCost?.toFixed(2)}</div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="cell-small">
                                            <div className="row">
                                                <div className="col-8">{t('general.cancelation-fee')}:</div>
                                                <div className="col-4">${planSelected.cancellationFee?.amount?.toFixed(2)}</div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className="cell-small">
                                            <div className="row text-center">
                                                <div className="col-12"><h4 className="label-blue text-center">{t('recommend.savings')}: ${(savings).toFixed(2)}</h4></div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    }
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 mt-2 mt-md-4">
                    <h2>{t('recommend.usage')}</h2>
                    {Object.keys(planSelected).length > 0 && <RecommendChart planSelected={planSelected} currentPlan={currentPlan} />}
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 mt-md-4">
                    <h2>{t('recommend.enviromental')}</h2>
                    <p className="small-legend"><strong> {t('recommend.car-miles')}</strong></p>
                    <div className="row justify-content-md-center">
                        <div className="col-6">
                            <h3>{t('recommend.this-plan')}</h3>
                            <div className="row justify-content-md-center">
                                <div className="col-4">
                                    <h5 className="mt-3">{planSelected.carsEquivalent?.toLocaleString('en-US', { maximumFractionDigits: 0 })}</h5>
                                </div>
                                <div className="col-4">
                                    <img className="img-fluid" src={pollutionImage} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <h3>{t('recommend.current-plan')}</h3>
                            <div className="row justify-content-md-center">
                                <div className="col-4">
                                    <h5 className="mt-3">{currentPlan.carsEquivalent?.toLocaleString('en-US', { maximumFractionDigits: 0 })}</h5>
                                </div>
                                <div className="col-4">
                                    <img className="img-fluid" src={pollutionImage} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center">
                        <p className="small-legend"><strong>{t('recommend.trees')}</strong></p>
                        <div className="col-6">
                            <div className="row justify-content-md-center">
                                <div className="col-4">
                                    <h5 className="mt-3">{planSelected.treesEquivalent?.toLocaleString('en-US', { maximumFractionDigits: 0 })}</h5>
                                </div>
                                <div className="col-4">
                                    <img className="img-fluid" src={treesImage} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row justify-content-md-center">
                                <div className="col-4">
                                    <h5 className="mt-3">{currentPlan.treesEquivalent?.toLocaleString('en-US', { maximumFractionDigits: 0 })}</h5>
                                </div>
                                <div className="col-4">
                                    <img className="img-fluid" src={treesImage} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Recommend;