import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from 'react-i18next';
import { Button, TextField, InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router";
import { eStorage, getStorageJSON } from "../../Services/StorageService";
import { postSettings } from "../../Components/RecommendationsBase/RecommendationsBase";
import { ePage } from "../../Components/Shared/Routes";


function SelectRecommendation(props) {

    useEffect(() => {
        props.functions.setTitle(t('sidebar.select-recommendation'));
    }, []);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [change, setChange] = useState('');
    const [invest, setInvest] = useState('');

    useEffect(() => {
        if (props.open) {
            const storageRecommendation = getStorageJSON(eStorage.criteria);
            if (storageRecommendation) {
                setChange(storageRecommendation.pureBehavior);
                setInvest(storageRecommendation.costLevel);
            }
        }
    }, [props.open]);

    const handleChange = (event) => {
        let value = event.target.value;
        if (value === 'pureBehavior')
            setInvest('');
        setChange(value);
    }

    const handleChangeInvest = (event) => {
        let value = event.target.value;
        setInvest(value);
    }

    const handleSubmit = () => {
        props.functions.setIsLoading(true);
        let model = {
            costLevel: change === 'pureBehavior' ? 0 : invest,
            pureBehavior: change
        }

        let user = getStorageJSON(eStorage.user);
        postSettings(model, user.customerId).then(data => {
            if (data) {
                navigate('/win-prize');
                props.functions.setIsLoading(false);
            }
        }).catch(console.log);
    }

    return (
        <div className="content">
            <div className='row justify-content-md-center  mb-1'>
                <div className="col-sm-12 col-md-10 col-lg-5">
                    <div className="container-card card-content h-100">
                        <div className='row mt-2 justify-content-md-center'>
                            <div className='col-md-10'>
                                <FormControl size='small' fullWidth>
                                    <InputLabel id="label-change">{t('recommendations.what-would')}</InputLabel>
                                    <Select label={t('recommendations.what-would')} labelId="label-change" value={change} onChange={handleChange} >
                                        <MenuItem value={'pureBehavior'}>{t('recommendations.pure-behavior')}</MenuItem>
                                        <MenuItem value={'invest'}>{t('recommendations.invest')}</MenuItem>
                                        <MenuItem value={'all'}>{t('recommendations.all')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        {(change === 'invest' || change === 'all') &&
                            <div className='row mt-3 justify-content-md-center'>
                                <div className='col-md-10'>
                                    <FormControl size='small' fullWidth>
                                        <InputLabel id="label-invest">{t('recommendations.how-much')}</InputLabel>
                                        <Select label={t('recommendations.how-much')} labelId="label-invest" value={invest} onChange={handleChangeInvest} >
                                            <MenuItem value={'1'}>{t('recommendations.low-invest')}</MenuItem>
                                            <MenuItem value={'2'}>{t('recommendations.medium-invest')}</MenuItem>
                                            <MenuItem value={'4'}>{t('recommendations.high-invest')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        }
                        <div className='row mt-4'>
                            <div className='col-12'>
                                <div className='justify-content-md-center'>
                                    <Button className='btn-primary' disabled={change === '' || ((change === 'invest' || change === 'all') && invest === '')} onClick={handleSubmit}>{t('general.see-recommendations')}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectRecommendation;