/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { getBadgesCustomerData, getBadgesData } from "../../Components/BadgesBase/BadgesBase";
import { eStorage, getStorageJSON } from "../../Services/StorageService";
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import ModalBadge from "../../Components/Shared/ModalBadges";

function Badges(props) {
    const { t } = useTranslation();
    const [badges, setBadges] = useState([]);
    const [allBadges, setAllBadges] = useState([]);
    const [user] = useState(getStorageJSON(eStorage.user));
    const [modal, setModal] = useState({ open: false });

    useEffect(() => {
        props.functions.setTitle(t('general.badges'));
        props.functions.setIsLoading(true);

        getBadgesData()
            .then(data => {
                setAllBadges(data);
            }).catch(console.log)
            .finally(() => props.functions.setIsLoading(false));

        getBadgesCustomerData(user.customerId).then(data => {
            setBadges(data);
        });
    }, []);

    const hasBadge = (badge) => {
        for (var i = 0; i < badges?.length; i++) {
            if (badge.badge == badges[i].badge) {
                return true;
            }
        }
        return false;
    }

    const viewDetail = (badge, isObtained) => {
        setModal({ open: true, badge: badge, isObtained: isObtained });
    }

    return (
        <div className='content'>
            <ModalBadge modal={modal} setModal={setModal} />
            <div className='container mb-4'>
                <h1>{t('achievements.achievements-are')}</h1>
                <div className="row mb-4">
                    <div className="col-md-12">
                        <Button className='btn-primary' component={Link} to="/win-prize">BACK TO WIN PRIZE</Button>
                    </div>
                </div>
                <div className="row">
                    {allBadges.filter(f => (f.type == 'general' || f.type == 'General') && f.badge != 'Platinum' && f.badge != 'Bronze' && f.badge != 'Gold' && f.badge != 'Silver').map((item, index) =>
                        <div key={index} className="col-6 col-md-2 mt-3 object-select" onClick={() => viewDetail(item, hasBadge(item))}>
                            <img alt="" className={hasBadge(item) ? "icon-savings" : "icon-savings badge-gray"} src={item.icon} />
                            <h3 className="m-0">{item.badge?.replace('-', ' ').replace('_', ' ')}</h3>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Badges;