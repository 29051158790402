import { eStorage, getStorage, getStorageJSON, saveStorageJSON } from "../../Services/StorageService";
import { dataServiceApiInstance } from "../../Util/Api";

export const postSettings = (model, customerId) => {
    const response = dataServiceApiInstance.post(`/actionplan/criteria?customerId=${customerId}`, model)
        .then(({ data }) => {
            if (data) {
                saveStorageJSON(eStorage.criteria, model);
                saveStorageJSON(eStorage.recommendations, null);
            }
            return data;
        });
    return response;
}

export const getRecommendationsData = (customerId, maxRecommendations) => {
    let language = getStorage(eStorage.language);
    let requestId = getStorage(eStorage.requestId);
    let criteria = getStorageJSON(eStorage.criteria);

    if (criteria == null)
        criteria = {
            pureBehavior: 'pureBehavior',
            costLevel: 0
        }
    const response = dataServiceApiInstance.get(`/actionplan/recommendations?customerId=${customerId}&lang=${language}&requestId=${requestId}&maxRecommendations=${maxRecommendations}&pureBehavior=${criteria.pureBehavior}&costLevel=${criteria.costLevel}`)
        .then(({ data }) => {
            if (data.status === 'SUCCESS')
                return data.results;
            else
                return [];
        });
    return response;
}

export const postRecommendations = (customerId, recommendationId, status, maxRecommendations) => {
    let language = getStorage(eStorage.language);
    let requestId = getStorage(eStorage.requestId);
    const response = dataServiceApiInstance.post(`/actionplan/recommendations?customerId=${customerId}&lang=${language}&requestId=${requestId}&recommendationId=${recommendationId}&status=${status}&maxRecommendations=${maxRecommendations}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}