import { eStorage, getStorage } from "../../Services/StorageService";
import { dataServiceApiInstance } from "../../Util/Api";


export const getEstimatedUseData = () => {
    const response = dataServiceApiInstance.get(`domain/Estimated_use`)
        .then(({ data }) => {
            return data.valueList;
        });
    return response;
}


export const getREPsData = () => {
    const response = dataServiceApiInstance.get(`reps?status=enabled`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getMinRatingData = () => {
    const response = dataServiceApiInstance.get(`domain/minimum_rating`)
        .then(({ data }) => {
            return data.valueList;
        });
    return response;
}

export const getContractData = () => {
    const response = dataServiceApiInstance.get(`domain/contract_duration`)
        .then(({ data }) => {
            return data.valueList;
        });
    return response;
}

export const getElectricPlansData = () => {
    const response = dataServiceApiInstance.get(`domain/electric_plans`)
        .then(({ data }) => {
            return data.valueList;
        });
    return response;
}

export const getRenewableData = () => {
    const response = dataServiceApiInstance.get(`domain/renewable_content`)
        .then(({ data }) => {
            return data.valueList;
        });
    return response;
}


export const getServiceArea = (zipCode) => {
    const response = dataServiceApiInstance.get(`/serviceArea/allByzipcode/${zipCode}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getPlansData = async (serviceArea, pageSize = 10, page = 1, queryParams = '') => {
    const language = getStorage(eStorage.language);
    const path = `/browseplans/paging?sauid=${serviceArea}&page-size=${pageSize}&lang=${language}&sort=ASC&page=${page}${queryParams}`;
    try {
        const response = await dataServiceApiInstance.get(path);
        return response.data;
    } catch (error) {
        console.log(error);
        Promise.reject(error);
    }
}

export const getOrderData = () => {
    const response = dataServiceApiInstance.get(`domain/order_values`)
        .then(({ data }) => {
            return data.valueList;
        });
    return response;
}

export const eFilter = {
    use: '&avg-kw=AVG',
    rep: '&rep=',
    rating: '&rep-rating=',
    contract: '&term=',
    min: '&price-min=',
    max: '&price-max=',
    type: '&type=',
    renewable: '&renewable=',
    order: '&order=',
    quick: '&quick='
}

export const eQuickFilter = {
    all: 'all',
    long: 'long',
    green: 'green',
    noContract: 'noContract',
    poorCredit: 'poorCredit'
}