
import { useTranslation } from 'react-i18next';
import { Dialog, Slide, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { forwardRef, useEffect } from 'react';
import ShareButtons from '../../Components/Shared/ShareButtons';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalCongratulations(props) {
    const { t } = useTranslation();
    const handleClose = () => {
        props.setModalOpen(false);
    };

    useEffect(() => {
        console.log(props);
    }, []);

    return (
        <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'md'} keepMounted onClose={handleClose}>
            <DialogTitle className='text-center'>CONGRATULATIONS!</DialogTitle>
            <hr className='m-0' />
            <DialogContent>
                <div className="row justify-content-center mt-2">
                    <div className="col-md-10 text-center">
                        <h3>You have done a great job, you have managed to complete the challenge: <strong>{props.challenge?.name}</strong> </h3>
                        <h3>We are validating your challenge, you are about to obtain:</h3>
                        <p className="large-legend">{props.challenge?.pointsEarned}</p>
                        <h3>Points</h3>
                    </div>
                </div>
                <div className="row justify-content-center mt-2">
                    <div className='col-md-10 text-center'>
                        <ShareButtons type='badge' name={'LED'} description={`I have completed the challenge: ${props.challenge?.name} Join the Enerwisely challenge.`} />
                    </div>
                </div>
            </DialogContent>
            <hr className='mb-0 mt-2' />
            <DialogActions className='justify-content-md-center'>
                <Button className='btn-secondary' onClick={handleClose}>{t('profile.close')}</Button>
            </DialogActions>
        </Dialog>
    );
}