import { eAction, eCategory, eEvent, submitTrackingEvent } from "../../Services/EventService";
import { eStorage, getStorage, getStorageJSON, saveStorageJSON } from "../../Services/StorageService";
import { calcEngineApiInstance, dataServiceApiInstance } from "../../Util/Api";
import { eAccountType } from "../Shared/Enum/EAccountType";

export const getCarbonEqData = (customerId) => {
    let language = getStorage(eStorage.language);
    const response = dataServiceApiInstance.get(`/fingerprint/carbon-footprint-eq/${customerId}?lang=${language}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getCarbonData = (customerId) => {
    let language = getStorage(eStorage.language);
    const response = dataServiceApiInstance.get(`/fingerprint/carbon-footprint/${customerId}?lang=${language}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getCarbonTexasData = () => {
    const response = dataServiceApiInstance.get(`plan/txAverageCarbonFootprint`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getFingerprintData = (customerId) => {
    let language = getStorage(eStorage.language);
    const response = dataServiceApiInstance.get(`/fingerprint/${customerId}?lang=${language}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getProfileData = (customerId) => {
    const response = dataServiceApiInstance.get(`/customer/profile/${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const postSmartFingerprint = (model) => {
    let accountType = getStorage(eStorage.accountType);
    let lang = getStorage(eStorage.language);

    let id = '';
    let entity = '';
    let type = '';
    let url = '';

    if (accountType === eAccountType.commercial) {
        entity = getStorageJSON(eStorage.location);
        id = entity.id;
        type = entity.type ? entity.type.split('::')[0] : 'BASIC';
        url = 'V2/';
    } else {
        entity = getStorageJSON(eStorage.user);
        id = entity.customerId;
        type = entity.type ? entity.type.split('::')[0] : 'BASIC';
        url = '';
    }

    let path = type === 'BASIC' ? `${url}preferences/${id}?type=smart` : `${url}smartFingerprint?lang=${lang}`;
    let axios = type === 'BASIC' ? dataServiceApiInstance : calcEngineApiInstance;

    const response = axios.post(path, model)
        .then(({ data }) => {
            let fingerprint = getStorageJSON(eStorage.fingerprint);
            if (fingerprint?.level === 'smart' || fingerprint?.level === 'genius') {
                fingerprint.simpleSchedule = model.simpleSchedule;
                fingerprint.selectedValues = model.selectedValues;
                saveStorageJSON(eStorage.fingerprint, fingerprint);
                submitTrackingEvent(eEvent.impactCompleted, eCategory.fingerprint, eAction.created);
                return fingerprint;
            } else {
                model.level = 'smart';
                saveStorageJSON(eStorage.fingerprint, model);
                return model;
            }
        });
    return response;
}