/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  XIcon,
  WhatsappIcon,
  EmailIcon,
} from 'react-share';
import { eStorage, getStorageJSON } from "../../Services/StorageService";
import { getParams } from "../../Util/Util";
import { getProfile } from "../AuthBase/AuthBase";
import { ePlatform } from "./Enum/EPlatform";

async function getUrFromService(referralCode, type, name) {
  await new Promise((resolve) => setTimeout(resolve, 1000));
  if (type == 'badge')
    return `${getParams().EMM_REDIRECT_BADGE_URL}${name}.html?referralCode=${referralCode}`;
  else
    return `${getParams().EMM_REDIRECT_URL}'electric-info?referralCode=${referralCode}`;
}

function ShareButtons(props) {
  const [url, setUrl] = useState('none');
  const [platform, setPlatform] = useState('');
  const [user] = useState(getStorageJSON(eStorage.user));
  const [profile, setProfile] = useState({});
  const [message, setMessage] = useState('');
  const { t } = useTranslation();
  const shareButtonFacebook = useRef();
  const shareButtonTwitter = useRef();
  const shareButtonWhatsapp = useRef();
  const shareButtonEmail = useRef();

  useEffect(() => {

    if (url !== 'none' && platform != '') {
      switch (platform) {
        case ePlatform.facebook:
          shareButtonFacebook.current?.click();
          break;
        case ePlatform.twitter:
          shareButtonTwitter.current?.click();
          break;
        case ePlatform.email:
          shareButtonEmail.current?.click();
          break;
        case ePlatform.whatsapp:
          shareButtonWhatsapp.current?.click();
          break;
      }

      setUrl('none');
      setPlatform('');
    }
  }, [url, shareButtonFacebook, shareButtonTwitter, shareButtonWhatsapp, shareButtonEmail]);

  useEffect(() => {
    getProfile(user.customerId).then(data => {
      setProfile(data);
      if (props.type === 'badge')
        setMessage(`${props.description} ${t('general.share-text-badge')}`);
      else
        setMessage(t('general.share-text'));
    });
  }, [props.badge]);

  const handleClick = async (platform) => {
    // Be sure to check for the "none" state, so we don't trigger an infinite loop.
    if (url === 'none') {
      const newUrl = await getUrFromService(profile?.currentVoucher, props.type, props?.name);
      setPlatform(platform);
      setUrl(newUrl);
    }
  };

  return (
    <div className="btns-shared">
      <FacebookShareButton onClick={() => handleClick(ePlatform.facebook)} hashtag={"#Enerwisely"} openShareDialogOnClick={url !== 'none'} ref={shareButtonFacebook} url={url} quote={message} via="EnerWisely"><FacebookIcon size={32} round={true} /></FacebookShareButton>
      <TwitterShareButton onClick={() => handleClick(ePlatform.twitter)} hashtag={"#Enerwisely"} openShareDialogOnClick={url !== 'none'} ref={shareButtonTwitter} url={url} via="EnerWisely" title={message}><XIcon size={32} round={true} /></TwitterShareButton>
      <EmailShareButton onClick={() => handleClick(ePlatform.email)} hashtag={"#Enerwisely"} openShareDialogOnClick={url !== 'none'} ref={shareButtonEmail} url={url} subject={t('general.share-title')} body={message} via="EnerWisely"><EmailIcon size={32} round={true} /></EmailShareButton>
      <WhatsappShareButton onClick={() => handleClick(ePlatform.whatsapp)} hashtag={"#Enerwisely"} openShareDialogOnClick={url !== 'none'} ref={shareButtonWhatsapp} url={url} via="EnerWisely" title={message}><WhatsappIcon size={32} round={true} /></WhatsappShareButton>
    </div>
  )
}

export default ShareButtons;