import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { clearStorage, eStorage } from './../../Services/StorageService';

const Logout = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    clearStorage();
    props.functions.setIsSession(false);
    props.functions.setUser({});
    navigate("/login");
  }, [navigate]);

  return null;
};

export default Logout;