

import { dataServiceApiInstance } from "../../Util/Api";

export const getProductsData = () => {
    const response = dataServiceApiInstance.get(`product/`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getProductData = (productId) => {
    const response = dataServiceApiInstance.get(`product/${productId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}


export const postOrder = (model) => {

    const response = dataServiceApiInstance.post(`order/`, model)
    .then(({ data }) => {
        return data;
    });
return response;
  }