/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, forwardRef, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTranslation } from "react-i18next";
import { getLogsData } from '../../Components/ProfileBase/ProfileBase';
import { eStorage, getStorageJSON } from '../../Services/StorageService';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalActivity(props) {
    const { t } = useTranslation();
    const [activity, setActivity] = useState([]);
    const [user] = useState(getStorageJSON(eStorage.user));

    useEffect(() => {
        getLogsData(user.customerId).then(data => {
            setActivity(data);
        });
    }, []);

    const handleClose = () => {
        props.setModalOpen(false);
    };

    return (
        <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'md'} keepMounted onClose={handleClose}>
            <DialogTitle className='text-center'>{t('modal.activity-title')}</DialogTitle>
            <hr className='m-0' />
            <DialogContent>
                <div className='row mt-2 justify-content-md-center'>
                    <div className='col-md-10'>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell scope="col">{t('profile.detail')}</TableCell >
                                        <TableCell scope="col">{t('profile.source')}</TableCell >
                                        <TableCell scope="col">{t('profile.target')}</TableCell >
                                        <TableCell scope="col">{t('profile.timestamp')}</TableCell >
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {activity?.map((item, index) => (
                                        <TableRow key={index}>
                                            <TableCell >{item.details}</TableCell >
                                            <TableCell >{item.source}</TableCell >
                                            <TableCell >{item.target}</TableCell >
                                            <TableCell >{item.ts}</TableCell >
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </DialogContent>
            <hr className='mb-0 mt-2' />
            <DialogActions className='justify-content-md-center'>
                <Button className='btn-secondary' onClick={handleClose}>{t('general.cancel')}</Button>
            </DialogActions>
        </Dialog>
    );
}
