/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import { eStorage, getStorageJSON } from "../../../Services/StorageService";
import Header from "../Header";
import AddressInfo from "../AddressInfo";
import UserInfo from "../UserInfo";
import OrderInfo from "../OrderInfo";
import { Stack, Step, StepLabel, Stepper } from "@mui/material";
import QontoStepIcon from "../../../Components/Shared/Stepper";
import StepConnectorCustom from "../../../Components/Shared/StepConnector";
import { getRep, replaceValues } from "../../../Components/IntegrationBase/IntegrationBase";
import ResponseInfo from "../ResponseInfo";
import ServiceInfo from "./ServiceInfo";
import Payment from "./Payment";
import { getDistributionPoints } from "../../../Components/IntegrationBase/PaylessBase";

function Payless(props) {
    const { t } = useTranslation();
    const [planSelected] = useState(getStorageJSON(eStorage.planSelected));
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Address Info', 'User Info', 'Service Info', 'Payment Info', 'Order Confirm'];
    const [address, setAddress] = useState(getStorageJSON(eStorage.address));
    const [service, setService] = useState({
        requestType: 'switching',
        alert: 'emailText'
    });
    const [user, setUser] = useState(getStorageJSON(eStorage.user));
    const [rep, setRep] = useState({});
    const [response, setResponse] = useState({});
    const [payment, setPayment] = useState({
        cardNumber: '',
        expiry: '',
        cvc: '',
        cardName: ''
    });

    const agreements = [
        { description: t('payless.agreement-user-one'), required: true, name: 'userOne' },
        { description: t('payless.agreement-user-two'), required: true, name: 'userTwo' }
    ];

    useEffect(() => {
        props.functions.setTitle(t('payless.title'));
        if (!user.hasOwnProperty('birthdate')) {
            let date = new Date();
            date.setFullYear(date.getFullYear() - 18);
            setUser(prevState => ({
                ...prevState, ['birthdate']: date
            }));
        }

        if (!service.hasOwnProperty('serviceDate')) {
            let date = new Date();
            date.setDate(date.getDate() + 1);
            setService(prevState => ({
                ...prevState, ['serviceDate']: date
            }));
        }

        if (!user.hasOwnProperty('ssn'))
            setUser(prevState => ({
                ...prevState, ['ssn']: ''
            }));

        getRep(planSelected.rep).then(data => {
            setRep(data);
        });

    }, []);

    useEffect(() => {
        if (activeStep === 1) {
            getDistributionPoints().then(data => {
                console.log(data);
            });
        }
    }, [activeStep]);


    const showResponse = (data) => {
        props.functions.setIsLoading(false);
        switch (data.status) {
            case 0:
                setResponse({
                    title: t('pulse.order-success-title'),
                    description: replaceValues(t('pulse.order-success-description'), { voucher: data.voucher }),
                    buttonOne: t('general.help-better'),
                    buttonOneUrl: '/feedback',
                    buttonTwoUrl: '',
                    buttonTwo: ''
                });
                break;
            default:
                setResponse({
                    title: t('general.error-order-title'),
                    description: t('general.error-order-description'),
                    buttonOne: t('general.select-other-plan'),
                    buttonOneUrl: '/browser',
                    buttonTwoUrl: '',
                    buttonTwo: ''
                });
                break;
        }
    }

    const handleSubmit = () => {

    }

    return (
        <div className='content'>
            <div className='container'>
                <div className='row justify-content-md-center'>
                    <div className='col-sm-12 col-md-12 col-lg-10'>
                        <Header functions={props.functions} planSelected={planSelected} />
                    </div>
                </div>
                <div className='row mt-3 justify-content-md-center'>
                    <div className='col-sm-12 col-md-12 col-lg-10'>
                        <div className="container-card card-content">
                            {Object.keys(response).length === 0 &&
                                <div>
                                    {activeStep === 0 && <AddressInfo functions={props.functions} address={address} setAddress={setAddress} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                    {activeStep === 1 && <UserInfo user={user} setUser={setUser} agreements={agreements} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                    {activeStep === 2 && <ServiceInfo service={service} setService={setService} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                    {activeStep === 3 && <Payment setPayment={setPayment} payment={payment} service={service} setService={setService} activeStep={activeStep} setActiveStep={setActiveStep} planSelected={planSelected} />}
                                    {activeStep === 4 && <OrderInfo handleSubmit={handleSubmit} address={address} setAddress={setAddress} activeStep={activeStep} setActiveStep={setActiveStep} user={user} setUser={setUser} service={service} setService={setService} />}
                                </div>
                            }
                            {Object.keys(response).length > 0 &&
                                <ResponseInfo response={response} />
                            }
                        </div>
                    </div>
                </div>
                <Stack className="mt-4 mb-4" sx={{ width: '100%' }}>
                    <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnectorCustom />}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Stack>
            </div>
        </div>
    )
}

export default Payless;