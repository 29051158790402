/* eslint-disable react-hooks/exhaustive-deps */

import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide, FormControl, InputLabel, Select } from '@mui/material';
import { forwardRef, useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalChangeAddress(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {

  }, []);

  const handleContinue = () => {
    props.setModalOpen(false);

  }

  const addAddress = () => {
    props.setModalOpen(false);
    navigate('/profile?tab=address');
  }

  return (
    <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'sm'} keepMounted onClose={handleContinue}>
      <DialogTitle className='text-center'>{t('modal.select-address')}</DialogTitle>
      <hr className='m-0' />
      <DialogContent>
        <div className='row mt-2 justify-content-md-center'>
          <div className='col-md-10'>
            <h3>{t('modal.select-address-description')}</h3>
            <div className="row mt-3 justify-content-md-center">
              <div className="col-md-10">
                <FormControl size="small" fullWidth>
                  <InputLabel id="address">{t('general.address')}</InputLabel>
                  {/* <Select labelId="address" required name="houseType" label={t('general.address')}>

                  </Select> */}
                </FormControl>
              </div>
            </div>
            <h3 className="mt-3 text-center"  onClick={() => addAddress()}><strong className="click-here">{t('profile.add-address')}</strong></h3>
          </div>
        </div>
      </DialogContent>
      <hr className='mb-0 mt-2' />
      <DialogActions className='justify-content-md-center'>
        <Button className='btn-secondary me-1' onClick={handleContinue}>{t('general.close')}</Button>
        <Button className='btn-primary' onClick={handleContinue}>{t('general.continue')}</Button>
      </DialogActions>
    </Dialog>
  );
}