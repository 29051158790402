/* eslint-disable no-script-url */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Image } from "semantic-ui-react";
import { getProductsData } from "../../Components/ShopBase/ShopBase";
import errorImage from "../../Assets/no-image.jpg";
import { Button, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import { getParams } from "../../Util/Util";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import StarRatings from "react-star-ratings";

function Shop(props) {
    const { t } = useTranslation();
    const [products, setProducts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        props.functions.setIsLoading(true);
        getProductsData().then(data => {
            setProducts(data);
            props.functions.setIsLoading(false);
        }).catch(console.log);
    }

    const onChangeSearch = () => {

    }

    const handleViewRecommend = (id) => {
        navigate('/product-detail/' + id);
    }

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            paritialVisibilityGutter: 60
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            paritialVisibilityGutter: 50
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            paritialVisibilityGutter: 30
        }
    };

    return (
        <div className="content">
            <div className='container-fluid'>
                <div className="row mt-3 justify-content-md-center">
                    <h2 className="text-center"> Recommended Product</h2>
                    <div className="col-md-6">
                        <Carousel
                            ssr
                            itemClass="image-item"
                            autoPlay={true}
                            autoPlaySpeed={2000}
                            responsive={responsive}
                        >
                            {products.slice(0, 5).map((product, index) => {
                                console.log(product.id);
                                return (
                                    <div key={index} className="container-card card-content h-100 me-1" onClick={() => handleViewRecommend(product.id)} >
                                        {/* <div className="card-title">
                                            Recommended Product
                                        </div> */}
                                        <div className="card-content">
                                            <Image
                                                draggable={false}
                                                style={{ width: "100%", height: "100%" }}
                                                src={`${getParams().DATA_SERVICE_BASEURL}product/image/${product?.picture}`}
                                                onError={(e) => { e.target.src = errorImage }}
                                            />
                                            <div className="row mt-2">
                                                <div className="col-md-12">
                                                    <div className="product-description-text">
                                                        {product.name}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <StarRatings
                                                        rating={4}
                                                        starDimension='20px'
                                                        starSpacing='0px'
                                                        starRatedColor="#00ACE9"
                                                        //  changeRating={this.changeRating}
                                                        numberOfStars={5}
                                                        name='rating'
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h2 className="text-center mt-2 price">
                                                        $ {product.cost?.toFixed(2)}
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Button className='btn-primary mt-2 mt-md-0' component={Link} to={'/product-detail/' + product.id}>
                                                        Order Now
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Carousel>
                    </div>
                </div>
                <div className="row justify-content-md-center mt-3">
                    <div className="col-md-6">
                        <FormControl fullWidth className="search-product">
                            <InputLabel htmlFor="outlined-adornment-password">Search product</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={'text'}
                                onChange={onChangeSearch}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Search ..."
                            />
                        </FormControl>
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-6">
                        <Button className='btn-tertiary-s'>Categories <ArrowDropDownIcon /></Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Shop;