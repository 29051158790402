/* eslint-disable react-hooks/exhaustive-deps */

import { useTranslation } from 'react-i18next';
import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { forwardRef, useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalMoveOut(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {

  }, []);

  const handleContinue = () => {
    props.setModalOpen(false);
    navigate('/profile?tab=address');
  }

  return (
    <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'sm'} keepMounted onClose={handleContinue}>
      <DialogTitle className='text-center'>{t('agreement.move-out')}</DialogTitle>
      <hr className='m-0' />
      <DialogContent>
        <div className='row mt-2 justify-content-md-center'>
          <div className='col-md-10'>
            <h3>{t('agreement.move-out-description')}</h3>
          </div>
        </div>
      </DialogContent>
      <hr className='mb-0 mt-2' />
      <DialogActions className='justify-content-md-center'>
        <Button className='btn-primary' onClick={handleContinue}>{t('general.continue')}</Button>
      </DialogActions>
    </Dialog>
  );
}