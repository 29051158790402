// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-calendar{
  width: 100%;
  min-width: 100%;
  border: none;
}

.close-modal{
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/Structure/Commercial/Dashboard/calendar.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".react-calendar{\n  width: 100%;\n  min-width: 100%;\n  border: none;\n}\n\n.close-modal{\n  text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
