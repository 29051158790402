
import { dataServiceApiInstance } from "../../Util/Api";

export const getChallengesActiveData = () => {
    const response = dataServiceApiInstance.get(`challenge/All`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getChallengesSuscribedData = (customerId) => {
    const response = dataServiceApiInstance.get(`challenge/suscribed/${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getChallengeData = (id) => {
    const response = dataServiceApiInstance.get(`challenge/${id}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const postCommentSuscribe = (model) => {
    const response = dataServiceApiInstance.post(`challenge/suscribe/`, model)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const postComplete = (model) => {
    const response = dataServiceApiInstance.post(`challenge/complete/`, model)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getCustomerChallengeCustom = (id) => {
    return dataServiceApiInstance.get(`challenge/customerChallengeCustom/${id}`)
        .then((response) => {
            return (response) ? response.data : [];
        });
}