/* eslint-disable react/jsx-no-target-blank */
import { React, useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Button, Switch } from '@mui/material';
import ModalActivity from './ModalActivity';
import { getStorageJSON } from '../../Services/StorageService';
import { eStorage } from './../../Services/StorageService';
import { updatePublicData } from '../../Components/ProfileBase/ProfileBase';
import { saveStorageJSON } from './../../Services/StorageService';
import { updateDataSharing } from './../../Components/ProfileBase/ProfileBase';

function Privacy(props) {
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const [address, setAddress] = useState(getStorageJSON(eStorage.address));
    const [user] = useState(getStorageJSON(eStorage.user));

    useEffect(() => {

    }, []);

    const handleChangePublicData = (event) => {
        const { name, checked } = event.target;
        setAddress(prevState => ({
            ...prevState, [name]: checked
        }));
        const model = {
            customerId: user.customerId,
            publicDataConsent: checked
        }
        updatePublicData(model)
            .then(() => {
                const data = address;
                data.public_data_consent = checked;
                saveStorageJSON(eStorage.address, data);
            })

    }

    const handleChangeThirdData = (event) => {
        const { name, checked } = event.target;
        setAddress(prevState => ({
            ...prevState, [name]: checked
        }));
        const model = {
            customerId: user.customerId,
            dataSharingConsent: checked
        }
        updateDataSharing(model)
            .then(() => {
                const data = address;
                data.data_sharing_consent = checked;
                saveStorageJSON(eStorage.address, data);
            })
    }

    return (
        <div>
            <ModalActivity open={modalOpen} setModalOpen={setModalOpen} />
            <div className="row justify-content-md-center">
                <div className="col-md-10">
                    <h2>{t('profile.how-energy')}</h2>
                </div>
            </div>
            <hr />
            <div className="row justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-start'><strong>{t('profile.view-data')}</strong></h3>
                    <h3 className='text-start'>{t('profile.reviw-data')}</h3>
                </div>
                <div className="col-md-5">
                    <Button className='btn-primary' onClick={() => setModalOpen(true)}>
                        {t('profile.view')}
                    </Button>
                </div>
            </div>
            <hr />
            <div className="row justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-start'><strong>{t('profile.download-data')}</strong></h3>
                    <h3 className='text-start'>{t('profile.your-data')}</h3>
                </div>
                <div className="col-md-5">
                    <a className='a-none' href='https://enerwisely.com/frequently-asked-questions' target='_blank'>
                        <Button className='btn-primary' >
                            {t('profile.go-faq')}
                        </Button>
                    </a>
                </div>
            </div>
            <hr />

            {address && <>
                <div className="row justify-content-md-center">
                    <div className="col-md-5">
                        <h3 className='text-start'><strong>{t('profile.public')}</strong></h3>
                        <h3 className='text-start'>{t('profile.public-data')}</h3>
                    </div>
                    <div className="col-md-5">
                        <Switch checked={address.public_data_consent} name="public_data_consent" onChange={handleChangePublicData} />
                    </div>
                </div>
                <hr />
                <div className="row justify-content-md-center">
                    <div className="col-md-5">
                        <h3 className='text-start'><strong>{t('profile.third-request')}</strong></h3>
                        <h3 className='text-start'>{t('profile.third-data')}</h3>
                    </div>
                    <div className="col-md-5">
                        <Switch checked={address.data_sharing_consent} name="data_sharing_consent" onChange={handleChangeThirdData} />
                    </div>
                </div>
            </>}
        </div>
    )
}

export default Privacy;