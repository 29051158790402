/* eslint-disable react-hooks/exhaustive-deps */
import { React } from 'react';
import { useTranslation } from "react-i18next";
import increasingImage from "../../Assets/Icons/increasing.png";
import reducingImage from "../../Assets/Icons/reducing.png";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { green, red } from '@mui/material/colors';

function Factors(props) {
    const { t } = useTranslation();

    return (
        <div className='row justify-content-md-center h-100'>
            <div className='col-sm-12 col-md-6 col-lg-6 mt-2 mt-md-0'>
                <div className="container-card card-content h-100">
                    <img alt="" className="icon-affect" src={reducingImage} />
                    <h2 className="mt-2 mb-3">{t('energy-score.factors-reducing')}</h2>
                    {Object.keys(props.factors).length > 0 && props.factors.reducing.map((item, index) =>
                        <h3 className="text-start ms-4 md-4 factors" key={index}><CheckCircleOutlineIcon sx={{ color: green[500], fontSize: 10 }} /> {item}</h3>
                    )}
                </div>
            </div>
            <div className='col-sm-12 col-md-6 col-lg-6 mt-2 mt-md-0'>
                <div className="container-card card-content h-100">
                    <img alt="" className="icon-affect" src={increasingImage} />
                    <h2 className="mt-2 mb-3">{t('energy-score.factors-increasing')}</h2>
                    {Object.keys(props.factors).length > 0 && props.factors.increasing.map((item, index) =>
                        <h3 className="text-start ms-4 md-4 factors" key={index}><RemoveCircleOutlineIcon sx={{ color: red[500], fontSize: 10 }} /> {item}</h3>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Factors;