import { React, forwardRef, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from "react-i18next";
import { Slide } from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalConfirm(props) {
    const { t } = useTranslation();

    useEffect(() => {
    }, []);

    const handleClose = () => {
        props.setModal(false);
    };

    const handleConfirm = () => {
        console.log(props.handleConfirm);
        props.handleConfirm();
    };

    return (
        <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'sm'} keepMounted onClose={handleClose}>
            <DialogTitle className='text-center'>{t('general.action-confirm')}</DialogTitle>
            <hr className='m-0' />
            <DialogContent>
                <div className='row mt-2 justify-content-md-center'>
                    <div className='col-md-10 text-center'>
                        <h3>{props.text}</h3>
                    </div>
                </div>
            </DialogContent>
            <hr className='mb-0 mt-2' />
            <DialogActions className='justify-content-md-center'>
                <Button className='btn-secondary' onClick={handleClose}>{t('general.close')}</Button>
                <Button className='btn-primary' onClick={handleConfirm}>{t('general.confirm')}</Button>
            </DialogActions>
        </Dialog>
    );
}