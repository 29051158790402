import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { MenuItem, Select, InputLabel, FormControl, TextField, Button, Tooltip } from '@mui/material';
import { getContractData, getElectricPlansData, getEstimatedUseData, getMinRatingData, getOrderData, getRenewableData, getREPsData } from "../../Components/BrowserBase/BrowserBase";
import { React, useEffect, useState } from 'react';
import { eStorage, getStorage } from "../../Services/StorageService";
import infoImage from "../../Assets/round-info-button.svg";
import MixedTooltip from "../shared/MixedTooltip";

function Search(props) {
    const { t } = useTranslation();
    const [estimated, setEstimated] = useState([]);
    const [reps, setReps] = useState([]);
    const [evaluation, setEvaluation] = useState([]);
    const [contract, setContract] = useState([]);
    const [planType, setPlanType] = useState([]);
    const [renewable, setRenewable] = useState([]);
    const [order, setOrder] = useState([]);
    const [language] = useState(getStorage(eStorage.i18nextLng));

    const [filter, setFilter] = useState({
        use: '1000',
        rep: '',
        rating: '',
        contract: '',
        min: '',
        max: '',
        type: '',
        renewable: '',
        order: ''
    });


    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        getEstimatedUseData().then(data => {
            data = data.filter(f => !f.values.en.toLowerCase().includes('i do'))
            setEstimated(data);
        }).catch(error => console.log('data :>> ', error));

        getREPsData().then(data => {
            setReps(data);
        });

        getMinRatingData().then(data => {
            setEvaluation(data);
        });

        getContractData().then(data => {
            setContract(data);
        });

        getElectricPlansData().then(data => {
            setPlanType(data);
        });

        getRenewableData().then(data => {
            setRenewable(data);
        });

        getOrderData().then(data => {
            setOrder(data);
        });
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        const filters = props.filter;
        filters[name] = value;
        setFilter(prevState => ({
            ...prevState, [name]: value
        }));
        props.onFilterChange(filters);
    }

    const handleReset = () => {
        const reset = {
            use: '1000',
            rep: '',
            rating: '',
            contract: '',
            min: '',
            max: '',
            type: '',
            renewable: '',
            order: ''
        };
        setFilter(reset);
        props.onFilterChange(reset);
    }

    return (
        <div>
            <div className="row">
                <div className="col-10">
                    <FormControl size="small" fullWidth>
                        <InputLabel id="estimated-use">{t('browser.estimated-use')}</InputLabel>
                        <Select
                            labelId="estimated-use"
                            label={t('browser.estimated-use')}
                            defaultValue=""
                            name="use"
                            onChange={handleChange}
                            value={filter.use}
                        >
                            {estimated.map((item, index) => (
                                <MenuItem key={index} value={item.key}>{item.values['en']}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-2 p-0">
                    <MixedTooltip customClass="mt-2" title={t('tooltips.browse-estimadetuse')} />
                </div>
            </div>
            <div className="row mt-3">
                <InputLabel id="price">{t('browser.price')}</InputLabel>
                <div className="col-10 pe-0">
                    <div className="row">
                        <div className="col-6">
                            <TextField fullWidth
                                size="small"
                                type="number"
                                name="min"
                                value={filter.min}
                                label={t('browser.min')}
                                onChange={handleChange} />
                        </div>
                        <div className="col-6">
                            <TextField fullWidth
                                size="small"
                                type="number"
                                name="max"
                                value={filter.max}
                                label={t('browser.max')} onChange={handleChange} />
                        </div>
                    </div>
                </div>
                <div className="col-2 p-0">
                    <MixedTooltip customClass="mt-2" title={t('tooltips.browse-price')} />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-10 pe-0">
                    <FormControl size="small" fullWidth>
                        <InputLabel id="rep">{t('browser.rep')}</InputLabel>
                        <Select
                            labelId="rep"
                            label={t('browser.rep')}
                            defaultValue=""
                            name="rep"
                            value={filter.rep}
                            onChange={handleChange}>
                            {reps.map((item, index) => (
                                <MenuItem key={index} value={item.uid}>{item.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-2 p-0">
                    <MixedTooltip  customClass="mt-2" title={t('tooltips.browse-provider')} />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-10 pe-0">
                    <FormControl size="small" fullWidth>
                        <InputLabel id="evaluation">{t('browser.evaluation')}</InputLabel>
                        <Select
                            labelId="evaluation"
                            label={t('browser.evaluation')}
                            onChange={handleChange}
                            name="rating"
                            value={filter.rating}
                            defaultValue="">
                            {evaluation.map((item, index) => (
                                <MenuItem key={index} value={item.key}>{item.values[language]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-2 p-0">
                    <MixedTooltip customClass="mt-2" title={t('tooltips.browse-rating')} />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-10 pe-0">
                    <FormControl size="small" fullWidth>
                        <InputLabel id="contract">{t('browser.contract')}</InputLabel>
                        <Select
                            labelId="contract"
                            label={t('browser.contract')}
                            onChange={handleChange}
                            name="contract"
                            value={filter.contract}
                            defaultValue="">
                            {contract.map((item, index) => (
                                <MenuItem key={index} value={item.key}>{item.values[language]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-2 p-0">
                    <MixedTooltip customClass="mt-2" title={t('tooltips.browse-contract')} />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-10 pe-0">
                    <FormControl size="small" fullWidth>
                        <InputLabel id="plan-type">{t('browser.plan-type')}</InputLabel>
                        <Select
                            labelId="plan-type"
                            label={t('browser.plan-type')}
                            defaultValue=""
                            name="type"
                            value={filter.type}
                            onChange={handleChange}>
                            {planType.map((item, index) => (
                                <MenuItem key={index} value={item.key}>{item.values[language]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-2 p-0">
                    <MixedTooltip customClass="mt-2" title={t('tooltips.browse-plantype')} />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-10 pe-0">
                    <FormControl size="small" fullWidth>
                        <InputLabel id="renewable">{t('browser.renewable')}</InputLabel>
                        <Select
                            labelId="renewable"
                            label={t('browser.renewable')}
                            defaultValue=""
                            name="renewable"
                            value={filter.renewable}
                            onChange={handleChange}>
                            {renewable.map((item, index) => (
                                <MenuItem key={index} value={item.key}>{item.values[language]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-2 p-0">
                    <MixedTooltip customClass="mt-2" title={t('tooltips.browse-renewable')} />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-10 pe-0">
                    <FormControl size="small" fullWidth>
                        <InputLabel id="sort">{t('browser.sort')}</InputLabel>
                        <Select
                            labelId="sort"
                            label={t('browser.sort')}
                            defaultValue=""
                            name="order"
                            value={filter.order}
                            onChange={handleChange}>
                            {order.map((item, index) => (
                                <MenuItem key={index} value={item.key}>{item.values[language]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="col-2 p-0">
                    <MixedTooltip customClass="mt-2" title={t('tooltips.browse-sortby')} />
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-12">
                    <Button className='btn-secondary  mb-3 mb-md-0' variant="outlined" onClick={handleReset}>
                        {t('browser.reset')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Search;