/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, List, ListItem, ListItemText, ListItemAvatar, Avatar } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ImageGoogle from '../../Assets/Logos/logo-google.png'
import ImageAmazon from '../../Assets/Logos/logo-amazon.png'
import ImageEcobee from '../../Assets/Logos/logo-ecobee.png'
import ImageHome from '../../Assets/Logos/logo-home.png'
import ImageHoneywell from '../../Assets/Logos/logo-Honeywell.png'
import ImageHoneywellTotal from '../../Assets/Logos/logo-honeywell-total.png'
import ImageGogleHome from '../../Assets/Logos/logo-GoogleHome.png'
import ImageEmporia from '../../Assets/Logos/logo-emporia.png'
import { Link } from 'react-router-dom';

function AddDevice(props) {
    const { t } = useTranslation();

    useEffect(() => {

    }, []);

    const onChangeSearch = () => {

    }

    const handleConnect = () => {

    }

    return (
        <div className='content'>
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <div className="container-card card-content">
                        <h2 className="text-start">LINK SMART HOME DEVICES</h2>
                        <div className="row justify-content-md-center mt-3">
                            <div className="col-md-10">
                                <FormControl fullWidth className="search-product">
                                    <InputLabel htmlFor="outlined-adornment-password">Search product</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={'text'}
                                        onChange={onChangeSearch}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    edge="end"
                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Search ..."
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div className="row justify-content-center object-select">
                            <div className="col-md-10">
                            <List sx={{ width: '100%' }}>
                                <ListItem onClick={handleConnect} component={Link} to="/connect-device-nest" >
                                    <ListItemAvatar>
                                        <Avatar className="b-brand">
                                            <img src={ImageGoogle}></img>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Google Home Nest Thermostat" />
                                </ListItem>
                                <hr className="w-75 m-auto mt-1 mb-1" />
                                <ListItem component={Link} to="/connect-device-resideo">
                                    <ListItemAvatar>
                                        <Avatar className="b-brand">
                                            <img src={ImageHoneywell}></img>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Honeywell Resideo" />
                                </ListItem>
                                <hr className="w-75 m-auto mt-1 mb-1" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="b-brand">
                                            <img src={ImageAmazon}></img>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Amazon Alexa Voice Assistant" />
                                </ListItem>
                                <hr className="w-75 m-auto mt-1 mb-1" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="b-brand">
                                            <img src={ImageEcobee}></img>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Ecobee" />
                                </ListItem>
                                <hr className="w-75 m-auto mt-1 mb-1" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="b-brand">
                                            <img src={ImageEmporia}></img>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Emporia" />
                                </ListItem>
                                <hr className="w-75 m-auto mt-1 mb-1" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="b-brand">
                                            <img src={ImageHome}></img>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Home Connect" />
                                </ListItem>
                                <hr className="w-75 m-auto mt-1 mb-1" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="b-brand">
                                            <img src={ImageGogleHome}></img>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Google Home Voice Assistant" />
                                </ListItem>
                                <hr className="w-75 m-auto mt-1 mb-1" />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className="b-brand">
                                            <img src={ImageHoneywellTotal}></img>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Honeywell Total Connect Comfort" />
                                </ListItem>
                            </List>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddDevice;