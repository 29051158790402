import { eStorage, getStorage, saveStorageJSON } from "../../Services/StorageService";
import { dataServiceApiInstance } from "../../Util/Api";
import { calcEngineApiInstance } from './../../Util/Api';


export const getRecommendBase = (order, queryFilter = '') => {
    let requestId = getStorage(eStorage.requestId);
    let language = getStorage(eStorage.language);

  const response = dataServiceApiInstance.get(`plan/recommended?request-id=${requestId}&limit=3&lang=${language}&order=${order}${queryFilter}`)
      .then(({ data }) => {

        return data;
      });
    return response;
  }

  export const getCurrentPlan = (customerId) => {
    let requestId = getStorage(eStorage.requestId);

    const response = dataServiceApiInstance.get(`plan/currentPlan?customerId=${customerId}&requestId=${requestId}`)
      .then(({ data }) => {

        return data;
      });
    return response;
  }

  export const getRecommendSortBy = () => {
    const response = dataServiceApiInstance.get(`domain/recommended_plan_sortby`)
      .then(({ data }) => {

        return data.valueList;
      });
    return response;
  }

export const postReScore = (model) => {
  let requestId = getStorage(eStorage.requestId);
  const fingerprint = JSON.parse(getStorage(eStorage.fingerprint));

  model['customer-id'] = fingerprint.customerId;
  model['request-id'] = requestId;

  const query = Object.keys(model)
    .map(k => `${k}=${model[k]}`)
    .join('&');

  const response = calcEngineApiInstance.post(`reScore?${query}`, model)
    .then(({ data }) => {
      return data;
    });
  return response;
}


export const postPreferences = (model, customerId) => {
  const response = dataServiceApiInstance.post(`/preferences/matching?customerId=${customerId}`, model)
      .then(({ data }) => {
          if (data) {
              saveStorageJSON(eStorage.matchingPreferences, model);
          }
          return data;
      });
  return response;
}