/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { getLocationsData, locationDefault } from "../../Components/LocationBase/LocationBase";
import { eStorage, getStorageJSON } from "../../Services/StorageService";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { ePage } from "../../Components/Shared/Routes";
import LocationOnIcon from '@mui/icons-material/LocationOn';

function Locations(props) {
    const { t } = useTranslation();
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        props.functions.setTitle(t('general.locations'));
        getLocations();
    }, []);

    const getLocations = () => {
        props.functions.setIsLoading(true);
        let company = getStorageJSON(eStorage.company);

        getLocationsData(company.id).then(data => {
            setLocations(data);
            props.functions.setIsLoading(false);
        });
    }

    return (
        <div className='content'>
            <div className='container mb-4'>
                <div className="row">
                    <div className="col-md-12">
                        <Button className='btn-primary' component={Link} to={ePage.addLocation}>
                            {t('general.add-location')}
                        </Button>
                    </div>
                </div>
                <div className="row mt-3">
                    {locations.map((item, index) =>
                        <div key={index} className="col-sm-12 col-md-6 col-lg-4 mt-2">
                            <div className="container-card dashboard-card b-card d-flex align-items-center justify-content-center mt-2 h-100">
                                <div className="row">
                                    <div className="col-md-12 d-flex align-items-center justify-content-center">
                                        <h2 className="title-section title-section-card mb-0">{item.name}</h2>
                                    </div>
                                    <div className="col-md-12 mt-2">
                                        <h3 className="subtitle-section">
                                            <LocationOnIcon className="icon-house-type" />  {item.address} {item.state}, {item.zipCode}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Locations;