
export const eModal = {
    efficiencySavings: 'efficiencySavings',
    energyLeakage: 'energyLeakage',
    planSavings: 'planSavings',
    reduceEmissions: 'reduceEmissions',
    dashboardSaving: 'dashboardSaving',
    dashboardEnergyUse: 'dashboardEnergyUse',
    dashboardSavedTree: 'dashboardSavedTree',
    dashboardLeaks: 'dashboardLeaks',
    energyScoreEfficiency: 'energyScoreEfficiency',
    energyScoreLeaks: 'energyScoreLeaks',
    energyScoreElectricyPlan: 'energyScoreElectricyPlan',
    energyScoreTrees: 'energyScoreTrees',
    environmentImpactCarbono: 'environmentImpactCarbono',
    environmentImpactOffseting: 'environmentImpactOffseting',
    resetPassSuccess: 'resetPassSuccess',
    resetPassInvalid: 'resetPassInvalid',
    resetPassServerError: 'resetPassServerError',
    moreBenefits: 'moreBenefits',
    homeEnergyScore: 'homeEnergyScore',
    completeQuiz: 'completeQuiz',
    completeRecommendations: 'completeRecommendations',
    completeAchievements: 'completeAchievements',
    skypMeter: 'skypMeter'
}
