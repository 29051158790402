/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import Header from "../Header";
import { eStorage, getStorage, getStorageJSON } from "../../../Services/StorageService";
import { Stack, Step, StepLabel, Stepper } from "@mui/material";
import QontoStepIcon from "../../../Components/Shared/Stepper";
import StepConnectorCustom from "../../../Components/Shared/StepConnector";
import AddressInfo from "../AddressInfo";
import UserInfo from "../UserInfo";
import ServiceInfo from "../ServiceInfo";
import OrderInfo from "../OrderInfo";
import { getServiceDates, postOrder } from "../../../Components/IntegrationBase/PulseBase";
import ResponseInfo from "../ResponseInfo";
import { getRep, replaceValues, repOtherPlan } from "../../../Components/IntegrationBase/IntegrationBase";
import { getParams } from "../../../Util/Util";
import { setDate } from "date-fns";

function Pulse(props) {
    const { t } = useTranslation();
    const [planSelected] = useState(getStorageJSON(eStorage.planSelected));
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Address Info', 'User Info', 'Service Info', 'Order Confirm'];
    const [address, setAddress] = useState(getStorageJSON(eStorage.address));
    const [billingAddress, setBillingAddress] = useState({ sameAddress: true });
    const [user, setUser] = useState(getStorageJSON(eStorage.user));
    const [rep, setRep] = useState({});
    const [service, setService] = useState({
        requestType: 'switching'
    });
    const [response, setResponse] = useState({ });

    const agreements = [
        { description: t('pulse.agreement-user-one'), required: true, name: 'userOne' },
        { description: t('pulse.agreement-user-two'), required: true, name: 'userTwo' },
        { description: t('pulse.agreement-user-three'), required: true, name: 'userThree' },
        { description: t('pulse.agreement-user-four'), required: true, name: 'userFour' }
    ];

    const [terms, setTerms] = useState([
        { id: 1, description: t('pulse.term-service-one'), required: false, value: false },
        { id: 2, description: t('pulse.term-service-two'), required: false, value: false },
        { id: 3, description: t('pulse.term-service-three'), required: false, value: false }
    ]);

    useEffect(() => {
        props.functions.setTitle(t('pulse.title'));
        if (!user.hasOwnProperty('birthdate')) {
            let date = new Date();
            date.setFullYear(date.getFullYear() - 18);
            setUser(prevState => ({
                ...prevState, ['birthdate']: date
            }));
        }

        if (!service.hasOwnProperty('serviceDate')) {
            let date = new Date();
            date.setDate(date.getDate() + 1);
            setService(prevState => ({
                ...prevState, ['serviceDate']: date
            }));
        }

        if (!user.hasOwnProperty('ssn'))
            setUser(prevState => ({
                ...prevState, ['ssn']: ''
            }));

        getRep(planSelected.rep).then(data => {
            setRep(data);
        });
    }, []);

    const handleSubmit = () => {
        props.functions.setIsLoading(true);
        let esiid = getStorageJSON(eStorage.user).esiid;
        let requestId = getStorage(eStorage.requestId);

        let addressOrder = {
            address: address.address,
            city: address.city,
            state: address.state,
            zipCode: address.zipCode,
        };

        let phone = user.phone.replaceAll(' ', '').replaceAll('-', '').substr(-10);
        let userOrder = {
            name: user.name,
            lastName: user.lastName,
            phone: phone,
            birthdate: user.birthdate?.toISOString().split('T')[0].replaceAll('-', '/'),
            ssn: user.ssn.replaceAll(' ', '').replaceAll('-', ''),
            language: user.language,
        }

        let typeRequestOrder = {
            requestType: service.requestType,
            serviceDate: service.serviceDate.toISOString().split('T')[0]
        };

        let addressBillOrder = {
            sameAsClientAddress: billingAddress.sameAddress,
            addressBill: billingAddress.sameAddress ? address.address : billingAddress.address,
            cityBill: billingAddress.sameAddress ? address.city : billingAddress.city,
            zipCodeBill: billingAddress.sameAddress ? address.zipCode : billingAddress.zipCode,
            stateBill: billingAddress.sameAddress ? address.state : billingAddress.state,
            unitBill: billingAddress.sameAddress ? address.apt : billingAddress.apt,
        };

        let otherDetails = {
            marketingEmails: terms[0].value,
            marketingPhoneCalls: terms[1].value,
            ebilling: terms[2].value,
            priorityMoveIn: false
        }

        let model = {
            customerId: user.customerId,
            requestId: requestId,
            planId: planSelected.uid ? planSelected.uid : planSelected.planUid,
            esiid: esiid,
            serviceArea: address.serviceArea,
            orderDate: service.serviceDate.toISOString().split('T')[0],
            orderDetails: {
                clientAddress: addressOrder,
                userinfo: userOrder,
                identityVerification: userOrder,
                request: typeRequestOrder,
                billingAddress: addressBillOrder,
                otherDetails: otherDetails
            }
        }

        postOrder(model).then(data => {
            showResponse(data);
        });
    }

    useEffect(() => {
        if (activeStep === 2) {
            let planId = planSelected.uid ? planSelected.uid : planSelected.planUid;
            getServiceDates(planId, address.zipCode)
                .then(response => {
                    if (Array.isArray(response) && response.length > 0) {
                        const init = new Date(`${response[0]} 00:00:00`);
                        const end = new Date(`${response[response.length - 1]} 00:00:00`);
                        setDate([init, end]);
                    }
                }).catch(error => console.log)
        }
    }, [activeStep]);

    const showResponse = (data) => {
        props.functions.setIsLoading(false);
        switch (data.status) {
            case 0:
                setResponse({
                    title: t('pulse.order-success-title'),
                    description: replaceValues(t('pulse.order-success-description'), { voucher: data.voucher }),
                    buttonOne: t('general.help-better'),
                    buttonOneUrl: '/feedback',
                    buttonTwoUrl: '',
                    buttonTwo: ''
                });
                break;
            case 1:
                setResponse({
                    title: t('pulse.order-deposit-title'),
                    description: replaceValues(t('pulse.order-deposit-description'), { deposit: data.deposit, rep: rep.name }),
                    buttonOne: t('pulse.order-deposit-pay'),
                    buttonOneUrl: `${data.redirect}&callback=${getParams.EMM_REDIRECT_URL}&orderID=${user.customerId}`,
                    buttonTwoUrl: repOtherPlan(rep.uid),
                    buttonTwo: t('general.select-other-plan'),
                    more: replaceValues(t('pulse.order-deposit-more'), { deposit: data.deposit, rep: rep.name, phone: rep.phone, confirm: data.confirmationId })
                });
                break;
            case 2:
                setResponse({
                    title: t('pulse.order-credit-title'),
                    description: replaceValues(t('pulse.order-credit-description'), { deposit: data.deposit }),
                    buttonOne: t('pulse.order-deposit-pay'),
                    buttonOneUrl: `${data.redirect}&callback=${getParams.EMM_REDIRECT_URL}&orderID=${user.customerId}`,
                    buttonTwoUrl: repOtherPlan(rep.uid),
                    buttonTwo: t('general.select-other-plan'),
                    more: replaceValues(t('pulse.order-credit-more'), { rep: rep.name, phone: rep.phone, confirm: data.confirmationId })
                });
                break;
            case 3:
                setResponse({
                    title: t('pulse.order-verification-title'),
                    description: replaceValues(t('pulse.order-verification-description'), { rep: rep.name }),
                    buttonOne: t('general.select-other-plan'),
                    buttonOneUrl: repOtherPlan(rep.uid),
                    buttonTwoUrl: '/feedback',
                    buttonTwo: t('general.keep-plan'),
                    more: replaceValues(t('pulse.order-verification-more'), { rep: rep.name, phone: rep.phone, confirm: data.confirmationId })
                });
                break;
            case 4:
                setResponse({
                    title: t('pulse.order-documentation-title'),
                    description: replaceValues(t('pulse.order-documentation-description'), { rep: rep.name, email: rep.email, confirm: data.confirmationId }),
                    buttonOne: t('general.select-other-plan'),
                    buttonOneUrl: '/browser',
                    buttonTwoUrl: '/feedback',
                    buttonTwo: t('general.ill-email'),
                    more: replaceValues(t('pulse.order-documentation-more'), { rep: rep.name, phone: rep.phone })
                });
                break;
            default:
                setResponse({
                    title: t('general.error-order-title'),
                    description: t('general.error-order-description'),
                    buttonOne: t('general.select-other-plan'),
                    buttonOneUrl: '/browser',
                    buttonTwoUrl: '',
                    buttonTwo: ''
                });
                break;
        }
    }

    return (
        <div className='content'>
            <div className='container'>
                <div className='row justify-content-md-center'>
                    <div className='col-sm-12 col-md-12 col-lg-10'>
                        <Header functions={props.functions} planSelected={planSelected} />
                    </div>
                </div>
                <div className='row mt-3 justify-content-md-center'>
                    <div className='col-sm-12 col-md-12 col-lg-10'>
                        <div className="container-card card-content">
                            {Object.keys(response).length === 0 &&
                                <div>
                                    {activeStep === 0 &&
                                        <AddressInfo functions={props.functions} address={address} setAddress={setAddress} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                    {activeStep === 1 &&
                                        <UserInfo user={user} setUser={setUser} agreements={agreements} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                    {activeStep === 2 &&
                                        <ServiceInfo terms={terms} setTerms={setTerms} service={service} setService={setService} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                    {activeStep === 3 &&
                                        <OrderInfo handleSubmit={handleSubmit} billingAddress={billingAddress} setBillingAddress={setBillingAddress} address={address} setAddress={setAddress} user={user} setUser={setUser} service={service} setService={setService} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                </div>
                            }
                            {Object.keys(response).length > 0 &&
                                <ResponseInfo response={response} />
                            }
                        </div>
                    </div>
                </div>
                <Stack className="mt-4 mb-4" sx={{ width: '100%' }}>
                    <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnectorCustom />}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Stack>
            </div>
        </div>
    )
}

export default Pulse;