import "bootstrap/dist/css/bootstrap.min.css";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Button, MenuItem } from '@mui/material';
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator';
import { eStorage, getStorageJSON } from "../../Services/StorageService";
import { postTicket } from './../../Components/IntegrationBase/IntegrationBase';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MaterialUISwitch } from "../../Components/Shared/Switch";
import { MuiTelInput } from "mui-tel-input";
import { useNavigate } from "react-router-dom";
import { ePage } from "../../Components/Shared/Routes";
import { getLocationsData } from './../../Components/LocationBase/LocationBase';

function ElectricalServiceRequest(props) {
  const { t } = useTranslation();
  const formRef = useRef();
  const [company] = useState(getStorageJSON(eStorage.company));
  const [locations, setLocations] = useState([]);
  const navigate = useNavigate();
  const [model, setModel] = useState({
    email: '',
    taxId:'',
    name: '',
    lastname: '',
    description:'',
    locationId: getStorageJSON(eStorage.location)?.id,
    companyName: getStorageJSON(eStorage.company)?.name
  });
  const [user] = useState(getStorageJSON(eStorage.user));
  const [phone, setPhone] = useState('+1');
  const [showPhoneError, setShowPhoneError] = useState(false);

  useEffect(() => {
    props.setIsLoading(true);
    props.setTitle(t('ElectricalServiceRequest.title'));
    setModel(prevState => ({
      ...prevState, ['companyName']: user.companyName,
      ['name']: user.name,
      ['lastname']: user.lastName,
      ['email']: user.email
    }));

    getLocationsData(company.id).then(data => {
      setLocations(data);
    }).catch(console.log).finally(() => props.setIsLoading(false));
  }, []);

  const handleSubmit = () => {
    props.setIsLoading(true);
    const request = {
      description: model.description,
      customerId: user.customerId,
      status: 'CREATED',
      type: 'REQUEST',
      metadata: {
        ...model,
        phone
      }
    }

    postTicket(request).then((resp) => {
      Notify.success(t('ElectricalServiceRequest.success'));
      navigate(ePage.dashboard);
    }).catch(() => {
      Notify.failure(t('ElectricalServiceRequest.error'))
    }).finally(() => props.setIsLoading(false))
  }

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setModel(prevState => ({
      ...prevState, [name]: name !== 'isSwitch' ? (name === 'description' ? value : value.trim()) : checked
    }));
  }

  const handleChangeDate = (value) => {
    setModel(prevState => ({
      ...prevState, ['startDate']: value
    }));
  };

  const handleChangePhone = (event, info) => {
    setPhone(event);

    setShowPhoneError(true);
    if (event.length <= 2)
      setPhone('+1');
    else
      setPhone(event);
  }

  return <>
    <ValidatorForm
      ref={formRef}
      onSubmit={handleSubmit}
      onError={errors => console.log(errors)}>
      <div className="content">
        <div className="row justify-content-center pt-3">
          <h1>{t('ElectricalServiceRequest.description')}</h1>
          <div className="col-md-8 container-card card-content h-100">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className="row mt-2">
                  <div className="col-md-12">
                    <TextValidator autoComplete="nope" fullWidth size="small" name="companyName" value={model.companyName} onChange={handleChange} label={t('general.business-name')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.business-name'))]} />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    <SelectValidator className="text-start" fullWidth size="small" name="entityType" value={model.entityType} defaultValue="" onChange={handleChange} label={t('general.entity-type')} >
                      <MenuItem value='Corporation'>Corporation</MenuItem>
                      <MenuItem value='LLC (Multi-Member)'>LLC (Multi-Member)</MenuItem>
                      <MenuItem value='LLC (Single-Member)'>LLC (Single-Member)</MenuItem>
                      <MenuItem value='Partnership'>Partnership</MenuItem>
                      <MenuItem value='Sole Proprietor / Individual'>Sole Proprietor / Individual</MenuItem>
                    </SelectValidator>
                  </div>
                  <div className="col-md-4">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker label={t('general.desired-start-date')} value={model.startDate} onChange={handleChangeDate} renderInput={(params) => <TextValidator style={{ width: '100%' }} size="small" {...params} />} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.desired-start-date'))]} />
                    </LocalizationProvider>
                  </div>
                  <div className="col-md-4">
                    <span className="input-font">{t('general.move-in')}</span>
                    <MaterialUISwitch checked={model.isSwitch} name="isSwitch" onChange={handleChange} />
                    <span className="input-font">{t('general.switch')}</span>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    <TextValidator autoComplete="nope" fullWidth size="small" name="name" value={model.name} onChange={handleChange} label={t('general.name')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.name'))]} />
                  </div>
                  <div className="col-md-4 mt-2 mt-md-0">
                    <TextValidator autoComplete="nope" fullWidth size="small" name="lastname" value={model.lastname} onChange={handleChange} label={t('general.last-name')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.last-name'))]} />
                  </div>
                  <div className="col-md-4">
                    <TextValidator autoComplete="nope" fullWidth size="small" name="taxId" value={model.taxId} onChange={handleChange} label={t('general.tax-id')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.tax-id'))]} />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-4">
                    <TextValidator autoComplete="nope" fullWidth size="small" name="email" value={model.email} onChange={handleChange} label={t('general.email')} validators={['required', 'isEmail']} errorMessages={[t('general.required').replace('#field#', t('general.email')), t('general.is-email').replace('#field#', t('general.email'))]} />
                  </div>
                  <div className="col-md-4">
                    <MuiTelInput preferredCountries={['US']} onlyCountries={['US']} helperText={phone.length !== 15 && showPhoneError ? t('general.phone-invalid') : ""}
                      error={phone.length !== 15 && showPhoneError} forceCallingCode={true} fullWidth size="small" name="phone" value={phone} onChange={handleChangePhone} label={t('general.cell-phone')} />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-12">
                    <SelectValidator className="text-start" fullWidth size="small" name="locationId" defaultValue="" value={model.locationId} onChange={handleChange} label={t('general.location')}>
                      {
                        locations.map((location) =>(
                          <MenuItem key={location.id} value={location.id}>{location.name} - {location.address}</MenuItem>
                        ))
                      }
                    </SelectValidator>
                  </div>
                </div>
                 <div className="row mt-2">
                  <div className="col-md-12">
                    <TextValidator fullWidth size="small" className="text-terms" name="description" multiline rows={3} onChange={handleChange} value={model.description} label={t('general.description')}
                      validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.description'))]} />
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col-12'>
                    <div className='justify-content-md-center'>
                      <Button className='btn-primary' type="submit">{t('sidebar.apply')}</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidatorForm>
  </>
}

export default ElectricalServiceRequest;