import { React, useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { Button, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { cancelSubscriptionData, deleteCardData, getCardsData, getSubscriptionData, postCard, postDefaultCard } from '../../Components/ProfileBase/ProfileBase';
import { addPropertyStorage, eStorage, getStorage, getStorageJSON } from '../../Services/StorageService';
import ModalCard from './ModalCard';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import ModalConfirm from './ModalConfirm';

function SubscriptionManagement(props) {
    const { t } = useTranslation();
    const [user] = useState(getStorageJSON(eStorage.user));
    const [cards, setCards] = useState([]);
    const [subscription, setSubscription] = useState({});
    const [modal, setModal] = useState(false);
    const [language] = useState(getStorage(eStorage.language));
    const [payment, setPayment] = useState({
        cardNumber: '',
        expiry: '',
        cvc: '',
        cardName: ''
    });
    const [modalConfirm, setModalConfirm] = useState(false);
    const [modalConfirmDelete, setModalConfirmDelete] = useState(false);
    const [cardId, setCardId] = useState('');

    useEffect(() => {
        props.functions.setIsLoading(true);
        getCards();
        getSubscription();
    }, []);

    const getCards = () => {
        getCardsData(user.customerId).then(data => {
            setCards(data);
            props.functions.setIsLoading(false);
        });
    }

    const getSubscription = () => {
        getSubscriptionData(user.customerId).then(data => {
            setSubscription(data);
        });
    }

    const confirmDeleteCard = () => {
        props.functions.setIsLoading(true);
        deleteCardData(user.customerId, cardId).then(data => {
            getCards();
            setModalConfirmDelete(false);
            props.functions.setIsLoading(false);
        });
    }

    const confirmCancel = () => {
        props.functions.setIsLoading(true);
        cancelSubscriptionData(user.customerId).then(data => {
            getSubscription();
            setModalConfirm(false);
            addPropertyStorage(eStorage.user, 'subscriptionPremium', false);
            props.functions.setUser(prevState => ({
                ...prevState, ['subscriptionPremium']: false
            }));
            Notify.success(t('subscription.canceled'), { position: 'center-bottom' });
            props.functions.setIsLoading(false);
        });
    }

    const deleteCard = (card) => {
        if (cards.length < 2) {
            Notify.failure(t('profile.error-delete-one-card'), { position: 'center-bottom' });
        } else if (card.default) {
            Notify.failure(t('profile.error-delete-default'), { position: 'center-bottom' });
        }
        else {
            setCardId(card.id);
            setModalConfirmDelete(true);
        }
    }

    const cancelSubscription = () => {
        setModalConfirm(true);
    }

    const defaultSubscription = (item) => {
        props.functions.setIsLoading(true);
        postDefaultCard(user.customerId, item.id).then(data => {
            getCards();
        }).finally(() => {
            props.functions.setIsLoading(false);
        });
    }

    const handleSubmit = () => {
        props.functions.setIsLoading(true);
        let expired = payment.expiry.split('/');
        let model = {
            number: payment.cardNumber.replaceAll(' ', ''),
            expMonth: parseInt(expired[0]),
            expYear: parseInt('20' + expired[1]),
            cvc: payment.cvc
        }

        postCard(user.customerId, model).then(data => {
            if (data.status == 'success') {
                Notify.success(t('subscription.subscription-success'), { position: 'center-bottom' });
                getCards();
                setModal(false);
            } else {
                if (data.error == 'card_exist')
                    Notify.failure(t('profile.error-exist-card'), { position: 'center-bottom' });
                else
                    Notify.failure(t('profile.error-add-card'), { position: 'center-bottom' });
            }
        }).finally(() => {
            props.functions.setIsLoading(false);
        });
    }

    return (
        <>
            <ModalCard functions={props.functions} handleSubmit={handleSubmit} payment={payment} setPayment={setPayment} open={modal} setModal={setModal} modal={modal} />
            <ModalConfirm functions={props.functions} setModal={setModalConfirm} handleConfirm={confirmCancel} text={t('subscription.cancel-confirm')} open={modalConfirm}></ModalConfirm>
            <ModalConfirm functions={props.functions} setModal={setModalConfirmDelete} handleConfirm={confirmDeleteCard} text={t('profile.confirm-delete-card')} open={modalConfirmDelete}></ModalConfirm>
            <div className="row justify-content-md-center">
                <div className="col-md-10">
                    <h2>{t('profile.subscription')}</h2>
                </div>
            </div>
            <hr />
            {subscription?.subscription ?
                <div className="row">
                    <div className="col-md-4 text-start">
                        <h3><strong>{t('subscription.type')}: </strong>{subscription.subscriptionPlan.name[language]}</h3>
                        <h3><strong>{t('subscription.since')}: </strong>{subscription.subscriptionPlan.created}</h3>
                    </div>
                    <div className="col-md-4 text-start">
                        <h3><strong>{t('subscription.amount')}: </strong>${subscription.subscriptionPlan.price.toFixed(2)}</h3>
                    </div>
                    <div className="col-md-4">
                        <Button className='btn-primary' onClick={cancelSubscription}>
                            {t('profile.cancel')}
                        </Button>
                    </div>
                </div> :
                <div className="row">
                    <div className="col-md-4 text-start">
                        <h3><strong>{t('subscription.type')}:</strong> Free Plan</h3>
                    </div>
                    <div className="col-md-4 text-start">
                        <h3><strong>{t('subscription.amount')}:</strong> $0.00</h3>
                    </div>
                    {/* <div className="col-md-4">
                        <Button className='btn-primary' component={Link} to="/subscription">
                            {t('profile.upgrade-subscription')}
                        </Button>
                    </div> */}
                </div>
            }
            {/* <hr />
            <div className="row justify-content-md-center">
                <div className="col-md-10">
                    <h2>{t('profile.payment-cards')}</h2>
                </div>
            </div>
            <hr />
            <div className="row justify-content-md-center">
                <div className="col-md-10">
                    <Button className='btn-primary' onClick={() => setModal(true)}>
                        {t('profile.add-card')}
                    </Button>
                </div>
            </div>
            <div className="row mt-2 justify-content-md-center">
                <div className='col-md-10'>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell scope="col"><strong>{t('profile.number-card')}</strong></TableCell >
                                    <TableCell scope="col"><strong>{t('profile.is-default')}</strong></TableCell >
                                    <TableCell scope="col"><strong>{t('profile.actions')}</strong></TableCell >
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {cards.map((item) =>
                                    <TableRow key={item.id}>
                                        <TableCell>&#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226; {item.lastDigits}</TableCell >
                                        <TableCell>
                                            {item.default &&
                                                <Switch name='isDefault' value="alerts" checked={item.default} />
                                            }
                                            {!item.default &&
                                                <Switch name='isDefault' value="alerts" checked={item.default} onChange={() => defaultSubscription(item)} />
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <IconButton className='btn-circle-secondary' onClick={() => deleteCard(item)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div> */}
        </>
    )
}

export default SubscriptionManagement;