/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";

function SMTRedirect(props) {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        let url = searchParams.get("url");
        if (url !== null)
            window.parent.window.location.href = url;
    }, []);
    return (
        <div>Redirect SMT...</div>
    )
}

export default SMTRedirect;