import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Button } from '@mui/material';
import LogoPrincipal from '../../Assets/logo-enerwisely.png';
import { Link } from 'react-router-dom';
import { React, useEffect, useState, useRef } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { getRecover, getRecoverByPhone } from "../../Components/AuthBase/AuthBase";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { ePage } from "../../Components/Shared/Routes";
import { useNavigate } from "react-router-dom";
import { MuiTelInput } from "mui-tel-input";
import { eStorage, saveStorage } from "../../Services/StorageService";

function RecoverSMS(props) {
    const { t } = useTranslation();
    const formRef = useRef();
    const navigate = useNavigate();
    const [phone, setPhone] = useState('+1');
    const [showPhoneError, setShowPhoneError] = useState(false);

    useEffect(() => {
        props.functions.setTitle(t('general.password-recover'));
    }, []);

    const handleChangePhone = (event, info) => {
        setPhone(event);

        setShowPhoneError(true);
        if (event.length <= 2)
            setPhone('+1');
        else
            setPhone(event);
    }

    const handleSubmit = () => {
        props.functions.setIsLoading(true);
        getRecoverByPhone(phone).then(data => {
            if (data) {
                Notify.success(t('general.sms-sent'), { position: 'center-bottom' });
                saveStorage(eStorage.phoneRecover, phone);
                navigate(ePage.validateCode);
            }

        }).catch(console.log).finally(() => props.functions.setIsLoading(false));
    }

    return (
        <ValidatorForm ref={formRef} className='content' onSubmit={handleSubmit} onError={errors => console.log(errors)}>
            <div className='container'>
                <div className='row justify-content-md-center  mb-1'>
                    <div className='col-sm-12 col-md-8 col-lg-6 container-card card-content text-center'>
                        <img className="logo-login" src={LogoPrincipal} alt="logo" />
                        <div className="row mt-4 justify-content-md-center">
                            <div className="col-md-8">
                                <MuiTelInput preferredCountries={['US']} onlyCountries={['US']} helperText={phone.length !== 15 && showPhoneError ? t('general.phone-invalid') : ""}
                                    error={phone.length !== 15 && showPhoneError} forceCallingCode={true} fullWidth size="small" name="phone" value={phone} onChange={handleChangePhone} label={t('general.cell-phone')} />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <Button className='btn-primary' type="submit">
                                    {t('general.recover')}
                                </Button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Button className='btn-basic mt-2' component={Link} to="/login">{t('general.have-account')}</Button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Button className='btn-basic mt-2' component={Link} to="/recover">{t('general.password-recover-sms')}</Button>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12">
                                <Button className='btn-secondary' variant="outlined" component={Link} to="/select-path">
                                    {t('general.join-now')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ValidatorForm>
    )
}

export default RecoverSMS;