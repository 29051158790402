import { addPropertyStorage, eStorage, getStorage, getStorageJSON, saveStorageJSON } from "../../Services/StorageService";
import { dataServiceApiInstance } from "../../Util/Api";
import { useNavigate } from 'react-router-dom';

export const getEsiid = (model) => {
    let search = "zipCode=" + model.address.zipCode + "&address=" + model.address.address;
    if (model.address.houseType !== 'apto' && model.address.houseType !== 'townhouse')
        model.address.apto = '';

    if (model.address.housingType === 'apto')
        search = search + " %23" + model.address.apto;
    else
        search = search + " " + model.address.apto;

    const response = dataServiceApiInstance.get(`esiids/getEsiid?${search}`)
        .then(({ data }) => {
            if (data.length === 1) {
                addPropertyStorage(eStorage.user, 'esiid', data[0].Esiid);
                model.address.serviceArea = data[0].TDSPUid;
                saveStorageJSON(eStorage.address, model.address);
            } else if (data.length > 1) {
                addPropertyStorage(eStorage.user, 'esiid', data[0].Esiid);
                model.address.serviceArea = data[0].TDSPUid;
                saveStorageJSON(eStorage.address, model.address);
            }
            return data;
        });
    return response;
}

export const getRep = (id) => {
    const response = dataServiceApiInstance.get(`reps/` + id)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const repOtherPlan = (rep) => {
    let reps = getStorage(eStorage.otherPlan);
    if (reps === null)
        reps = [rep];
    else
        reps.push(rep);

    let planSelected = getStorageJSON(eStorage.planSelected);

    if (planSelected?.recommend)
        return `/recommend?reps=${reps.join(".")}`;
    else
        return `/browser?reps=${reps.join(".")}`;
}

export const replaceValues = (text, model) => {
    if (model?.rep)
        text = text.replaceAll('#rep#', `<strong>${model.rep}</strong>`);
    if (model?.phone)
        text = text.replaceAll('#phone#', `<strong>${model.phone}</strong>`);
    if (model?.email)
        text = text.replaceAll('#email#', `<strong>${model.email}</strong>`);
    if (model?.confirm)
        text = text.replaceAll('#confirm#', `<strong>${model.confirm}</strong>`);
    if (model?.deposit)
        text = text.replaceAll('#deposit#', `<strong>${model.deposit}</strong>`);
    if (model?.voucher)
        text = text.replaceAll('#voucher#', `<strong>${model.voucher}</strong>`);
    return text;
}

export const postTicket = (model) => {
    const response = dataServiceApiInstance.post(`/ticket/`, model)
        .then(({ data }) => {
            return data;
        });
    return response;
}