/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, List, ListItemButton, ListItemAvatar, Avatar, ListItem, ListItemText, IconButton } from '@mui/material';
import Chip from '@mui/material/Chip';
import { FileUploader } from "react-drag-drop-files";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import ModalQuiz from "./ModalQuiz";
import { getChallengeData, getCustomerChallengeCustom, postCommentSuscribe, postComplete } from "../../Components/ChallengesBase/ChallengesBase";
import { useParams } from "react-router-dom";
import { eStorage, getStorageJSON } from "../../Services/StorageService";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from "react-router-dom";
import ModalCongratulations from "./ModalCongratulations";
import { useNavigate } from 'react-router-dom';
import { ePage } from "../../Components/Shared/Routes";
const fileTypes = ["JPG", "PNG", "GIF"];

function Challenge(props) {
    const { t } = useTranslation();
    const [openModalCongratulations, setOpenModalCongratulations] = useState(false);
    const [openModalQuiz, setOpenModalQuiz] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [challenge, setChallenge] = useState({});
    const { id } = useParams();
    const [bests, setBests] = useState([]);
    const [customerChallengeId, setCustomerChallengeId] = useState('');
    const { width, height } = useWindowSize();
    const [tumbs, setTumbs] = useState([]);
    const [quizResponse, setQuizResponse] = useState([]);
    const [completed, setCompleted] = useState('notsuscribed');
    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, []);

    const customerChallengeCustomGet = () => {
        getCustomerChallengeCustom(id)
            .then(resp => {
                if (resp) {
                    if (resp.length > 0) {
                        setCustomerChallengeId(resp[0].customerChallengeId);
                        setBests(resp.map(m => ({ name: `${m.name} ${m.lastname}`, points: m.points })));
                        checkStatus(resp);
                    }
                }
            }).catch(console.log);
    }

    const checkStatus = (users) => {
        let currentCustomer = getStorageJSON(eStorage.user);
        let customer = users.find(w => w.customerId == currentCustomer.customerId);

        setCompleted(customer?.status);
    }


    const handleFile = (file) => {
        if (file && file.length) {
            const pictures = [];
            for (let index = 0; index < file.length; index++) {
                const element = file[index];
                pictures.push({
                    name: element.name,
                    picture: URL.createObjectURL(element),
                    base64: '',
                    element: element,
                    size: element.size
                })
            };
            setTumbs([...tumbs, ...pictures]);
        }
    };

    const getData = () => {
        customerChallengeCustomGet();
        getChallengeData(id).then(data => {
            setChallenge(data[0]);
            console.log(data);
        }).catch(console.log);
    }

    const handleSuscribed = () => {
        let customer = getStorageJSON(eStorage.user);
        let model = {
            challengeId: id,
            customerId: customer.customerId
        }

        postCommentSuscribe(model).then(data => {
            Notify.success('You have successfully subscribed to the challenge.', { position: 'center-bottom', });
            getData();
        });
    };

    const handleComplete = () => {
        props.functions.setIsLoading(true);

        let customer = getStorageJSON(eStorage.user);
        let model = {
            challengeId: id,
            customerId: customer.customerId,
            customerChallengeId: customerChallengeId,
            quiz: { questions: quizResponse },
            images: []
        };

        if (tumbs && tumbs.length > 0) {
            const base64ToResolve = tumbs.map(m => (toBase64(m.element)));
            Promise.all(base64ToResolve)
                .then(images => {
                    model.images = images.map(m => (m.split(',')[1]));
                    sendRequest(model);
                })
                .catch((error) => {
                    console.log(error);
                    props.functions.setIsLoading(false);
                });
        } else {
            sendRequest(model);
        }
    }

    const handleConnectDevice = () => {
        navigate(ePage.connectDevice);
    }

    const sendRequest = (model) => {
        props.functions.setIsLoading(true);

        if (challenge.type === 'quiz' && quizResponse.length === 0) {
            props.functions.setIsLoading(false);
            Notify.failure('You are required complete the quiz.', { position: 'center-bottom' });
        }


        if (challenge.verificationImage && model.images.length > 0) {
            postComplete(model).then(data => {
                setShowConfetti(true);
                Notify.success(t('challenge.messageComplete'), { position: 'center-bottom', });
                setTimeout(() => {
                    setShowConfetti(false);
                    setOpenModalCongratulations(true);
                }, 5000);
            }).catch(console.log)
                .finally(() => props.functions.setIsLoading(false));
        } else {
            props.functions.setIsLoading(false);
            Notify.failure('You are required to upload verification images of the challenge.', { position: 'center-bottom' });
        }

    }

    const handleQuizModal = () => {
        setOpenModalQuiz(true);
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

    return (
        <div className='content'>
            {showConfetti &&
                <Confetti numberOfPieces={1000} width={width} height={height} />
            }
            <ModalCongratulations challenge={challenge} open={openModalCongratulations} setModalOpen={setOpenModalCongratulations} />
            <ModalQuiz open={openModalQuiz} setQuizResponse={setQuizResponse} quiz={challenge?.quiz} setModalOpen={setOpenModalQuiz} />
            <div className='container'>
                <div className="row">
                    <div className="col-md-8">
                        <div className='container-card card-content'>
                            <div className="row">
                                <div className="col-md-6">
                                    <h2 className="text-start">{challenge?.name}</h2>
                                </div>
                                <div className="col-md-6 text-end">
                                    {completed === 'suscribed' &&
                                        <Chip className="me-1" label="Participating" color="primary" />
                                    }
                                    {completed === 'completed' &&
                                        <Chip label="Completed" color="secondary" />
                                    }
                                </div>
                            </div>
                            <div className="row mt-3 justify-content-md-center">
                                <div className="col-md-5 ">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h6 className="text-start">Deadline {new Date(challenge?.endDate).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</h6>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h6 className="text-start">Description:</h6>
                                            <p className="description-text text-start">{challenge?.challengeDescription}</p>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-12">
                                            <h6 className="text-start">Why is this important?</h6>
                                            <p className="description-text text-start">{challenge?.important}</p>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-12">
                                            <h6 className="text-start">Education</h6>
                                            <p className="description-text text-start">{challenge?.education}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h6 className="text-start">Prize:</h6>
                                            <p className="description-text text-start">{challenge?.prizeDescription}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h6 className="text-start">How will winners be chosen?</h6>
                                            <p className="description-text text-start">{challenge?.winners}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h6 className="text-start">How to participate:</h6>
                                            <p className="description-text text-start">{challenge?.participate}</p>
                                        </div>
                                    </div>
                                    {challenge?.type === 'quiz' && completed === 'suscribed' &&
                                        <div className="row">
                                            <div className="col-md-12 text-start">
                                                <h6 className="text-start">Actions to complete:</h6>
                                                <Button className={'btn-primary-s me-1'} onClick={handleQuizModal}>Complete Quiz</Button>
                                            </div>
                                        </div>
                                    }

                                    {challenge?.type === 'smartdevices' && completed === 'suscribed' &&
                                        <div className="row">
                                            <div className="col-md-12 text-start">
                                                <h6 className="text-start">Actions to complete:</h6>
                                                <Button className={'btn-primary-s me-1'} onClick={handleConnectDevice}>Connect Device</Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <hr />
                            {tumbs.length > 0 &&
                                <div className="row mx-5 justify-content-md-center">
                                    {tumbs.map((m, index) => {
                                        return (
                                            <div className="col-md-3" key={index}>
                                                <img src={m.picture} data-name={m.picture} height="100" alt={m.name} />
                                            </div>
                                        )
                                    })}
                                </div>}
                            {completed === 'suscribed' && challenge.type !== 'devicemanagement' &&
                                <div className="row justify-content-md-center">
                                    <div className="col-md-5">
                                        <FileUploader handleChange={handleFile} name="file" multiple={true} types={fileTypes} />
                                    </div>
                                </div>
                            }

                            {completed === 'suscribed' && challenge.type === 'devicemanagement' &&
                                <h6 className="text-center">The challenge will start automatically at the configured time, no additional action is necessary.</h6>
                            }

                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <IconButton className="float-start btn-circle" component={Link} to="/win-prize">
                                        <ArrowBackIcon />
                                    </IconButton>
                                    {completed === 'suscribed' && challenge.type !== 'devicemanagement' &&
                                        <Button className='btn-primary' onClick={handleComplete}>{t('challenge.completeChallenge')}</Button>
                                    }
                                    {completed === 'notsuscribed' &&
                                        <Button className='btn-primary' onClick={handleSuscribed}>{'SUSCRIBE'}</Button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className='container-card card-content'>
                            <h2 className="text-start">RANKING OF PARTICIPANTS</h2>

                            <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {bests.map((value, index) => {
                                    const labelId = `checkbox-list-secondary-label-${index}`;
                                    return (
                                        <ListItem
                                            key={index}
                                            secondaryAction={
                                                <>
                                                    {index === 0 && <EmojiEventsIcon className='first-icon'></EmojiEventsIcon>}
                                                    <span className='points-ranking'>{value.points} {t('general.points')}</span>
                                                </>
                                            }
                                            disablePadding >
                                            <ListItemButton>
                                                <span className='number-list'>{index + 1}. </span>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        alt={value.name}
                                                        src={`/static/images/avatar/${index + 1}.jpg`}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText id={labelId} primary={value.name} />
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Challenge;