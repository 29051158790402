/* eslint-disable react-hooks/exhaustive-deps */
import './App.css';
import NavBar from './Components/Shared/NavBar';
import i18n from './Util/i18n';
import { React, Suspense, useState, useEffect } from 'react';
import LocaleContext from './Util/LocaleContext';
import AppRoutes, { ePage } from './Components/Shared/Routes';
import Loading from './Components/Shared/Loading';
import NavTitle from './Components/Shared/NavTitle';
import { eStorage, getStorage, getStorageJSON } from './Services/StorageService';
import Sidebar from './Components/Shared/Sidebar';
import { getAppVersion, getPoints } from './Components/DashboardBase/DashboardBase';
import ModalRecommendation from './Components/Shared/ModalRecommendation';
import Modal from './Components/Shared/Modal';
import ReactGA from 'react-ga'; import ReactPixel from 'react-facebook-pixel';
import { useLocation } from "react-router-dom";
import { getParams } from './Util/Util';
import ModalAgreement from './Components/Shared/ModalAgreement';
import ModalExpiration from './Components/Shared/ModalExpiration';
import ModalMoveOut from './Components/Shared/ModalMoveOut';
import ModalChangeAddress from './Components/Shared/ModalChangeAddress';
import ModalVersionApp from './Components/Shared/ModalVersionApp';
import { Capacitor } from '@capacitor/core';
import { eDevice } from './Components/Shared/Enum/EDevice';
import { eModal } from './Services/ModalService';
import { useNavigate } from 'react-router-dom';
import { eAccountType } from './Components/Shared/Enum/EAccountType';
import SidebarCommercial from './Components/Shared/SidebarCommercial';
import ModalAvatar from './Components/Shared/ModalAvatar';
// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import addNotification from 'react-push-notification';
import { Notifications } from 'react-push-notification';
// import { updateNotificationToken } from './Components/NotificationBase/NotificationBase';

function App() {
  const [locale, setLocale] = useState(i18n.language);
  const [isSession, setIsSession] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showBottomBar, setShowBottomBar] = useState(false);
  const [user, setUser] = useState({});
  const [fingerprint, setFingerprint] = useState({});
  const [address, setAddress] = useState({});
  const [points, setPoints] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [modalRecommendationOpen, setModalRecommendationOpen] = useState(false);
  const [modalAgreementOpen, setModalAgreementOpen] = useState(false);
  const [modalMoveOutOpen, setModalMoveOutOpen] = useState(false);
  const [modalVersionApp, setModalVersionApp] = useState(false);
  const [modalAvatar, setModalAvatar] = useState(false);
  const [ModalChangeAddressOpen, setModalChangeAddressOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [expirationModalOpen, setExpirationModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [language, setLanguage] = useState('');
  const [path, setPath] = useState('en');
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const [device, setDevice] = useState(eDevice.web);
  const navigate = useNavigate();
  const [company, setCompany] = useState({});
  const [locationActive, setLocationActive] = useState({});
  const [accountType, setAccountType] = useState(eAccountType.residential);
  const [imageSrc, setImageSrc] = useState(null);
  const [currentAvatar, setCurrentAvatar] = useState(null);

  // const firebaseConfig = {
  //   apiKey: "AIzaSyC9ZGYBqk-ur-Ma68Phdnfrfql0p7L983s",
  //   authDomain: "enerwisely.firebaseapp.com",
  //   projectId: "enerwisely",
  //   storageBucket: "enerwisely.appspot.com",
  //   messagingSenderId: "512129108373",
  //   appId: "1:512129108373:web:5467b2088a839500d28e9c"
  // };

  // Initialize Firebase
  // const app = initializeApp(firebaseConfig);
  // const messaging = getMessaging(app);

  // onMessage(messaging, (payload) => {
  //   console.log('Message received. ', payload);
  //   addNotification({
  //     title: payload.notification.title,
  //     subtitle: 'Enerwisely',
  //     message: payload.notification.body,
  //     theme: 'light',
  //     native: false,
  //     duration: 100000,
  //   });
  // });

  useEffect(() => {
    initPixel();
    initGA();
    if (window.innerWidth <= 711) {
      setDevice(eDevice.mobile);
    } else if (window.innerWidth >= 712 && window.innerWidth <= 960)
      setDevice(eDevice.tablet);
    else
      setDevice(eDevice.web);

    window.addEventListener('resize', (event) => {
      if (event.target.innerWidth <= 767)
        setDevice(eDevice.mobile);
      else if (window.innerWidth >= 712 && window.innerWidth <= 960)
        setDevice(eDevice.tablet);
      else
        setDevice(eDevice.web);
    });

    getVersion();

  }, []);



  useEffect(() => {
    let session = getStorage(eStorage.isSession);
    setIsSession(session);
    if (session && Object.keys(user)) {
      let user = getStorageJSON(eStorage.user);
      setUser(user);
      getData(user);

      let fingerprint = getStorageJSON(eStorage.fingerprint);
      if (fingerprint !== null)
        setFingerprint(fingerprint);

      let address = getStorageJSON(eStorage.address);
      if (address !== null)
        setAddress(address);

      let company = getStorageJSON(eStorage.company);
      if (company !== null)
        setCompany(company);

      let location = getStorageJSON(eStorage.location);
      if (location !== null)
        setLocationActive(location);

      let accountType = getStorage(eStorage.accountType);
      if (accountType !== null) {
        setAccountType(accountType);
      }

    }

    listenPath();
    window.scrollTo(0, 0)

  }, [location]);

   const handleLogin = () => {
  //   getToken(messaging, { vapidKey: "BPRcftsl1DiJyk1W5ftSdyd7bPxMEnXf63DQvm62kPukAVKgeHt4kIRtDoKN3o95y0eRlPQ6tm5J4sA6WPrmI90" }).then((currentToken) => {
  //     if (currentToken) {
  //       console.log(currentToken);
  //       let customer = getStorageJSON(eStorage.user);
  //       let requestId = getStorage(eStorage.requestId);
  //       updateNotificationToken(requestId, customer.customerId, currentToken).then(data => {

  //       }).catch(console.log);
  //     } else {
  //       console.log('No registration token available. Request permission to generate one.');
  //       // ...
  //     }
  //   }).catch((err) => {
  //     console.log('An error occurred while retrieving token. ', err);
  //     // ...
  //   });

   }

  const getData = (user) => {
    getPoints(user.customerId).then(data => {
      setPoints(data);
    }).catch(console.log);
  }

  const getVersion = (user) => {
    getAppVersion().then(data => {
      let platform = Capacitor.getPlatform();
      if ((platform == 'android' && Number(getParams().ANDROID_VERSION) < Number(data.android)) || (platform == 'ios' && Number(getParams().IOS_VERSION) < Number(data.ios)))
        setModalVersionApp(true);
    }).catch(console.log);
  }

  const listenPath = () => {
    let pathName = location.pathname;
    if (pathName !== path) {
      setPath(pathName);
      ReactGA.set({ page: pathName });
      ReactPixel.pageView();
    }

    if (pathName === ePage.electricInfo || pathName === ePage.creatingAgreement || pathName === ePage.acceptAgreement
      || pathName === ePage.downloading || pathName === ePage.electricityBill || pathName === ePage.selectPath || pathName === ePage.electricityBill
      || pathName === ePage.addLocation) {
      setShowSidebar(false);
      setShowBottomBar(false);
      setShowMenu(false);
    }
    else {
      setShowSidebar(true);
      setShowBottomBar(true);
      setShowMenu(true);
    }
  }

  const initPixel = () => {
    let advancedMatching = {};
    let options = {
      autoConfig: true,
      debug: false
    };

    ReactPixel.init(getParams().REACT_APP_FACEBOOK_PIXEL, advancedMatching, options);
  }

  const initGA = () => {
    ReactGA.initialize(getParams().REACT_APP_GATOKEN.trim());
  }

  const handleViewRecommendations = () => {
    let fingerprint = getStorageJSON(eStorage.fingerprint);
    let user = getStorageJSON(eStorage.user);
    let userType = 'BASIC';
    if (user !== undefined && user.type)
      userType = user.type.split('::')[0];

    if (userType === 'BASIC') {
      setModalType(eModal.moreBenefits);
      setModalOpen(true);
    } else if (userType !== 'BASIC' && (Object.keys(fingerprint).length === 0 || fingerprint.level === 'basic' || fingerprint.level === 'smart')) {
      setModalType(eModal.completeRecommendations);
      setModalOpen(true);
    } else {
      let criteria = getStorage(eStorage.criteria);
      if (criteria != null)
        navigate('/recommendations');
      else
        navigate('/select-recommendation');
    }
  };

  return (
    <div className='App'>
      <Notifications position='bottom-right' />
      <header className="App-header">
        <LocaleContext.Provider value={{ locale, setLocale }}>
          {isLoading && <Loading />}
          <Suspense fallback={<Loading />}>
            <ModalRecommendation open={modalRecommendationOpen} setIsLoading={setIsLoading} setModalOpen={setModalRecommendationOpen} />
            <Modal isSession={isSession} open={modalOpen} setModalOpen={setModalOpen} modalType={modalType} />
            <ModalExpiration open={expirationModalOpen} setModalOpen={setExpirationModalOpen} />
            <ModalAgreement open={modalAgreementOpen} setModalOpen={setModalAgreementOpen} user={user} isSession={isSession} />
            <ModalMoveOut open={modalMoveOutOpen} setModalOpen={setModalMoveOutOpen} user={user} />
            <ModalChangeAddress open={ModalChangeAddressOpen} setModalOpen={setModalChangeAddressOpen} user={user} />
            <ModalVersionApp open={modalVersionApp} setModalOpen={setModalVersionApp} />
            <ModalAvatar open={modalAvatar} isSession={isSession} setModalOpen={setModalAvatar} setAvatar={setCurrentAvatar} imageSrc={imageSrc} />
            <NavBar
              device={device}
              setIsLoading={setIsLoading}
              setLanguage={setLanguage}
              setUser={setUser}
              setIsSession={setIsSession}
              setMenuOpen={setMenuOpen}
              setModalAvatar={setModalAvatar}
              currentAvatar={currentAvatar}
              setImageSrc={setImageSrc}
              menuOpen={menuOpen}
              language={language}
              isSession={isSession}
              points={points}
              user={user}
              address={address}
              location={locationActive}
              company={company}
              accountType={accountType}
              setModalChangeAddressOpen={setModalChangeAddressOpen}
              showMenu={showMenu}
            />
            {accountType == eAccountType.commercial && isSession && <NavTitle setMenuOpen={setMenuOpen} title={title} />}
            <div className="row me-0">
              {isSession && showSidebar && accountType == eAccountType.residential && device === eDevice.mobile &&
              <Sidebar
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
              />}

              {isSession && showSidebar && accountType == eAccountType.commercial && <SidebarCommercial
                setModalRecommendationOpen={setModalRecommendationOpen}
                menuOpen={menuOpen}
                setMenuOpen={setMenuOpen}
                fingerprint={fingerprint}
                user={user}
                setExpirationModalOpen={setExpirationModalOpen}
                modalParameters={{ open: modalOpen, set: setModalOpen, setType: setModalType }}
                handleViewRecommendations={handleViewRecommendations} />}
              <main onClick={() => setMenuOpen(false)} className={accountType == eAccountType.commercial ? "col app-content-commercial" : "col app-content"}>
                <AppRoutes
                  setModalRecommendationOpen={setModalRecommendationOpen}
                  modalParameters={{ open: modalOpen, set: setModalOpen, setType: setModalType }}
                  email={email}
                  setEmail={setEmail}
                  setPoints={setPoints}
                  setIsSession={setIsSession}
                  session={isSession}
                  setUser={setUser}
                  user={user}
                  setTitle={setTitle}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  device={device}
                  setModalAgreementOpen={setModalAgreementOpen}
                  setModalMoveOutOpen={setModalMoveOutOpen}
                  setLanguage={setLanguage}
                  setFingerprint={setFingerprint}
                  fingerprint={fingerprint}
                  setShowSidebar={setShowSidebar}
                  points={points}
                  handleViewRecommendations={handleViewRecommendations}
                  accountType={accountType}
                  setLocation={setLocationActive}
                  setAccountType={setAccountType}
                  handleLogin={handleLogin} />
              </main>
              {/* {(device === eDevice.mobile || device === eDevice.tablet) && showBottomBar && isSession && <BottomNavigationBar
                fingerprint={fingerprint}
                user={user}
                modalParameters={{ open: modalOpen, set: setModalOpen, setType: setModalType
                handleViewRecommendations={handleViewRecommendations} }}
              />} */}
            </div>
          </Suspense>
        </LocaleContext.Provider>
      </header>
    </div>
  );
}

export default App;
