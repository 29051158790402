
import { eStorage, getStorage } from "../../Services/StorageService";
import { dataServiceApiInstance, orchestratorApiInstance } from "../../Util/Api";

export const getProfileData = (customerId) => {
    const response = dataServiceApiInstance.get(`/customer/profile/${customerId}`)
        .then(({ data }) => {

            return data;
        });
    return response;
}

export const getRepData = (rep) => {
    const response = dataServiceApiInstance.get(`/reps/${rep}`)
        .then(({ data }) => {

            return data;
        });
    return response;
}

export const getPlanData = (planId) => {
    const response = dataServiceApiInstance.get(`/plan/${planId}`)
        .then(({ data }) => {

            return data;
        });
    return response;
}

export const getLogsData = (customerId) => {
    let language = getStorage(eStorage.language);
    const response = dataServiceApiInstance.get(`customer/dataSharingLogs?customerId=${customerId}&lang=${language}`)
        .then(({ data }) => {

            return data;
        });
    return response;
}

export const updateProfile = (model) => {
    const response = dataServiceApiInstance.post(`customer/updateProfile`, model)
        .then(({ data }) => {
            return data;
        })
    return response;
}

export const updatePublicData = (model) => {
    const query = Object.keys(model)
        .map(k => `${k}=${model[k]}`)
        .join('&');
    const response = dataServiceApiInstance.post(`customer/updatePublicDataConsent?${query}`, model)
        .then(({ data }) => {
            return data;
        })
    return response;
}

export const updateDataSharing = (model) => {
    const query = Object.keys(model)
        .map(k => `${k}=${model[k]}`)
        .join('&');
    const response = dataServiceApiInstance.post(`customer/updateDataSharingConsent?${query}`, model)
        .then(({ data }) => {
            return data;
        })
    return response;
}

export const getCommPref = (customerId) => {
    const response = dataServiceApiInstance.get(`customer/comm_pref/${customerId}`)
        .then(({ data }) => {
            return data;
        })
    return response;
}

export const updateCommPref = (model, customerId) => {
    const response = dataServiceApiInstance.post(`customer/updateCommPref?customerId=${customerId}`, model)
        .then(({ data }) => {
            return data;
        })
    return response;
}

export const closeAccountService = (customerId, inputs) => {

    const response = dataServiceApiInstance.post('customer/delete/' + customerId, inputs)
        .then(({ data }) => {
            return data;
        }).catch((error) => {
            return error;
        });
    return response;
}


export const changePasswordService = (changePasswordParams) => {
    const response = orchestratorApiInstance.post('/auth/change_password', changePasswordParams)
        .then(({ data }) => {
            return data;
        })
        .catch(error => {
            console.log(error);
        });
    return response;
}


export const updatePhone = (requestId, customerId, phone) => {

    const response = dataServiceApiInstance.post(`customer/updatePhone?customerId=${customerId}&requestId=${requestId}&phone=${phone}`)
        .then(({ data }) => {

            return data;
        });
    return response;
}

export const updateUseNickname = (customerId, isUse) => {

    const response = dataServiceApiInstance.post(`customer/updateUseNickname?customerId=${customerId}&willUse=${isUse}`)
        .then(({ data }) => {

            return data;
        });
    return response;
}

export const getCardsData = (customerId) => {
    const response = orchestratorApiInstance.get(`subscription/cards/${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getSubscriptionData = (customerId) => {
    const response = orchestratorApiInstance.get(`subscription/customer/${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const postCard = (customerId, model) => {
    const response = orchestratorApiInstance.post(`subscription/card/${customerId}/`, model)
        .then(({ data }) => {

            return data;
        });
    return response;
}

export const deleteCardData = (customerId, cardId) => {
    const response = orchestratorApiInstance.post(`subscription/card/delete/${customerId}/${cardId}`)
        .then(({ data }) => {

            return data;
        });
    return response;
}

export const cancelSubscriptionData = (customerId) => {
    const response = orchestratorApiInstance.post(`subscription/cancel/${customerId}`)
        .then(({ data }) => {

            return data;
        });
    return response;
}

export const postDefaultCard = (customerId, cardId) => {
    const response = orchestratorApiInstance.post(`subscription/card/default/${customerId}/${cardId}`)
        .then(({ data }) => {

            return data;
        });
    return response;
}

export const saveAvatar = (customerId, base64) =>{
    const response = dataServiceApiInstance.post(`user/save-avatar`,{customerId, base64})
        .then(({ data }) => {
            return data;
        });
    return response;
}