/* eslint-disable react-hooks/exhaustive-deps */
import useImage from '../../Assets/Icons/energy-used.png';
import impactImage from '../../Assets/Icons/impact.png';
import moneyImage from '../../Assets/Icons/savings.png';
import downImage from '../../Assets/Icons/down-arrow.png';
import upImage from '../../Assets/Icons/up-arrow.png';
import MixedTooltip from "../shared/MixedTooltip";
import { React, useState } from 'react';
import { useTranslation } from "react-i18next";
import pollutionImage from '../../Assets/Icons/pollution.png';
import screenshotPeriodonImage from '../../Assets/Screenshot/screenshot-period.png';
import { eStorage, getStorageJSON } from '../../Services/StorageService';

function Savings(props) {
    const { t } = useTranslation();
    const [fingerprint] = useState(getStorageJSON(eStorage.fingerprint));

    return (
        <div className="container-card card-content h-100">
            {fingerprint === null && <img className="img-fluid" src={screenshotPeriodonImage}></img>}
            {fingerprint !== null &&
                <>
                    <h2 className="text-start title-section mb-0">{t('general.period-changes') + " " + props.periodName}</h2>
                    <div className="row d-flex align-items-center justify-content-center h-100">
                        <div className="col-12 col-md-6 h-md-100">
                            <div className='row d-flex align-items-center justify-content-center h-100'>
                                <div className='col-md-12 number-circle-cost pt-4'>
                                    <img className="img-fluid p-1 img-emission" src={moneyImage} alt="icon-savings" />
                                    <p className="large-legend text-center m-0">${props.currentView.differenceValue
                                        ? Math.round(props.currentView.differenceValue) : ''} <img className="icon-arrow" alt="icon-arrow" src={props.currentView.difference > 0 ? upImage : downImage} /></p>
                                    <h2>Cost <MixedTooltip title={t('tooltips.dashboard-savings')} /></h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-5 mt-2 mt-md-0">
                            <div className='row'>
                                <div className="col-md-12 col-6 container-card-gray">
                                    <div className='row d-flex align-items-center justify-content-center'>
                                        <div className='col-md-5'>
                                            <img className="img-fluid img-emission p-1" alt="icon-savings" src={impactImage} />
                                        </div>
                                        <div className='col-md-6'>
                                            <p className="large-legend-green text-center m-0">{props.currentView.treesDifference
                                                ? Math.round(props.currentView.treesDifference) : ''} <img className="icon-arrow" alt="icon-arrow" src={props.currentView.difference > 0 ? upImage : downImage} /></p>
                                            <h2>{t('dashboard.trees-needed')} <MixedTooltip title={t('tooltips.dashboard-treessaved')} />
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-6 mt-md-4 mt-0 container-card-gray">
                                    <div className='row d-flex align-items-center justify-content-center'>
                                        <div className='col-md-5'>
                                            <img className="img-fluid img-emission p-1" alt="icon-savings" src={pollutionImage} />
                                        </div>
                                        <div className='col-md-6'>
                                            <p className="large-legend-carbon text-center m-0">{props.currentView.co2Difference
                                                ? Math.abs(Math.round(props.currentView.co2Difference)) : ''} <img className="icon-arrow" alt="icon-arrow" src={props.currentView.difference > 0 ? upImage : downImage} /></p>
                                            <h2>{t('dashboard.co2-emission')} <MixedTooltip title={t('tooltips.dashboard-treessaved')} />
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default Savings;