
import { eStorage, getStorage } from "../../Services/StorageService";
import { dataServiceApiInstance } from "../../Util/Api";

export const getEnergyIntensity = (customerId) => {
    const response = dataServiceApiInstance.get(`actionplan/energy_intensity?customerId=${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getMinPlanDetails = () => {
    let requestId = getStorage(eStorage.requestId);
    const response = dataServiceApiInstance.get(`plan/minPlanDetails?request-id=${requestId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getKpis = (customerId) => {
    let requestId = getStorage(eStorage.requestId);
    const response = dataServiceApiInstance.get(`actionplan/kpis?customerId=${customerId}&requestId=${requestId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getFactorsData = (customerId) => {
    let requestId = getStorage(eStorage.requestId);
    let language = getStorage(eStorage.language);
    const response = dataServiceApiInstance.get(`/actionplan/factors?customerId=${customerId}&lang=${language}&requestId=${requestId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}
