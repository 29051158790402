
import { useTranslation } from 'react-i18next';
import { Dialog, Slide, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { forwardRef, useEffect } from 'react';
import imageMaintenance from "../../Assets/page-maintenance.png";
import { Link } from 'react-router-dom';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalOutage(props) {
    const { t } = useTranslation();

    useEffect(() => {
    }, []);

    const handleClose = () => {
        props.setModal({ open: false });
    };

    return (
        <Dialog open={props.modal.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'md'} keepMounted onClose={handleClose}>
            <DialogTitle className='text-center'>{t('general.outage-title')}</DialogTitle>
            <hr className='m-0' />
            <DialogContent>
                <div className="row justify-content-center">
                    <div className='col-md-6'>
                        <img alt="" className="img-fluid" src={imageMaintenance} />
                    </div>
                </div>
                <div className="row justify-content-center mt-2">
                    <div className='col-md-8'>
                        <h3 className='text-center'>EnerWisely is currently offline.  Somethings awesome is coming. Stay tuned.</h3>
                        <h3 className='text-center'>EnerWisely esta fuera de linea. Algo increíble esta por venir. Estén atentos.</h3>
                    </div>
                </div>
            </DialogContent>
            <hr className='mb-0 mt-2' />
            <DialogActions className='justify-content-md-center'>
                <Button className='btn-secondary me-1' onClick={handleClose}>
                    Close
                </Button>
                {/* <Button className='btn-primary' component={Link} to="/new-home-characteristics">{t('select-path.quick-match')}</Button> */}
            </DialogActions>
        </Dialog>
    );
}