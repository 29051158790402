import { chariotApiInstance } from "../../Util/Api";

export const getWaiveDepositOptions = () => {
    const response = chariotApiInstance.get(`/waiveDepositOptions`)
        .then(({ data }) => {

            return data;
        });
    return response;
}

export const getServiceTypeDate = (type, date, esiid) => {
    const response = chariotApiInstance.get(`/serviceTypeDate?moving=${type}&serviceDate=${date}&esiid=${esiid}`)
        .then(({ data }) => {

            return data;
        });
    return response;
}