import { dataServiceApiInstance, smartDeviceApiInstance } from "../../Util/Api";


export const postToken = (model) => {
    const response = dataServiceApiInstance.post(`token/`, model)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const postDevice = (model) => {
    const response = dataServiceApiInstance.post(`smart-devices/customer`, model)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getDevicesGoogle = (customerId) => {
    const response = smartDeviceApiInstance.get(`google/${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getDevicesResideo = (customerId) => {
    const response = smartDeviceApiInstance.get(`resideo/${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}


export const getGoogleCredentials = (customerId) => {
    const response = dataServiceApiInstance.get(`google/credentials/${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getDevices = (customerId) => {
    const response = dataServiceApiInstance.get(`smart-devices/customer/${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getDevice = (customerId, deviceId, type ) => {
    const response = smartDeviceApiInstance.get(`${type}/${customerId}/${deviceId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const postCommand = (model, type) => {
    const response = smartDeviceApiInstance.post(`${type}/command`, model)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const postValues = (model) => {
    const response = dataServiceApiInstance.post(`smart-devices/limit`, model)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getEvents = (id) => {
    console.log(id);
    const response = dataServiceApiInstance.get(`smart-devices/events/${id}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}