import { dataServiceApiInstance } from "../../Util/Api";

export const postLike = (model) => {
    const response = dataServiceApiInstance.post(`like/`, model)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const deleteLike = (model) => {
    const response = dataServiceApiInstance.post(`like/delete`, model)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const postComment = (model) => {
    const response = dataServiceApiInstance.post(`comment/`, model)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getCommentsData = (itemId) => {
    const response = dataServiceApiInstance.get(`comment/${itemId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}