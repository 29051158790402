import { rhythmApiInstance } from "../../Util/Api";

export const postOrder = (model) => {
    const response = rhythmApiInstance.post(`/`, model)
        .then(({ data }) => {

            return data;
        });
    return response;
}

export const getServiceStartDates = (rep, uid) => {
    return rhythmApiInstance.get(`/${rep}/${uid}`)
    .then(({data})=>data);
}