/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useRef, useState } from 'react';
import { TextField, Select, FormControl, InputLabel, Checkbox, MenuItem, Button, InputAdornment, IconButton } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { MuiTelInput } from 'mui-tel-input';
import { eStorage, getStorage } from "../../Services/StorageService";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


function UserInfo(props) {
    const { t } = useTranslation();
    const formRef = useRef();
    const [elementsChecked, setElementsChecked] = useState(0);
    const [showPhoneError, setShowPhoneError] = useState(false);
    const [password, setPassword] = useState("password");

    useEffect(() => {
        if (props.user.phone === undefined)
            props.setUser(prevState => ({
                ...prevState, ['phone']: '+1'
            }));

        props.setUser(prevState => ({
            ...prevState, ['language']: getStorage(eStorage.language)
        }));

    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        props.setUser(prevState => ({
            ...prevState, [name]: value
        }));
    }

    const handleChangePhone = (event) => {
        setShowPhoneError(true);
        if (event.length <= 2) {
            props.setUser(prevState => ({
                ...prevState, ['phone']: '+1'
            }));
        }
        else
            props.setUser(prevState => ({
                ...prevState, ['phone']: event
            }));
    }

    const handleChangeDate = (value) => {
        props.setUser(prevState => ({
            ...prevState, ['birthdate']: value
        }));
    };

    const handleChangeCheck = (event) => {
        const { checked } = event.target;
        const check = checked ? elementsChecked + 1 : elementsChecked - 1;
        setElementsChecked(check);
    }

    const handleSubmit = () => {
        setShowPhoneError(true);
        if (props.user.phone.length === 15) {
            props.setActiveStep(props.activeStep + 1);
        }
    }

    const handleBackStep = () => {
        props.setActiveStep(props.activeStep - 1);
    };


    const handleClickShowPassword = () => {
        setPassword(password === 'password' ? 'text' : 'password');
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div>
            <ValidatorForm
                ref={formRef}
                onSubmit={handleSubmit}
                onError={errors => console.log(errors)}
            >
                <div className="row justify-content-md-center">
                    <h2 className="mb-3">{t('general.identity-verification')}</h2>
                    <div className="col-md-5">
                        <TextValidator fullWidth size="small" name="name" value={props.user.name} label={t('general.name')} disabled={true} />
                    </div>
                    <div className="col-md-5 mt-2 mt-md-0">
                        <TextValidator fullWidth size="small" name="lastName" value={props.user.lastName} label={t('general.last-name')} disabled={true} />
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-5 mt-2">
                        <MuiTelInput preferredCountries={['US']} helperText={props.user.phone?.length !== 15 && showPhoneError ? "Tel is invalid" : ""}
                            error={props.user.phone?.length !== 15 && showPhoneError} onlyCountries={['US']} forceCallingCode={true} fullWidth size="small" name="phone" value={props.user.phone === undefined ? '+1' : props.user.phone} onChange={handleChangePhone} label={t('general.cell-phone')} />
                    </div>
                    <div className="col-md-5 mt-2">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker label={t('general.date-birth')} value={props.user.birthdate} onChange={handleChangeDate} renderInput={(params) => <TextValidator style={{ width: '100%' }} size="small" {...params} />} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.date-birth'))]} />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <div className="col-md-5 mt-2">
                        <TextValidator type={password} fullWidth size="small" name="ssn" maxlength="9" length="9" value={props.user.ssn} onChange={handleChange} label={t('general.security-number')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.security-number'))]} />
                        <InputAdornment className="password-eye d-flex justify-content-end" position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                                {password === 'password' ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    </div>
                    <div className="col-md-5 mt-2">
                        <FormControl size="small" fullWidth>
                            <InputLabel id="preferred-language">{t('general.preferred-language')}</InputLabel>
                            <Select labelId="preferred-language" required name="language" value={props.user.language === undefined ? 'en' : props.user.language} onChange={handleChange} label={t('general.preferred-language')}>
                                <MenuItem value={'es'}>{t('general.spanish')}</MenuItem>
                                <MenuItem value={'en'}>{t('general.english')}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                {props.agreements?.map((item, index) => (
                    <div key={index} className="row mt-3 justify-content-md-center">
                        <div className="col-2 col-md-1">
                            <Checkbox name={item.name} onChange={handleChangeCheck} />
                        </div>
                        <div className="col-10 col-md-8">
                            <TextField fullWidth size="small" className="text-terms" multiline rows={3} defaultValue={item.description} label={t('general.terms')} />
                        </div>
                    </div>
                ))}

                <div className="row mt-4">
                    <div className="col-md-12">
                        {props.activeStep > 0 && <Button className='btn-secondary me-2' onClick={handleBackStep}>{t('general.back')}</Button>}
                        {props.activeStep < 3 && props.agreements && <Button className='btn-primary' disabled={props.agreements.length !== elementsChecked} type="submit">{t('general.next')}</Button>}
                        {props.activeStep < 4 && !props.agreements && <Button className='btn-primary' type="submit">{t('general.next')}</Button>}
                        {props.activeStep === 3 && <Button className='btn-primary' disabled={props.agreements.length === elementsChecked ? 'true' : 'false'} type="submit">{t('general.continue')}</Button>}
                    </div>
                </div>
            </ValidatorForm>
        </div>
    )
}

export default UserInfo;