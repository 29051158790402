import { eAction, eCategory, eEvent, submitTrackingEvent } from "../../Services/EventService";
import { eStorage, getStorage, saveLoginCommercialStorage, saveLoginStorage } from "../../Services/StorageService";
import { dataServiceApiInstance, orchestratorApiInstance } from "../../Util/Api";
import { eAccountType } from "../Shared/Enum/EAccountType";
import { ePage } from "../Shared/Routes";

export const postLogin = (model) => {

    let accountType = getStorage(eStorage.accountType);
    let url = accountType === eAccountType.commercial ? 'V2/auth/login' : 'auth/login';

    const response = orchestratorApiInstance.post(url, model)
        .then(({ data }) => {
            let result = {};
            if (data?.status !== 'failed') {
                if (data?.userInfo?.companyId) {
                    saveLoginCommercialStorage(data);
                }
                else
                    saveLoginStorage(data);
                result.page = getPageRedirect(data);
            }
            submitTrackingEvent(eEvent.login, eCategory.account, eAction.other);
            result.language = data.preferredLang;
            result.status = data.status;
            return result;
        }).catch(console.log);
    return response;
}

export const getRecover = async (model) => {

    try {
        let urlBase = window.location.protocol + '//' + window.location.host;
        const response = await orchestratorApiInstance.get(`auth/getResetPassword?email=${model.email.toLowerCase()}&baseUrl=${urlBase}`);
        submitTrackingEvent(eEvent.forgotPassword, eCategory.account, eAction.other);
        return response.data;
    } catch (error) {
        console.log(error);
        return new Error(error.message);
    }
}

export const getRecoverByPhone = async (phone) => {

    try {
        let urlBase = window.location.protocol + '//' + window.location.host;
        const response = await orchestratorApiInstance.get(`auth/getResetPasswordByPhone?phone=${phone}`);
        submitTrackingEvent(eEvent.forgotPassword, eCategory.account, eAction.other);
        return response.data;
    } catch (error) {
        console.log(error);
        return new Error(error.message);
    }
}

export const validateCode = async (code) => {
    try {
        const response = await orchestratorApiInstance.post('auth/codeValidate?code=' + code);
        if (response.status === 200) {
            return response.data;
        }
    } catch (error) {
        return new Error(error.message);
    }
}

export const sendResetPassword = async (model) => {
    try {
        const response = await orchestratorApiInstance.post('auth/reset_password', model);
        if (response.status === 200) {
            return response.data;
        }
        return 'serverError';

    } catch (error) {
        return new Error(error.message);
    }
}

export const sendResetPasswordByPhone = async (model) => {
    try {
        const response = await orchestratorApiInstance.post('auth/reset_password_phone', model);
        if (response.status === 200) {
            return response.data;
        }
        return 'serverError';

    } catch (error) {
        return new Error(error.message);
    }
}

const getPageRedirect = (data) => {
    let accountType = getStorage(eStorage.accountType);
    let page = ePage.selectPath;

    const redirectUrl = localStorage.getItem("redirectUrl");
    if (redirectUrl) {
        localStorage.removeItem("redirectUrl");
        return redirectUrl;
    }else if (data.agreement_status === 'Pending - Customer Authorization')
        page = ePage.acceptAgreement;
    else if (accountType === eAccountType.commercial && data.location !== null)
        page = ePage.dashboard;
    else if (data.userInfo.type !== 'BASIC::BROWSE' && accountType === eAccountType.residential)
        page = ePage.home;
    else if (accountType === eAccountType.commercial && data.location === null)
        page = ePage.addLocation;

    return page;
}

export const updateLanguageData = (customerId, language) => {
    const response = dataServiceApiInstance.post(`customer/updatePreferredLang?customerId=${customerId}&lang=${language}`)
        .then(({ data }) => {
            return data;
        }).catch(console.log);
    return response;
}

export const getProfile = (customerId) => {
    const response = dataServiceApiInstance.get(`/customer/profile/${customerId}`)
        .then(({ data }) => {
            return data;
        }).catch(console.log);
    return response;
}

export const loginAccountType = (email) => {
    let emailFormat = encodeURIComponent(email.toLowerCase());
    const response = orchestratorApiInstance.post(`V2/auth/accountType/${emailFormat}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getCustomersAddress = () => {
    const response = dataServiceApiInstance.get(`/customer/address`)
        .then(({ data }) => {
            return data;
        }).catch(console.log);
    return response;
}


export const updateCoordinates = async (model) => {
    try {
        const response = await dataServiceApiInstance.post('customer/coordinates', model);
        if (response.status === 200) {
            return response.data;
        }
        return 'serverError';

    } catch (error) {
        return new Error(error.message);
    }
}