/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Stack, Stepper, Step, StepLabel } from '@mui/material';
import QontoStepIcon from "../../Components/Shared/Stepper";
import { React, useEffect, useState } from 'react';
import StepConnectorCustom from "../../Components/Shared/StepConnector";
import Info from "./Info";
import Goal from "./Goal";
import { getGoalsByLocation, upsertGoals } from "../../Components/goalBase/goalBase";
import { ePage } from "../../Components/Shared/Routes";
import { useNavigate } from "react-router-dom";
import { addPropertyStorage, eStorage, getStorageJSON } from "../../Services/StorageService";

const steps = ['Info', 'Step 1', 'Step 2', 'Step 3'];

function AddLocation(props) {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [model, setModel] = useState({});
  const [apiGoals, setApiGoals] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const navigate = useNavigate();
  const [goals, setGoals] = useState({
    carbon: 5,
    energy: 5,
    bill: 5
  });

  useEffect(() => {
    let location = getStorageJSON(eStorage.location);
    if (location?.id) {
      setLocationId(location.id);
      getApiGoals(location.id);
    }
    // if (location.esiid)
    //   setActiveStep(1);
  }, []);

  useEffect(() => {
    switch (activeStep) {
      case 0:
        props.functions.setTitle(t('general.add-location'));
        break;
      case 1: case 2: case 3:
        props.functions.setTitle(t('goals.set-goals'));
        break;
      default: break
    }

  }, [activeStep]);

  const getApiGoals = (locationId) => {
    props.functions.setIsLoading(true);
    getGoalsByLocation(locationId).then(data => {
      updateValues(data);
    }).finally(() => props.functions.setIsLoading(false))
  }

  const handleChange = (value) => {

    setGoals(prevState => ({
      ...prevState, [activeStep === 1 ? 'energy' : activeStep === 2 ? 'carbon' : 'bill']: value
    }));
  }

  const handleSubmit = () => {
    let location = getStorageJSON(eStorage.location);
    const model = Object.keys(goals).map(key => {
      return {
        id: null,
        locationId: location.id,
        percentage: goals[key],
        status: true,
        type: key.toUpperCase(),
      }
    });
    sendGoals(model);
  }

  const sendGoals = (model) => {
    props.functions.setIsLoading(true);
    upsertGoals(model).then(data => {
      addPropertyStorage(eStorage.location, eStorage.goals, data);
      updateValues(data);
      navigate(ePage.creatingAgreement);
    }).finally(() => props.functions.setIsLoading(false))
  }

  const updateValues = (data) => {
    if (data && Array.isArray(data)) {
      setApiGoals(data);
      data.forEach((item) => {
        setGoals(prevState => ({
          ...prevState, [item.type.toLowerCase()]: item.percentage
        }));
      });
    }
  }

  return (
    <div className="content">
      {activeStep === 0 && <Info model={model} setModel={setModel} functions={props.functions} setActiveStep={setActiveStep} setLocationId={setLocationId} />}
      {activeStep === 1 && <Goal type='energy' goals={goals} handleChange={handleChange} goalValue={5} functions={props.functions} setActiveStep={setActiveStep} activeStep={activeStep} locationId={locationId} />}
      {activeStep === 2 && <Goal type='carbon' goals={goals} handleChange={handleChange} goalValue={5} functions={props.functions} setActiveStep={setActiveStep} activeStep={activeStep} locationId={locationId} />}
      {activeStep === 3 && <Goal type='bill' goals={goals} handleChange={handleChange} goalValue={5} functions={props.functions} setActiveStep={setActiveStep} activeStep={activeStep} locationId={locationId} handleSubmit={handleSubmit} />}
      <Stack className="mt-4" sx={{ width: '100%' }}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnectorCustom />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </div>
  )
}

export default AddLocation;