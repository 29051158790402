/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState, useRef } from 'react';
import Address from "../../Components/Shared/Address";
import { MenuItem, FormControl, InputLabel, Select, Button } from '@mui/material';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { getEsiid } from "../../Components/IntegrationBase/IntegrationBase";

function AddressInfo(props) {
    const { t } = useTranslation();
    const [showAllAddress, setShowAllAddress] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [houseType, setHouseType] = useState('');
    const formRef = useRef();

    useEffect(() => {
        setHouseType('house');
        if (props.address !== null && props.address !== undefined && props.address?.address !== undefined && props.address?.address !== null) {
            setShowAllAddress(true);
            setDisabled(true);
            props.setAddress(prevState => ({
                ...prevState, ['state']: 'TX'
            }));
            if (props.address.houseType === undefined)
                props.setAddress(prevState => ({
                    ...prevState, ['houseType']: houseType
                }));
        }
    }, []);

    useEffect(() => {

    }, [props.address]);

    const handleChangeHouseType = (event) => {
        props.setAddress(prevState => ({
            ...prevState, ['houseType']: event.target.value
        }));
    }

    const handleSubmit = () => {

        props.functions.setIsLoading(true);
        let model = { address: props.address };
        model.address.houseType = houseType;

        getEsiid(model).then(data => {
            if (data.length === 1) {
                props.setActiveStep(2);
                props.setActiveStep(props.activeStep + 1);
            } else if (data.length > 1){
                props.setActiveStep(2);
                props.setActiveStep(props.activeStep + 1);
            }
            props.functions.setIsLoading(false);
        });
    }

    const handleBackStep = () => {
        props.setActiveStep(props.activeStep - 1);
    };

    return (
        <div>
            <ValidatorForm
                ref={formRef}
                onSubmit={handleSubmit}
                onError={errors => console.log(errors)}
            >
                <h2 className="mb-3">{t('general.service-address')}</h2>
                {!showAllAddress &&
                    <div className="row mt-2 justify-content-md-center">
                        <div className="col-md-5">
                            <FormControl size="small" fullWidth>
                                <InputLabel id="type-housing">{t('general.type-housing')}</InputLabel>
                                <Select labelId="type-housing" required name="houseType" value={houseType} defaultValue={houseType} onChange={handleChangeHouseType} label={t('general.type-housing')}>
                                    <MenuItem value='house'>{t('general.house')}</MenuItem>
                                    <MenuItem value='townhouse'>{t('general.town-house')}</MenuItem>
                                    <MenuItem value='apto'>{t('general.apto')}</MenuItem>
                                    <MenuItem value='mobile'>{t('general.mobile-home')}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                }
                <div className="row mt-2 justify-content-md-center">
                    <div className="col-md-5">
                        <Address disabled={disabled} showAllAddress={showAllAddress} setAddress={props.setAddress} address={props.address} />
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-12">
                        {props.activeStep > 0 && <Button className='btn-secondary me-2' onClick={handleBackStep}>{t('general.back')}</Button>}
                        {props.activeStep < 3 && <Button className='btn-primary' type="submit">{t('general.next')}</Button>}
                    </div>
                </div>
            </ValidatorForm>
        </div>
    )
}

export default AddressInfo;