/* eslint-disable react-hooks/exhaustive-deps */
import useImage from '../../../Assets/Icons/energy-used.png';
import impactImage from '../../../Assets/Icons/enviromental-impact.png';
import moneyImage from '../../../Assets/Icons/money-savings.png';
import downImage from '../../../Assets/Icons/down-arrow.png';
import upImage from '../../../Assets/Icons/up-arrow.png';
import pollutionImage from '../../../Assets/Icons/pollution.png';
import MixedTooltip from "../../shared/MixedTooltip";
import { React, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { lbsToTonnes } from '../../../Util/Util';

function Savings(props) {
    const { t } = useTranslation();

    useEffect(() => {
 
    }, []);

        const format = (number, fraction = 0) => {
        const format = !number || isNaN(number) ? 0 : number;
        return format.toLocaleString('en-US', { maximumFractionDigits: fraction })
    }

    return (
        <div className="row">
            <div className="col-6 col-md-12 col-lg-6 col-sm-4 mt-lg-2">
                <img className="icon-savings" src={moneyImage} alt="icon-savings" />
                <h5 className="text-center m-0">${props.currentView.differenceValue
                    ? Math.round(props.currentView.differenceValue) : ''} <img className="icon-arrow" alt="icon-arrow" src={props.currentView.difference > 0 ? upImage : downImage} /></h5>
                <h3>{t('dashboard.cost')} <MixedTooltip title={t('tooltips.dashboard-savings')} />
                </h3>
            </div>
            <div className="col-6 col-md-12 col-lg-6 col-sm-4 mt-sm-1 mt-lg-2">
                <img className="icon-savings" alt="icon-savings" src={useImage} />
                <h5 className="text-center m-0">{props.currentView.difference
                    ? Math.abs(Math.round(props.currentView.difference)) : ''} kWh <img className="icon-arrow" alt="icon-arrow" src={props.currentView.difference > 0 ? upImage : downImage} /></h5>
                <h3>{t('dashboard.energy-used')} <MixedTooltip title={t('tooltips.dashboard-energyused')} />
                </h3>
            </div>
            <div className="col-6 col-md-12 col-lg-6 col-sm-4 mt-sm-1 mt-lg-4 mt-lg-4">
                <img className="icon-savings" alt="icon-savings" src={impactImage} />
                <h5 className="text-center m-0">{props.currentView.treesDifference
                    ? Math.round(props.currentView.treesDifference) : ''} <img className="icon-arrow" alt="icon-arrow" src={props.currentView.difference > 0 ? upImage : downImage} /></h5>
                <h3>{t('dashboard.trees-needed')} <MixedTooltip title={t('tooltips.dashboard-treessaved')} />
                </h3>
            </div>
            <div className="col-6 col-md-12 col-lg-6 col-sm-4 mt-sm-1  mt-lg-4">
                <img className="icon-savings" alt="icon-savings" src={pollutionImage} />
                <h5 className="text-center m-0">{props.currentView.co2Difference
                    ? format(Math.abs(lbsToTonnes(props.currentView.co2Difference)),2): ''} <span className='text-lowercase'>tonnes</span> <img className="icon-arrow" alt="icon-arrow" src={props.currentView.difference > 0 ? upImage : downImage} /></h5>
                <h3>{t('dashboard.co2-emission')} <MixedTooltip title={t('tooltips.dashboard-treessaved')} />
                </h3>
            </div>
        </div>
    )
}

export default Savings;