/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState, forwardRef, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from "react-i18next";
import { closeAccountService } from '../../Components/ProfileBase/ProfileBase';
import { eStorage, getStorageJSON } from '../../Services/StorageService';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Slide } from '@mui/material';
import { TextField } from '@mui/material';
import { Switch } from '@mui/material';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalCloseAccount(props) {
  const { t } = useTranslation();
  const [user] = useState(getStorageJSON(eStorage.user));
  const [address] = useState(getStorageJSON(eStorage.address));
  const [password, setPassword] = useState('');
  const [closeCheckbox, setCloseCheckbox] = useState(false);

  useEffect(() => {
  }, []);

  const handleClose = () => {
    props.setModalOpen(false);
  };

  const handlePassword = (event) => {
    const { value } = event.target;
    setPassword(value);
  }

  const handleCheck = (event) => {
    const { checked } = event.target;
    setCloseCheckbox(checked);
  }

  const handleCloseAccount = () => {
    const inputs = {
      credentials: password,
      emails: props.checks.emails,
      value: props.checks.value,
      privacy: props.checks.privacy,
      unwanted: props.checks.unwanted,
      reason: props.reason,
    }

    closeAccountService(user.customerId, inputs)
    .then((data)=>{
      if (data === 'ok') {
       localStorage.clear();
       window.location = '/';
      } else {
        Notify.failure(t('closeAccount.error'), { position: 'center-bottom', });
      }
    })
    .catch(()=>{
      Notify.failure(t('closeAccount.error'), { position: 'center-bottom', });
    })
  }

  return (
    <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'md'} keepMounted onClose={handleClose}>
      <DialogTitle className='text-center'>{user.name} {user.lastName}</DialogTitle>
      <hr className='m-0' />
      <DialogContent>
        <div className='row mt-2 justify-content-md-center'>
          <div className='col-md-10'>
            <div>
              <div className="closemodal" style={{ padding: '40px' }}>
                {t('general.address')}{address != null && <p className="body"> {address.address + ", " + address.city + ", " + address.state + ", " + address.zipCode} </p>}
                <br />
                <p className="body">
                  {t('closeAccount.passwordConfirm')}
                </p><br />
                <div style={{ display: 'flex' }}>
                  <div><label>{t('closeAccount.password')}: </label> </div>

                  <TextField fullWidth size="small" type="password" defaultValue={password} label={t('general.password')} onChange={handlePassword} />
                </div>

                <div>
                  <Switch onChange={handleCheck} /> {t('closeAccount.unsubscribe')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <hr className='mb-0 mt-2' />
      <DialogActions className='justify-content-md-center'>
        <Button className='btn-secondary' onClick={handleClose}>{t('general.close')}</Button>
        <Button className='btn-secondary' disabled={!password || !closeCheckbox ? true : false} onClick={handleCloseAccount}>{t('closeAccount.closeAccount')}</Button>
      </DialogActions>
    </Dialog>
  );
}