/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef } from 'react';
import avatar from '../../Assets/avatar.png';
import { Button } from '@mui/material';
import { green, red, yellow } from '@mui/material/colors';
import { eLevel } from "../../Services/LevelService";
import memberImage from '../../Assets/Badges/EnerWisely-Badges_Member Status.svg';
import bronzeImage from '../../Assets/Badges/EnerWisely-Badges_Bronze Status.svg';
import silverImage from '../../Assets/Badges/EnerWisely-Badges_Silver Status.svg';
import goldImage from '../../Assets/Badges/EnerWisely-Badges_Gold Status.svg';
import platinumImage from '../../Assets/Badges/EnerWisely-Badges_Platinum Status.svg';
import { getBadgesData, getBadgesPublications, getLevel, getPointsData } from "../../Components/AchievementsBase/AchievementsBase";
import { eStorage, getStorageJSON } from "../../Services/StorageService";
import { getBestsData, getHistoricData } from "../../Components/RankingBase/RankingBase";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { getParams } from "../../Util/Util";
import { Link } from 'react-router-dom';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import TaskIcon from '@mui/icons-material/Task';
import downImage from '../../Assets/Icons/down-arrow.png';
import upImage from '../../Assets/Icons/up-arrow.png';
import { useNavigate } from 'react-router-dom';
import ProgressBar from "../../Components/Shared/ProgressBar";
import CurrrentRanking from "./CurrentRanking.";
import Publication from "./Publication";
import { ePeriod, getPeriodData, progressPergentage } from "../../Components/DashboardBase/DashboardBase";
import { getGoalsByCustomer } from "../../Components/goalBase/goalBase";
import { getCustomersAddress, updateCoordinates } from "../../Components/AuthBase/AuthBase";
import { getGeocode } from "use-places-autocomplete";
import thermostatImage from '../../Assets/Icons/thermostat.png';
import smartHomeImage from '../../Assets/Icons/smart-home.png';
import { getDevices } from "../../Components/DeviceBase/DeviceBase";

function Home(props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [avatarUrl, setAvatarUrl] = useState('');
    const [level, setLevel] = useState('');
    const [points, setPoints] = useState('');
    const [user] = useState(getStorageJSON(eStorage.user));
    const [historicPosition, setHistoricPosition] = useState([]);
    const [badges, setBadges] = useState([]);
    const [allBadges, setAllBadges] = useState([]);
    const [modal, setModal] = useState({ open: false });
    const [bests, setBests] = useState([]);
    const [publications, setPublications] = useState([]);
    const [periodData, setPeriodData] = useState({});
    const [allPublications, setAllPublications] = useState([]);
    const [lastElement, setLastElement] = useState(null);
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        props.functions.setTitle(t('home.title'));
        getData();
        generateUrlAvatar();
        getPeriodDataApi();
    }, []);

    const generateCoordinates = () => {
        getCustomersAddress().then(data => {
            {
                data.map((item, index) => {
                    getGeocode({ address: item.address }).then((results) => {

                        let model = {
                            latitude: results[0].geometry.location.lat(),
                            longitude: results[0].geometry.location.lng(),
                            id: item.id
                        }
                        updateCoordinates(model).then(data => {
                            console.log(data);
                        });
                    });
                })
            }

        });

    }

    useEffect(() => {
        if (props.currentAvatar) {
            setAvatarUrl(props.currentAvatar);
        }
        generateUrlAvatar();
    }, [props.currentAvatar]);

    const observer = useRef(
        new IntersectionObserver((entries) => {
            const first = entries[0];
            if (first.isIntersecting) {
                const button = document.getElementById('nextButton');
                if (button) {
                    button.click();
                }
            }
        })
    );

    useEffect(() => {
        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    const getPeriodDataApi = () => {
        //kwh
        Promise.all([getGoalsByCustomer(user.customerId), getPeriodData(user.customerId, ePeriod.lastMonthVSPrevious, 'dollars')])
            .then((resp) => {
                setPeriodData(resp[1]);
                const carbon = resp[0].find(f => f.type == "CARBON" && f.percentage > 0)?.percentage ?? 0;
                const energy = resp[0].find(f => f.type == "ENERGY" && f.percentage > 0)?.percentage ?? 0;
                const bill = resp[0].find(f => f.type == "BILL" && f.percentage > 0)?.percentage ?? 0;
            })
            .catch(console.log)
    }

    const getData = () => {
        props.functions.setIsLoading(true);
        getPointsData(user.customerId).then(data => {
            setLevel(getLevel(data));
            setPoints(data);
            props.functions.setIsLoading(false);
        });

        getHistoricData(user.customerId).then(data => {

            if (data !== '' && data !== undefined) {
                setHistoricPosition(data);
            }
            else {
                setHistoricPosition([{ position: 17 }]);
            }
        });

        getBadgesData(user.customerId).then(data => {
            setBadges(data);
        });

        getBestsData().then(data => {
            setBests(data);
        });

        getBadgesPublications(user.customerId, 1, 10).then(data => {
            setAllPublications(data.data);
            setPublications([data.data[0]]);
        });

        getDevices(user.customerId).then(data => {
            setDevices(data);
        });
    }

    const generateUrlAvatar = () => {
        const user = getStorageJSON(eStorage.user);
        if (user && Object.keys(user).length > 0 && user.avatar) {
            const url = `${getParams().DATA_SERVICE_BASEURL}user/avatar/${user.avatar}`;
            setAvatarUrl(url);
        } else
            setAvatarUrl(avatar);
    }

    const hasBadge = (badge) => {
        const found = badges.findIndex(f => f.badge === badge.badge);
        return found >= 0;
    }

    const viewDetail = (badge, isObtained) => {
        const found = allBadges.find(f => f.badge === badge.badge);
        setModal({ open: true, badge: found, isObtained: isObtained });
    }

    const buildTableGoals = (energy, carbon, bill, periodDataList) => {
        return {
            energy: calculateEnergy(energy, periodDataList),
            carbon: calculateCarbon(carbon, periodDataList),
            cost: calculateCost(bill, periodDataList)
        };
    };

    const calculateEnergy = (energy, periodLocation) => {
        let total = 0;
        if (periodLocation) {
            total = progressPergentage(periodLocation?.lastMonth_total ?? 0, periodLocation?.previousMonth_total ?? 0, energy);
        }
        return {
            value: total ?? 0,
            color: getColor(total)
        }
    }

    const calculateCarbon = (carbon, periodLocation) => {
        let total = 0;
        if (periodLocation) {
            total = progressPergentage(periodLocation?.co2 ?? 0, periodLocation?.co2_previous ?? 0, carbon);
        }
        return {
            value: total,
            color: getColor(total)
        }
    }

    const calculateCost = (cost, periodLocation) => {
        let total = 0;
        if (periodLocation) {
            total = progressPergentage(periodLocation?.lastMonth_value ?? 0, periodLocation?.previousMonth_value ?? 0, cost);
        }
        return {
            value: total,
            color: getColor(total)
        }
    }

    const getColor = (valor) => {
        if (valor < 60) {
            return red['700'];
        }
        if (valor > 80) {
            return green['A700'];
        }
        return yellow['500'];
    }

    const handledIncreased = () => {
        if (allPublications.length > 0 && publications.length > 0) {
            props.functions.setIsLoading(true);
            setPublications([...publications, ...allPublications.slice(2)]);
            props.functions.setIsLoading(false);
        }
    }

    const handleOpenDevice = (id, type) => {
        navigate(`/device/thermostat/${id}/${type}`);
    };

    return (
        <div className="content">
            <div className='container'>
                <div className="row justify-content-md-center">
                    <div className="col-md-5">
                        <div className="container-card card-content">
                            <div className="row">
                                <div className="col-md-2">
                                    <img className="avatar" src={avatarUrl} alt="avatar" onError={(e) => { e.target.src = avatar }} />
                                </div>
                                <div className="col-md-10 text-md-start text-center">
                                    <h2 className="align-middle mt-2 mb-0">{user.name} {user.lastName}</h2>
                                    <h3>{points} {t('home.points')}</h3>
                                </div>
                            </div>
                            <hr className="mt-1 mb-1" />
                            <div className="row">
                                <div className="col-md-12">
                                    <ProgressBar currentLevel={level} name={level} />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-6">
                                    {historicPosition.length > 0 &&
                                        <p className="large-legend">{historicPosition[historicPosition.length - 1]?.position}</p>
                                    }
                                    <h3>{t('home.position')}</h3>
                                </div>
                                <div className="col-md-6">
                                    {level === eLevel.newSaver && <img src={memberImage} className="icon-badge-home" alt="member" />}
                                    {level === eLevel.bronze && <img src={bronzeImage} className="icon-badge-home" alt="money" />}
                                    {level === eLevel.silver && <img src={silverImage} className="icon-badge-home" alt="money" />}
                                    {level === eLevel.gold && <img src={goldImage} className="icon-badge-home" alt="money" />}
                                    {level === eLevel.platinum && <img src={platinumImage} className="icon-badge-home" alt="money" />}
                                    <h3>{t('dashboard.youre-level').replace('#LEVEL#', level)}</h3>
                                </div>
                            </div>
                            <Button className='btn-primary' component={Link} to="/win-prize">{t('home.recommended')}</Button>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <div className="container-card card-content">
                                    <h2 className="text-start">{t('home.ranking')}</h2>
                                    <p className="description-text text-start mb-3">{t('home.congratulate')}</p>
                                    <CurrrentRanking functions={props.functions} bests={bests} />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <div className="container-card card-content">
                                    <h2 className="text-start">{t('home.archievements')}</h2>
                                    {badges.length > 0 &&
                                        <div className="row">
                                            <div className="col-6 col-md-4" onClick={() => viewDetail(badges[0], hasBadge(badges[0]))}>
                                                <img className="icon-savings" src={badges[0].icon} alt="icon-savings" />
                                                <p className="card-date m-0">{new Date(badges[0].lastest).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</p>
                                                <h3 className="m-0">{badges[0].badge?.replace('-', ' ').replace('_', ' ')}</h3>
                                                <span className="small-legend m-0">{badges[0].count} {t('general.times')}</span>
                                            </div>
                                            {badges.length >= 2 &&
                                                <div className="col-6 col-md-4" onClick={() => viewDetail(badges[1], hasBadge(badges[1]))}>
                                                    <img className="icon-savings" src={badges[1].icon} alt="icon-savings" />
                                                    <p className="card-date m-0">{new Date(badges[1].lastest).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</p>
                                                    <h3 className="m-0">{badges[1].badge?.replace('-', ' ').replace('_', ' ')}</h3>
                                                    <span className="small-legend m-0">{badges[1].count} {t('general.times')}</span>
                                                </div>
                                            }
                                            {badges.length >= 3 &&
                                                <div className="col-6 col-md-4" onClick={() => viewDetail(badges[2], hasBadge(badges[2]))}>
                                                    <img className="icon-savings" src={badges[2].icon} alt="icon-savings" />
                                                    <p className="card-date m-0">{new Date(badges[2].lastest).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}</p>
                                                    <h3 className="m-0">{badges[2].badge?.replace('-', ' ').replace('_', ' ')}</h3>
                                                    <span className="small-legend m-0">{badges[2].count} {t('general.times')}</span>
                                                </div>
                                            }
                                        </div>
                                    }
                                    <hr className="mt-2" />
                                    <Button className='btn-primary' component={Link} to="/badges">SEE ALL BADGES</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="container-card card-content d-flex flex-column  h-100">
                                    <h2 className="text-start">{t('home.my-smart-devices')}</h2>
                                    <div className="row">
                                        {devices.map((item, index) => (
                                            <div key={index} className='col-6 col-md-4 text-center object-select' onClick={() => handleOpenDevice(item.id, item.type)}>
                                                <img className="icon-savings" src={thermostatImage} alt="icon-savings" />
                                                <h3 className="m-0">{t('home.thermostat')}</h3>
                                                <h3>{item.name}</h3>
                                            </div>
                                        ))}

                                        {devices.length == 0 &&
                                            <div className='col-md-12 text-center mt-4'>
                                                <img className="icon-savings" src={smartHomeImage} alt="icon-savings" />
                                                <h3 className="m-0 mt-2 text-green">{t('home.no-device')}</h3>
                                            </div>
                                        }
                                    </div>
                                    <div className="row mt-auto">
                                        <hr></hr>
                                        <div className="col-md-12">
                                            <Button className='btn-primary me-1' component={Link} to="/add-device">{t('home.add-new-device')}</Button>
                                            <Button className='btn-secondary' variant="outlined" component={Link} to="/my-usage?tab=mysmartdevice">
                                                {t('home.show-all-devices')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="container-card card-content">
                                            <div className="row mt-3 mb-2 text-md-start text-center">
                                                <div className="col-md-4 d-flex flex-column align-items-md-start align-items-center">
                                                    <div className="col-md-12 number-circle-cost-sm pt-3">
                                                        <p className="large-legend-sm text-center m-0">
                                                            ${periodData?.differenceValue?.toFixed(0)}
                                                            {periodData?.previousMonth_value > periodData?.lastMonth_value ?
                                                                <img className="icon-arrow" alt="icon-arrow" src={downImage} /> :
                                                                <img className="icon-arrow" alt="icon-arrow" src={upImage} />
                                                            }
                                                        </p>
                                                        <h2>{t('home.cost')}</h2>
                                                    </div>
                                                </div>
                                                <div className="col-md-8 d-flex flex-column align-items-md-start align-items-center text-md-start">
                                                    <h2 className="text-md-start text-center">{t('home.savings')}</h2>
                                                    <Button className="btn-primary" component={Link} to="/my-usage">{t('home.detail')}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <div className="container-blue card-content">
                                            <div className="row mt-3 mb-1">
                                                <div className="col-md-4">
                                                    <EmojiEventsIcon className='star'></EmojiEventsIcon>
                                                </div>
                                                <div className="col-md-8 d-flex flex-column align-items-md-start align-items-center text-md-start">
                                                    <h2 className="text-md-start text-center text-withe">{t('home.announcement')}</h2>
                                                    <Button className='btn-primary' component={Link} to="/win-prize">{t('home.win')}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <div className="container-card card-content">
                                    <AddCircleIcon aria-label="delete">
                                        <DeleteIcon />
                                    </AddCircleIcon> {t('home.achievement')}
                                    <hr />
                                    <div className="row">
                                        <div className="col-6 col-md-6">
                                            <MilitaryTechIcon className="share-action" />
                                            <h3>{t('home.badge')}</h3>
                                        </div>
                                        <div className="col-6 col-md-6">
                                            <TaskIcon className="share-action" />
                                            <h3>{t('home.challenge')}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {publications?.map((value, index) => {
                            return index === publications.length - 1 && !props.functions.isLoading ? (
                                <div key={index}>
                                    <Publication publication={value} />
                                    <div className="mt-4"></div>
                                    <div className="mt-4" ref={setLastElement}></div>
                                </div>
                            ) : (
                                <div key={index}>
                                    <Publication publication={value} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <button className="hideButton" id="nextButton" onClick={handledIncreased}></button>
        </div>
    )
}

export default Home;