/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import pollutionImage from '../../Assets/Icons/pollution.png';
import { eDevice } from '../../Components/Shared/Enum/EDevice';
import treesImage from '../../Assets/Icons/trees.png';
import savingsImage from '../../Assets/Icons/savings.png';
import { Button } from '@mui/material';
import { eModal } from './../../Services/ModalService';
import leaksImage from '../../Assets/Icons/leaks.png';
import efficiencyImage from "../../Assets/Icons/my-efficiency.png";
import MixedTooltip from '../shared/MixedTooltip';
import screenshotSavingsImage from '../../Assets/Screenshot/screenshot-savings.png';
import { eStorage, getStorageJSON } from '../../Services/StorageService';

function EnviromentalImpact(props) {
    const { t } = useTranslation();
    const [fingerprint] = useState(getStorageJSON(eStorage.fingerprint));

    useEffect(() => {
        console.log(props.comparison);
    }, []);

    const handleModal = (evnt) => {
        props.functions.modalParameters.setType(evnt.target.dataset.type);
        props.functions.modalParameters.set(true);
    }

    return (
        <div className="container-card card-content h-100">
            {fingerprint === null && <img className="img-fluid" src={screenshotSavingsImage}></img>}
            {fingerprint !== null &&
                <>
                    <h2 className='text-start'>ANNUAL SAVINGS POTENTIAL</h2>
                    <div className='row'>
                        <div className="col-6 col-md-6">
                            <div className='row d-flex align-items-center justify-content-center h-100 container-card-gray m-1'>
                                <div className='col-md-5'>
                                    <img className="img-fluid img-emission-sm p-1" alt="icon-savings" src={efficiencyImage} />
                                </div>
                                <div className='col-md-7'>
                                    <p className="large-legend-md text-center m-0">$ {(Math.round(props.comparison.savingsOpportunity > 0 ? props.comparison.savingsOpportunity : (props.comparison.savingsOpportunity * -1))).toLocaleString('en-US', { maximumFractionDigits: 0 })}</p>
                                    <h2>EFFICIENCY <MixedTooltip title={t('tooltips.dashboard-treessaved')} /></h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-6">
                            <div className='row d-flex align-items-center justify-content-center h-100 container-card-gray m-1'>
                                <div className='col-md-5'>
                                    <img className="img-fluid img-emission-sm p-1" alt="icon-savings" src={pollutionImage} />
                                </div>
                                <div className='col-md-7'>
                                    <p className="large-legend-carbon-md text-center m-0">{Math.round(Math.abs(props.comparison.hvacSavings)).toLocaleString('en-US', { maximumFractionDigits: 0 })}</p>
                                    <h2>{t('dashboard.co2-emission')} <MixedTooltip title={t('tooltips.dashboard-treessaved')} /></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-6 col-md-6">
                            <div className='row d-flex align-items-center justify-content-center h-100 container-card-gray m-1'>
                                <div className='col-md-5'>
                                    <img className="img-fluid img-emission-sm p-1" alt="icon-savings" src={leaksImage} />
                                </div>
                                <div className='col-md-7'>
                                    <p className="large-legend-waste-md text-center m-0">$ {Math.round(props.comparison.leakageOpportunity).toLocaleString('en-US', { maximumFractionDigits: 0 })}</p>
                                    <h2>WASTE <MixedTooltip title={t('tooltips.dashboard-treessaved')} /></h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-6">
                            <div className='row d-flex align-items-center justify-content-center h-100 container-card-gray m-1'>
                                <div className='col-md-5'>
                                    <img className="img-fluid img-emission p-1" alt="icon-savings" src={treesImage} />
                                </div>
                                <div className='col-md-7'>
                                    <p className="large-legend-green-md text-center m-0">{props.comparison.renewableImprovements >= 0 ? Math.round(props.comparison.renewableImprovements).toLocaleString('en-US', { maximumFractionDigits: 0 })
                                        : Math.round(-1 * props.comparison.renewableImprovements).toLocaleString('en-US', { maximumFractionDigits: 0 })}</p>
                                    <h2>TREES NEEDED <MixedTooltip title={t('tooltips.dashboard-treessaved')} /></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default EnviromentalImpact;