import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from "@mui/material";
import CreditCard from "../../Components/Shared/CreditCard";
import { useState, forwardRef, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { ValidatorForm } from "react-material-ui-form-validator";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function ModalCard(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const formRef = useRef();

    const handleClose = () => {
        props.setModal(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        props.setPayment(prevState => ({
            ...prevState, [name]: value
        }));
    }

    const handleSubmit = () => {
        props.handleSubmit();
    }

    return (
        <Dialog open={props.modal} TransitionComponent={Transition} fullWidth={true} maxWidth={'md'} keepMounted onClose={handleClose}>
            <ValidatorForm ref={formRef} onSubmit={handleSubmit} onError={errors => console.log(errors)}>
                <DialogTitle className='text-center'>{t('profile.add-card')}</DialogTitle>
                <hr className='m-0' />
                <DialogContent>
                    <div className="row">
                        <div className="col-md-12">
                            <CreditCard payment={props.payment} setPayment={props.setPayment} handleChange={handleChange}></CreditCard>
                        </div>
                    </div>
                </DialogContent>
                <hr className='mb-0 mt-2' />
                <DialogActions className='justify-content-md-center'>
                    <Button className='btn-secondary me-1' onClick={handleClose}>{t('general.close')}</Button>
                    <Button className='btn-primary' type="submit">
                        {t('general.save')}
                    </Button>
                </DialogActions>
            </ValidatorForm>
        </Dialog>
    );
}
