import "bootstrap/dist/css/bootstrap.min.css";
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Box, Button} from '@mui/material';
import { eStorage, getStorage } from "../../Services/StorageService";
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function MenuResidential(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleViewPlanRecommend = (route) => {
        let matching = getStorage(eStorage.matchingPreferences);
        if (matching != null)
            navigate('/recommend');
        else
            navigate('/recommend');
    }

    return (
        <Box className='top-menu' sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button sx={{ color: '#000' }} className={props.path === 'home' ? 'menu-button' : ''} component={Link} to="/home">
                <HomeIcon /> {t('menu.home')}
            </Button>
            <Button sx={{ color: '#000' }} className={props.currentPath === '/inputs' ? 'menu-button' : ''} component={Link} to="/inputs">
                <ToggleOnIcon />   {t('menu.inputs')}
            </Button>
            <Button sx={{ color: '#000' }} className={props.currentPath === '/win-prize' ? 'menu-button' : ''} component={Link} to="/win-prize">
                <WorkspacePremiumIcon />   {t('menu.win')}
            </Button>
            <Button sx={{ color: '#000' }} className={props.currentPath === '/my-usage' ? 'menu-button' : ''} component={Link} to="/my-usage">
                <ElectricMeterIcon />   {t('menu.usage')}
            </Button>
            <Button sx={{ color: '#000' }} className={props.currentPath === '/select-shop' ? 'menu-button' : ''} component={Link} to="/select-shop">
                <ShoppingCartIcon />  {t('menu.shop')}
            </Button>
        </Box>
    )
}

export default MenuResidential;