/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import { MenuItem, Select, InputLabel, FormControl, TextField, Checkbox } from '@mui/material';
import wakeupImage from '../../Assets/Icons/wakeup.png';
import sleepImage from '../../Assets/Icons/sleep.png';
import leaveImage from '../../Assets/Icons/leave.png';
import returnImage from '../../Assets/Icons/return.png';
import { getSchedule } from "../../Util/Util";
import MixedTooltip from "../shared/MixedTooltip";
import thermostatCoolingImage from '../../Assets/Icons/thermostat-cooling.png';
import thermostatHealtingImage from '../../Assets/Icons/thermostat-heating.png';
import occupantsImage from '../../Assets/Icons/occupants.png';
import homeWorkImage from '../../Assets/Icons/home-work.png';
import billImage from '../../Assets/Icons/bill.png';
import renewableImage from '../../Assets/Icons/input-renewable.png';
import { getOccupants, getRenewable, getTemperatures } from "../../Util/Util";

function Schedules(props) {
    const { t } = useTranslation();
    const [wfh, setWfh] = useState('no');
    const [profile, setProfile] = useState({});

    const handleChangeWfh = (event) => {
        setWfh(event.target.value);
    }

    return (
        <div className='container'>
            <div className='row justify-content-md-center'>
                <div className="col-sm-12 col-md-10 col-lg-7">
                    <div className="container-card card-content">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4">
                                        <img alt="" className="icon-savings" src={wakeupImage} />
                                    </div>
                                    <div className="col-9 col-md-8 mt-2">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="wake-up">{t('inputs.wake-weekday')}</InputLabel>
                                            <Select labelId="wake-up" name="weekday_wakeup" value={props.simpleSchedule.weekday_wakeup} onChange={props.handleChangesSchedule} label={t('inputs.wake-weekday')}>
                                                {getSchedule().map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <FormControl className="mt-3" size="small" fullWidth>
                                            <InputLabel id="wake-up">{t('inputs.wake-weekend')}</InputLabel>
                                            <Select labelId="wake-up" name="weekend_wakeup" value={props.simpleSchedule.weekend_wakeup} onChange={props.handleChangesSchedule} label={t('inputs.wake-weekend')}>
                                                {getSchedule().map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4 mt-2">
                                        <img alt="" className="icon-savings" src={leaveImage} />
                                    </div>
                                    <div className="col-9 col-md-8 mt-2">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="leave-home">{t('inputs.leave-weekday')}</InputLabel>
                                            <Select labelId="leave-home" name="weekday_leave" value={props.simpleSchedule.weekday_leave} onChange={props.handleChangesSchedule} label={t('inputs.leave-weekday')}>
                                                {getSchedule().map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <FormControl className="mt-3" size="small" fullWidth>
                                            <InputLabel id="leave-home">{t('inputs.leave-weekend')}</InputLabel>
                                            <Select labelId="leave-home" name="weekend_leave" value={props.simpleSchedule.weekend_leave} onChange={props.handleChangesSchedule} label={t('inputs.leave-weekend')}>
                                                {getSchedule().map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4 mt-2">
                                        <img alt="" className="icon-savings" src={returnImage} />
                                    </div>
                                    <div className="col-9 col-md-8 mt-2">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="return-home">Return Home Weekday</InputLabel>
                                            <Select labelId="return-home" name="weekday_return" value={props.simpleSchedule.weekday_return} onChange={props.handleChangesSchedule} label='Return Home Weekday'>
                                                {getSchedule().map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <FormControl className="mt-3" size="small" fullWidth>
                                            <InputLabel id="return-home">Return Home Weekend</InputLabel>
                                            <Select labelId="return-home" name="weekend_return" value={props.simpleSchedule.weekend_return} onChange={props.handleChangesSchedule} label='Return Home Weekend'>
                                                {getSchedule().map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4 mt-2">
                                        <img alt="" className="icon-savings" src={sleepImage} />
                                    </div>
                                    <div className="col-9 col-md-8 mt-2">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="go-sleep">Go to Sleep Weekday</InputLabel>
                                            <Select labelId="go-sleep" name="weekday_sleep" value={props.simpleSchedule.weekday_sleep} onChange={props.handleChangesSchedule} label='Go Sleep Weekday'>
                                                {getSchedule().map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <FormControl className="mt-3" size="small" fullWidth>
                                            <InputLabel id="go-sleep">Go to Slep Weekend</InputLabel>
                                            <Select labelId="go-sleep" name="weekend_sleep" value={props.simpleSchedule.weekend_sleep} onChange={props.handleChangesSchedule} label='Go to Slep Weekend'>
                                                {getSchedule().map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4">
                                        <img alt="" className="icon-savings" src={thermostatCoolingImage} />
                                    </div>
                                    <div className="col-9 col-md-8 d-flex align-items-center justify-content-center">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="thermostat-cooling">{t('smart-fingerprint-data.thermostat-cooling')}</InputLabel>
                                            <Select labelId="thermostat-cooling" name="coolingTemperature" value={props.selectedValues.coolingTemperature} onChange={props.handleChangesSelected} label={t('smart-fingerprint-thermostat-cooling')}>
                                                {getTemperatures().map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4">
                                        <img alt="" className="icon-savings" src={thermostatHealtingImage} />
                                    </div>
                                    <div className="col-9 col-md-8 d-flex align-items-center justify-content-center">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="thermostat-heating">{t('smart-fingerprint-data.thermostat-heating')}</InputLabel>
                                            <Select labelId="thermostat-heating" name="heatingTemperature" value={props.selectedValues.heatingTemperature} onChange={props.handleChangesSelected} label={t('smart-fingerprint-data.thermostat-heating')}>
                                                {getTemperatures().map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4">
                                        <img alt="" className="icon-savings" src={occupantsImage} />
                                    </div>
                                    <div className="col-9 col-md-8 d-flex align-items-center justify-content-center">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="number-occupants">{t('smart-fingerprint-data.number-occupants')}</InputLabel>
                                            <Select labelId="number-occupants" name="numbersOfOccupant" value={props.selectedValues.numbersOfOccupant} onChange={props.handleChangesSelected} label={t('smart-fingerprint-data.number-occupants')}>
                                                {getOccupants().map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4">
                                        <img alt="" className="icon-savings" src={homeWorkImage} />
                                    </div>
                                    <div className="col-9 col-md-8 d-flex align-items-center justify-content-center">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="work-home">{t('smart-fingerprint-data.work-home')}</InputLabel>
                                            <Select labelId="work-home" name="wfh" value={wfh} onChange={handleChangeWfh} label={t('smart-fingerprint-data.work-home')}>
                                                <MenuItem value={'yes'}>{t('general.yes')}</MenuItem>
                                                <MenuItem value={'no'}>{t('general.no')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {profile?.currentPlan === null &&
                            <div>
                                <hr />
                                < div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-3 col-md-4">
                                                <img alt="" className="icon-savings" src={billImage} />
                                            </div>
                                            <div className="col-9 col-md-8 mt-3 d-flex align-items-center justify-content-center">
                                                <TextField fullWidth size="small" name="currentPlanAverageBill" value={props.selectedValues.currentPlanAverageBill} onChange={props.handleChangesSelected} label={t('smart-fingerprint-data.average-bill')} disabled={props.currentAvg} />
                                                <Checkbox name="currentAvg" checked={props.currentAvg} onChange={props.handleChangeAvg} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-3 col-md-4 mt-3">
                                                <img alt="" className="icon-savings" src={renewableImage} />
                                            </div>
                                            <div className="col-9 col-md-8 mt-3 d-flex align-items-center justify-content-center">
                                                <FormControl size="small" fullWidth>
                                                    <InputLabel id="currentPlanRenewable">{t('smart-fingerprint-data.current-plan')}</InputLabel>
                                                    <Select labelId="currentPlanRenewable" name="currentPlanRenewable" value={props.selectedValues.currentPlanRenewable} onChange={props.handleChangesSelected} label={t('smart-fingerprint-data.current-plan')}>
                                                        {getRenewable().map((item, index) => (
                                                            <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Schedules;