
import { dataServiceApiInstance } from "../../Util/Api";

export const getTopData = () => {
    const response = dataServiceApiInstance.get(`ranking/top`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getBestsData = () => {
    const response = dataServiceApiInstance.get(`ranking/bests`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getBestData = () => {
    const response = dataServiceApiInstance.get(`ranking/best`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getCurrentRankingData = (customerId) => {
    const response = dataServiceApiInstance.get(`ranking/current/${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getHistoricData = (customerId) => {
    const response = dataServiceApiInstance.get(`ranking/historic/${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}