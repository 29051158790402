import { dataServiceApiInstance } from "../../Util/Api";

export const sendGoals = (model) => {
  const response = dataServiceApiInstance.post(`goals/`, model)
    .then(({ data }) => {
      return data;
    });
  return response;
}

export const getGoalsByLocation = (locationId) => {
  const response = dataServiceApiInstance.get(`goals/goalsByLocation/${locationId}`)
    .then(({ data }) => {
      return data;
    });
  return response;
}

export const getGoalsByCustomer = (customerId) => {
  const response = dataServiceApiInstance.get(`goals/goalsByCustomer/${customerId}`)
    .then(({ data }) => {
      return data;
    });
  return response;
}

export const saveGoals = (model) => {
  const response = dataServiceApiInstance.post(`goals/saveMultiple`, model)
    .then(({ data }) => {
      return data;
    });
  return response;
}

export const upsertGoals = (model) => {
  const response = dataServiceApiInstance.post(`goals/upsertMultiple`, model)
    .then(({ data }) => {
      return data;
    });
  return response;
}