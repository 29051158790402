/* eslint-disable react/jsx-no-target-blank */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { getParams } from "../../Util/Util";
import starImage from '../../Assets/Icons/star.png';
import outlineStarImage from '../../Assets/Icons/star-outline.png';
import { Button, Tooltip } from '@mui/material';
import padlockImage from '../../Assets/Icons/padlock.png';
import leafImage from '../../Assets/Icons/leaf.png';
import { useNavigate } from "react-router-dom";
import { React, useState } from 'react';
import { eStorage, getStorage, saveStorageJSON } from "../../Services/StorageService";
import { ePage } from "./Routes";
import { eAction, eCategory, eEvent, submitTrackingEvent } from "../../Services/EventService";
import MixedTooltip from "../../Structure/shared/MixedTooltip";
import ModalViewPDF from "./ModalViewPDF";
import { eDevice } from "./Enum/EDevice";

function PlanCard(props) {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [language] = useState(getStorage(eStorage.language));
    const [hover, setHover] = useState({ active: false, type: '' });
    const [modalViewPDF, setModalViewPDF] = useState({ open: false, url: '', name: '' });

    const handleOrderPlan = () => {
        let isSession = getStorage(eStorage.isSession);

        let plan = props.data;
        plan.use = props?.use;
        plan.recommend = props?.recommend || false;

        saveStorageJSON(eStorage.planSelected, plan);
        submitTrackingEvent(eEvent.orderCreated, eCategory.plan, eAction.created);
        if (props.data.orderingTarget === null)
            window.open(props.data.deepLink);
        else if (isSession)
            navigate(`/${props.data.orderingTarget}`);
        else {
            if (props.data.orderingTarget !== null)
                navigate(`${ePage.electricInfo}?target=${props.data.orderingTarget}`);
        }
    }

    const handleHover = (value, type) => {
        setHover({ active: value, type: type });
    }

    const handleOpenDocument = (url, name) => {
        if (props.functions.device === eDevice.mobile)
            setModalViewPDF({ open: true, name: name, url: url });
        else
            window.open(url, '_blank');
    }

    return (
        <div className={props?.selected ? "container-card h-100 plan-border" : "container-card h-100"}>
            <ModalViewPDF modal={modalViewPDF} setModal={setModalViewPDF} />
            <div className={props?.selected ? "card-title plan-score-title-selected" : "card-title"}>{props.data.name}
                {props?.recommend &&
                    <h3 className={"plan-score"}>{Math.floor(props.data?.score * 100)}% {t('general.matching-score')} <MixedTooltip className="ms-1" customClass="plan-card-img" title={t('tooltips.matching-score')} type={"plancard"} />
                        {/* <Tooltip className="ms-1" title={t('tooltips.matching-score')}><img alt="" className="icon-info plan-card-img" src={infoImage} /></Tooltip> */}
                    </h3>
                }
            </div>
            <div className={props.functions.device === eDevice.mobile ? "card-content p-2" : "card-content"}>
                <div className="row">
                    <div className="col-4">
                        <img alt="" className="icon-plan plan-card-img" src={`${getParams().DATA_SERVICE_BASEURL}reps/logo/${props.data.rep}`} />
                        <p className="card-subtitle-s m-0">PUC #{props.data.repPUCNumber}</p>
                        <Button className='btn-primary-m mt-2' onClick={handleOrderPlan}>
                            {t('general.order')}
                        </Button>
                        {props.data.repOrderingPhone != null && props.data.repOrderingPhone != '' &&
                            <>
                                <p className="card-subtitle-s m-0 mt-1">{t('general.or-call')}</p>
                                <p className="label-phone m-0">{props.data.repOrderingPhone}</p>
                            </>
                        }
                    </div>
                    <div className="col-8">
                        {props.use == 500 &&
                            <p className="price m-0">{props.data.avgKwh.avg500.toFixed(2)} ¢/kWh</p>
                        }
                        {props.use == 1000 &&
                            <p className="price m-0">{props.data.avgKwh.avg1000.toFixed(2)} ¢/kWh</p>
                        }
                        {props.use == 2000 &&
                            <p className="price m-0">{props.data.avgKwh.avg2000.toFixed(2)} ¢/kWh</p>
                        }
                        {props?.recommend &&
                            <p className="price m-0">  {(props.data.avgKwh * 100).toFixed(2)} ¢/kWh</p>
                        }
                        {props.use && <p className="card-subtitle m-0 lh-1">{props.use ? props.use : ''} kWh</p>}
                        <div><img src={leafImage} className='icon-min plan-card-img' alt="" /> <span className="label-min plan-card-img">{Math.floor(props.data.renewable * 100)}% Green</span> <img src={padlockImage} className='icon-min ms-3 plan-card-img' alt="" /> <span className="label-min">{props.data.contractTerm} {props?.recommend && t('general.months')}</span></div>
                        <div className="lh-1">
                            {props.data.repRating >= 1 ? <img alt="" className="plan-card-img" src={starImage} />
                                : <img alt="" className="plan-card-img" src={outlineStarImage} />
                            }
                            {props.data.repRating >= 2 ? <img alt="" className="plan-card-img" src={starImage} />
                                : <img alt="" className="plan-card-img" src={outlineStarImage} />
                            }
                            {props.data.repRating >= 3 ? <img alt="" className="plan-card-img" src={starImage} />
                                : <img alt="" className="plan-card-img" src={outlineStarImage} />
                            }
                            {props.data.repRating >= 4 ? <img alt="" className="plan-card-img" src={starImage} />
                                : <img alt="" className="plan-card-img" src={outlineStarImage} />
                            }
                            {props.data.repRating === 5 ? <img alt="" className="plan-card-img" src={starImage} />
                                : <img alt="" className="plan-card-img" src={outlineStarImage} />
                            }
                        </div>
                        <hr className="m-1" />
                        <p className="label-min-light m-0">{t('browser.plan-type')} - <span className="label-min">{props.data.type}</span></p>
                        <p className="label-min-light m-0">{t('browser.early-termination')} -
                            {props.data.cancellationFeeTerms !== null && <span className="label-min"> {props.data.cancellationFeeTerms}</span>}
                            {props.data.cancellationFeeTerms === null && <span className="label-min"> ${props.data.cancellationFee.amount}</span>}
                        </p>
                        {props.functions.device !== eDevice.mobile &&
                            <div className="row">
                                <div onMouseEnter={() => handleHover(true, 'efl')} onMouseLeave={() => handleHover(false, '')} className="col-4">
                                    <a className="link-file" onClick={() => handleOpenDocument(`${getParams().DATA_SERVICE_BASEURL}browseplans/files/${props.data.uid}/${props.data.factSheet}`, 'EFL')}  >
                                        {hover.active && hover.type === 'efl' ? t('browser.efl') : 'EFL'}
                                    </a>
                                </div>
                                <div onMouseEnter={() => handleHover(true, 'yrac')} onMouseLeave={() => handleHover(false, '')} className="col-4">
                                    <a className="link-file" onClick={() => handleOpenDocument(`${getParams().DATA_SERVICE_BASEURL}reps/fileForField/${props.data.rep}/cr/${language}`, 'YRAC')} >
                                        {hover.active && hover.type === 'yrac' ? t('browser.rights') : 'YRAC'}
                                    </a>
                                </div>
                                <div onMouseEnter={() => handleHover(true, 'tos')} onMouseLeave={() => handleHover(false, '')} className="col-4">
                                    <a className="link-file" onClick={() => handleOpenDocument(`${getParams().DATA_SERVICE_BASEURL}reps/fileForField/${props.data.rep}/tos/${language}`, 'TOS')} >
                                        {hover.active && hover.type === 'tos' ? t('browser.terms') : 'TOS'}
                                    </a>
                                </div>

                            </div>
                        }
                    </div>
                </div>
                {props.functions.device === eDevice.mobile &&
                    <div className="row">
                        <div onMouseEnter={() => handleHover(true, 'efl')} onMouseLeave={() => handleHover(false, '')} className="col-4">
                            <a className="link-file" onClick={() => handleOpenDocument(`${getParams().DATA_SERVICE_BASEURL}browseplans/files/${props.data.uid}/${props.data.factSheet}`, 'EFL')}  >
                                {hover.active && hover.type === 'efl' ? t('browser.efl') : 'EFL'}
                            </a>
                        </div>
                        <div onMouseEnter={() => handleHover(true, 'yrac')} onMouseLeave={() => handleHover(false, '')} className="col-4">
                            <a className="link-file" onClick={() => handleOpenDocument(`${getParams().DATA_SERVICE_BASEURL}reps/fileForField/${props.data.rep}/cr/${language}`, 'YRAC')} >
                                {hover.active && hover.type === 'yrac' ? t('browser.rights') : 'YRAC'}
                            </a>
                        </div>
                        <div onMouseEnter={() => handleHover(true, 'tos')} onMouseLeave={() => handleHover(false, '')} className="col-4">
                            <a className="link-file" onClick={() => handleOpenDocument(`${getParams().DATA_SERVICE_BASEURL}reps/fileForField/${props.data.rep}/tos/${language}`, 'TOS')} >
                                {hover.active && hover.type === 'tos' ? t('browser.terms') : 'TOS'}
                            </a>
                        </div>

                    </div>
                }
            </div>
        </div >
    )
}

export default PlanCard;