/* eslint-disable react/jsx-no-target-blank */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import { getParams } from "../../Util/Util";
import starImage from '../../Assets/Icons/star.png';
import outlineStarImage from '../../Assets/Icons/star-outline.png';
import padlockImage from '../../Assets/Icons/padlock.png';
import leafImage from '../../Assets/Icons/leaf.png';
import { eStorage, getStorage } from "../../Services/StorageService";
import { eDevice } from "../../Components/Shared/Enum/EDevice";

function Header(props) {
    const { t } = useTranslation();
    const [language] = useState(getStorage(eStorage.language));
    const [hover, setHover] = useState({ active: false, type: '' });

    useEffect(() => {
    }, []);

    const handleHover = (value, type) => {
        setHover({ active: value, type: type });
    }

    return (
        <div className="container-card h-100">
            <div className="card-title">
                <div className="row">
                    <div onMouseEnter={() => handleHover(true, 'efl')} onMouseLeave={() => handleHover(false, '')} className="col-4">
                        <a className="link-file" href={`${getParams().DATA_SERVICE_BASEURL}browseplans/files/${props.planSelected.uid}/${props.planSelected.factSheet}`} target="_blank">
                            {hover.active && (props.functions.device !== eDevice.mobile) && hover.type === 'efl' ? t('browser.efl') : 'EFL'}
                        </a>
                    </div>
                    <div onMouseEnter={() => handleHover(true, 'yrac')} onMouseLeave={() => handleHover(false, '')} className="col-4">
                        <a className="link-file" href={`${getParams().DATA_SERVICE_BASEURL}reps/fileForField/${props.planSelected.rep}/cr/${language}`} target="_blank">
                            {hover.active && (props.functions.device !== eDevice.mobile) && hover.type === 'yrac' ? t('browser.rights') : 'YRAC'}
                        </a>
                    </div>
                    <div onMouseEnter={() => handleHover(true, 'tos')} onMouseLeave={() => handleHover(false, '')} className="col-4">
                        <a className="link-file" href={`${getParams().DATA_SERVICE_BASEURL}reps/fileForField/${props.planSelected.rep}/tos/${language}`} target="_blank">
                            {hover.active && (props.functions.device !== eDevice.mobile) && hover.type === 'tos' ? t('browser.terms') : 'TOS'}
                        </a>
                    </div>
                </div>
            </div>
            <div className="card-content">
                <div className="row justify-content-md-center">
                    <div className="col-md-2">
                        <img alt="" className="icon-plan" src={`${getParams().DATA_SERVICE_BASEURL}reps/logo/${props.planSelected.rep}`} />
                        <div>
                            {props.planSelected.repRating >= 1 ? <img alt="" src={starImage} ></img>
                                : <img alt="" src={outlineStarImage} ></img>
                            }
                            {props.planSelected.repRating >= 2 ? <img alt="" src={starImage} ></img>
                                : <img alt="" src={outlineStarImage} ></img>
                            }
                            {props.planSelected.repRating >= 3 ? <img alt="" src={starImage} ></img>
                                : <img alt="" src={outlineStarImage} ></img>
                            }
                            {props.planSelected.repRating >= 4 ? <img alt="" src={starImage} ></img>
                                : <img alt="" src={outlineStarImage} ></img>
                            }
                            {props.planSelected.repRating === 5 ? <img alt="" src={starImage} ></img>
                                : <img alt="" src={outlineStarImage} ></img>
                            }
                        </div>
                        <p className="card-subtitle m-0">PUC #{props.planSelected.repPUCNumber}</p>
                    </div>
                    <div className="col-md-3">
                        <h2 className="m-0 mt-3">{props.planSelected.name}</h2>
                        <h3 className="mb-2">{props.planSelected.type}</h3>
                        {props.planSelected.use == 500 &&
                            <p className="price m-0">{props.planSelected.avgKwh.avg500.toFixed(2)} ¢/kWh</p>
                        }
                        {props.planSelected.use == 1000 &&
                            <p className="price m-0">{props.planSelected.avgKwh.avg1000.toFixed(2)} ¢/kWh</p>
                        }
                        {props.planSelected.use == 2000 &&
                            <p className="price m-0">{props.planSelected.avgKwh.avg2000.toFixed(2)} ¢/kWh</p>
                        }
                        {props.planSelected.recommend && <p className="price m-0">{(props.planSelected.avgKwh * 100).toFixed(2)} ¢/kWh</p> }
                        {props.planSelected.use && <p className="card-subtitle m-0">{props.planSelected.use ? props.planSelected.use : ''} kWh</p>}
                    </div>
                </div>
                <hr className="m-3" />
                <div className="row justify-content-md-center">
                    <div className="col-md-3">
                        <img alt="" src={leafImage} className='icon-min'></img> <span className="label-min">{Math.floor(props.planSelected.renewable * 100)}% Green</span>
                    </div>
                    <div className="col-md-4">
                        <p className="label-min-light m-0">{t('browser.early-termination')} -
                            {props.planSelected.cancellationFeeTerms !== null && <span className="label-min"> {props.planSelected.cancellationFeeTerms}</span>}
                            {props.planSelected.cancellationFeeTerms === null && <span className="label-min"> ${props.planSelected.cancellationFee.amount}</span>}
                        </p>
                    </div>
                    <div className="col-md-3">
                        <img alt="" src={padlockImage} className='icon-min' ></img> <span className="label-min">{props.planSelected.contractTerm} {props.planSelected.contractTerm.toString().length <= 2 ? t('general.months') : ''} </span>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Header;