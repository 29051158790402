/* eslint-disable react-hooks/exhaustive-deps */
import { React, useState } from 'react';
import { useTranslation } from "react-i18next";
import scoring1Image from "../../Assets/EnergyScore/scoring-1.png";
import scoring2Image from "../../Assets/EnergyScore/scoring-2.png";
import scoring3Image from "../../Assets/EnergyScore/scoring-3.png";
import scoring4Image from "../../Assets/EnergyScore/scoring-4.png";
import scoring5Image from "../../Assets/EnergyScore/scoring-5.png";
import scoring6Image from "../../Assets/EnergyScore/scoring-6.png";
import scoring7Image from "../../Assets/EnergyScore/scoring-7.png";
import scoring8Image from "../../Assets/EnergyScore/scoring-8.png";
import scoring9Image from "../../Assets/EnergyScore/scoring-9.png";
import scoring10Image from "../../Assets/EnergyScore/scoring-10.png";
import MixedTooltip from '../shared/MixedTooltip';
import screenshotEnergyScoreImage from '../../Assets/Screenshot/screenshot-energy-use.png';
import { eStorage, getStorageJSON } from '../../Services/StorageService';

function EnergyScore(props) {
    const { t } = useTranslation();
    const energyIntensityScoreImage = ['', scoring1Image, scoring2Image, scoring3Image, scoring4Image, scoring5Image, scoring6Image, scoring7Image, scoring8Image, scoring9Image, scoring10Image];
    const [fingerprint] = useState(getStorageJSON(eStorage.fingerprint));

    return (
        <div className="container-card card-content h-100">
            {fingerprint === null && <img className="img-fluid" src={screenshotEnergyScoreImage}></img>}
            {fingerprint !== null &&
                <>
                    <h2 className="text-start">{t('energy-score.your-home-energy')}</h2>
                    <div className="ms-4 mt-md-2 mt-2 me-4 mb-0">
                        <img className='img-fluid' src={
                            props.energyIntensity?.energyIntensityScore >= 0 && props.energyIntensity?.energyIntensityScore < energyIntensityScoreImage.length ? energyIntensityScoreImage[props.energyIntensity?.energyIntensityScore] : ''}
                            alt="energyIntensity" />
                    </div>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="col-2  col-md-3 col-lg-4 col-xs-3">
                            <label className="label-small number-energy-left">1</label>
                            <label className="small-legend legend-energy-left">{t('energy-score.higher-energy')}</label>
                        </div>
                        <div className='col-6  col-md-3 col-lg-4 col-xs-6'>&nbsp;</div>
                        <div className="col-2 col-md-3 col-lg-4 col-xs-3">
                            <label className="label-small number-energy-right">10</label>
                            <label className="small-legend legend-energy-right">{t('energy-score.lower-energy')}</label>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default EnergyScore;