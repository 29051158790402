import "bootstrap/dist/css/bootstrap.min.css";
import ModalUpdatePhone from "../../../Components/Shared/ModalUpdatePhone";
import DashboardLocation from "./DashboardLocation";
import { Box, Tabs, Tab, Button } from '@mui/material';
import { React, useEffect, useState } from 'react';
import { eStorage, getStorageJSON, saveStorage } from "../../../Services/StorageService";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DashboardGeneral from "./DashboardGeneral";
import { ePeriod, progressPergentage, getDashboardLocationsWithData, getPeriodDataLocationsDashboard } from "../../../Components/DashboardBase/DashboardBase";
import { eMetric } from "../../../Components/Shared/Enum/EMetric";
import { green, red, yellow } from "@mui/material/colors";
import { getLocationsData } from "../../../Components/LocationBase/LocationBase";
import { TabPanel, TabContext } from "@mui/lab";
import { eDevice } from "../../../Components/Shared/Enum/EDevice";

function DashboardCommercial(props) {
    const { t } = useTranslation();
    const [modalOpen, setModalOpen] = useState(false);
    const [value, setValue] = useState('general');
    const [energyIntensity, setEnergyIntensity] = useState([]);
    const [carbonFootprint, setCarbonFootprint] = useState({});
    const [locationId, setLocationId] = useState(getStorageJSON(eStorage.location)?.id);
    const [company] = useState(getStorageJSON(eStorage.company));
    const [metric, setMetric] = useState(eMetric.cost);
    const [periodData, setPeriodData] = useState({});
    const [periodDataList, setPeriodDataList] = useState({});
    const [periodDataSum, setPeriodDataSum] = useState({});
    const [period, setPeriod] = useState(ePeriod.lastWeekVSPrevious);
    const [currentView, setCurrentView] = useState({});
    const [locations, setLocations] = useState([]);
    const [generalStats, setGeneralStats] = useState({});
    const [generalCost, setGeneralCost] = useState('0');
    const [goals, setGoals] = useState({
        carbon: 0,
        bill: 0,
        energy: 0
    });
    const [tableGoals, setTableGoals] = useState({
        energy: {
            value: 0,
            color: '#000000'
        },
        carbon: {
            value: 0,
            color: '#000000'
        },
        cost: {
            value: 0,
            color: '#000000'
        }
    });

    useEffect(() => {
        props.functions.setIsLoading(true);
        props.functions.setTitle(t('general.dashboard'));
        let address = getStorageJSON(eStorage.address);
        if (address?.phone === null)
            setModalOpen(true);

        getLocationsData(company.id).then(data => {
            //setLocations(data);
            getData(data, null);
            console.log(data);
        }).catch((err) => {
            console.log(err);
            props.functions.setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        changeProgress();
    }, [period]);

    const handleChange = (event, newValue) => {
        if (newValue !== 'general') {
            setLocationId(newValue);
            saveStorage(eStorage.locationId, newValue);
        }
        setValue(newValue);
        getData(locations, newValue !== 'general' ? newValue : null);
    };

    const getData = (locations, locationIdToFind) => {
        if (locations.length > 0) {
            props.functions.setIsLoading(true);
            let co2 = 0;
            let yearConsuption = 0;
            let carbonFootprint = 0;

            const locationsToFind = (!locationIdToFind) ? locations.map(m => (m.id)) : locations.filter(f => f.id === locationIdToFind).map(m => (m.id));
            Promise.all([getPeriodDataLocationsDashboard(locationsToFind), getDashboardLocationsWithData(locationsToFind)])
                .then((allData) => {
                    const response = allData[0];
                    const responses = allData[1];

                    if (response && response.code === 200) {
                        const periodDataLocation = response.data[locationIdToFind ?? locationId];
                        if (!period) {
                            setPeriod(ePeriod.lastWeekVSPrevious);
                        }
                        if (!currentView || Object.keys(currentView).length === 0) {
                            setCurrentView(periodDataLocation.lastWeekVSPrevious);
                        }
                        setPeriodData({
                            lastWeekVSPrevious: periodDataLocation.lastWeekVSPrevious,
                            lastMonthVSPrevious: periodDataLocation.lastMonthVSPrevious,
                            lastWeekVSLastYear: periodDataLocation.lastWeekVSLastYear,
                            lastMonthVSLastYear: periodDataLocation.lastMonthVSLastYear
                        });
                        calculateAllPeriodData(response.data);
                        setPeriodDataList(response.data);
                    }
                    let allCosts = 0;
                    locations.forEach((location, index) => {
                        if (responses[location.id] && responses[location.id]?.Stats && responses[location.id]?.energy_intensity && responses[location.id]?.carbon_footprint && responses[location.id]?.goals && responses[location.id]?.charts) {
                            const generalStatsData = responses[location.id].Stats;
                            const energyIntensityData = responses[location.id].energy_intensity;
                            const carbonFootprintData = responses[location.id].carbon_footprint;
                            const goalsData = responses[location.id].goals;
                            const chartData = responses[location.id].charts;

                            location.generalStats = generalStatsData;
                            co2 += generalStatsData?.co2 ?? 0;

                            location.energyIntensity = energyIntensityData;
                            yearConsuption += energyIntensityData?.yearConsuption ?? 0;

                            location.carbonFootprint = carbonFootprintData;
                            carbonFootprint += carbonFootprintData?.co2 ?? 0;

                            location.charts = chartData;

                            if (goalsData && goalsData.length > 0) {
                                location.goals = {
                                    carbon: goalsData.filter(w => w.type === 'CARBON')[0]?.percentage ?? 0,
                                    bill: goalsData.filter(w => w.type === 'BILL')[0]?.percentage ?? 0,
                                    energy: goalsData.filter(w => w.type === 'ENERGY')[0]?.percentage ?? 0,
                                };
                                location.tableGoals = buildTableGoals(location.id, location.goals.energy, location.goals.carbon, location.goals.bill, response.data);
                                allCosts += calculateGeneralCost(location.id, response.data) ?? 0;
                                setTableGoals(location.tableGoals);
                                setGoals(goalsData);
                            }
                        }
                    });
                    setGeneralCost(isNaN(allCosts) ? '0' : allCosts.toFixed(0));
                    setGeneralStats({ co2: co2 });
                    setEnergyIntensity({ yearConsuption: yearConsuption });
                    setCarbonFootprint({ co2: carbonFootprint });
                    setLocations(locations.slice());
                }).catch(console.log)
                .finally(() => props.functions.setIsLoading(false))
        }
    }

    const calculateAllPeriodData = (data) => {
        let lastWeekVSPrevious = {};
        let lastMonthVSPrevious = {};
        let lastWeekVSLastYear = {};
        let lastMonthVSLastYear = {};
        for (const [key, value] of Object.entries(data)) {
            lastWeekVSPrevious = sumNumericValuesInObject(lastWeekVSPrevious, value.lastWeekVSPrevious);
            lastMonthVSPrevious = sumNumericValuesInObject(lastMonthVSPrevious, value.lastMonthVSPrevious);
            lastWeekVSLastYear = sumNumericValuesInObject(lastWeekVSLastYear, value.lastWeekVSLastYear);
            lastMonthVSLastYear = sumNumericValuesInObject(lastMonthVSLastYear, value.lastMonthVSLastYear);
        }
        setPeriodDataSum({
            lastWeekVSPrevious,
            lastMonthVSPrevious,
            lastWeekVSLastYear,
            lastMonthVSLastYear,
        })
    }

    const sumNumericValuesInObject = (base, newValues) => {
        for (const [key, value] of Object.entries(newValues)) {
            if (!isNaN(value)) {
                base[key] = (base[key]) ? base[key]+= value : value;
            } else if (Array.isArray(value)) {
                base[key] = (base[key]) ? base[key].map((num, idx) => (isNaN(num) ? num : num + value[idx])) : value;
            } else if (!base[key]) {
                base[key] = value;
            }
        }
        return base;
    }

    const handlePeriodSelect = (value) => {
        setPeriod(value);
        let period = {};
        if (value === ePeriod.lastWeekVSPrevious)
            period = periodData.lastWeekVSPrevious;
        else if (value === ePeriod.lastMonthVSPrevious)
            period = periodData.lastMonthVSPrevious;
        else if (value === ePeriod.lastWeekVSLastYear)
            period = periodData.lastWeekVSLastYear;
        else if (value === ePeriod.lastMonthVSLastYear)
            period = periodData.lastMonthVSLastYear;
        setCurrentView(period);
    }

    const changeProgress = () => {
        let locationsUpdate = locations;
        locationsUpdate.forEach((location, index) => {
            location.tableGoals = buildTableGoals(location.id, location.goals.energy, location.goals.carbon, location.goals.bill, periodDataList);
            setTableGoals(location.tableGoals);
        });
        setLocations(locationsUpdate);
    }

    const handleTypeChange = (viewType) => {
        setMetric(viewType);
        getData(locations, value !== 'general' ? value : null);
    }

    const buildTableGoals = (locationId, energy, carbon, bill, periodDataList) => {
        return {
            energy: calculateEnergy(locationId, energy, periodDataList),
            carbon: calculateCarbon(locationId, carbon, periodDataList),
            cost: calculateCost(locationId, bill, periodDataList)
        };
    };

    const calculateEnergy = (locationId, energy, periodDataList) => {
        let total;
        const periodLocation = periodDataList[locationId];
        switch (period) {
            case ePeriod.lastWeekVSPrevious:
                total = progressPergentage(periodLocation?.lastWeekVSPrevious?.lastWeek_total ?? 0, periodLocation?.lastWeekVSPrevious?.previousWeek_total ?? 0, energy);
                break;
            case ePeriod.lastMonthVSPrevious:
                total = progressPergentage(periodLocation?.lastMonthVSPrevious?.lastMonth_total ?? 0, periodLocation?.lastMonthVSPrevious?.previousMonth_total ?? 0, energy);
                break;
            case ePeriod.lastWeekVSLastYear:
                total = progressPergentage(periodLocation?.lastWeekVSLastYear?.lastWeek_total ?? 0, periodLocation?.lastWeekVSLastYear?.previousWeek_total ?? 0, energy);
                break;
            case ePeriod.lastMonthVSLastYear:
                total = progressPergentage(periodLocation?.lastWeekVSLastYear?.lastMonth_total ?? 0, periodLocation?.lastWeekVSLastYear?.previousYearMonth_total ?? 0, energy);
                break;
            default: total = 0; break;
        }
        return {
            value: total ?? 0,
            color: getColor(total)
        }
    }

    const calculateCarbon = (locationId, carbon, periodDataList) => {
        let total;
        const periodLocation = periodDataList[locationId];
        switch (period) {
            case ePeriod.lastWeekVSPrevious:
                total = progressPergentage(periodLocation?.lastWeekVSPrevious?.co2 ?? 0, periodLocation?.lastWeekVSPrevious?.co2_previous ?? 0, carbon);
                break;
            case ePeriod.lastMonthVSPrevious:
                total = progressPergentage(periodLocation?.lastMonthVSPrevious?.co2 ?? 0, periodLocation?.lastMonthVSPrevious?.co2_previous ?? 0, carbon);
                break;
            case ePeriod.lastWeekVSLastYear:
                total = progressPergentage(periodLocation?.lastWeekVSLastYear?.co2 ?? 0, periodLocation?.lastWeekVSLastYear?.co2_previous ?? 0, carbon);
                break;
            case ePeriod.lastMonthVSLastYear:
                total = progressPergentage(periodLocation?.lastWeekVSLastYear?.co2 ?? 0, periodLocation?.lastWeekVSLastYear?.co2_previous ?? 0, carbon);
                break;
            default: total = 0; break;
        }
        return {
            value: total,
            color: getColor(total)
        }
    }

    const calculateCost = (locationId, cost, periodDataList) => {
        let total;
        const periodLocation = periodDataList[locationId];
        switch (period) {
            case ePeriod.lastWeekVSPrevious:
                total = progressPergentage(periodLocation?.lastWeekVSPrevious?.lastWeek_value ?? 0, periodLocation?.lastWeekVSPrevious?.previousWeek_value ?? 0, cost);
                break;
            case ePeriod.lastMonthVSPrevious:
                total = progressPergentage(periodLocation?.lastMonthVSPrevious?.lastMonth_value ?? 0, periodLocation?.lastMonthVSPrevious?.previousMonth_value ?? 0, cost);
                break;
            case ePeriod.lastWeekVSLastYear:
                total = progressPergentage(periodLocation?.lastWeekVSLastYear?.lastWeek_value ?? 0, periodLocation?.lastWeekVSLastYear?.previousWeek_value ?? 0, cost);
                break;
            case ePeriod.lastMonthVSLastYear:
                total = progressPergentage(periodLocation?.lastWeekVSLastYear?.lastMonth_value ?? 0, periodLocation?.lastWeekVSLastYear?.previousYearMonth_value ?? 0, cost);
                break;
            default: total = 0; break;
        }
        return {
            value: total,
            color: getColor(total)
        }
    }

    const getColor = (valor) => {
        if (valor < 60) {
            return red['700'];
        }
        if (valor > 80) {
            return green['A700'];
        }
        return yellow['500'];
    }

    const calculateGeneralCost = (locationId, periodDataList) =>{
        let total = 0;

        if (!periodDataList || Object.keys(periodDataList).length === 0) {
            return total;
        }
        const periodLocation = periodDataList[locationId];
        switch (period) {
            case ePeriod.lastWeekVSPrevious:
                total = periodLocation.lastWeekVSPrevious.lastWeek_value - periodLocation.lastWeekVSPrevious.previousWeek_value;
                break;
            case ePeriod.lastMonthVSPrevious:
                total = periodLocation.lastMonthVSPrevious.lastMonth_value - periodLocation.lastMonthVSPrevious.previousMonth_value;
                break;
            case ePeriod.lastWeekVSLastYear:
                total = periodLocation.lastWeekVSLastYear.lastWeek_value - periodLocation.lastWeekVSLastYear.previousWeek_value;
                break;
            case ePeriod.lastMonthVSLastYear:
                total = periodLocation.lastMonthVSLastYear.lastMonth_value - periodLocation.lastMonthVSLastYear.previousYearMonth_value;
                break;
            default: total = 0; break;
        }
        return total;
    }

    return (
        <div className="content">
            <ModalUpdatePhone modalOpen={modalOpen} />
            <div className='container-fluid'>
                <TabContext value={value}>
                    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <div className="row">
                            <div className="col-lg-9">
                                <Tabs style={{ float: 'left' }} value={value} onChange={handleChange} centered>
                                    <Tab value={'general'} label={t('dashboard.dashboard-general')} />
                                    {locations.map((item, index) =>
                                        <Tab value={item.id} key={index} label={item.name} />
                                    )}
                                </Tabs>
                            </div>
                            {(props.functions.device !== eDevice.mobile && props.functions.device !== eDevice.tablet) &&
                                <div className="col-lg-3">
                                    <Button className='btn-primary mt-2 float-end' component={Link} to="/electrical-service-request">{t('dashboard.card-request-electricity')}</Button>
                                </div>
                            }
                        </div>
                    </Box>
                    <TabPanel className="ps-0 pe-0 m-0" value={'general'}>
                        <DashboardGeneral
                            carbonFootprint={carbonFootprint}
                            energyIntensity={energyIntensity}
                            functions={props.functions}
                            generalStats={generalStats}
                            handleTypeChange={handleTypeChange}
                            metric={metric}
                            periodData={periodDataSum}
                            currentView={currentView}
                            handlePeriodSelect={handlePeriodSelect}
                            period={period}
                            tableGoals={tableGoals}
                            generalCost={generalCost}
                            locations={locations} />
                    </TabPanel>
                    {
                        locations.map((item, indx) =>
                        (
                            <TabPanel className="ps-0 pe-0 m-0" value={item.id} key={indx} >
                                <DashboardLocation
                                    carbonFootprint={locations?.filter(w => w.id === locationId)[0]?.carbonFootprint}
                                    energyIntensity={locations?.filter(w => w.id === locationId)[0]?.energyIntensity}
                                    functions={props.functions}
                                    generalStats={locations?.filter(w => w.id === locationId)[0]?.generalStats}
                                    step={value}
                                    handleTypeChange={handleTypeChange}
                                    metric={metric}
                                    periodData={periodDataList ? periodDataList[locationId] : {}}
                                    currentView={currentView}
                                    handlePeriodSelect={handlePeriodSelect}
                                    period={period}
                                    goals={locations?.filter(w => w.id === locationId)[0]?.goals}
                                    tableGoals={locations?.filter(w => w.id === locationId)[0]?.tableGoals}
                                    locations={locations?.filter(w => w.id === locationId)}
                                    charts={locations?.filter(w => w.id === locationId)[0]?.charts} />
                            </TabPanel>)
                        )
                    }
                </TabContext>
            </div>
        </div>
    )
}

export default DashboardCommercial;