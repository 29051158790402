/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import Header from "../Header";
import { eStorage, getStorage, getStorageJSON } from "../../../Services/StorageService";
import { Stack, Step, StepLabel, Stepper } from "@mui/material";
import QontoStepIcon from "../../../Components/Shared/Stepper";
import StepConnectorCustom from "../../../Components/Shared/StepConnector";
import AddressInfo from "../AddressInfo";
import UserInfo from "../UserInfo";
import ServiceInfo from "../ServiceInfo";
import OrderInfo from "../OrderInfo";
import { postOrder } from "../../../Components/IntegrationBase/FlagshipBase";
import ResponseInfo from "../ResponseInfo";
import { replaceValues } from "../../../Components/IntegrationBase/IntegrationBase";

function Flagship(props) {
    const { t } = useTranslation();
    const [planSelected] = useState(getStorageJSON(eStorage.planSelected));
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Address Info', 'User Info', 'Service Info', 'Order Confirm'];
    const [address, setAddress] = useState(getStorageJSON(eStorage.address));
    const [billingAddress, setBillingAddress] = useState({ sameAddress: true });
    const [response, setResponse] = useState({});
    const [service, setService] = useState({
        requestType: 'switching'
    });

    const [user, setUser] = useState(getStorageJSON(eStorage.user));

    const agreements = [
        { description: t('flagship.agreement-user-one'), required: true, name: 'userOne' },
        { description: t('flagship.agreement-user-two'), required: true, name: 'userTwo' },
        { description: t('flagship.agreement-user-three'), required: true, name: 'userThree' },
        { description: t('flagship.agreement-user-four'), required: true, name: 'userFour' },
        { description: t('flagship.agreement-user-five'), required: true, name: 'userFive' }
    ];

    const [terms, setTerms] = useState([
        { id: 1, description: t('flagship.term-service-one'), required: false, value: false },
        { id: 2, description: t('flagship.term-service-two'), required: false, value: false },
        { id: 3, description: t('flagship.term-service-three'), required: false, value: false },
    ]);

    useEffect(() => {
        props.functions.setTitle(t('flagship.title'));
        if (!user.hasOwnProperty('birthdate')) {
            let date = new Date();
            date.setFullYear(date.getFullYear() - 18);
            setUser(prevState => ({
                ...prevState, ['birthdate']: date
            }));
        }

        if (!service.hasOwnProperty('serviceDate')) {
            let date = new Date();
            date.setDate(date.getDate() + 1);
            setService(prevState => ({
                ...prevState, ['serviceDate']: date
            }));
        }

        if (!user.hasOwnProperty('ssn'))
            setUser(prevState => ({
                ...prevState, ['ssn']: ''
            }));
    }, []);

    const handleSubmit = () => {
        props.functions.setIsLoading(true);
        let esiid = getStorageJSON(eStorage.user).esiid;
        let requestId = getStorage(eStorage.requestId);

        let addressOrder = {
            address: address.address,
            city: address.city,
            state: address.state,
            zipCode: address.zipCode,
        };

        let phone = user.phone.replaceAll(' ', '').replaceAll('-', '').substr(-10);
        let userOrder = {
            name: user.name,
            lastName: user.lastName,
            phone: phone,
            birthdate: user.birthdate?.toISOString().split('T')[0].replaceAll('-', '/'),
            ssn: user.ssn.replaceAll(' ', '').replaceAll('-', ''),
            language: user.language,
        }

        let typeRequestOrder = {
            requestType: service.requestType,
            serviceDate: service.serviceDate?.toISOString().split('T')[0]
        };

        let addressBillOrder = {
            sameAsClientAddress: billingAddress.sameAddress,
            addressBill: billingAddress.sameAddress ? address.address : billingAddress.address,
            cityBill: billingAddress.sameAddress ? address.city : billingAddress.city,
            zipCodeBill: billingAddress.sameAddress ? address.zipCode : billingAddress.zipCode,
            stateBill: billingAddress.sameAddress ? address.state : billingAddress.state,
            unitBill: billingAddress.sameAddress ? address.apt : billingAddress.apt,
        };

        let otherDetails = {
            marketingEmails: true,
            marketingPhoneCalls: true,
            ebilling: true
        }

        let model = {
            customerId: user.customerId,
            requestId: requestId,
            planId: planSelected.uid ? planSelected.uid : planSelected.planUid,
            esiid: esiid,
            serviceArea: address.serviceArea,
            orderDate: service.serviceDate?.toISOString().split('T')[0],
            orderDetails: {
                clientAddress: addressOrder,
                userinfo: userOrder,
                identityVerification: userOrder,
                request: typeRequestOrder,
                billingAddress: addressBillOrder,
                otherDetails: otherDetails
            }
        }
        postOrder(model).then(data => {
            showResponse(data);
        });
    }

    const showResponse = (data) => {
        props.functions.setIsLoading(false);
        switch (data.status) {
            case 0:
                setResponse({
                    title: t('flagship.order-success-title'),
                    description:  replaceValues(t('flagship.order-success-description'), { voucher: data.voucher }),
                    buttonOne: t('general.help-better'),
                    buttonOneUrl: '/feedback',
                    buttonTwoUrl: '',
                    buttonTwo: ''
                });
                break;
            case 1:
                setResponse({
                    title: t('flagship.deposit-title'),
                    description: t('flagship.deposit-description').replace('#depositAmount#', data.deposit),
                    buttonOne: t('flagship.order-deposit-pay'),
                    buttonOneUrl: data.redirect,
                    buttonTwoUrl: '/browser',
                    buttonTwo: t('general.select-other-plan')
                });
                break;
            default:
                setResponse({
                    title: t('flagship.deposit-title'),
                    description: t('flagship.deposit-description'),
                    buttonOne: t('general.select-other-plan'),
                    buttonOneUrl: '/browser',
                    buttonTwoUrl: '',
                    buttonTwo: ''
                });
                break;
        }
    }

    return (
        <div className='content'>
            <div className='container'>
                <div className='row justify-content-md-center'>
                    <div className='col-sm-12 col-md-12 col-lg-10'>
                        <Header functions={props.functions} planSelected={planSelected} />
                    </div>
                </div>
                <div className='row mt-3 justify-content-md-center'>
                    <div className='col-sm-12 col-md-12 col-lg-10'>
                        <div className="container-card card-content">
                            {Object.keys(response).length === 0 &&
                                <div>
                                    {activeStep === 0 &&
                                        <AddressInfo functions={props.functions} address={address} setAddress={setAddress} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                    {activeStep === 1 &&
                                        <UserInfo user={user} setUser={setUser} agreements={agreements} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                    {activeStep === 2 &&
                                        <ServiceInfo terms={terms} setTerms={setTerms} service={service} setService={setService} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                    {activeStep === 3 &&
                                        <OrderInfo handleSubmit={handleSubmit} billingAddress={billingAddress} setBillingAddress={setBillingAddress} address={address} setAddress={setAddress} user={user} setUser={setUser} service={service} setService={setService} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                </div>
                            }
                            {Object.keys(response).length > 0 &&
                                <ResponseInfo response={response} />
                            }
                        </div>
                    </div>
                </div>
                {Object.keys(response).length === 0 &&
                    <Stack className="mt-4 mb-4" sx={{ width: '100%' }}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnectorCustom />}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Stack>
                }
            </div>
        </div>
    )
}

export default Flagship;