import { pulseApiInstance } from "../../Util/Api";

export const postOrder = (model) => {
    const response = pulseApiInstance.post(`/`, model)
        .then(({ data }) => {

            return data;
        });
    return response;
}


export const getServiceDates = (planId, zipCode) => {
    return pulseApiInstance.get(`/blackoutDates/${planId}/${zipCode}`)
    .then(({data})=>data);
}