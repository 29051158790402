import houseImage1 from '../../Assets/HomeChallenge/house-1.jpg';
import houseImage2 from '../../Assets/HomeChallenge/house-2.jpg';
import houseImage3 from '../../Assets/HomeChallenge/house-3.jpg';
import houseImage4 from '../../Assets/HomeChallenge/house-4.jpg';
import houseImage5 from '../../Assets/HomeChallenge/house-5.jpg';
import houseImage6 from '../../Assets/HomeChallenge/house-6.jpg';
import houseImage7 from '../../Assets/HomeChallenge/house-7.jpg';
import houseImageGray1 from '../../Assets/HomeChallenge/house-1-gray.jpg';
import houseImageGray2 from '../../Assets/HomeChallenge/house-2-gray.jpg';
import houseImageGray3 from '../../Assets/HomeChallenge/house-3-gray.jpg';
import houseImageGray4 from '../../Assets/HomeChallenge/house-4-gray.jpg';
import houseImageGray5 from '../../Assets/HomeChallenge/house-5-gray.jpg';
import houseImageGray6 from '../../Assets/HomeChallenge/house-6-gray.jpg';
import houseImageGray7 from '../../Assets/HomeChallenge/house-7-gray.jpg';
import { HtmlTooltip } from "../../Components/Shared/HtmlTooltip";
import ModalChallenge from "./ModalChallenge";
import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';


function House(props) {
    const [popover, setPopover] = useState({});
    const [modalChallenge, setModalChallenge] = useState(false);

    const handleTooltipOpen = (event) => {
        const { id } = event.currentTarget;
        setPopover(props.challengeImages.find(w => w.imageRelation == id));
    };

    const handleOpenModal = () => {
        setModalChallenge(true);
    }

    return (
        <div>
            <ModalChallenge challenge={popover} setModal={setModalChallenge} modalOpen={modalChallenge}></ModalChallenge>
            <h2 className="text-start">TOP CHALLENGE: COMPLETE THE HOME TOUR</h2>
            <div className="row justify-content-md-center">
                <div className="col-12 col-md-9 p-0">
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <h6>{popover?.name}</h6>
                                {popover?.suscribed === 'notsubscribed' ?
                                    <Button className={'btn-primary-s me-1'} onClick={handleOpenModal}>Detail</Button> :
                                    popover?.suscribed === 'suscribed' ?
                                        <Button className={'btn-blue-s me-1'} component={Link} to={'/challenge/' + popover?.id + '/' + popover?.customerChallengeId}>Subscribed</Button> :
                                        <Button className={'btn-blue-s me-1'} component={Link} to={'/challenge/' + popover?.id + '/' + popover?.customerChallengeId}>View</Button>
                                }
                            </React.Fragment>
                        }
                        placement="top"
                        arrow
                    >
                        <img id="5" className="img-fluid" role="button" src={
                            props.challengeImages.find(w => w.imageRelation === 5 && w.suscribed === 'completed') ?
                                houseImage5 : houseImageGray5
                        }
                            onMouseEnter={handleTooltipOpen} />
                    </HtmlTooltip>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-4 col-md-3 p-0">
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <h6>{popover?.name}</h6>
                                {popover?.suscribed === 'notsubscribed' ?
                                    <Button className={'btn-primary-s me-1'} onClick={handleOpenModal}>Detail</Button> :
                                    popover?.suscribed === 'suscribed' ?
                                        <Button className={'btn-blue-s me-1'} component={Link} to={'/challenge/' + popover?.id + '/' + popover?.customerChallengeId}>Subscribed</Button> :
                                        <Button className={'btn-blue-s me-1'} component={Link} to={'/challenge/' + popover?.id + '/' + popover?.customerChallengeId}>View</Button>
                                }
                            </React.Fragment>
                        }
                        placement="top"
                        arrow
                    >
                        <img id="4" className="img-fluid" role="button" src={
                           props.challengeImages.find(w => w.imageRelation === 4 && w.suscribed === 'completed') ?
                                houseImage4 : houseImageGray4
                        }
                            onMouseEnter={handleTooltipOpen} />
                    </HtmlTooltip>
                </div>
                <div className="col-4 col-md-3 p-0">
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <h6>{popover?.name}</h6>
                                {popover?.suscribed === 'notsubscribed' ?
                                    <Button className={'btn-primary-s me-1'} onClick={handleOpenModal}>Detail</Button> :
                                    popover?.suscribed === 'suscribed' ?
                                        <Button className={'btn-blue-s me-1'} component={Link} to={'/challenge/' + popover?.id + '/' + popover?.customerChallengeId}>Subscribed</Button> :
                                        <Button className={'btn-blue-s me-1'} component={Link} to={'/challenge/' + popover?.id + '/' + popover?.customerChallengeId}>View</Button>
                                }
                            </React.Fragment>
                        }
                        placement="top"
                        arrow
                    >
                        <img id="7" className="img-fluid" role="button" src={
                            props.challengeImages.find(w => w.imageRelation === 7 && w.suscribed === 'completed') ?
                                houseImage7 : houseImageGray7
                        }
                            onMouseEnter={handleTooltipOpen} />
                    </HtmlTooltip>
                </div>
                <div className="col-4 col-md-3 p-0">
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <h6>{popover?.name}</h6>
                                {popover?.suscribed === 'notsubscribed' ?
                                    <Button className={'btn-primary-s me-1'} onClick={handleOpenModal}>Detail</Button> :
                                    popover?.suscribed === 'suscribed' ?
                                        <Button className={'btn-blue-s me-1'} component={Link} to={'/challenge/' + popover?.id + '/' + popover?.customerChallengeId}>Subscribed</Button> :
                                        <Button className={'btn-blue-s me-1'} component={Link} to={'/challenge/' + popover?.id + '/' + popover?.customerChallengeId}>View</Button>
                                }
                            </React.Fragment>
                        }
                        placement="top"
                        arrow
                    >
                        <img id="3" className="img-fluid" role="button" src={
                            props.challengeImages.find(w => w.imageRelation === 3 && w.suscribed === 'completed') ?
                                houseImage3 : houseImageGray3
                        }
                            onMouseEnter={handleTooltipOpen} />
                    </HtmlTooltip>
                </div>
            </div>
            <div className="row justify-content-md-center">
                <div className="col-4 col-md-3 p-0">
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <h6>{popover?.name}</h6>
                                {popover?.suscribed === 'notsubscribed' ?
                                    <Button className={'btn-primary-s me-1'} onClick={handleOpenModal}>Detail</Button> :
                                    popover?.suscribed === 'suscribed' ?
                                        <Button className={'btn-blue-s me-1'} component={Link} to={'/challenge/' + popover?.id + '/' + popover?.customerChallengeId}>Subscribed</Button> :
                                        <Button className={'btn-blue-s me-1'} component={Link} to={'/challenge/' + popover?.id + '/' + popover?.customerChallengeId}>View</Button>
                                }
                            </React.Fragment>
                        }
                        placement="top"
                        arrow
                    >
                        <img id="6" className="img-fluid" role="button" src={
                            props.challengeImages.find(w => w.imageRelation === 6 && w.suscribed === 'completed') ?
                                houseImage6 : houseImageGray6
                        }
                            onMouseEnter={handleTooltipOpen} />
                    </HtmlTooltip>
                </div>
                <div className="col-4 col-md-3 p-0">
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <h6>{popover?.name}</h6>
                                {popover?.suscribed === 'notsubscribed' ?
                                    <Button className={'btn-primary-s me-1'} onClick={handleOpenModal}>Detail</Button> :
                                    popover?.suscribed === 'suscribed' ?
                                        <Button className={'btn-blue-s me-1'} component={Link} to={'/challenge/' + popover?.id + '/' + popover?.customerChallengeId}>Subscribed</Button> :
                                        <Button className={'btn-blue-s me-1'} component={Link} to={'/challenge/' + popover?.id + '/' + popover?.customerChallengeId}>View</Button>
                                }
                            </React.Fragment>
                        }
                        placement="top"
                        arrow
                    >
                        <img id="2" className="img-fluid" role="button" src={
                            props.challengeImages.find(w => w.imageRelation === 2 && w.suscribed === 'completed') ?
                                houseImage2 : houseImageGray2
                        }
                            onMouseEnter={handleTooltipOpen} />
                    </HtmlTooltip>
                </div>
                <div className="col-4 col-md-3 p-0">
                    <HtmlTooltip
                        title={
                            <React.Fragment>
                                <h6>{popover?.name}</h6>
                                {popover?.suscribed === 'notsubscribed' ?
                                    <Button className={'btn-primary-s me-1'} onClick={handleOpenModal}>Detail</Button> :
                                    popover?.suscribed === 'suscribed' ?
                                        <Button className={'btn-blue-s me-1'} component={Link} to={'/challenge/' + popover?.id + '/' + popover?.customerChallengeId}>Subscribed</Button> :
                                        <Button className={'btn-blue-s me-1'} component={Link} to={'/challenge/' + popover?.id + '/' + popover?.customerChallengeId}>View</Button>
                                }
                            </React.Fragment>
                        }
                        placement="top"
                        arrow
                    >
                        <img id="1" className="img-fluid" role="button" src={
                            props.challengeImages.find(w => w.imageRelation === 1 && w.suscribed === 'completed') ?
                                houseImage1 : houseImageGray1
                        }
                            onMouseEnter={handleTooltipOpen} />
                    </HtmlTooltip>
                </div>
            </div>
        </div>
    )
}

export default House;