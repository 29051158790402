function Error404(props) {

    return (
        <div className="content">
            <div className='container'>
                <h1>Error404</h1>
            </div>
        </div>
    )
}

export default Error404;