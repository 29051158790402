/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { getStorageJSON } from '../../Services/StorageService';
import { eStorage } from './../../Services/StorageService';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';
import ModalAddress from './ModalAddress';
import EditIcon from '@mui/icons-material/Edit';
import { eAccountType } from '../../Components/Shared/Enum/EAccountType';

function Addresses(props) {
    const { t } = useTranslation();
    const [user] = useState(getStorageJSON(eStorage.user));
    const [addresses, setAddresses] = useState([]);
    const [modal, setModal] = useState({ open: false, address: null });
    const [address, setAddress] = useState(getStorageJSON(eStorage.address));

    useEffect(() => {
        if (props.openModal)
            setModal({ open: true, address: null });
    }, []);

    const add = () => {
        setModal({ open: true, address: null });
    }

    const edit = () => {
        setModal({ open: true, address: address });
    }

    return (
        <div>
            <ModalAddress functions={props.functions} open={modal.open} setModal={setModal} modal={modal} />
            {/* <div className="row justify-content-md-center">
                <div className="col-md-10">
                    <Button className='btn-primary me-2' onClick={add}>
                        {t('profile.add-address')}
                    </Button>
                </div>
            </div>
            <hr /> */}
            {address &&
                <div className="row mt-2 justify-content-md-center">
                    <div className='col-md-10'>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell scope="col"><strong>{t('profile.address')}</strong></TableCell >
                                        {user?.agreementStatus === 'Non Active - Moved Out' &&
                                            <TableCell scope="col"><strong>{t('general.edit')}</strong></TableCell >
                                        }
                                        <TableCell scope="col"><strong>{t('profile.type')}</strong></TableCell >
                                        <TableCell scope="col"><strong>{t('dashboard.status')}</strong></TableCell >
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{address.address}, {address.city} {address.state}, {address.zipCode}</TableCell >
                                        {user?.agreementStatus === 'Non Active - Moved Out' &&
                                            <TableCell>
                                                <Button className='logout'>
                                                    <EditIcon onClick={edit} />
                                                </Button>
                                            </TableCell>
                                        }
                                        <TableCell>{address.addressType === 'Small Non-Residential' ? t('profile.commercial') : t('profile.residential')}</TableCell>
                                        <TableCell>{t('profile.active')}</TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            }
        </div>
    )
}

export default Addresses;