
import "bootstrap/dist/css/bootstrap.min.css";
import { React } from 'react';
import { ePeriod } from "../../../Components/DashboardBase/DashboardBase";
import ChartWeek from "./ChartWeek";
import ChartMonth from "./ChartMonth";
import { eChart } from "../../../Components/Shared/Enum/EChart";

function ChartProgressContainer(props) {
    return <>
        <div className="row mt-1">
            <div className="col-md-12">
                {props.period === ePeriod.lastWeekVSPrevious && <ChartWeek goals={props.goals} metric={props.metric} periodData={props.periodData.lastWeekVSPrevious} />}
                {props.period === ePeriod.lastMonthVSPrevious && <ChartMonth goals={props.goals} metric={props.metric} type={eChart.lastMonthVSPrevious} periodData={props.periodData.lastMonthVSPrevious} />}
                {props.period === ePeriod.lastWeekVSLastYear && <ChartWeek goals={props.goals} metric={props.metric} periodData={props.periodData.lastWeekVSLastYear} />}
                {props.period === ePeriod.lastMonthVSLastYear && <ChartMonth goals={props.goals} metric={props.metric} type={eChart.lastMonthVSLastYear} periodData={props.periodData.lastMonthVSLastYear} />}
            </div>
        </div>
    </>
}

export default ChartProgressContainer;
