/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */


import "bootstrap/dist/css/bootstrap.min.css";
import { eLevel } from "../../Services/LevelService";

function ProgressBar(props) {
    const readings = [
        {
            value: 10,
            color: '#224b64',
            currentLevel: props.currentLevel == eLevel.newSaver
        },
        {
            value: 25,
            color: '#CD7F32',
            currentLevel: props.currentLevel == eLevel.bronze
        },
        {
            value: 25,
            color: '#BEBEBE',
            currentLevel: props.currentLevel == eLevel.silver
        },
        {
            value: 25,
            color: '#FFD700',
            currentLevel: props.currentLevel == eLevel.gold
        },
        {
            value: 15,
            color: '#E5E4E2',
            currentLevel: props.currentLevel == eLevel.platinum
        }
    ];

    let values = readings && readings.length && readings.map((item, i) => {
        if (item.value > 0) {
            return (
                <div className="value" style={{ 'color': '#032F4A', 'width': item.value + '%' }} key={i}>
                    {item.currentLevel ? <span style={{ fontSize: '12px' }}>{props.name}</span> : <span>&nbsp;</span>}
                </div>
            )
        }
    }, this);

    let calibrations = readings && readings.length && readings.map((item, i) => {
        if (item.value > 0) {
            return (
                <div className="graduation" style={{ 'color': '#032F4A', 'width': item.value + '%' }} key={i}>
                    {item.currentLevel ? <span>|</span> : <span>&nbsp;</span>}
                </div>
            )
        }
    }, this);

    let bars = readings && readings.length && readings.map((item, i) => {
        if (item.value > 0) {
            return (
                <div className="bar" style={{ 'backgroundColor': item.color, 'width': item.value + '%' }} key={i}>

                </div>
            )
        }
    }, this);

    return (
        <div className="multicolor-bar">
            <div className="values">
                {values == '' ? '' : values}
            </div>
            <div className="scale">
                {calibrations == '' ? '' : calibrations}
            </div>
            <div className="bars">
                {bars == '' ? '' : bars}
            </div>
        </div>
    )
}

export default ProgressBar;