/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import { eStorage, getStorageJSON } from "../../../Services/StorageService";
import Savings from "./Savings";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { eDevice } from "../../../Components/Shared/Enum/EDevice";
import CardsContainerLocation from "./CardsContainerLocation";
import ChartGoal from "./ChartGoal";
import { ePeriod } from "../../../Components/DashboardBase/DashboardBase";
import { eMetric } from "../../../Components/Shared/Enum/EMetric";
import { FormControlLabel, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Radio, RadioGroup } from "@mui/material";
import ChartConsumptionContainer from "./ChartConsumptionContainer";
import ChartTexas from "./ChartTexas";
import { getParams, lbsToTonnes } from "../../../Util/Util";
import ModalChartHeatMap from "./ModalChartHeatMap";
import ModalChartHeatMapCarbon from "./ModalChartHeatMapCarbon";
import LoadingChartImage from '../../../Assets/loading-chart.gif';
import GoalCalendarGraphics from "./GoalCalendarGraphics";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import ChartProgressContainer from "./ChartProgressContainer";
import Edit from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { ePage } from '../../../Components/Shared/Routes';

function DashboardLocation(props) {
    const { t } = useTranslation();
    const [fingerprint, setFingerprint] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenCarbon, setModalOpenCarbon] = useState(false);
    const [location, setLocation] = useState({ current: { id: 0 }, previous: { id: 0 } });
    const [chartObject, setChartObject] = useState({});
    const [showButtonEnergy, setShowButtonEnergy] = useState(false);
    const [showButtonCarbon, setShowButtonCarbon] = useState(false);
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        let fingerprint = getStorageJSON(eStorage.fingerprint);
        if (fingerprint !== null)
            setFingerprint(fingerprint);
    }, []);

    useEffect(() => {
        console.log(props.period);

        if (props.locations && props.locations.length > 0 && props.locations[0]?.id !== location.current.id) {
            const locationIn = props.locations[0];
            setLocation({ current: locationIn, previous: location.current });
        }
    }, [props.locations]);

    const handleOpen = (type) => {
        const chartData = {
            location: location.current,
            type,
            start: fingerprint?.simpleSchedule?.weekday_wakeup,
            end: fingerprint?.simpleSchedule?.weekday_sleep,
            values: props.charts?.filter(w => w.chartType === type)[0]
        };
        setChartObject(chartData);
        if (type === 'carbon') {
            setModalOpenCarbon(true);
        } else {
            setModalOpen(true);
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleEdit = () => {
        navigate(ePage.editGoals);
    }

    const imageOnErrorEnergy = (event) => {
        setShowButtonEnergy(false);
        event.currentTarget.src = LoadingChartImage;
    };

    const imageOnErrorCarbon = (event) => {
        setShowButtonCarbon(false);
        event.currentTarget.src = LoadingChartImage;
    };

    const imageOnLoadEnergy = (event) => {
        if (!event.target.currentSrc.includes('loading-chart'))
            setShowButtonEnergy(true);
        else
            setShowButtonEnergy(false);
    };

    const imageOnLoadCarbon = (event) => {
        if (!event.target.currentSrc.includes('loading-chart'))
            setShowButtonCarbon(true);
        else
            setShowButtonCarbon(false);
    };

    const format = (number, fraction = 0) => {
        const format = !number || isNaN(number) ? 0 : number;
        return format.toLocaleString('en-US', { maximumFractionDigits: fraction })
    }

    const calculateCost = () => {
        return (props.period === ePeriod.lastWeekVSPrevious
            ? props.periodData?.lastWeekVSPrevious?.lastWeek_value - props.periodData?.lastWeekVSPrevious?.previousWeek_value
            : props.period === ePeriod.lastMonthVSPrevious
                ? props.periodData?.lastMonthVSPrevious?.lastMonth_value - props.periodData?.lastMonthVSPrevious?.previousMonth_value
                : props.period === ePeriod.lastWeekVSLastYear
                    ? props.periodData?.lastWeekVSLastYear.lastWeek_value - props.periodData?.lastWeekVSLastYear?.previousWeek_value
                    : props.periodData?.lastMonthVSLastYear?.lastMonth_value - props.periodData?.lastMonthVSLastYear?.previousYearMonth_value)?.toFixed(0)
    }

    return (
        <div>
            <GoalCalendarGraphics locationId={location.current.id} open={open} setOpen={setOpen} />
            {(props.functions.device === eDevice.mobile || props.functions.device === eDevice.tablet) &&
                <div className="mt-0 pt-1}">
                    <ChartConsumptionContainer
                        currentView={props.currentView}
                        handlePeriodSelect={props.handlePeriodSelect}
                        functions={props.functions}
                        handleTypeChange={props.handleTypeChange}
                        metric={props.metric}
                        period={props.period}
                        periodData={props.periodData}
                        goals={props.goals}
                    />
                </div>
            }
            {(props.functions.device === eDevice.mobile || props.functions.device === eDevice.tablet) && <Savings currentView={props.currentView} functions={props.functions} />}
            {(props.functions.device === eDevice.mobile || props.functions.device === eDevice.tablet) && <CardsContainerLocation carbonFootprint={props.carbonFootprint} energyIntensity={props.energyIntensity} selectedValues={fingerprint.selectedValues} functions={props.functions} generalStats={props.generalStats} />}


            {/* desktop disposition */}
            {(props.functions.device !== eDevice.mobile && props.functions.device !== eDevice.tablet) &&
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-3 mt-0 mt-md-2" >
                        <div className="container-card dashboard-card b-card mt-2 h-100 p-2 ">
                            <div className="row pt-2 d-flex align-items-center justify-content-center">
                                <div className="col-md-5">
                                    <div className="number-circle text-center">
                                        ${calculateCost() ?? 0} <p>{calculateCost() <= 0 ? 'less' : 'more'}</p>
                                    </div>
                                </div>
                                <div className="col-md-5 text-center">
                                    <h2 className="text-center title-section title-section-card">THIS MONTH</h2>
                                    <h2 className="text-center title-section title-section-card">vs</h2>
                                    <h2 className="text-center title-section title-section-card">LAST MONTH</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-4 mt-0 mt-md-2" >
                        <div className="container-card dashboard-card b-card mt-2 h-100 p-0">
                            <h2 className="text-center title-section pt-4 title-section-card"><ElectricBoltIcon className='icon-house-type' /> {format(props.energyIntensity?.yearConsuption)} kWh</h2>
                            <h3 className="subtitle-section">Electric consumption for last 12 months</h3>
                            <hr className="m-0 p-0" />
                            <h2 className="text-center title-section pt-4 title-section-card"><LightbulbIcon className='icon-house-type' /> {fingerprint.selectedValues?.homeSize ? format(props.energyIntensity?.yearConsuption / fingerprint.selectedValues?.homeSize) : 0} kWh/sqft</h2>
                            <h3 className="subtitle-section">Energy intensity (amount of energy/building)</h3>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg mt-0 mt-md-2" >
                        <div className="container-card dashboard-card b-card mt-2 h-100">
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{ borderBottom: "none" }} className="cell-small">
                                                <div className="row">
                                                    <div className="col-4"><h2 className="title-section">CARBON</h2> </div>
                                                    <div className="col-4"><h2 className="title-section">EMMISION</h2></div>
                                                    <div className="col-4"><h2 className="title-section">INTENSITY</h2></div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ borderBottom: "none" }} className="cell-small">
                                                <div className="row">
                                                    <div className="col-4 fw-bold">Standard</div>
                                                    <div className="col-4">{format(lbsToTonnes(props.carbonFootprint?.co2))} tonnes</div>
                                                    <div className="col-4">{fingerprint.selectedValues?.homeSize ? format((lbsToTonnes(props.carbonFootprint?.co2) / fingerprint.selectedValues.homeSize), 2) : 0} tonnes/sqft</div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="cell-small">
                                                <div className="row">
                                                    <div className="col-4 fw-bold">Itemized</div>
                                                    <div className="col-4">{format(lbsToTonnes(props.generalStats?.co2))} tonnes</div>
                                                    <div className="col-4">{fingerprint?.selectedValues?.homeSize ? format((lbsToTonnes(props.generalStats?.co2) / fingerprint.selectedValues.homeSize), 2) : 0} tonnes/sqft</div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ borderBottom: "none" }} className="cell-small">
                                                <div className="row">
                                                    <div className="col-4 fw-bold">Difference</div>
                                                    <div className="col-4">{format(lbsToTonnes(props.carbonFootprint?.co2 - props.generalStats?.co2))} tonnes</div>
                                                    <div className="col-4">{fingerprint?.selectedValues?.homeSize ? format(((lbsToTonnes(props.carbonFootprint?.co2) / fingerprint.selectedValues.homeSize) - (lbsToTonnes(props.generalStats?.co2) / fingerprint.selectedValues.homeSize)), 2) : 0} tonnes/sqft</div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>}
            <hr className="mt-4 mb-2 mt-2" />
            <div className="row mt-3">
                <div className="col-md-3">
                    <FormControl size="small" fullWidth>
                        <InputLabel id="period-select">{t('dashboard.period-select')}</InputLabel>
                        <Select labelId="period-select" value={props.period} label="period-select" onChange={(event) => { props.handlePeriodSelect(event.target.value) }}>
                            <MenuItem value={ePeriod.lastWeekVSPrevious}>{t('dashboard.week-vs-previous')}</MenuItem>
                            <MenuItem value={ePeriod.lastMonthVSPrevious}>{t('dashboard.month-vs-previous')}</MenuItem>
                            <MenuItem value={ePeriod.lastWeekVSLastYear}>{t('dashboard.week-vs-last-year')}</MenuItem>
                            <MenuItem value={ePeriod.lastMonthVSLastYear}>{t('dashboard.month-vs-last-year')}</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="col-md-3">
                    <FormControl>
                        <RadioGroup value={props.metric} row onChange={(event) => { props.handleTypeChange(event.target.value) }}>
                            <FormControlLabel value={eMetric.energy} control={<Radio />} label={t('dashboard.energy')} />
                            <FormControlLabel value={eMetric.carbon} control={<Radio />} label={t('dashboard.carbon')} />
                            <FormControlLabel value={eMetric.cost} control={<Radio />} label={t('dashboard.cost')} />
                        </RadioGroup>
                    </FormControl>
                </div>
                {props.currentView && Object.keys(props.currentView).length > 0 &&
                    <>
                        <div className="col-md-3">
                            <h5 className="mb-1">{Math.abs(Math.round(props.currentView.differencePercentage * 100))}% {props.currentView.differencePercentage > 0 ? t('dashboard.more') : t('dashboard.less')}</h5>
                            <h3 className="text-start">{t('dashboard.consumption')}</h3>
                        </div>
                        <div className="col-md-3">
                            <h5 className="mb-1">{Math.abs(Math.round(props.currentView.temperature_difference))}°F {props.currentView.temperature_difference > 0 ? t('dashboard.hotter') : t('dashboard.cooler')}</h5>
                            <h3 className="text-start">{t('dashboard.outdoor-temp')}</h3>
                        </div>
                    </>
                }
            </div>
            <div className="b-blue mt-2">
                <div className="row text-start">
                    <div className="col-md-12 pl-1 mb-2">
                        <h3 className='m-0 location-label'><LocationOnIcon /> {location.current.address}, {location.current.city} {location.current.state}, {location.current.zipCode}</h3>
                    </div>
                </div>
                {(props.functions.device !== eDevice.mobile && props.functions.device !== eDevice.tablet) &&
                    <div className="row">
                        <div className="col-md-6">
                            <div className="container-card b-card card-content pb-0">
                                <h2 className="text-start title-section">METRIC VARIATIONS</h2>
                                {props.periodData && <ChartConsumptionContainer
                                    currentView={props.currentView}
                                    functions={props.functions}
                                    metric={props.metric}
                                    period={props.period}
                                    periodData={props.periodData} />}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="container-card b-card card-content pb-0 h-100">
                                <h2 className="text-start">{t('general.period-changes')}</h2>
                                {props.periodData && <Savings currentView={
                                    props.period === ePeriod.lastWeekVSPrevious ? props.periodData?.lastWeekVSPrevious
                                        : props.period === ePeriod.lastMonthVSPrevious ? props.periodData?.lastMonthVSPrevious
                                            : props.period === ePeriod.lastWeekVSLastYear ? props.periodData?.lastWeekVSLastYear
                                                : props.periodData?.lastMonthVSLastYear
                                } functions={props.functions} />}
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="container-card b-card card-content pb-0">
                                <h2 className="text-start">{t('dashboard.location')}</h2>
                                {props.locations.length > 0 &&
                                    <ChartTexas period={props.period} metric={props.metric} locations={props.locations} />
                                }
                            </div>
                        </div>
                    </div>
                }

                <div className="row mt-4">
                    <div className="col-sm-12 col-md-12 col-lg-6 mt-3 mt-md-0">
                        <div className="container-card card-content pb-3">
                            <h2 className="text-start">{t('dashboard.progress-goals')} <CalendarMonthIcon onClick={handleClickOpen} /> <Edit onClick={handleEdit} /></h2>
                            <div className="row justify-content-md-center">
                                <div className="col-md-6">
                                    {props.tableGoals && <ChartGoal size='lg' tableGoals={props.tableGoals} />}
                                </div>
                            </div>
                            {props.periodData && Object.keys(props.periodData).length > 0 &&
                                <div className="row">
                                    <div className="col-md-4 text-center">
                                        <h3>{t('dashboard.energy')}</h3>
                                        <span className="label-energy-s">
                                            {props.tableGoals?.energy.value}%
                                        </span>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <h3>{t('dashboard.carbon')}</h3>
                                        <span className="label-carbon-s">
                                            {props.tableGoals?.carbon.value}%
                                        </span>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <h3>{t('dashboard.cost')}</h3>
                                        <span className="label-cost-s">
                                            {props.tableGoals?.cost.value}%
                                        </span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 mt-3 mt-md-0">
                        <div className="container-card card-content pb-3">
                            {props.periodData && <ChartProgressContainer
                                currentView={props.currentView}
                                functions={props.functions}
                                metric={props.metric}
                                period={props.period}
                                goals={props.goals}
                                periodData={props.periodData} />}
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="row mt-4">
                <div className="col-md-6">
                    <h2 className="text-start">{t('dashboard.energy-variations')}</h2>
                    <div className="container-card card-content">
                        {showButtonEnergy &&
                            <h3><strong className="click-here" onClick={() => handleOpen('energy')}>{t('general.see-more-detail')}</strong></h3>}
                        <img className="img-fluid" alt="energy" onError={imageOnErrorEnergy} onLoad={imageOnLoadEnergy} src={`${getParams().DATA_SERVICE_BASEURL}location/chart/${props.charts?.filter(w => w.chartType === 'energy')[0]?.chartId}`} />
                    </div>
                </div>
                <div className="col-md-6">
                    <h2 className="text-start">{t('dashboard.carbon-variations')}</h2>
                    <div className="container-card card-content">
                        {showButtonCarbon &&
                            <h3><strong className="click-here" onClick={() => handleOpen('carbon')}>{t('general.see-more-detail')}</strong></h3>}
                        <img className="img-fluid" alt="carbon" onError={imageOnErrorCarbon} onLoad={imageOnLoadCarbon} src={`${getParams().DATA_SERVICE_BASEURL}location/chart/${props.charts?.filter(w => w.chartType === 'carbon')[0]?.chartId}`} />
                    </div>
                </div>
                <ModalChartHeatMapCarbon modalOpen={modalOpenCarbon} setModalOpen={setModalOpenCarbon} chartObject={chartObject} />
                <ModalChartHeatMap modalOpen={modalOpen} setModalOpen={setModalOpen} chartObject={chartObject} />
            </div>
        </div>
    )
}

export default DashboardLocation;