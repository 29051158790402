/* eslint-disable react-hooks/exhaustive-deps */

import { useState } from 'react';
import Goal from './../Locations/Goal';
import { Stack, Stepper, Step, StepLabel } from '@mui/material';
import StepConnectorCustom from "../../Components/Shared/StepConnector";
import QontoStepIcon from "../../Components/Shared/Stepper";
import { eStorage, getStorageJSON, getStorage, saveStorageJSON } from "./../../Services/StorageService";
import { useEffect } from 'react';
import { getGoalsByCustomer, getGoalsByLocation, upsertGoals } from "../../Components/goalBase/goalBase";
import { useNavigate } from 'react-router-dom';
import { eAccountType } from "./../../Components/Shared/Enum/EAccountType";

const steps = ['Step 1', 'Step 2', 'Step 3'];
export default function EditGoals(props) {

  const [activeStep, setActiveStep] = useState(0);
  const [accountType] = useState(getStorage(eStorage.accountType));
  const [user] = useState(getStorageJSON(eStorage.user));
  const [locationId, setLocationId] = useState('');
  const [goals, setGoals] = useState({
    carbon: 0,
    energy: 0,
    bill: 0
  });
  const [dbGoals, setDbGoals] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getApiGoals();
  }, []);

  const getApiGoals = () => {
    props.functions.setIsLoading(true);
    const locationId = getStorage(eStorage.locationId);
    setLocationId(locationId);
    let promise = null;
    if (accountType === eAccountType.commercial) {
      promise = getGoalsByLocation(locationId)
    } else {
      promise = getGoalsByCustomer(user.customerId);
    }

    promise.then(data => {
      console.log(data);
      setDbGoals(data);
      updateValues(data);
    }).catch(() => navigate('/')).finally(() => props.functions.setIsLoading(false))
  }

  const handleChange = (value) => {
    setGoals(prevState => ({
      ...prevState, [activeStep === 0 ? 'energy' : activeStep === 1 ? 'carbon' : 'bill']: value
    }));
  }

  const handleSubmit = () => {
    const model = Object.keys(goals).map(key => {
      return {
        id: dbGoals ? dbGoals?.find(f => f.type.toLowerCase() === key)?.id : null,
        locationId: locationId,
        percentage: goals[key],
        customerId: user.customerId,
        status: true,
        type: key.toUpperCase(),
      }
    });
    console.log(model);
    sendGoals(model);
  }

  const sendGoals = (model) => {
    props.functions.setIsLoading(true);
    upsertGoals(model).then(data => {
      saveStorageJSON(eStorage.goals, model);
      updateValues(data);
      props.functions.setIsLoading(false);
      navigate('/');
    }).catch(console.log).finally(() => props.functions.setIsLoading(false))
  }

  const updateValues = (data) => {
    if (data && Array.isArray(data)) {
      data.forEach((item) => {
        setGoals(prevState => ({
          ...prevState, [item.type.toLowerCase()]: item?.percentage ? item.percentage : 5
        }));
      });
    }
  }

  const handleFullSubmit = (event) => {
    if (event.type === 'bill') {
      handleSubmit();
    } else {
      setActiveStep(activeStep + 1);
    }
  }

  return <>
    <div className="content">
      {activeStep === 0 && goals['energy'] > 0 && <Goal type='energy' goalValue={goals['energy']} handleChange={handleChange} skipCondition={true} handleFullSubmit={handleFullSubmit} functions={props.functions} setActiveStep={setActiveStep} activeStep={activeStep} locationId={locationId} />}
      {activeStep === 1 && <Goal type='carbon' goalValue={goals['carbon']} handleChange={handleChange} skipCondition={true} handleFullSubmit={handleFullSubmit} functions={props.functions} setActiveStep={setActiveStep} activeStep={activeStep} locationId={locationId} />}
      {activeStep === 2 && <Goal type='bill' goalValue={goals['bill']} handleChange={handleChange} skipCondition={true} handleFullSubmit={handleFullSubmit} functions={props.functions} setActiveStep={setActiveStep} activeStep={activeStep} locationId={locationId} handleSubmit={handleSubmit} />}
      <Stack className="mt-4" sx={{ width: '100%' }}>
        <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnectorCustom />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </div>
  </>
}