import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { addPropertyStorage, eStorage, getStorage, getStorageJSON } from '../../Services/StorageService';
import { getAgreementContent, postAgreementAccepted } from '../../Components/AgreementBase/AgreementBase';
import { ePage } from '../../Components/Shared/Routes';
import { eAccountType } from '../../Components/Shared/Enum/EAccountType';
import { getParams } from '../../Util/Util';

function Accept(props) {
    const { t } = useTranslation();
    const [user] = useState(getStorageJSON(eStorage.user));
    const [location] = useState(getStorageJSON(eStorage.location));
    const [url, setUrl] = useState('');
    const navigate = useNavigate();
    const [view, setView] = useState('agreement');
    const [showAgreement, setShowAgreement] = useState(false);
    const popupWindowRef = useRef(null);

    useEffect(() => {
        props.functions.setTitle(t('general.accept-agreement'));
        getAgreement();
    }, []);

    const getAgreement = () => {
        props.functions.setIsLoading(true);
        let container = document.getElementById('content');

        let accountType = getStorage(eStorage.accountType);
        let id = accountType == eAccountType.commercial ? location.id : user.customerId;

        getAgreementContent(id, container).then(data => {
            console.log(data);
            if (data === '') {
                getAgreement();
            } else {
                props.functions.setIsLoading(false);
                setUrl(data.links.filter(w => w.content === 'confirm')[0].href);
            }
        });
    }

    const handleSubmit = () => {
        let accountType = getStorage(eStorage.accountType);
        let id = accountType == eAccountType.commercial ? location.id : user.customerId;

        postAgreementAccepted(id).then(data => {
            if (data.status === 'Success') {
                props.functions.setIsLoading(false);
                Notify.success(t('agreement.has-been-accepted'), { position: 'center-bottom' });
                addPropertyStorage(eStorage.user, 'agreementStatus', true);
                navigate(ePage.downloading);
            } else {
                Notify.failure(t('agreement.error-accepted'), { position: 'center-bottom' });
            }
        });
    }

    const doAcceptAgreement = () => {
        props.functions.setIsLoading(true);
        openPopup();
        setTimeout(() => {
            if (popupWindowRef.current) {
                popupWindowRef.current.close();
                popupWindowRef.current = null;
            }
        }, 3000);
        handleSubmit();
    }

    const openPopup = () => {
        const popupWidth = 600;
        const popupHeight = 400;
        const left = (window.innerWidth - popupWidth) / 2;
        const top = (window.innerHeight - popupHeight) / 2;

        // Configurar opciones de la ventana emergente
        const popupOptions = `width=${popupWidth},height=${popupHeight},left=${left},top=${top},resizable=yes,scrollbars=yes`;

        // Abrir la ventana emergente
        popupWindowRef.current = window.open(url, '_blank', popupOptions);
    };

    return (
        <div className="content">
            <div className='container'>
                <h1>{t('agreement.accept-subtitle')}</h1>
                <div className="row justify-content-md-center">
                    <div className="col-sm-12 col-md-10 col-lg-7">
                        <div className="container-card card-content">
                            <div className="row justify-content-md-center mt-2">
                                <div className="col-md-12">
                                    <h3><strong className="click-here" onClick={() => setShowAgreement(!showAgreement)} >{t('general.click-here')} </strong> {t('general.see-agreement')}</h3>
                                </div>
                            </div>
                            <div className={showAgreement ? "row justify-content-md-center agreement-show" : "agreement-hide"}>
                                <div className="col-md-8">
                                    {view === 'agreement' ? <div id='content' className="agreement-content"></div> :

                                        //    <Iframe url={url}
                                        //         width="100%"
                                        //         height="400px"
                                        //         display="initial"
                                        //         position="relative" />
                                        <></>
                                    }
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-12">
                                    <Button id="confirm2" className='btn-primary' onClick={doAcceptAgreement}>
                                        {t('general.confirm')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Accept;