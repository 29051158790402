/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Button, Stack, Stepper, Step, StepLabel, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import QontoStepIcon from "../../../Components/Shared/Stepper";
import { React, useEffect, useState } from 'react';
import StepConnectorCustom from "../../../Components/Shared/StepConnector";
import Home from "./Home";
import Characteristics from "./Characteristics";
import { postGeniusFingerprint } from "../../../Components/GeniusFingerprintBase/GeniusFingerprintBase";
import { ePage } from "../../../Components/Shared/Routes";
import { useNavigate } from "react-router-dom";
import { eStorage, getStorage, getStorageJSON, saveStorageJSON } from "../../../Services/StorageService";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { getChartsData } from "../../../Components/DashboardBase/DashboardBase";
import Schedule from "./Schedule";
import { postSmartFingerprint } from "../../../Components/SmartFingerprintBase/SmartFingerprintBase";
import { getLocationsData, locationDefault } from "../../../Components/LocationBase/LocationBase";

const steps = ['Step 1', 'Step 2', 'Step 3'];

function FingerprintCommercial(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [location, setLocation] = useState(getStorageJSON(eStorage.location));
  const [currentAvg, setCurrentAvg] = useState(true);
  const [locations, setLocations] = useState([]);

  const [simpleSchedule, setsimpleSchedule] = useState(
    {
      weekday_wakeup: '06:00',
      weekday_leave: '07:00',
      weekday_sleep: '22:00',
      weekday_return: '18:00',
      weekend_wakeup: '08:00',
      weekend_leave: '11:00',
      weekend_sleep: '22:00',
      weekend_return: '20:00',
    });

  const [selectedValues, setSelectedValues] = useState(
    {
      coolingTemperature: 75,
      heatingTemperature: 72,
      numbersOfOccupant: 1,
      currentElectricityProvider: null,
      currentPlanEndDate: null,
      currentPlan: null,
      currentPlanCancellationFee: '',
      currentPlanAverageBill: 0,
      currentPlanRenewable: "IDONTKNOW",
      remodelHouse: 'no',
      replacedWaterHeater: 'Less than 5',
      yearBuilt: '2005',
      homeSize: 0,
      smartThermostats: 'no',
      haveAPool: 'no',
      refrigerators: 1,
      solarPanels: 'no',
      electricalVehicles: 'no',
      electricityStorage: 'no',
    });

  const [domainValues, setDomainValues] = useState({
    domainKeyFuel_heating: 'Electric',
    domainKeyWater_heating: 'Electric',
    domainKeyHome_LightBulbs: 'LED',
    domainKeyHome_HVAC: 'Less than 5',
    domainKeyHome_WaterHeaterAge: 'Less than 5'
  });

  useEffect(() => {
    props.functions.setTitle(t('general.building-features'));
    getLocations();
    setValues(getStorageJSON(eStorage.fingerprint));
  }, []);


  const getLocations = () => {
    let company = getStorageJSON(eStorage.company);
    getLocationsData(company.id).then(data => {
      setLocations(data);
      console.log(data);
    }).catch(console.log).finally(() => { });
  }

  const setValues = (fingerprint) => {
    console.log(fingerprint);

    if (fingerprint?.simpleSchedule !== null && fingerprint?.simpleSchedule !== undefined)
      setsimpleSchedule(fingerprint.simpleSchedule);

    if (fingerprint?.domainValues !== null && fingerprint?.domainValues !== undefined) {
      setDomainValues(fingerprint.domainValues);
      if (fingerprint.domainValues.domainKeyHome_WaterHeaterAge === undefined || fingerprint.domainValues.domainKeyHome_WaterHeaterAge === 'yes')
        setDomainValues(prevState => ({ ...prevState, ['domainKeyHome_WaterHeaterAge']: 'Less than 5' }));
    }

    if (fingerprint?.selectedValues !== null && fingerprint?.selectedValues !== undefined) {
      setSelectedValues(fingerprint.selectedValues);
      if (fingerprint.selectedValues.yearBuilt === undefined)
        setSelectedValues(prevState => ({ ...prevState, ['yearBuilt']: '2005' }));
      if (fingerprint.selectedValues.smartThermostats === undefined)
        setSelectedValues(prevState => ({ ...prevState, ['smartThermostats']: 'no' }));
      if (fingerprint.selectedValues.haveAPool === undefined)
        setSelectedValues(prevState => ({ ...prevState, ['haveAPool']: 'no' }));
      if (fingerprint.selectedValues.refrigerators === undefined)
        setSelectedValues(prevState => ({ ...prevState, ['refrigerators']: 1 }));
      if (fingerprint.selectedValues.solarPanels === undefined)
        setSelectedValues(prevState => ({ ...prevState, ['solarPanels']: 'no' }));
      if (fingerprint.selectedValues.electricalVehicles === undefined)
        setSelectedValues(prevState => ({ ...prevState, ['electricalVehicles']: 'no' }));
    }
  }

  const handleNextStep = () => {
    if (selectedValues.homeSize > 0) {
      if (activeStep < 2)
        setActiveStep(activeStep + 1);
    }
  }

  const handleChangesDomain = (event) => {
    const { name, value } = event.target;
    setDomainValues(prevState => ({
      ...prevState, [name]: value
    }));
  }

  const handleChangesSelected = (event) => {
    const { name, value } = event.target;
    setSelectedValues(prevState => ({
      ...prevState, [name]: value
    }));
  }

  const handleChangeAvg = (event) => {
    setCurrentAvg(event.target.checked);
  }

  const handleChangesSchedule = (event) => {
    const { name, value } = event.target;
    setsimpleSchedule(prevState => ({
      ...prevState, [name]: value
    }));
  }

  const handleChange = (event) => {
    props.functions.setIsLoading(true);
    const { value } = event.target;
    let selectedLocation = locations.filter(w => w.id === value)[0];
    setLocation(selectedLocation);
    saveStorageJSON(eStorage.fingerprint,selectedLocation.preferences);
    saveStorageJSON(eStorage.location, selectedLocation);
    handleChangeLocation(value);
    setValues(selectedLocation.preferences);
  }

  const handleSubmitSmart = () => {
    props.functions.setIsLoading(true);

    let model = {
      locationId: location.id,
      requestId: getStorage(eStorage.requestId),
      selectedValues: selectedValues,
      simpleSchedule: simpleSchedule,
      domainValues: domainValues
    }

    postSmartFingerprint(model).then(data => {
      props.functions.setFingerprint(data);
      Notify.success(t('general.successfulUpdate'), { position: 'center-bottom', });
      getLocations();
      handleNextStep();
  }).catch (console.log)
      .finally(() => props.functions.setIsLoading(false));
}

const handleSubmitGenius = () => {
  props.functions.setIsLoading(true);

  let model = {
    locationId: location.id,
    requestId: getStorage(eStorage.requestId),
    selectedValues: selectedValues,
    simpleSchedule: simpleSchedule,
    domainValues: domainValues
  }

  postGeniusFingerprint(model).then(data => {
    props.functions.setFingerprint(data);
    Notify.success(t('general.successfulUpdate'), { position: 'center-bottom', });

    let location = getStorageJSON(eStorage.location);
    props.functions.setIsLoading(true);
    getChartsData(location.id).then(data => {
      props.functions.setIsLoading(false)
      navigate(ePage.dashboard);
    }).catch(console.log);

  }).catch(console.log)
    .finally(() => props.functions.setIsLoading(false))
}

const handleChangeLocation = (locationId) =>
  locationDefault(locationId).then(data => {
    Notify.success(t('locations.change-location'), { position: 'center-bottom', });
    props.functions.setIsLoading(false);
  });


return (
  <div className="content">

    <div className="row mt-2 mb-2 justify-content-md-center">
      <div className="col-md-4">
        <FormControl size='small' fullWidth>
          <InputLabel id="label-change">{t('fingerprint.location-select')}</InputLabel>
          <Select className="text-start" fullWidth size="small" name="locationId" value={location.id} onChange={handleChange} label={t('fingerprint.location-select')}>
            {locations.map((location) => (
              <MenuItem key={location.id} value={location.id}>{location.name} - {location.address}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>

    {activeStep === 0 && <Schedule handleChangeAvg={handleChangeAvg} handleChangesSelected={handleChangesSelected} handleChangesSchedule={handleChangesSchedule} selectedValues={selectedValues} simpleSchedule={simpleSchedule} functions={props.functions} setActiveStep={props.setActiveStep} />}
    {activeStep === 1 && <Home handleChangesDomain={handleChangesDomain} handleChangesSelected={handleChangesSelected} domainValues={domainValues} selectedValues={selectedValues} functions={props.functions} setActiveStep={setActiveStep} />}
    {activeStep === 2 && <Characteristics handleChangesDomain={handleChangesDomain} handleChangesSelected={handleChangesSelected} domainValues={domainValues} selectedValues={selectedValues} functions={props.functions} setActiveStep={setActiveStep} />}

    <div className="row mt-3">
      <div className="col-md-12">
        {activeStep === 0 && <Button className='btn-primary' onClick={handleSubmitSmart}>{t('general.next')}</Button>}
        {activeStep === 1 && <Button className='btn-primary' onClick={handleNextStep}>{t('general.next')}</Button>}
        {activeStep === 2 && <Button className='btn-primary' onClick={handleSubmitGenius}>{t('general.next')}</Button>}
      </div>
    </div>

    <Stack className="mt-4 mb-3" sx={{ width: '100%' }}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnectorCustom />}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon} onClick={() => setActiveStep(index)}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  </div>
)
}

export default FingerprintCommercial;