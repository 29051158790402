// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.react-rater-star {
  font-size: 40px;
  line-height: 0px;
}

.react-rater-star.is-active {
  color: #DF3FAF !important;
}

.react-rater-star.will-be-active {
  color: #f196d6 !important;
}

.home-size-error {
  display: block;
  position: absolute;
  margin-top: 80px !important;
  font-weight: 400;
  font-size: 0.75rem;
}

html {
  overflow-x: hidden;
}

.number-energy-left{
  text-align: center;
    padding-left: 15px;
  display: block;
}

.legend-energy-left {
  text-align: end;
  display: block;
}


.number-energy-right{
  text-align: center;
  padding-right: 10px;
  display: block;
}

.legend-energy-right {
  text-align: center;
  display: block;

}

.dashboard-percentaje {
  color: #00ACE9 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 80px !important;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;;AAGA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,2BAA2B;EAC3B,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;IAChB,kBAAkB;EACpB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;;AAGA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,cAAc;;AAEhB;;AAEA;EACE,yBAAyB;EACzB,uDAAuD;EACvD,0BAA0B;EAC1B,kBAAkB;AACpB","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.react-rater-star {\n  font-size: 40px;\n  line-height: 0px;\n}\n\n.react-rater-star.is-active {\n  color: #DF3FAF !important;\n}\n\n.react-rater-star.will-be-active {\n  color: #f196d6 !important;\n}\n\n.home-size-error {\n  display: block;\n  position: absolute;\n  margin-top: 80px !important;\n  font-weight: 400;\n  font-size: 0.75rem;\n}\n\nhtml {\n  overflow-x: hidden;\n}\n\n.number-energy-left{\n  text-align: center;\n    padding-left: 15px;\n  display: block;\n}\n\n.legend-energy-left {\n  text-align: end;\n  display: block;\n}\n\n\n.number-energy-right{\n  text-align: center;\n  padding-right: 10px;\n  display: block;\n}\n\n.legend-energy-right {\n  text-align: center;\n  display: block;\n\n}\n\n.dashboard-percentaje {\n  color: #00ACE9 !important;\n  font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n  font-size: 80px !important;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
