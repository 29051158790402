
import { eStorage, getStorage } from "../../Services/StorageService";
import { dataServiceApiInstance } from "../../Util/Api";

export const eInsights = {
    montly: '',
    weekly: 'weekly',
    seasonal: 'seasonal',
}

export const getInsightsData = (type, viewType, customerId) => {
    let language = getStorage(eStorage.language);
    const response = dataServiceApiInstance.get(`/fingerprint/insights${type === eInsights.montly ? '' : '/' + type}/${customerId}?lang=${language}&view=${viewType}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getEnergyIntensityData = (customerId) => {
    const response = dataServiceApiInstance.get(`actionplan/energy_intensity?customerId=${customerId}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getDayNightIndexesData = (viewType, customerId) => {
    let language = getStorage(eStorage.language);
    const response = dataServiceApiInstance.get(`/fingerprint/dayNightIndexes/${customerId}?lang=${language}&view=${viewType}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}

export const getWeekWeekendIndexesData = (viewType, customerId) => {
    let language = getStorage(eStorage.language);
    const response = dataServiceApiInstance.get(`/fingerprint/weekWeekendIndexes/${customerId}?lang=${language}&view=${viewType}`)
        .then(({ data }) => {
            return data;
        });
    return response;
}