import { Routes, Route } from 'react-router-dom';
import Dashboard from '../../Structure/Dashboard/Dashboard';
import ElectricInfo from '../../Structure/ElectricInfo/ElectricInfo';
import Login from '../../Structure/Login/Login';
import Recover from '../../Structure/Recover/Recover';
import ElectricityBill from '../../Structure/ElectricityBill/ElectricityBill';
import Error404 from '../../Structure/Error/404';
import PrivateRoute from './PrivateRoute';
import Creating from '../../Structure/Agreement/Creating';
import Accept from '../../Structure/Agreement/Accept';
import Downloading from '../../Structure/Downloading/Downloading';
import ResetPassword from './../../Structure/ResetPassword/ResetPassword';
import Profile from '../../Structure/Profile/Profile';
import Badges from '../../Structure/Badges/Badges';
import CloseAccount from './../../Structure/Profile/CloseAccount';
import SMTRedirect from '../../Structure/Helper/SMTRedirect';
import VerifySettings from '../../Structure/Agreement/VerifySettings';
import SelectRecommendation from '../../Structure/SelectRecommendation/SelectRecommendation';
import RecoverSMS from '../../Structure/Recover/RecoverSMS';
import ValidateCode from '../../Structure/Recover/ValidateCode';
import AddLocation from '../../Structure/Locations/AddLocation';
import DashboardCommercial from '../../Structure/Commercial/Dashboard/DashboardCommercial';
import ElectricalServiceRequest from '../../Structure/ElectricalServiceRequest/ElectricalServiceRequest';
import Locations from '../../Structure/Locations/Locations';
import Users from '../../Structure/Commercial/Users/Users';
import AddUser from '../../Structure/Commercial/Users/AddUser';
import FingerprintCommercial from '../../Structure/Commercial/Fingerprint/FingerprintCommercial';
import EditGoals from '../../Structure/Goals/EditGoals';
import Home from '../../Structure/Home/Home';
import Challenge from '../../Structure/Challenges/Challenge';
import Inputs from '../../Structure/Inputs/Inputs';
import Gamificator from '../../Structure/Gamificator/Gamificator';
import Browser from '../../Structure/Browser/Browser';
import Notifications from '../../Structure/Notifications/Notifications';
import Shop from '../../Structure/Shop/Shop';
import ProductDetail from '../../Structure/Shop/ProductDetail';
import SelectShop from '../../Structure/Shop/SelecShop';
import Pulse from '../../Structure/Integrations/Pulse/Pulse';
import Texpo from '../../Structure/Integrations/Texpo/Texpo';
import Payless from '../../Structure/Integrations/Payless/Payless';
import Chariot from '../../Structure/Integrations/Chariot/Chariot';
import LoneStar from '../../Structure/Integrations/LoneStar/LoneStar';
import EnergyToGo from '../../Structure/Integrations/EnergyToGo/EnergyToGo';
import PowerNext from '../../Structure/Integrations/PowerNext/PowerNext';
import NewPowerTexas from '../../Structure/Integrations/NewPoweTexas/NewPowerTexas';
import Flagship from '../../Structure/Integrations/Flagship/Flagship'; 
import Constellation from '../../Structure/Integrations/Constellation/Constellation';
import Rhythm from '../../Structure/Integrations/Rhythm/Rhythm';
import Goodcharlie from '../../Structure/Integrations/GoodCharlie/GoodCharlie';
import Recommend from '../../Structure/Recommend/Recommend';
import Checkout from '../../Structure/Shop/Checkout';
import AddDevice from '../../Structure/Device/AddDevice';
import Order from '../../Structure/Profile/Order';
import Triggers from '../../Structure/Device/Triggers';
import ThermostatDevice from '../../Structure/Device/ThermostatDevice';
import ConnectDeviceResideo from '../../Structure/Device/ConnectDeviceResideo';
import ConnectDeviceNest from '../../Structure/Device/ConnectDeviceNest';
import Logout from '../../Structure/Logout/Logout';
import Suscribe from '../../Structure/Challenges/Suscribe';

export const ePage = {
    login: '/login',
    logout: '/logout',
    selectPath: '/select-path',
    dashboard: '/dashboard',
    acceptAgreement: '/accept-agreement',
    creatingAgreement: '/creating-agreement',
    recommendations: '/recommendations',
    smartFingerprintData: '/smart-fingerprint-data',
    geniusFingerprintData: '/genius-fingerprint-data',
    smartFingerprint: '/smart-fingerprint',
    geniusFingerprint: '/genius-fingerprint',
    electricInfo: '/electric-info',
    downloading: '/downloading',
    quickMatch: '/new-home-characteristics',
    recommend: '/recommend',
    basicFingerprint: '/basic-fingerprint',
    homeEnergyScore: '/energy-score',
    matchingPreferences: '/matching-preferences',
    browsePublic: '/browser/public',
    browse: '/browser',
    closeAccount: '/close-account',
    profile: '/profile',
    electricityBill: '/electricity-bill',
    selectShop: '/select-shop',
    selectRecommendation: '/select-recommendation',
    validateCode: '/validate-code',
    PhoneNumber: 'phone-number',
    subscription: '/subscription',
    addLocation: "/add-location",
    serviceRequest: "/electrical-service-request",
    locations: '/locations',
    addUser: '/add-user',
    users: '/users',
    editGoals: '/edit-goals',
    home: '/home',
    myUsage: '/my-usage',
    inputs: '/inputs',
    shop:'/shop',
    devices:'/devices',
    addDevice:'/add-device',
    connectDevice:'/connect-device'
}

function AppRoutes(props) {
    return (
        <Routes>
            <Route path="/" element={<Login functions={props} />} />
            <Route path="/login" element={<Login functions={props} />} />
            <Route path="/logout" element={<Logout functions={props}  />}/>
            <Route path="/suscribe" element={<Suscribe functions={props}  />}/>
            <Route path="/smt-redirect" element={<SMTRedirect functions={props} />} />
            <Route path="/recover" element={<Recover functions={props} />} />
            <Route path="/recover-sms" element={<RecoverSMS functions={props} />} />
            <Route path="/validate-code" element={<ValidateCode functions={props} />} />
            <Route path="/electric-info" element={<ElectricInfo functions={props} />} />
            <Route exact path="/resetpassword/:otu" element={<ResetPassword functions={props} />} />
            <Route element={<PrivateRoute />}>
                <Route path="/challenge/:id/:customerChallengeId?" element={<Challenge functions={props} />} />
                <Route path="/select-recommendation" element={<SelectRecommendation functions={props} />} />
                <Route path="/my-usage" element={<Dashboard functions={props} />} />
                <Route path="/dashboard" element={<DashboardCommercial functions={props} />} />
                <Route path="/fingerprint-data" element={<FingerprintCommercial functions={props} />} />
                <Route path="/electricity-bill" element={<ElectricityBill functions={props} />} />
                <Route path="/creating-agreement" element={<Creating functions={props} />} />
                <Route path="/accept-agreement" element={<Accept functions={props} />} />
                <Route path="/verify-settings" element={<VerifySettings functions={props} />} />
                <Route path="/downloading" element={<Downloading functions={props} />} />
                <Route path="/profile" element={<Profile functions={props} />} />
                <Route path="/badges" element={<Badges functions={props} />} />
                <Route path="/browser" element={<Browser functions={props} />} />
                <Route path='/close-account' element={<CloseAccount functions={props} />} />
                <Route path="/locations" element={<Locations functions={props} />} />
                <Route path="/add-location" element={<AddLocation functions={props} />} />
                <Route path="/users" element={<Users functions={props} />} />
                <Route path="/add-user" element={<AddUser functions={props} />} />
                <Route path="/edit-goals" element={<EditGoals functions={props} />} />
                <Route path="/electrical-service-request" element={<ElectricalServiceRequest {...props} />} />
                <Route path="/home" element={<Home functions={props} />} />
                <Route path="/inputs" element={<Inputs functions={props} />} />
                <Route path="/win-prize" element={<Gamificator functions={props} />} />
                <Route path="/notifications" element={<Notifications functions={props} />} />
                <Route path="/shop" element={<Shop functions={props} />} /> 
                <Route path="/select-shop" element={<SelectShop functions={props} />} />
                <Route path="/product-detail/:id" element={<ProductDetail functions={props} />} />
                <Route path="/browser" element={<Browser functions={props} />} />
                <Route path="/order-pulse" element={<Pulse functions={props} />} />
                <Route path="/order-energytogo" element={<EnergyToGo functions={props} />} />
                <Route path="/order-lonestar" element={<LoneStar functions={props} />} />
                <Route path="/order-texpo" element={<Texpo functions={props} />} />
                <Route path="/order-powernext" element={<PowerNext functions={props} />} />
                <Route path="/order-newpowertx" element={<NewPowerTexas functions={props} />} />
                <Route path="/order-payless" element={<Payless functions={props} />} />
                <Route path="/order-chariot" element={<Chariot functions={props} />} />
                <Route path="/order-constellation" element={<Constellation functions={props} />} />
                <Route path="/order-flagship" element={<Flagship functions={props} />} />
                <Route path="/order-rhythm" element={<Rhythm functions={props} />} />
                <Route path="/order-goodcharlie" element={<Goodcharlie functions={props} />} />
                <Route path="/recommend" element={<Recommend functions={props} />} />
                <Route path="/checkout" element={<Checkout functions={props} />} />
                <Route path="/add-device" element={<AddDevice functions={props} />} />
                <Route path="/connect-device-nest" element={<ConnectDeviceNest functions={props} />} />
                <Route path="/connect-device-resideo" element={<ConnectDeviceResideo functions={props} />} />
                <Route path="/order" element={<Order functions={props} />} />
                <Route path="/trigger-device/:id" element={<Triggers functions={props} />} />
                <Route path="/device/thermostat/:id/:type" element={<ThermostatDevice functions={props} />} />
            </Route>
            <Route path="*" element={<Error404 functions={props} />} />
        </Routes>
    )
}

export default AppRoutes;