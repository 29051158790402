/* eslint-disable react-hooks/exhaustive-deps */
import { React, useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Switch } from '@mui/material';
import { getStorageJSON } from '../../Services/StorageService';
import { eStorage } from './../../Services/StorageService';
import { getCommPref } from '../../Components/ProfileBase/ProfileBase';
import { updateCommPref } from './../../Components/ProfileBase/ProfileBase';

function Communications() {
    const { t } = useTranslation();
    const [notifications, setNotifications] = useState({
        emailNotification: true,
        textNotification: true,
        messageNotification: true,
        dataReadyNotification: true,
        alertsNotification: true,
        tipsNotification: true,
        remindersNotification: true,
        requestsNotification: true,
    });
    const [commPref, setCommPref] = useState({
        alerts: "yes",
        messages: "yes",
        reminders: "yes",
        requests: "yes",
        tips: "yes"
    });
    const [user] = useState(getStorageJSON(eStorage.user));


    useEffect(() => {
        getCommPref(user.customerId)
            .then(data => {
                if (data) {
                    setNotifications({
                        emailNotification: data.email === 'yes',
                        textNotification: data.text === 'yes',
                        messageNotification: data.messages === 'yes',
                        dataReadyNotification: data.dateready === 'yes',
                        alertsNotification: data.alerts === 'yes',
                        tipsNotification: data.tips === 'yes',
                        remindersNotification: data.reminders === 'yes',
                        requestsNotification: data.requests === 'yes',
                    });
                }
            });
    }, []);

    const handleChangeSwitch = (event) => {
        const { name, checked, value } = event.target;
        setNotifications(prevState => ({
            ...prevState, [name]: checked
        }));
        const model = commPref;

        model[value] = checked ? 'yes' : 'no';
        setCommPref(model);
        Promise.resolve(updateCommPref(model, user.customerId));
    }

    return (
        <div>
            <div className="row justify-content-md-center">
                <div className="col-md-10">
                    <h2>{t('profile.notifications')}</h2>
                </div>
            </div>
            <hr />
            <div className="row mt-2 justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-start'><strong>{t('profile.email')}</strong></h3>
                </div>
                <div className="col-md-5">

                </div>
            </div>
            <div className="row mt-2 justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-start'>{t('profile.insights&alerts')}</h3>
                </div>
                <div className="col-md-5">
                    <Switch name='alertsNotification' value="alerts" onChange={handleChangeSwitch} checked={notifications.alertsNotification} />
                </div>
            </div>
            <div className="row mt-2 justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-start'>{t('profile.newsletter')}</h3>
                </div>
                <div className="col-md-5">
                    <Switch name='tipsNotification' value="tips" onChange={handleChangeSwitch} checked={notifications.tipsNotification} />
                </div>
            </div>
            <div className="row mt-2 justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-start'>{t('profile.remainders')}</h3>
                </div>
                <div className="col-md-5">
                    <Switch name='remindersNotification' value="reminders" onChange={handleChangeSwitch} checked={notifications.remindersNotification} />
                </div>
            </div>
            <div className="row mt-2 justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-start'>{t('profile.referrals')}</h3>
                </div>
                <div className="col-md-5">
                    <Switch name='requestsNotification' value="requests" onChange={handleChangeSwitch} checked={notifications.requestsNotification} />
                </div>
            </div>
            <hr />
            <div className="row justify-content-md-center">
                <div className="col-md-8">
                    <h2>{t('profile.who')}</h2>
                </div>
            </div>
            <hr />
            <div className="row mt-2 justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-start'><strong>{t('profile.mesg')}</strong></h3>
                </div>
                <div className="col-md-5">

                </div>
            </div>
            <div className="row mt-2 justify-content-md-center">
                <div className="col-md-5">
                    <h3 className='text-start'>{t('profile.allow')}</h3>
                </div>
                <div className="col-md-5">
                    <Switch name='messageNotification' value="messages" onChange={handleChangeSwitch} checked={notifications.messageNotification} />
                </div>
            </div>
        </div>
    )
}

export default Communications;