/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import { MenuItem, Select, InputLabel, FormControl, TextField } from '@mui/material';
import homeImage from '../../../Assets/Icons/home.png';
import homeSizeImage from '../../../Assets/Icons/home-size.png';
import heaterImage from '../../../Assets/Icons/heater.png';
import waterHeaterImage from '../../../Assets/Icons/water-heater.png';
import acImage from '../../../Assets/Icons/ac.png';
import heaterAgeImage from '../../../Assets/Icons/water-heaterage.png';
import { getYears } from "../../../Util/Util";
import { getFuelHeatingData, getHvacData, getWaterAgeData, getWaterHeatingData } from "../../../Components/GeniusFingerprintBase/GeniusFingerprintBase";
import { eStorage, getStorage } from "../../../Services/StorageService";

function Home(props) {
    const { t } = useTranslation();
    const [fuelHeating, setFuelHeating] = useState([]);
    const [waterHeating, setWaterHeating] = useState([]);
    const [waterAge, setWaterAge] = useState([]);
    const [hvac, setHvac] = useState([]);
    const [language] = useState(getStorage(eStorage.language));

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        getFuelHeatingData().then(data => {
            setFuelHeating(data);
        });

        getWaterHeatingData().then(data => {
            setWaterHeating(data);
        });

        getHvacData().then(data => {
            setHvac(data);
        });

        getWaterAgeData().then(data => {
            setWaterAge(data);
        });
    };

    return (
        <div className='container'>
            <div className='row justify-content-md-center'>
                <div className="col-sm-12 col-md-10 col-lg-7">
                    <div className="container-card card-content">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4">
                                        <img className="icon-savings" src={homeImage} alt="" />
                                    </div>
                                    <div className="col-9 col-md-8 mt-2 d-flex align-items-center justify-content-center">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="year-built">{t('genius-fingerprint-data.building-age')}</InputLabel>
                                            <Select labelId="year-built" name="yearBuilt" value={props.selectedValues.yearBuilt} onChange={props.handleChangesSelected} label={t('genius-fingerprint-data.home-age')}>
                                                {getYears().map((item, index) => (
                                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4 mt-2">
                                        <img alt="" className="icon-savings" src={homeSizeImage} />
                                    </div>
                                    <div className="col-9 col-md-8 mt-2 d-flex align-items-center justify-content-center">
                                        <TextField type={'number'} fullWidth size="small" name="homeSize" onChange={props.handleChangesSelected} value={props.selectedValues.homeSize} label={t('genius-fingerprint-data.building-size')} />
                                        {props.selectedValues.homeSize <= 0 || props.selectedValues.homeSize == undefined &&
                                            < p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained home-size-error">{t('genius-fingerprint.home-size-error')}</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4">
                                        <img alt="" className="icon-savings" src={heaterImage} />
                                    </div>
                                    <div className="col-9 col-md-8 mt-2 d-flex align-items-center justify-content-center">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="heating-system">{t('genius-fingerprint-data.heating-system')}</InputLabel>
                                            <Select labelId="heating-system" name="domainKeyFuel_heating" value={props.domainValues.domainKeyFuel_heating} onChange={props.handleChangesDomain} label={t('genius-fingerprint-data.heating-system')}>
                                                {fuelHeating.map((item, index) => (
                                                    <MenuItem key={index} value={item.key}>{item.values[language]}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4 mt-2">
                                        <img alt="" className="icon-savings" src={waterHeaterImage} />
                                    </div>
                                    <div className="col-9 col-md-8 mt-2 d-flex align-items-center justify-content-center">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="water-heating">{t('genius-fingerprint-data.water-heating')}</InputLabel>
                                            <Select labelId="water-heating" name="domainKeyWater_heating" value={props.domainValues.domainKeyWater_heating} onChange={props.handleChangesDomain} label={t('genius-fingerprint-data.water-heating')}>
                                                {waterHeating.map((item, index) => (
                                                    <MenuItem key={index} value={item.key}>{item.values[language]}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4">
                                        <img alt="" className="icon-savings" src={acImage} />
                                    </div>
                                    <div className="col-9 col-md-8 d-flex align-items-center justify-content-center">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="air-conditioning">{t('genius-fingerprint-data.air-conditioning')}</InputLabel>
                                            <Select labelId="air-conditioning" name='domainKeyHome_HVAC' value={props.domainValues.domainKeyHome_HVAC} onChange={props.handleChangesDomain} label={t('genius-fingerprint-data.air-conditioning')}>
                                                {hvac.map((item, index) => (
                                                    <MenuItem key={index} value={item.key}>{item.values[language]}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-3 col-md-4 mt-2">
                                        <img alt="" className="icon-savings" src={heaterAgeImage} />
                                    </div>
                                    <div className="col-9 col-md-8 mt-2 d-flex align-items-center justify-content-center">
                                        <FormControl size="small" fullWidth>
                                            <InputLabel id="water-heater">{t('genius-fingerprint-data.water-heater')}</InputLabel>
                                            <Select labelId="water-heater" name='domainKeyHome_WaterHeaterAge' value={props.domainValues.domainKeyHome_WaterHeaterAge} onChange={props.handleChangesDomain} label={t('genius-fingerprint-data.water-heater')}>
                                                <MenuItem value={'Less than 5'}>{t('general.less-than')}</MenuItem>
                                                <MenuItem value={'5 to 10 years'}>{t('general.5-to-10')}</MenuItem>
                                                <MenuItem value={'More than 10'}>{t('general.more-than')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Home;