/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Button, InputAdornment, IconButton } from '@mui/material';
import LogoPrincipal from '../../Assets/logo-enerwisely.png';
import { Link, useNavigate } from 'react-router-dom';
import { React, useEffect, useState, useRef } from 'react';
import { getIp } from "../../Util/Util";
import { getCustomersAddress, loginAccountType, postLogin } from "../../Components/AuthBase/AuthBase";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { eStorage, getStorage, getStorageJSON, saveStorage } from "../../Services/StorageService";
import { ePage } from "../../Components/Shared/Routes";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import i18n from '../../Util/i18n';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { eDevice } from "../../Components/Shared/Enum/EDevice";
import { eAccountType } from "../../Components/Shared/Enum/EAccountType";
import ModalOutage from "../../Components/Shared/ModalOutage";

function Login(props) {
    const { t } = useTranslation();
    const formRef = useRef();
    const [model, setModel] = useState({ email: '', password: '' });
    const navigate = useNavigate();
    const [password, setPassword] = useState("password");
    const [modalOutage, setModalOutage] = useState({ open: false });

    const handleLoginVaidate = () => {
        loginAccountType(model.email).then(data => {

            if (model.email == 'patricia@quantumnewenergy.com' || model.email == 'patricia.vega@global.t-bird.edu' || model.email == 'svredondo@gmail.com' || model.email == 'jana@plasmonics.tech' || model.email == 'okcaljo@me.com' || model.email == 'artblanco88@gmail.com' || model.email == 'ido.acs12@gmail.com' || model.email == 'salvadorayalaresideo@gmail.com') {
                if (data.residential) {
                    saveStorage(eStorage.accountType, eAccountType.residential);
                    handleSubmit(eAccountType.residential);
                }
                else if (data.commercial) {
                    saveStorage(eStorage.accountType, eAccountType.commercial);
                    handleSubmit(eAccountType.commercial);
                }
                else
                    Notify.failure(t('general.login-error'), { position: 'center-bottom', });

            } else {
                console.log('setmodaloutage');
                setModalOutage({ open: true });
            }
        })
    }

    const handleSubmit = (accountType) => {
        props.functions.setIsLoading(true);
        model.site = 'MatchMaker';
        model.email = model.email.toLowerCase();

        postLogin(model).then(data => {
            if (data?.status === 'failed')
                Notify.failure(t('general.login-error'), { position: 'center-bottom', });
            else {
                props.functions.setAccountType(accountType);
                i18n.changeLanguage(data.language);
                props.functions.setLanguage(data.language);
                Notify.success(t('general.login-success'), { position: 'center-bottom', });
                props.functions.setIsSession(true);
                const user = getStorageJSON(eStorage.user);
                saveStorage(eStorage.attemptRefresh, 0);
                props.functions.setUser(user);
                if (user.agreementStatus === 'Non Active - Time Out' || user.agreementStatus === 'Non Active - Expired' || user.agreementStatus === 'No Agreements Found' ||
                    user.agreementStatus === 'Active - Expiring in 15 days or less' || user.agreementStatus === 'Active - Expiring in 30 days or less') {
                    props.functions.setModalAgreementOpen(true);
                } else if (user.agreementStatus === 'Non Active - Moved Out')
                    props.functions.setModalMoveOutOpen(true);

                props.functions.handleLogin();
                navigate(data.page, { replace: true });
            }
        }).finally(() => props.functions.setIsLoading(false));
    }

    useEffect(() => {
        props.functions.setTitle(t('general.login'));
        getIp().then(ip => model.ipAddress = ip);
        checkSession();
    }, []);



    const checkSession = () => {
        let session = getStorage(eStorage.isSession);
        if (session)
            navigate(ePage.home);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setModel(prevState => ({
            ...prevState, [name]: value
        }));
        if (name === 'email')
            props.functions.setEmail(value);
    }

    const handleClickShowPassword = () => {
        setPassword(password === 'password' ? 'text' : 'password');
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <ValidatorForm ref={formRef} className='content' onSubmit={handleLoginVaidate} onError={errors => console.log(errors)}>
            <div className='container'>
                <ModalOutage modal={modalOutage} setModal={setModalOutage} />
                <div className='row justify-content-md-center mb-1'>
                    <div className='col-md-8 col-lg-6 container-card card-content text-center'>
                        <img className="logo-login" src={LogoPrincipal} alt="logo" />
                        <div className="row mt-4 justify-content-md-center">
                            <div className="col-md-8">
                                <TextValidator fullWidth size="small" name="email" value={model.email} onChange={handleChange} label={t('general.email')} validators={['required', 'isEmail']} errorMessages={[t('general.required').replace('#field#', t('general.email')), t('general.is-email').replace('#field#', t('general.email'))]} />
                            </div>
                        </div>
                        <div className="row mt-3 justify-content-md-center">
                            <div className="col-md-8">
                                <TextValidator type={password} fullWidth size="small" name="password" value={model.password} onChange={handleChange} label={t('general.password')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.password'))]} />
                                <InputAdornment className="password-eye d-flex justify-content-end" position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {password === 'password' ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <Button className='btn-primary' type="submit">
                                    {t('general.login')}
                                </Button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Button className='btn-basic mt-2' onClick={()=> setModalOutage({ open: true })}>{t('general.forgot-password')}</Button>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12">
                                <Button className='btn-secondary' variant="outlined" onClick={()=> setModalOutage({ open: true })}>
                                    {t('general.join-now')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ValidatorForm>
    )
}

export default Login;