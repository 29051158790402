/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Stack, Stepper, Step, StepLabel } from '@mui/material';
import QontoStepIcon from "../../Components/Shared/Stepper";
import { React, useEffect, useState } from 'react';
import UserInfo from "./UserInfo";
import StepConnectorCustom from "../../Components/Shared/StepConnector";
import AddressInfo from "./AddressInfo";
import MeterInfo from "./MeterInfo";
import { eStorage, getStorage, getStorageJSON } from "../../Services/StorageService";
import ModalEsiid from "../../Components/Shared/ModalEsiid";
import { useSearchParams } from "react-router-dom";
import ModalOutage from "../../Components/Shared/ModalOutage";
import { eSite } from "../../Components/Shared/Enum/ESite";
import { eAccountType } from "./../../Components/Shared/Enum/EAccountType";
import Goal from './../Locations/Goal';
import { getGoalsByCustomer, getGoalsByLocation, upsertGoals } from "../../Components/goalBase/goalBase";


function ElectricInfo(props) {
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const [model, setModel] = useState({});
    const [isSession, setIsSession] = useState(false);
    const [modal, setModal] = useState({ open: false });
    const [modalOutage, setModalOutage] = useState({ open: false });
    const [searchParams, setSearchParams] = useSearchParams();
    const [steps, setSteps] = useState(['User Info', 'Address Info', 'Meter Info']);
    const [accountType, setAccountType] = useState(getStorage(eStorage.accountType));
    const [activeGoals, setActiveGoals] = useState(true);
    const [goals, setGoals] = useState({
        carbon: 5,
        energy: 5,
        bill: 5
    });

    useEffect(() => {
        props.functions.setTitle(t('general.create-account'));
        let isSession = getStorage(eStorage.isSession);
        setIsSession(isSession);
        if (isSession) {
            setActiveStep(1);
        }
    }, []);

    useEffect(() => {
        let urlSite = searchParams.get("site");
        if (!urlSite)
            searchParams.set('site', eSite.fingerprint);

        searchParams.set('page', activeStep + 1);
        setSearchParams(searchParams);

        let accountTypeStorage = getStorage(eStorage.accountType);
        if (!accountType) {
            setAccountType(accountTypeStorage);
        }
        if (activeStep === 1 && accountTypeStorage && accountTypeStorage === eAccountType.residential && activeGoals) {
            setSteps(['User Info', 'Address Info', 'Energy Goal', 'Carbon Goal', 'Cost Goal', 'Meter Info']);
            switch (activeStep) {
                case 0:
                    props.functions.setTitle(t('general.create-account'));
                    break;
                case 1:
                    props.functions.setTitle(t('general.your-service-address'));
                    break;
                case 5:
                    props.functions.setTitle(t('general.fetch-data'));
                    break;
                default: break
            }
        } else {
            switch (activeStep) {
                case 0:
                    props.functions.setTitle(t('general.create-account'));
                    break;
                case 1:
                    props.functions.setTitle(t('general.your-service-address'));
                    break;
                case 2:
                    props.functions.setTitle('SET YOUR GOALS');
                    break;
                case 3:
                    props.functions.setTitle('SET YOUR GOALS');
                    break;
                case 4:
                    props.functions.setTitle('SET YOUR GOALS');
                    break;
                case 5:
                    props.functions.setTitle(t('general.fetch-data'));
                    break;
                default: break
            }
        }
    }, [activeStep]);

    const handleChange = (value) => {
        let type = '';
        switch (activeStep) {
            case 2: type = 'energy'; break;
            case 3: type = 'carbon'; break;
            case 4: type = 'bill'; break;
        }
        setGoals(prevState => ({
            ...prevState, [type]: value
        }));
    }

    const handleSubmitGoal = (event) => {
        if (event && event?.type === 'bill') {
            handleChange(event.value);
            const newGoals = goals;
            goals['bill'] = event.value;
            sendGoalsToApi(newGoals);
        } else {
            setActiveStep(activeStep + 1);
        }
    }

    const sendGoalsToApi = (goals) => {
        const user = getStorageJSON(eStorage.user);
        const model = Object.keys(goals).map(key => {
            return {
                id: null,
                locationId: null,
                percentage: goals[key],
                customerId: user.customerId,
                status: true,
                type: key.toUpperCase(),
            }
        });

        props.functions.setIsLoading(true);
        upsertGoals(model).then(data => {
            setActiveStep(activeStep + 1);
        }).catch(console.log).finally(() => props.functions.setIsLoading(false))
    }

    return (
        <div className="content">
            <ModalOutage modal={modalOutage} setModal={setModalOutage} />
            <ModalEsiid modal={modal} setModal={setModal} />
            {activeStep === 0 && <UserInfo model={model} setModel={setModel} functions={props.functions} setActiveStep={setActiveStep} />}
            {activeStep === 1 && <AddressInfo setModal={setModal} model={model} setModel={setModel} functions={props.functions} setActiveStep={setActiveStep} />}

            {activeStep === 2 && accountType === eAccountType.residential && activeGoals && <><div className="content"><Goal skipCondition={true} type='energy' goalValue={goals['energy']} handleChange={handleChange} functions={props.functions} setActiveStep={setActiveStep} activeStep={activeStep} handleFullSubmit={handleSubmitGoal} /></div></>}
            {activeStep === 3 && accountType === eAccountType.residential && activeGoals && <Goal skipCondition={true} type='carbon' goalValue={goals['carbon']} handleChange={handleChange} functions={props.functions} setActiveStep={setActiveStep} activeStep={activeStep} handleFullSubmit={handleSubmitGoal} />}
            {activeStep === 4 && accountType === eAccountType.residential && activeGoals && <Goal skipCondition={true} type='bill' goalValue={goals['bill']} handleChange={handleChange} functions={props.functions} setActiveStep={setActiveStep} activeStep={activeStep} handleFullSubmit={handleSubmitGoal} />}

            {((activeStep === 2 && accountType === eAccountType.commercial) || (activeStep === 5 && accountType === eAccountType.residential)) && <MeterInfo setModal={setModal} model={model} setModel={setModel} functions={props.functions} setActiveStep={setActiveStep} />}
            <Stack className="mt-4" sx={{ width: '100%' }}>
                <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnectorCustom />}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Stack>
        </div>
    )
}

export default ElectricInfo;