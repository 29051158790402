import { React, useState, forwardRef } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { useTranslation } from "react-i18next";
import Matching from '../MatchingPreferences/Matching';
import { useEffect } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalMatchingCriteria(props) {
    const { t } = useTranslation();

    const [model, setModel] = useState({
        cost: 100,
        carbon: 20,
        rating: 20
    });

    useEffect(()=>{

    }, [model])

    const handleClose = () => {
        props.setModalOpen(false);
    };

    const handleSubmit = () => {
        props.handleChange(model);
        props.setModalOpen(false);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setModel(prevState => ({
            ...prevState, [name]: value
        }));
    }

    return (
        <Dialog open={props.open} TransitionComponent={Transition} fullWidth={true} maxWidth={'sm'} keepMounted onClose={handleClose}>
            <DialogTitle className='text-center'>{t('general.matching-criteria')}</DialogTitle>
            <hr className='m-0' />
            <DialogContent>
            <div className='row mt-2 justify-content-md-center'>
                    <div className='col-md-12'>
                        <Matching functions={props.functions} handleChange={handleChange} setModel={setModel} model={model} />
                    </div>
                </div>
            </DialogContent>
            <hr className='mb-0 mt-2' />
            <DialogActions className='justify-content-md-center'>
                <Button className='btn-secondary' onClick={handleClose}>{t('general.close')}</Button>
                <Button className='btn-primary' onClick={handleSubmit}>{t('general.find-best-plan')}</Button>
            </DialogActions>
        </Dialog>
    );
}
