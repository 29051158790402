import { MenuItem, Menu, Divider } from '@mui/material';
import { useState, useEffect } from 'react';
import { eStorage, getStorageJSON } from '../../Services/StorageService';
import {  getNewNotifications } from '../NotificationBase/NotificationBase';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

function Notification(props) {
    const [notifications, setNotifications] = useState([]);
    const [user] = useState(getStorageJSON(eStorage.user));
    const navigate = useNavigate();

    useEffect(() => {

        getNewNotifications(user.customerId).then(data => {
            setNotifications(data);
            props.setNewNotifications(data.filter(w => w.status === 'new')?.length)
        });

    }, []);

    const handleViewNotifications = () => {
        navigate('/notifications');
    }

    return (
        <Menu
            anchorEl={props.anchorNotification}
            id="account-notification"
            open={props.openNotification}
            onClose={props.handleCloseNotification}
            onClick={props.handleCloseNotification}
            PaperProps={{
                elevation: 0,
                sx: {
                    width: '400px',
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {notifications.length > 0 && notifications.map((value, index) => {
                return (
                    <MenuItem key={index}>
                        {value.text}
                    </MenuItem>
                )
            })
            }
            < Divider />
            <h3 className="click-here text-center" onClick={handleViewNotifications}><strong>See all notifications</strong></h3>
        </Menu>
    )
}

export default Notification;