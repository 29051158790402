import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import { useTranslation } from "react-i18next";
import { React, useEffect } from 'react';
import { getState } from "../../Util/Util";
import { TextValidator } from 'react-material-ui-form-validator';

function Address(props) {
    const { t } = useTranslation();
    const { ready, value, suggestions: { status, data }, setValue, clearSuggestions, } = usePlacesAutocomplete({
        requestOptions: {
            types: ["address"],
            componentRestrictions: { country: 'us' }
        },
        debounce: 300,
    });

    useEffect(() => {
    }, [props.validations]);

    const ref = useOnclickOutside(() => {
        clearSuggestions();
    });

    const handleInput = (e) => {
        setValue(e.target.value);
    };

    const handleSelect =
        ({ description }) =>
            () => {
                setValue(description, false);
                clearSuggestions();
                getGeocode({ address: description }).then((results) => {

                    let address = buildAddress(results[0].address_components);
                    address.value = results[0].formatted_address;
                    address.latitude = results[0].geometry.location.lat();
                    address.longitude = results[0].geometry.location.lng();
                    props.setAddress(address);
                });
            };

    const buildAddress = (component) => {
        console.log('buildAddress', component);
        let address = {};

        for (var i = 0; i < component.length; i++) {
            switch (component[i].types[0]) {
                case 'street_number':
                    address.streetNum = component[i].long_name;
                    break;
                case 'route':
                    address.route = component[i].short_name;
                    break;
                case 'locality':
                    address.city = component[i].long_name;
                    break;
                case 'administrative_area_level_1':
                    address.state = component[i].long_name;
                    break;
                case 'postal_code':
                    address.zipCode = component[i].long_name;
                    break;
                default: break;
            }
        }
        console.log(address);
        return {
            address: address.streetNum + ' ' + address.route,
            city: address.city,
            state: address.state,
            zipCode: address.zipCode
        }
    }

    const renderSuggestions = () =>
        data.map((suggestion) => {
            const { place_id, structured_formatting: { main_text, secondary_text }, } = suggestion;

            return (
                <li key={place_id} onClick={handleSelect(suggestion)}>
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </li>
            );
        });

    const handleChange = (event) => {
        const { name, value } = event.target;
        props.setAddress(prevState => ({
            ...prevState, [name]: value
        }));
    }

    return (
        <div ref={ref}>
            {!props.showAllAddress && <TextValidator autoComplete="nope" fullWidth size="small" value={value} onChange={handleInput} disabled={!ready} label={t('general.enter-adress')}
                validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.enter-adress'))]} />}
            {status === "OK" && <ul className="list-places">{renderSuggestions()}</ul>}

            {props.showAllAddress && <div>
                <div className="row">
                    <div className="col-md-12">
                        <FormControl size="small" fullWidth>
                            <InputLabel id="type-housing">{t('general.type-housing')}</InputLabel>
                            <Select labelId="type-housing" name="houseType" required value={props.address.houseType} defaultValue={props.address.houseType} onChange={handleChange} label={t('general.type-housing')} disabled={props.disabled}>
                                <MenuItem value='house'>{t('general.house')}</MenuItem>
                                <MenuItem value='townhouse'>{t('general.town-house')}</MenuItem>
                                <MenuItem value='apto'>{t('general.apto')}</MenuItem>
                                <MenuItem value='mobile'>{t('general.mobile-home')}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                </div>
                <div className="row mt-2">
                    <div className="col-md-12">
                        <TextValidator autoComplete="nope" fullWidth size="small" name="address" value={props.address.address} onChange={handleChange} label={t('general.address')} validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.address'))]} disabled={props.disabled} />
                    </div>
                </div>
                {(props.address.houseType === 'apto' || props.address.houseType === 'townhouse') &&
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <TextValidator autoComplete="nope" fullWidth size="small" name="apto" value={props.address.apto} onChange={handleChange} label={t('general.apto')}
                                validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.apto'))]} disabled={props.disabled} />
                        </div>
                    </div>
                }
                <div className="row mt-2">
                    <div className="col-md-12">
                        <TextValidator autoComplete="nope" fullWidth size="small" name="city" value={props.address.city} onChange={handleChange} label={t('general.city')}
                            validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.city'))]} disabled={props.disabled} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-12">
                        <TextValidator autoComplete="nope" fullWidth size="small" name="zipCode" value={props.address.zipCode} onChange={handleChange} label={t('general.zip-code')}
                            validators={['required']} errorMessages={[t('general.required').replace('#field#', t('general.zip-code'))]} disabled={props.disabled} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-12">
                        <FormControl size="small" fullWidth>
                            <InputLabel id="state">{t('general.state')}</InputLabel>
                            <Select labelId="state" name="state" required value={props.address.state} onChange={handleChange} label={t('general.state')} disabled={props.disabled}>
                                {getState().map((item, index) => (
                                    <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default Address;