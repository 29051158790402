/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { Button } from '@mui/material';
import LogoPrincipal from '../../Assets/logo-enerwisely.png';
import { Link } from 'react-router-dom';
import { React, useEffect, useState, useRef } from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { getRecover, getRecoverByPhone, validateCode } from "../../Components/AuthBase/AuthBase";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { ePage } from "../../Components/Shared/Routes";
import { useNavigate } from "react-router-dom";
import ReactInputVerificationCode from 'react-input-verification-code';
import Countdown, { zeroPad } from 'react-countdown';
import { eStorage, getStorage } from "../../Services/StorageService";

function ValidateCode(props) {
    const { t } = useTranslation();
    const formRef = useRef();
    const navigate = useNavigate();
    const [code, setCode] = useState('');
    const [enableResend, setEnableResend] = useState(false);

    useEffect(() => {
        props.functions.setTitle(t('general.password-recover'));
    }, []);

    const handleChange = (event) => {
        setCode(event);
    }

    const handleSubmit = () => {
        if (code.length === 6 && !code.includes('·')) {
            props.functions.setIsLoading(true);
            validateCode(code).then(data => {
                console.log(data);
                if (data) {
                    navigate('/resetpassword/' + code);
                }else{
                    Notify.failure(t('general.sms-sent-invalid'), { position: 'center-bottom' });
                }

            }).catch(console.log).finally(() => props.functions.setIsLoading(false));
        }
    }

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            setEnableResend(true);
        } else {
            setEnableResend(false);
            return <p className="small-legend">{zeroPad(minutes)}:{zeroPad(seconds)}</p>;
        }
    };

    const resendCode = () => {
        props.functions.setIsLoading(true);
        let phone = getStorage(eStorage.phoneRecover);
        getRecoverByPhone(phone).then(data => {
            if (data) {
                Notify.success(t('general.sms-sent'), { position: 'center-bottom' });
            }

        }).catch(console.log)
        .finally(() => props.functions.setIsLoading(false));
    }

    return (
        <ValidatorForm ref={formRef} className='content' onSubmit={handleSubmit} onError={errors => console.log(errors)}>
            <div className='container'>
                <div className='row justify-content-md-center  mb-1'>
                    <div className='col-sm-12 col-md-8 col-lg-6 container-card card-content text-center'>
                        <img className="logo-login" src={LogoPrincipal} alt="logo" />
                        <div className="row mt-4 justify-content-md-center">
                            <div className="col-12">
                                <h3>{t('general.enter-code')}</h3>
                            </div>
                        </div>
                        <div className="row mt-4 justify-content-md-center">
                            <div className="col-12">
                                <ReactInputVerificationCode onChange={handleChange} length={6} />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <Button className='btn-primary' type="submit">
                                    {t('general.confirm')}
                                </Button>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12">
                                <Button className='btn-basic mt-2' onClick={resendCode} disabled={!enableResend} >{t('general.resend-code')}</Button>
                                {!enableResend &&
                                    <Countdown date={Date.now() + 120000} renderer={renderer}> </Countdown>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <Button className='btn-basic mt-2' component={Link} to="/login">{t('general.have-account')}</Button>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-12">
                                <Button className='btn-secondary' variant="outlined" component={Link} to="/select-path">
                                    {t('general.join-now')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ValidatorForm>
    )
}

export default ValidateCode;