/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-computed-key */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";
import { React, useEffect, useState } from 'react';
import Header from "../Header";
import { eStorage, getStorage, getStorageJSON } from "../../../Services/StorageService";
import { Stack, Step, StepLabel, Stepper } from "@mui/material";
import QontoStepIcon from "../../../Components/Shared/Stepper";
import StepConnectorCustom from "../../../Components/Shared/StepConnector";
import AddressInfo from "../AddressInfo";
import UserInfo from "../UserInfo";
import ServiceInfo from "../ServiceInfo";
import OrderInfo from "../OrderInfo";
import { getSwitchTypes } from "../../../Components/IntegrationBase/ConstellationBase";
import ResponseInfo from "../ResponseInfo";

function Constellation(props) {
    const { t } = useTranslation();
    const [planSelected] = useState(getStorageJSON(eStorage.planSelected));
    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Address Info', 'Service Info', 'User Info', 'Order Confirm'];
    const [address, setAddress] = useState(getStorageJSON(eStorage.address));
    const [billingAddress, setBillingAddress] = useState({ sameAddress: true });
    const [response, setResponse] = useState({ });
    const [service, setService] = useState({
        requestType: 'switching'
    });
    const [typeRequest, setTypeRequest] = useState([]);
    const [user, setUser] = useState(getStorageJSON(eStorage.user));
    const [commentaries, setCommentaries] = useState([]);

    const agreements = [
        { description: t('constellation.agreement-user-one'), required: true, name: 'userOne' },
        { description: t('constellation.agreement-user-two'), required: true, name: 'userTwo' }
    ];

    useEffect(() => {
        props.functions.setTitle(t('constellation.title'));
        if (!user.hasOwnProperty('birthdate')) {
            let date = new Date();
            date.setFullYear(date.getFullYear() - 18);
            setUser(prevState => ({
                ...prevState, ['birthdate']: date
            }));
        }
        if (!user.hasOwnProperty('ssn'))
            setUser(prevState => ({
                ...prevState, ['ssn']: ''
            }));
    }, []);

    useEffect(() => {
        if (activeStep === 1) {
            props.functions.setIsLoading(true);
            getSwitchTypes().then(data => {
                if (data.IsSuccess) {
                    let types = data.SwitchTypes.map((type, index) => {
                        return {
                            value: index,
                            name: type.SwitchTypeSubCode !== null ? type.SwitchTypeSubCode : type.SwitchTypeCode
                        }
                    });
                    setService(prevState => ({
                        ...prevState, ['requestType']: [0]
                    }));
                    setTypeRequest(types);
                    setCommentaries(data.SwitchTypes.map((type, index) => {
                        return {
                            id: index,
                            description: type.SwitchDescription
                        }
                    }));
                    props.functions.setIsLoading(false);
                }
            });
        }
    }, [activeStep]);

    const handleSubmit = () => {
        let requestId = getStorage(eStorage.requestId);

        let addressOrder = {
            address: address.address,
            city: address.city,
            state: address.state,
            zipCode: address.zipCode,
        };

        let userOrder = {
            name: user.name,
            lastName: user.lastName,
            phone: user.phone,
            birthdate: user.birthdate,
            ssn: user.ssn,
            language: user.language,
        }

        let typeRequestOrder = {
            requestType: service.requestType,
            serviceDate: service.serviceDate
        };

        const addressBillOrder = {
            sameAsClientAddress: billingAddress.sameAddress,
            addressBill: billingAddress.sameAddress ? address.address : billingAddress.address,
            cityBill: billingAddress.sameAddress ? address.city : billingAddress.city,
            zipCodeBill: billingAddress.sameAddress ? address.zipCode : billingAddress.zipCode,
            stateBill: billingAddress.sameAddress ? address.state : billingAddress.state,
            unitBill: billingAddress.sameAddress ? address.apt : billingAddress.apt,
        };

        let order = {
            customerId: user.customerId,
            requestId: requestId,
            planId: planSelected.uid,
            esiid: user.esiid,
            serviceArea: address.serviceArea,
            orderDetails: {
                clientAddress: addressOrder,
                userinfo: userOrder,
                identityVerification: userOrder,
                request: typeRequestOrder,
                billingAddress: addressBillOrder
            }
        }
    }

    return (
        <div className='content'>
            <div className='container'>
                <div className='row justify-content-md-center'>
                    <div className='col-sm-12 col-md-12 col-lg-10'>
                        <Header functions={props.functions} planSelected={planSelected} />
                    </div>
                </div>
                <div className='row mt-3 justify-content-md-center'>
                    <div className='col-sm-12 col-md-12 col-lg-10'>
                        <div className="container-card card-content">
                            {Object.keys(response).length === 0 &&
                                <div>
                                    {activeStep === 0 &&
                                        <AddressInfo functions={props.functions} address={address} setAddress={setAddress} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                    {activeStep === 1 &&
                                        <ServiceInfo typeRequest={typeRequest} commentaries={commentaries} service={service} setService={setService} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                    {activeStep === 2 &&
                                        <UserInfo user={user} setUser={setUser} agreements={agreements} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                    {activeStep === 3 &&
                                        <OrderInfo handleSubmit={handleSubmit} address={address} setAddress={setAddress} billingAddress={billingAddress} setBillingAddress={setBillingAddress} user={user} setUser={setUser} service={service} setService={setService} activeStep={activeStep} setActiveStep={setActiveStep} />}
                                </div>
                            }
                            {Object.keys(response).length > 0 &&
                                <ResponseInfo response={response} />
                            }
                        </div>
                    </div>
                </div>
                {Object.keys(response).length === 0 &&
                    <Stack className="mt-4 mb-4" sx={{ width: '100%' }}>
                        <Stepper alternativeLabel activeStep={activeStep} connector={<StepConnectorCustom />}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Stack>
                }
            </div>
        </div>
    )
}

export default Constellation;