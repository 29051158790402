/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import "bootstrap/dist/css/bootstrap.min.css";
import { React, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { getProductData, postOrder } from "../../Components/ShopBase/ShopBase";
import { useParams } from "react-router-dom";
import { Button } from '@mui/material';
import errorImage from "../../Assets/no-image.jpg";
import { getParams } from "../../Util/Util";
import { eStorage, getStorageJSON, saveStorageJSON } from "../../Services/StorageService";
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { useNavigate } from 'react-router-dom';
import StarRatings from "react-star-ratings";
import { Link } from 'react-router-dom';

function ProductDetail(props) {
    const { t } = useTranslation();
    const { id } = useParams();
    const [product, setProduct] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        getProductData(id).then(data => {
            setProduct(data);
        }).catch(console.log);
    }

    const handleOrder = (id, amount) => {
        let customer = getStorageJSON(eStorage.user);
        let model = {
            customerId: customer.customerId,
            productId: id,
            amount: amount
        }

        postOrder(model).then(data => {
            saveStorageJSON(eStorage.checkout, product);
            Notify.success('Your order has been successfully registered, we will contact you shortly.', { position: 'center-bottom', });
            navigate('/checkout');
        }).catch(console.log);
    }

    return (
        <div className='content'>
            <div className='container'>
                <div className="row d-flex justify-content-center">
                    <div className="col-md-10">
                        <div className="container-card card-content">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="images p-3">
                                        <div className="text-center p-4"> <img id="main-image" src={`${getParams().DATA_SERVICE_BASEURL}product/image/${product?.picture}`} onError={(e) => { e.target.src = errorImage }} width="250" /> </div>
                                        <div className="thumbnail text-center">
                                            <img onclick="change_image(this)" src={errorImage} width="70" />
                                            <img onclick="change_image(this)" src={errorImage} width="70" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="product p-4">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <Button className='btn-tertiary-s' component={Link} to={'/shop'}>Back</Button>
                                        </div>
                                        <div className="mt-4 mb-3 text-start">
                                            <h2 className="text-start title-section mb-0">{product.name}</h2>
                                            <StarRatings
                                                rating={4}
                                                starDimension='20px'
                                                starSpacing='0px'
                                                starRatedColor="#00ACE9"
                                                //  changeRating={this.changeRating}
                                                numberOfStars={5}
                                                name='rating'
                                            />
                                            <div className="price d-flex flex-row align-items-center mt-3">
                                                <span className="product-price">${product.cost?.toFixed(2)}
                                                </span>
                                            </div>
                                        </div>
                                        <p className="description-text text-start">{product.description}</p>

                                        <div className="row text-start">
                                            <div className="col-md-12">
                                                <Button className='btn-primary mt-4 mt-md-0 ' onClick={() => handleOrder(product.id, product.amount)} >
                                                    Order
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductDetail;