/* eslint-disable react-hooks/exhaustive-deps */

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { React, useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { Button, FormControl, InputLabel, MenuItem, Select, Checkbox } from '@mui/material';
import { postUser } from '../../../Components/UserBase/UserBase';
import { getLocationsData } from '../../../Components/LocationBase/LocationBase';
import { eStorage, getStorage, getStorageJSON } from '../../../Services/StorageService';
import { useNavigate } from 'react-router-dom';
import { ePage } from '../../../Components/Shared/Routes';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import ListItemText from '@mui/material/ListItemText';
import { isMailAvailable } from './../../../Components/ElectricInfoBase/ElectricInfoBase';

function AddUser(props) {
    const [model, setModel] = useState({ name: '', lastname: '', email: '', clientIp: '', uniqueVisitorId: '', preferredLang: '', role: 'user', locations:[] });
    const formRef = useRef();
    const { t } = useTranslation();
    const [locations, setLocations] = useState([]);
    const [company] = useState(getStorageJSON(eStorage.company));
    const navigate = useNavigate();

    useEffect(() => {
        props.functions.setTitle(t('users.add-user'));

        getLocationsData(company.id).then(data => {
            setLocations(data);
        });
    }, []);

    const handleSubmit = () => {
        props.functions.setIsLoading(true);
        let modelRequest = Object.assign({}, model);
        modelRequest.companyId = company.id;
        modelRequest.isCommercial = true;
        modelRequest.sitePath = getStorage(eStorage.site);
        delete modelRequest.locations;

        isMailAvailable(model.email).then(data => {
            if (data) {
                register(modelRequest);
            } else {
                Notify.failure(t('general.email-used'), { position: 'center-bottom', });
            }
        }).catch(() => {
            Notify.failure(t('general.email-used'), { position: 'center-bottom', });
            props.functions.setIsLoading(false);
        });
    }

    const register = (modelRequest)=>{
        postUser(modelRequest).then(data => {
            Notify.success('User has been added successfully', { position: 'center-bottom', });
            navigate(ePage.users);
        }).catch(console.log).finally(() => props.functions.setIsLoading(false));
    }

    const handleChange = (event) => {
        const { name, value, checked } = event.target;
        setModel(prevState => ({
            ...prevState, [name]: value.trim()
        }));
    }

    const handleChangeMultiple = (event) => {
        const { name, value} = event.target;
        if (Array.isArray(value)) {
            const selected = value.filter(f => f!= undefined);
            setModel(prevState => ({
                ...prevState, [name]: selected
            }));
        }
    }


    return (
        <div className='content'>
            <ValidatorForm ref={formRef} className='container' onSubmit={handleSubmit} onError={errors => console.log(errors)}>
                <div className='row justify-content-md-center'>
                    <div className='col-sm-12 col-md-10 col-lg-7 container-card card-content text-center'>
                        <div className="row mt-2 justify-content-md-center">
                            <div className="col-md-5">
                                <TextValidator autoComplete="nope" fullWidth size="small" name="name" value={model.name} onChange={handleChange} label={model.isCommercial ? t('general.legal-name') : t('general.name')} validators={['required']} errorMessages={[t('general.required').replace('#field#', model.isCommercial ? t('general.legal-name') : t('general.name'))]} />
                            </div>
                            <div className="col-md-5">
                                <TextValidator autoComplete="nope" fullWidth size="small" name="lastname" value={model.lastname} onChange={handleChange} label={model.isCommercial ? t('general.legal-last-name') : t('general.last-name')} validators={['required']} errorMessages={[t('general.required').replace('#field#', model.isCommercial ? t('general.legal-last-name') : t('general.last-name'))]} />
                            </div>
                        </div>
                        <div className="row mt-2 justify-content-md-center">
                            <div className="col-md-10">
                                <TextValidator autoComplete="nope" fullWidth size="small" name="email" value={model.email} onChange={handleChange} label={t('general.email')} validators={['required', 'isEmail']} errorMessages={[t('general.required').replace('#field#', t('general.email')), t('general.is-email').replace('#field#', t('general.email'))]} />
                            </div>
                        </div>
                        <div className='row mt-2 justify-content-md-center'>
                            <div className="col-md-5">
                                <FormControl size="small" fullWidth>
                                    <InputLabel id="role">Role</InputLabel>
                                    <Select labelId="role" name="role" value={model.role} defaultValue={model.role} onChange={handleChange} label='Role'>
                                        <MenuItem value='admin'>Admin</MenuItem>
                                        <MenuItem value='user'>User</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="col-md-5">
                                <FormControl size="small" fullWidth>
                                    <InputLabel id="locations">Locations</InputLabel>
                                    <Select labelId="locations" name="locations" value={model.locations} multiple onChange={handleChangeMultiple} label='Locations'
                                        renderValue={(selected) => locations.filter(f => selected.indexOf(f.id) > -1).map(m => (m.name)).join(',')}>
                                        {locations.map((item, index) =>
                                            <MenuItem key={index} value={item.id}>
                                                <Checkbox checked={model.locations.indexOf(item.id) > -1} />
                                                <ListItemText primary={item.name} />
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <hr />
                        <div className="row mt-2">
                            <div className="col-md-12">
                                <Button className='btn-primary' type="submit">
                                    Create
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </ValidatorForm >
        </div>
    )
}

export default AddUser;